import { LayerProperties } from 'objects/types/common'
import { AnyPaint } from 'mapbox-gl'

const layout: mapboxgl.AnyLayout = {
  'text-font': ['Open Sans Regular'],
  'text-size': 18,
  'text-allow-overlap': false,
  'text-field': [
    'format',
    ['get', 'label'],
    { 'font-scale': 1 },
    '\n',
    {},
    ['get', 'kpSNCF'],
    { 'font-scale': 0.75 },
  ],
  'text-pitch-alignment': 'viewport',
  'text-variable-anchor': ['center', 'left', 'right', 'top', 'bottom', 'top-left',
    'top-right', 'bottom-left', 'bottom-right'],
}

const paint: AnyPaint = {
  'text-color': 'black',
  'text-halo-width': 2,
  'text-halo-color': 'rgba(255,255,255,0.9)',
}

const stationLayerProps: LayerProperties = {
  label: {
    type: 'symbol',
    minzoom: 11,
    layout,
    paint,
  },
}

export default stationLayerProps
