import { DateTime } from 'luxon'
import { Attribute, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { MidiObject } from 'objects/types'
import {
  S9MGPTTypeMap, TrackProtectionType, TrackProtectionTypeMap, WorkTrainsTypeMap,
} from 'objects/types/protections/const'

const TRACK_PROTECTION_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'zepType',
    path: 'zepType',
    label: 'Attributes.TrackProtection.type',
    parser: Parser.trackProtectionType,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: TrackProtectionTypeMap,
    creation: {
      required: true,
      defaultValue: TrackProtectionType.LINE,
    },
  },
  {
    key: 'geographicArea',
    path: 'geographicArea',
    label: 'Attributes.Protection.geographicZone',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'technology',
    path: 'technology',
    label: 'Attributes.TrackProtection.technology',
    parser: Parser.mgpt,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: S9MGPTTypeMap,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'workTrainsAllowed',
    path: 'workTrainsAllowed',
    label: 'Attributes.TrackProtection.workTrains',
    parser: Parser.workTrains,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: WorkTrainsTypeMap,
    creation: {
      required: true,
      defaultValue: 'false',
    },
  },
  {
    key: 'securityTimeForAgreement',
    path: 'securityTimeForAgreement',
    label: 'Attributes.TrackProtection.securityTimeForAgreement',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'securityTimeForRestitution',
    path: 'securityTimeForRestitution',
    label: 'Attributes.TrackProtection.securityTimeForRestitution',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'comment',
    path: 'comment',
    label: 'Attributes.common.comment',
    parser: Parser.identity,
    type: InputTypes.TextArea,
    isEditable: true,
    required: false,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default TRACK_PROTECTION_ATTRIBUTES
