import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'

import { RootState, store } from 'Store'
import { FeedbackState, setError, setMessage } from 'reducers/feedback'
import { MapState } from 'reducers/map'
import { INVALID_GEOM_TYPES } from '../../Map/utils'
import './FeedbackSnackBar.scss'
import { DEFAULT_AUTOCLOSE_DURATION, Severity, handleFeedback } from './utils'

export default function FeedbackSnackBar(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { centeredFeature, captureClick } = useSelector((state: RootState): MapState => state.map)
  const { feedback, message } = useSelector((state: RootState) => state.feedback as FeedbackState)
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState<Severity>(Severity.error)
  const [autoCloseDuration, setAutoCloseDuration] = useState<number | null>(DEFAULT_AUTOCLOSE_DURATION)
  const hasInit = useRef(false)

  useEffect(() => {
    if (hasInit.current === false) {
      hasInit.current = true
      return
    }

    if (feedback) {
      handleFeedback(feedback, setOpen, setSeverity, setAutoCloseDuration)
      return
    }
    if (centeredFeature
      && ((centeredFeature.geometry && INVALID_GEOM_TYPES.includes(centeredFeature.geometry.type))
      || centeredFeature?.geometry === undefined)) {
      setOpen(true)
      setSeverity(Severity.warning)
      dispatch(setMessage('Map.Error.noGeometry'))
    }
  }, [feedback, centeredFeature])

  useEffect(() => {
    if (captureClick) {
      setOpen(true)
      setAutoCloseDuration(null)
      setSeverity(Severity.info)
    }
  }, [captureClick])

  const onClose = () => {
    setOpen(false)
    dispatch(setMessage(''))
    setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
    store.dispatch(setError())
  }

  return (
    <Snackbar
      id="map-feedback-snackbar"
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={autoCloseDuration}
    >
      <Alert severity={severity} variant="filled">
        {t(message)}
      </Alert>
    </Snackbar>
  )
}
