import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'

import { LayerProps } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl, supervisorFilter } from 'objects/utils'
import trackNodesProps from './TrackNodesLayout'

const sourceLayer = ObjectLayer.TrackNode

export default function TrackNodesLayer({ visible }: LayerProps): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        {...trackNodesProps.node}
        id={`${sourceLayer}-layer`}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        source-layer={sourceLayer}
        filter={supervisorFilter()}
      />
      <Layer
        {...trackNodesProps.label}
        id={`${sourceLayer}-layer-label`}
        layout={{
          ...trackNodesProps.label.layout,
          visibility: visible ? 'visible' : 'none',
        }}
        source-layer={sourceLayer}
        filter={supervisorFilter()}
      />
    </Source>
  )
}
