import { get } from 'lodash'

import { store } from 'Store'
import { LAYER_NAME } from 'objects/attributes'
import { MidiObject } from 'objects/types'
import { ObjectKind, ObjectLayer, OrphanExtremityType } from 'objects/types/const'
import { GeoEditorState, enableGeoEditor } from 'reducers/geoEditor'
import { MapState } from 'reducers/map'
import { CreationPanelState, setNewObjectField } from 'reducers/panels/creationPanel'
import { setModificationItemField } from 'reducers/panels/modificationPanel'
import { PanelName } from 'reducers/panels/panel'
import { FieldValue } from 'reducers/types'

import { getActivePanel } from 'helpers'
import { fetchIsolatorDetails } from 'objects/Isolators/IsolatorServices'
import { fetchSignalDetails } from 'objects/Signals/SignalServices'
import { LAYER_TO_KIND, findObjectKind } from 'objects/kind'
import { S11ObjectLayers } from 'objects/types/instructions'
import { ElectricalElement, TrackProtection } from 'objects/types/protections'
import getClickedObjectDetails from '../getObjectDetails'
import { MapLayerObject } from '../utils'
import {
  cleanCaptureClick, formatPath, getSelectedCaptureClickParam, getSelectedMergeObject,
  onCaptureS11Object, onCaptureSectorObject, onCaptureSubSectorObject, onCaptureTrackProtection,
} from './helpers'

const onFeatureClick = async (e: MapLayerObject): Promise<void> => {
  const { captureClick, captureClickIndex, mergeCaptureClick } = store.getState().map as MapState
  const { newObjectKind } = store.getState().creationPanel as CreationPanelState
  const { item } = store.getState().detailsPanel
  const { active: geoEditorActive } = store.getState().TIVEditor as GeoEditorState

  if (geoEditorActive) return

  if (e) {
    const object: MidiObject = e.properties
    const layer: ObjectLayer = e.sourceLayer

    if (mergeCaptureClick) {
      const itemKind = findObjectKind(item)
      const clickedKind = LAYER_TO_KIND[layer]
      if (clickedKind === itemKind && item.id !== object.id) {
        getSelectedMergeObject(object, clickedKind, item)
      }
      return
    }

    if (captureClick !== undefined) {
      if (layer === ObjectLayer.TrackProtection) {
        onCaptureTrackProtection(object as TrackProtection)
      } else if (S11ObjectLayers.includes(layer)) {
        const itemKind = findObjectKind(item)

        if (itemKind === ObjectKind.Sector) {
          onCaptureSectorObject(object as ElectricalElement)
          return
        }

        if (itemKind === ObjectKind.SubSector) {
          onCaptureSubSectorObject(object as ElectricalElement)
          return
        }

        onCaptureS11Object(object as ElectricalElement)
      } else {
        let usedObject = object

        if (layer === ObjectLayer.Signal) {
          usedObject = await fetchSignalDetails(object.id)
        } else if (layer === ObjectLayer.Isolator) {
          usedObject = await fetchIsolatorDetails(object.id)
        }

        // Handle if there is an array of params
        const params = getSelectedCaptureClickParam(captureClick, layer)
        if (!params) {
          return
        }
        const { updateParams } = params
        // Choose the right update function, either in Modification or Creation panel
        const updateFunction = getActivePanel() === PanelName.modification
          ? setModificationItemField
          : setNewObjectField

        // For each param, update the value inside the object
        updateParams.forEach(p => {
          let value: FieldValue = p.clickedObjectPath !== undefined ? get(usedObject, p.clickedObjectPath) : usedObject
          if (p.clickedObjectPath === 'extremityType') {
            value = layer === ObjectLayer.Signal ? OrphanExtremityType.SIGNAL : OrphanExtremityType.JOINT
          }
          // Store the layer value if the param asks for it
          if (p.clickedObjectPath === LAYER_NAME) value = layer
          const formattedPath = formatPath(captureClickIndex, p.path)
          store.dispatch(updateFunction({ path: formattedPath, value }))
        })

        cleanCaptureClick()

        // Show geoEditor again if Track Section creation
        if (newObjectKind === ObjectKind.TrackSection) store.dispatch(enableGeoEditor())
      }
    } else if (getActivePanel() !== PanelName.modification && getActivePanel() !== PanelName.creation) {
      getClickedObjectDetails(object, layer)
    }
  }
}

export default onFeatureClick
