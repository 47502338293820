import { store } from 'Store'
import { DateTime } from 'luxon'
import ElectricalStationServices from 'objects/ElectricalStation/ElectricalStationServices'
import { Attribute, INDEX_PARAM_PLACEHOLDER, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { MidiObject } from 'objects/types'
import { KP } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { BooleanTypeMap, FGPTTypeMap } from 'objects/types/protections/const'
import { formatIdentityChoice } from 'objects/utils'

const NEW_EMPTY_KP: KP = {
  trackSection: '',
  trackCode: '',
  value: '',
}
const FEEDER_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'locations',
    path: 'locations',
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    isArray: true,
    groupTitle: 'Attributes.Signal.positions',
    elements: [
      {
        key: 'trackSection',
        path: ['trackSection', 'track', 'name'],
        label: 'Attributes.common.track',
        parser: Parser.identity,
        errorPath: 'locations',
        type: InputTypes.CaptureClick,
        isEditable: true,
        required: true,
        creation: {
          required: true,
          defaultValue: '',
        },
        captureClickHelper: 'Modification.helperText.trackCaptureClick',
        captureClickParams: {
          updateParams: [
            {
              clickedObjectPath: 'trackName',
              path: ['locations', INDEX_PARAM_PLACEHOLDER, 'trackSection', 'track', 'name'],
            },
            {
              clickedObjectPath: 'id',
              path: ['locations', INDEX_PARAM_PLACEHOLDER, 'trackSection', 'id'],
            },
          ],
          objectLayer: ObjectLayer.TrackSection,
          displayedValueFormatter: formatIdentityChoice,
        },
      },
      {
        key: 'value',
        path: 'value',
        errorPath: 'locations',
        updatePath: ['locations', INDEX_PARAM_PLACEHOLDER, 'value'],
        label: 'Attributes.common.kp',
        parser: Parser.identity,
      },
    ],
    defaultElement: NEW_EMPTY_KP,
    required: true,
    creation: {
      required: true,
      defaultValue: [NEW_EMPTY_KP],
    },
  },
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'upmre',
    path: 'upmre',
    label: 'Attributes.Protection.upmre',
    parser: Parser.identity,
    type: InputTypes.Numeric,
    removeIfNull: true,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'technology',
    path: 'technology',
    label: 'Attributes.TrackProtection.technology',
    parser: Parser.mgpt,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: FGPTTypeMap,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'canBeTakenAlone',
    path: 'canBeTakenAlone',
    label: 'Attributes.ElectricalElement.canBeTakenAlone',
    parser: Parser.boolean,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: BooleanTypeMap,
    creation: {
      required: true,
      defaultValue: false,
    },
  },
  {
    key: 'electricalStation',
    path: 'electricalStation',
    label: 'Attributes.ElectricalElement.electricalStation',
    parser: Parser.electricalStationParser,
    isEditable: true,
    removeIfNull: true,
    required: true,
    type: InputTypes.Select,
    values: store.getState().instruction.electricalStations,
    creation: {
      required: true,
      defaultValue: null,
    },
    dependency: {
      path: 'zoe',
      source: ElectricalStationServices.getZoes,
      key: ['electricalStation', 'id'],
    },
  },
  {
    key: 'zoe',
    path: 'zoe',
    label: 'Attributes.ElectricalElement.zoe',
    parser: Parser.zoeParser,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    removeIfNull: true,
    values: store.getState().instruction.zoes,
    creation: {
      required: true,
      defaultValue: null,
    },
    shouldBeDisabled: {
      path: ['electricalStation'],
      values: [null, ''],
    },
  },
  {
    key: 'securityTimeForAgreement',
    path: 'securityTimeForAgreement',
    label: 'Attributes.TrackProtection.securityTimeForAgreement',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'securityTimeForRestitution',
    path: 'securityTimeForRestitution',
    label: 'Attributes.TrackProtection.securityTimeForRestitution',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'comment',
    path: 'comment',
    label: 'Attributes.common.comment',
    parser: Parser.identity,
    type: InputTypes.TextArea,
    isEditable: true,
    required: false,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'sector',
    path: 'sector',
    hideIfEmpty: true,
    label: 'Attributes.ElectricalElement.sector',
    parser: Parser.sector,
  },
  {
    key: 'subSector',
    path: 'subSector',
    hideIfEmpty: true,
    label: 'Attributes.ElectricalElement.subSector',
    parser: Parser.sector,
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default FEEDER_ATTRIBUTES
