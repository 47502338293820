/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TrackProtection, TrackProtectionGroup } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'

export const addTrackProtection = createAsyncThunk<TrackProtectionGroup, TrackProtection, ThunkApiConfig>(
  'trackProtectionGroup/addTrackProtection',
  async (trackProtectionToAdd, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as TrackProtectionGroup

    const updatedTrackProtectionGroup = {
      id,
      checksum,
      composition: [...composition.map(comp => ({ id: comp.id })), {
        id: trackProtectionToAdd.id,
      }],
    }

    try {
      const response: TrackProtectionGroup = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${updatedTrackProtectionGroup.id}`,
        updatedTrackProtectionGroup, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteTrackProtection = createAsyncThunk<TrackProtectionGroup, string, ThunkApiConfig>(
  'trackProtectionGroup/deleteTrackProtection',
  async (deletedTpId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as TrackProtectionGroup

    const updatedTrackProtectionGroup: Partial<TrackProtectionGroup> = {
      id,
      checksum,
      composition: composition.flatMap(comp => (comp.id !== deletedTpId ? ({ id: comp.id }) as TrackProtection : [])),
    }

    try {
      const response: TrackProtectionGroup = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${updatedTrackProtectionGroup.id}`,
        updatedTrackProtectionGroup, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
