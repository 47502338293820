import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

import { isAnyOf } from '@reduxjs/toolkit'
import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'

export const revertFulfilledMatcher = isAnyOf(
  ElectricalElementServices.revert.fulfilled,
  ElectricalProtectionGroupServices.revert.fulfilled,
  FeederServices.revert.fulfilled,
  IsolatorServices.revert.fulfilled,
  SectorServices.revert.fulfilled,
  SignalServices.revert.fulfilled,
  SubSectorServices.revert.fulfilled,
  TrackProtectionGroupServices.revert.fulfilled,
  TrackProtectionServices.revert.fulfilled,
  TrackSectionServices.revert.fulfilled,
  TrackNodeServices.revert.fulfilled,
  TunnelServices.revert.fulfilled,
)

export const revertPendingMatcher = isAnyOf(
  ElectricalElementServices.revert.pending,
  ElectricalProtectionGroupServices.revert.pending,
  FeederServices.revert.pending,
  IsolatorServices.revert.pending,
  SectorServices.revert.pending,
  SignalServices.revert.pending,
  SubSectorServices.revert.pending,
  TrackProtectionGroupServices.revert.pending,
  TrackProtectionServices.revert.pending,
  TrackSectionServices.revert.pending,
  TrackNodeServices.revert.pending,
  TunnelServices.revert.pending,
)

export const revertErrorMatcher = isAnyOf(
  ElectricalElementServices.revert.rejected,
  ElectricalProtectionGroupServices.revert.rejected,
  FeederServices.revert.rejected,
  IsolatorServices.revert.rejected,
  SectorServices.revert.rejected,
  SignalServices.revert.rejected,
  SubSectorServices.revert.rejected,
  TrackProtectionGroupServices.revert.rejected,
  TrackProtectionServices.revert.rejected,
  TrackSectionServices.revert.rejected,
  TrackNodeServices.revert.rejected,
  TunnelServices.revert.rejected,
)
