import { DateTime } from 'luxon'
import { Attribute, INDEX_PARAM_PLACEHOLDER, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { KP } from 'objects/types/common'
import { DirectionTypeMap, ObjectLayer } from 'objects/types/const'
import { formatIdentityChoice } from 'objects/utils'
import { MidiObject } from 'objects/types'
import { ALL_SIGNAL_TYPES } from './SignalTypes'

const NEW_EMPTY_KP: KP = {
  trackSection: '',
  trackCode: '',
  value: '',
}

const SIGNAL_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'kilometricPoints',
    path: 'kilometricPoints',
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    isArray: true,
    groupTitle: 'Attributes.Signal.positions',
    elements: [
      {
        key: 'trackSection',
        path: ['trackSection', 'track', 'name'],
        errorPath: 'kilometricPoints',
        label: 'Attributes.common.track',
        parser: Parser.identity,
        type: InputTypes.CaptureClick,
        isEditable: true,
        required: true,
        creation: {
          required: true,
          defaultValue: '',
        },
        captureClickHelper: 'Modification.helperText.trackCaptureClick',
        captureClickParams: {
          updateParams: [
            {
              clickedObjectPath: 'trackName',
              path: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'trackSection', 'track', 'name'],
            },
            {
              clickedObjectPath: 'id',
              path: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'trackSection', 'id'],
            },
          ],
          objectLayer: ObjectLayer.TrackSection,
          displayedValueFormatter: formatIdentityChoice,
        },
      },
      {
        key: 'value',
        path: 'value',
        errorPath: 'kilometricPoints',
        updatePath: ['kilometricPoints', INDEX_PARAM_PLACEHOLDER, 'value'],
        label: 'Attributes.common.kp',
        parser: Parser.identity,
      },
    ],
    defaultElement: NEW_EMPTY_KP,
    required: true,
    creation: {
      required: true,
      defaultValue: [NEW_EMPTY_KP],
    },
  },
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'type',
    path: 'type',
    label: 'Attributes.Signal.type',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: ALL_SIGNAL_TYPES.map(signal => ({ label: signal, value: signal })),
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'readingDirection',
    path: 'readingDirection',
    label: 'Attributes.Signal.direction',
    parser: Parser.directionType,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: DirectionTypeMap,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default SIGNAL_ATTRIBUTES
