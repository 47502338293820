/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { DEFAULT_PROJECTION_SLUG } from 'config/config'
import { Feature, LineString } from 'geojson'
import { fetchTrackDetails } from 'objects/Lines/LineServices'
import {
  AsyncTask,
  DetailsPayload,
  ObjectHistory, RevertPayload, Track, TrackSection,
} from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter,
} from 'objects/utils'
import { MapState } from 'reducers/map'
import { GetPaginatedResponse } from 'reducers/types'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'

export const fetchTSGeometryHelper = async (
  id: string,
): Promise<Feature<LineString, TrackSection>> => {
  const projectionId = store.getState().map.selectedProjection.id
  const response: Feature<LineString, TrackSection> = await get(
    `/chartis/v2/layer/${ObjectLayer.TrackSection}/geojson_feature/sch/`, { id, projectionId },
  )
  return response as Feature<LineString, TrackSection>
}

export const fetchTSDetailsHelper = async (id: string): Promise<TrackSection> => (
  get(`/${MIDI_URI}/${ObjectURI.TrackSections}/${id}`, { ...addInstructionParameter() })
)

const getDetails = createAsyncThunk<TrackSection, DetailsPayload, ThunkApiConfig>(
  'trackSection/getDetails',
  async (params, thunkApi) => {
    try {
      return await fetchTSDetailsHelper(params.id)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<LineString, TrackSection>, string, ThunkApiConfig>(
  'trackSection/getGeometry',
  async (id, thunkApi) => {
    try {
      return await fetchTSGeometryHelper(id)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<TrackSection, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'trackSection/create',
  async (newTrackSection: ObjOfStrOrNum | nestedObject, thunkApi) => {
    const newGeom = store.getState().TIVEditor?.object?.geometry
    const { selectedProjection } = store.getState().map as MapState
    let trackSectionWithGeom = { ...newTrackSection, geom: newGeom } as TrackSection

    if (trackSectionWithGeom?.track?.id) {
      const oldTS = await fetchTrackDetails(trackSectionWithGeom.track.id)
      if (oldTS.trackType !== trackSectionWithGeom.track.trackType) {
        const { id: _, ...track } = trackSectionWithGeom.track
        trackSectionWithGeom = { ...trackSectionWithGeom, track: track as Track }
      }
    }

    try {
      const trackSection: TrackSection = await post(`/${MIDI_URI}/${ObjectURI.TrackSections}/`, trackSectionWithGeom, {
        params: {
          projectionSlug: selectedProjection?.slug || DEFAULT_PROJECTION_SLUG,
          ...addInstructionParameter(),
        },
      })
      return trackSection
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<AsyncTask, TrackSection, ThunkApiConfig>(
  'trackSection/updateById',
  async (updatedTrackSection, thunkApi) => {
    try {
      const previousTrackSection = store.getState().detailsPanel.item
      const updatingInstruction = previousTrackSection.instructions.length !== updatedTrackSection.instructions.length
      let newTrackSection = updatedTrackSection
      if (previousTrackSection?.track?.trackType !== updatedTrackSection?.track?.trackType) {
        const { id: _, detailUrl: __, ...track } = newTrackSection.track
        newTrackSection = { ...updatedTrackSection, track: track as Track }
      }

      const task = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackSections}/${updatedTrackSection.id}/`, newTrackSection, {
          params: {
            ...addInstructionParameter(updatingInstruction, updatedTrackSection.instructions?.
              [updatedTrackSection.instructions.length - 1]?.id),
            async: true,
          },
        },
      )
      return task
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<AsyncTask, string, ThunkApiConfig>(
  'trackSection/deleteById',
  async (id: string, thunkApi) => {
    try {
      const task = await deleteRequest(`/${MIDI_URI}/${ObjectURI.TrackSections}/${id}/`,
        { params: { ...addInstructionParameter(), async: true } })
      return task
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<TrackSection, ValidationParams, ThunkApiConfig>(
  'trackSection/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: TrackSection = await post(`/${MIDI_URI}/${ObjectURI.TrackSections}/${id}/${operation}/`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getAll = createAsyncThunk<GetPaginatedResponse, number, ThunkApiConfig>(
  'trackSection/getAll',
  async (page, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.TrackSections}/`, { page })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'trackSection/getHistory',
  async (params, thunkApi) => {
    try {
      return await get(`/${MIDI_URI}/${ObjectURI.TrackSections}/${params.id}/history`)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'trackSection/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.TrackSections}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<TrackSection, string, ThunkApiConfig>(
  'trackSection/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.TrackSections}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TrackSectionServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  getAll,
  getHistory,
  revert,
  resetGaiaId,
}

export default TrackSectionServices
