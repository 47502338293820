import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { DeleteChildrenPayload, ProtectionChild } from 'objects/types/protections/const'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GenericAction } from 'types'

type Props = {
  deleteAction: GenericAction<DeleteChildrenPayload>;
  field: ProtectionChild;
}

export default function DeleteButton({ deleteAction, field }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { item } = useSelector((state: RootState) => state.detailsPanel)
  const [confirm, setConfirmation] = useState(false)

  const toggle = () => {
    setConfirmation(prev => !prev)
  }

  const onDelete = () => {
    dispatch(deleteAction({ id: item.id, field }))
  }

  const getPopupText = () => {
    switch (field) {
      case ProtectionChild.composition:
        return t('Details.button.deleteComposition')
      case ProtectionChild.extremities:
        return t('Details.button.deleteExtremities')
      case ProtectionChild.associatedSubAreas:
        return t('Details.button.deleteAssociated')
      case ProtectionChild.responsibleSubAreas:
        return t('Details.button.deleteResponsible')

      default:
        return t('Details.button.deleteComposition')
    }
  }

  return (
    <>
      <SimpleButton
        title={t('Details.button.deleteAllChildren')}
        onClick={toggle}
        style={ButtonStyle.light}
      />

      <ConfirmationDialog
        title={t('Details.button.deleteAllChildren')}
        content={getPopupText()}
        toggle={toggle}
        open={confirm}
        actionToConfirm={onDelete}
      />
    </>
  )
}
