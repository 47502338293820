import { ReactElement } from 'react'

import ContextMenu, { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'

const INSTRUCTIONS_CONTEXT_MENU = (action: () => void): ContextMenuItem[] => [
  {
    key: 'delete',
    label: 'Details.instructionsContextMenu.delete',
    onClick: () => { action() },
    show: true,
  },
]

type Props = {
  action: () => void;
}

export default function InstructionsContextMenu({ action }: Props): ReactElement {
  return <ContextMenu menuItems={INSTRUCTIONS_CONTEXT_MENU(action)} />
}
