import get from 'lodash/get'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'Store'
import { Attribute, InputTypes } from 'objects/attributes'
import { MapState } from 'reducers/map'
import { ResponseError } from 'types'

import ELECTRICAL_ELEMENT_ATTRIBUTES from 'objects/ElectricalElements/ElectricalElementAttributes'
import { FieldValue } from 'reducers/types'
import CaptureClick from './CaptureClick/CaptureClick'
import CustomAutoComplete from './CustomAutoComplete/CustomAutoComplete'
import CustomDatePicker from './CustomDatePicker/CustomDatePicker'
import CustomNumericField from './CustomNumericField'
import CustomSelect from './CustomSelect/CustomSelect'
import CustomTextField from './CustomTextField'
import { InputDataType, validateField } from './utils'

export type GenericInputProps = InputDataType & {
  onFieldError: (k: string, v: boolean) => void;
  responseError: ResponseError | undefined;
}

export default function GenericInput({
  field, value, onChange, onFieldError, responseError, index, noLabel, disabled: disabledProp,
}: GenericInputProps): ReactElement {
  const dispatch = useDispatch()
  const { captureClick } = useSelector((state: RootState): MapState => state.map)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const isMounted = useRef(false)

  // Handle local validation for this Attribute
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      return
    }
    const errorText = validateField(field, value)
    if (errorText) {
      setErrorMessage(errorText)
      onFieldError(field.key, true)
    } else {
      setErrorMessage(undefined)
      onFieldError(field.key, false)
    }
  }, [value])

  // Handle response validation from back-end for this Attribute
  useEffect(() => {
    const path = field?.errorPath || field.path
    if (responseError && get(responseError.data, path)) {
      const errors = get(responseError.data, path)
      // Handling nested nonFieldErrors
      if (errors.nonFieldErrors) return
      const message = typeof errors[0] !== 'string' ? errors[0]?.[field.key] : errors[0]
      setErrorMessage(message)
      const hasError = !!message
      onFieldError(field.key, hasError)
    }
  }, [responseError])

  const onValueChange = (val: FieldValue, fieldInput: Attribute) => {
    if (field.dependency?.key) {
      let dependencyPath: string | string[] = ''
      if (Array.isArray(field.dependency.key) && field.dependency.key[0] === fieldInput.key) {
        dependencyPath = field.dependency.key.slice(1)
      } else if (field.dependency.key === fieldInput.key) {
        dependencyPath = field.dependency.key
      }

      dispatch(field.dependency.source(get(val, dependencyPath)))
      const attribute = ELECTRICAL_ELEMENT_ATTRIBUTES().find(attr => attr.key === field.dependency?.path)
      if (attribute) {
        onChange(null, attribute)
      }
    }
    onChange(val, fieldInput)
  }
  const disabled = disabledProp || captureClick !== undefined

  switch (field.type) {
    case InputTypes.Select:
      return (
        <CustomSelect
          key={field.key}
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )
    case InputTypes.DatePicker:
      return (
        <CustomDatePicker
          key={field.key}
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )
    case InputTypes.Autocomplete:
      return (
        <CustomAutoComplete
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
        />
      )
    case InputTypes.CaptureClick:
      return (
        <CaptureClick
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          index={index}
          noLabel={noLabel}
        />
      )
    case InputTypes.Numeric:
      return (
        <CustomNumericField
          key={field.key}
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
          noLabel={noLabel}
        />
      )
    case InputTypes.TextArea:
      return (
        <CustomTextField
          key={field.key}
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
          noLabel={noLabel}
          multiline
          inputProps={{ maxLength: 512 }}
          maxRows={4}
        />
      )
    default:
      return (
        <CustomTextField
          key={field.key}
          field={field}
          value={value}
          onChange={onValueChange}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          disabled={disabled}
          noLabel={noLabel}
        />
      )
  }
}
