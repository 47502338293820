/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import ExtremityCreation from 'objects/common/Extremities/types'
import { Feeder } from 'objects/types/protections'
import { DeleteChildrenPayload } from 'objects/types/protections/const'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { store } from 'Store'
import { nestedObject, ObjOfStrOrNum, ThunkApiConfig } from 'types'

export const addExtremity = createAsyncThunk<Feeder, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'feeder/addExtremity',
  async (newExtremity, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as Feeder
    const { layerName: _layerName, ...typedNewExtremity } = newExtremity as unknown as ExtremityCreation

    const kp = typedNewExtremity?.location?.value

    const formattedNewExtremity = {
      ...typedNewExtremity,
      value: kp,
    }

    const updatedFeeder = {
      id,
      checksum,
      extremities: [
        ...extremities,
        formattedNewExtremity,
      ],
    }

    try {
      const response: Feeder = await patch(
        `/${MIDI_URI}/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        updatedFeeder, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteExtremity = createAsyncThunk<Feeder, number, ThunkApiConfig>(
  'feeder/deleteExtremityById',
  async (deletedExtremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as Feeder

    const updatedFeeder = {
      id,
      checksum,
      extremities: extremities.filter(extremity => extremity.id !== deletedExtremityId),
    }

    try {
      const response: Feeder = await patch(
        `/${MIDI_URI}/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        updatedFeeder, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteElements = createAsyncThunk<Feeder, DeleteChildrenPayload, ThunkApiConfig>(
  'feeder/deleteElements',
  async (params, thunkApi) => {
    const { id, field } = params
    const updatedFeeder = {
      id,
      [field]: [],
    }

    try {
      const response: Feeder = await patch(
        `/${MIDI_URI}/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        updatedFeeder, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
