/* eslint-disable @typescript-eslint/no-explicit-any */
import { Geometry } from '@nebula.gl/edit-modes'
import { get, patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { DEFAULT_PROJECTION_SLUG } from 'config/config'
import {
  Feature, FeatureCollection, LineString, Polygon,
} from 'geojson'
import { fetchTSGeometryHelper } from 'objects/TrackSections/TrackSectionServices'
import { AsyncTask, TrackSection } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { Projection } from 'objects/types/projections'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { AdminState } from 'reducers/admin'
import { MapState } from 'reducers/map'
import { store } from 'Store'
import { ThunkApiConfig } from 'types'
import { addInstructionParameter } from 'objects/utils'
import { LineStringFeature } from './types'

const getDefaultProjectionId = (projectionsList: Projection[]): string => (
  projectionsList.find(s => s.slug === DEFAULT_PROJECTION_SLUG)?.id || ''
)

const getTrackSectionGeometry = createAsyncThunk<Feature<LineString, TrackSection>, string, ThunkApiConfig>(
  'geoEditor/getTrackSectionGeometry',
  async (id, thunkApi) => {
    try {
      return await fetchTSGeometryHelper(id)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const updateTrackSectionGeometry = createAsyncThunk<AsyncTask, Geometry, ThunkApiConfig>(
  'geoEditor/updateTrackSectionGeometry',
  async (geometry, thunkApi) => {
    try {
      const { item } = store.getState().detailsPanel
      const { selectedProjection } = store.getState().map as MapState
      const response = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackSections}/${item.id}/`,
        {
          geom: geometry,
          checksum: item.checksum,
          id: item.id,
        },
        {
          params: {
            projectionSlug: selectedProjection?.slug || DEFAULT_PROJECTION_SLUG,
            ...addInstructionParameter(),
            async: true,
          },
        },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTrackSectionsByBbox = createAsyncThunk(
  'geoEditor/getTrackSectionsByBbox',
  async (bbox?: Polygon) => {
    const { projectionsList } = store.getState().admin as AdminState
    const { selectedProjection } = store.getState().map as MapState
    const response: FeatureCollection = await get(
      `/chartis/v2/layer/${ObjectLayer.TrackSection}/geojson/sch/`,
      { bbox, page_size: 8000, projectionId: selectedProjection?.id || getDefaultProjectionId(projectionsList) },
    )
    return response.features.filter(f => f.geometry.type !== null) as LineStringFeature[]
  },
)

const GeoEditorService = {
  getTrackSectionGeometry,
  updateTrackSectionGeometry,
  getTrackSectionsByBbox,
}

export default GeoEditorService
