/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  get,
  post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AsyncResponse, MidiObject } from 'objects/types'
import { ObjectKind } from 'objects/types/const'
import { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'
import { GetPaginatedResponse, MergeHistory } from 'reducers/types'
import { ThunkApiConfig } from 'types'

const mergeHistories = createAsyncThunk<MidiObject, MergeHistory, ThunkApiConfig>(
  'object/mergeHistories',
  async (params, thunkApi) => {
    try {
      const { instruction, ...ids } = params
      const response = await post(`/${MIDI_URI}/merge-histories/`, { ...ids }, {
        params: {
          instruction,
        },
      })
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getConflicts = createAsyncThunk<GetPaginatedResponse, {id: string; kind: ObjectKind}, ThunkApiConfig>(
  'object/getConflicts',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/midi-objects/${params.id}/conflicts/`)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const extend = createAsyncThunk<MidiObject, any, ThunkApiConfig>(
  'object/extend',
  async (params, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/extend-object/${params}/`, {}, { params: addInstructionParameter() })
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTaskStatus = createAsyncThunk<AsyncResponse, string, ThunkApiConfig>(
  'object/getTaskStatus',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/midi-object-process-results/${id}/`, {})
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDetails = createAsyncThunk<MidiObject, string, ThunkApiConfig>(
  'object/getDetails',
  async (uri, thunkApi) => {
    try {
      const response = await get(uri, { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ObjectServices = {
  mergeHistories, getConflicts, extend, getTaskStatus, getDetails,
}

export default ObjectServices
