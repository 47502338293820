/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask, DetailsPayload, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { ElectricalProtectionGroup, Protection } from 'objects/types/protections'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter,
  filterFields, filterNonEmptyFields, getUpdatedFields, isUpdatingObjectInstruction,
} from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'
import ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES from '../ElectricalProtectionGroupAttributes'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addElectricalElement, deleteElectricalElement } from './composition'
import {
  addExtremity,
  deleteElements,
  deleteExtremity, switchExtremityDirection,
} from './extremities'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'

const getDetails = createAsyncThunk<ElectricalProtectionGroup, DetailsPayload, ThunkApiConfig>(
  'electricalProtectionGroup/getDetails',
  async (params, thunkApi) => {
    try {
      const response: ElectricalProtectionGroup = await get(
        `/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${params.id}`,
        { ...addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, ElectricalProtectionGroup>, string, ThunkApiConfig>(
  'electricalProtectionGroup/getGeometry',
  async (id, thunkApi) => {
    const projectionId = store.getState().map.selectedProjection.id
    try {
      const response: Feature<Geometry, ElectricalProtectionGroup> = await get(
        `/chartis/v2/layer/${ObjectLayer.ElectricalProtectionGroup}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<ElectricalProtectionGroup, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'electricalProtectionGroup/create',
  async (newElectricalProtectionGroup, thunkApi) => {
    try {
      const newEPG = filterNonEmptyFields(newElectricalProtectionGroup as unknown as Protection,
        ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES())

      const response: ElectricalProtectionGroup = await post(
        `/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/`,
        newEPG, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, ElectricalProtectionGroup, ThunkApiConfig>(
  'electricalProtectionGroup/updateById',
  async (updatedElectricalProtectionGroup, thunkApi) => {
    try {
      const updatedEPG = filterNonEmptyFields(updatedElectricalProtectionGroup,
        ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES())
      const updatingInstruction = isUpdatingObjectInstruction(updatedElectricalProtectionGroup)
      const newEPG = updatingInstruction ? { instructions: updatedElectricalProtectionGroup.instructions }
        : filterFields(updatedEPG, getUpdatedFields(ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES()))

      const response: ElectricalProtectionGroup = await patch(
        `/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${updatedElectricalProtectionGroup.id}`,
        newEPG,
        {
          params: addInstructionParameter(updatingInstruction, newEPG.instructions?.
            [newEPG.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'electricalProtectionGroup/deleteById',
  async (id, thunkApi) => {
    try {
      // eslint-disable-next-line max-len
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<ElectricalProtectionGroup, ValidationParams, ThunkApiConfig>(
  'electricalProtectionGroup/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: ElectricalProtectionGroup = await post(
        `/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${id}/${operation}/`, {},
        { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'electricalProtectionGroup/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(
        `/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${params.id}/history`,
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'electricalProtectionGroup/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<ElectricalProtectionGroup, string, ThunkApiConfig>(
  'electricalProtectionGroup/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.ElectricalProtectionGroups}/${id}/${RESET_GAIA_URI}/`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ElectricalProtectionGroupServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addElectricalElement,
  deleteElectricalElement,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
  addExtremity,
  deleteExtremity,
  deleteElements,
  switchExtremityDirection,
  getHistory,
  revert,
  resetGaiaId,
}

export default ElectricalProtectionGroupServices
