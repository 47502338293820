import { Modal, Paper } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import CustomTextField from 'components/Common/Inputs/CustomTextField'
import SimpleButton from 'components/Common/SimpleButton/SimpleButton'
import Parser from 'objects/parser'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setProjectionLabel, ProjectionState, toggleOpenModal } from 'reducers/projection'
import ProjectionServices from 'services/ProjectionServices'
import { RootState } from 'Store'

import './ProjectionModificationModal.scss'

type Props = {
  onClickLinearize: () => void;
}

export default function ProjectionModificationModal({ onClickLinearize }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { projection, openModal } = useSelector((state: RootState) => state.projection) as ProjectionState

  const onLinearize = () => {
    dispatch(toggleOpenModal())
    onClickLinearize()
  }

  return (
    <Modal open={openModal} onClose={() => dispatch(toggleOpenModal())} id="projection-modal">
      <Paper className="d-flex flex-column">
        <div className="title d-flex justify-content-center align-items-center text-center">
          <h1>
            {t('Projections.modal.modification.title')}
          </h1>
        </div>
        <div>
          <CustomTextField
            value={projection?.name || ''}
            field={{
              key: 'label',
              path: 'label',
              label: 'Projections.creation.inputLabel',
              parser: Parser.identity,
            }}
            onChange={v => dispatch(setProjectionLabel(v as string))}
          />
        </div>
        {projection.taskStatus !== 'SUCCESS' && (
          <div className="px-3">
            {t('Projections.modal.modification.statusMessage')}
          </div>
        )}
        <div className="button-wrapper mt-auto">
          <SimpleButton
            title="Sauvegarder"
            onClick={() => dispatch(ProjectionServices.update(projection))}
          />
        </div>
        <div className="button-wrapper mt-3">
          <SimpleButton
            disabled={projection.taskStatus !== 'SUCCESS'}
            title="Linéariser la représentation"
            onClick={onLinearize}
          />
        </div>
      </Paper>
    </Modal>
  )
}
