export enum UserTrackProtectionType {
  LINE = 'Ligne',
  STATION = 'Gare',
  NOT_ALONE = 'ZE',
  CONSTRUCTION = 'Chantier de l\'équipement',
  RFN = 'Hors RFN'
}

export enum TrackProtectionType {
  LINE = 'ZEP L',
  STATION = 'ZEP G',
  NOT_ALONE = 'ZE',
  CONSTRUCTION = 'ZEP C',
  RFN = 'ZEP HRFN'
}

export const TrackProtectionTypeMap = [
  {
    label: UserTrackProtectionType.LINE,
    value: TrackProtectionType.LINE,
  },
  {
    label: UserTrackProtectionType.STATION,
    value: TrackProtectionType.STATION,
  },
  {
    label: UserTrackProtectionType.NOT_ALONE,
    value: TrackProtectionType.NOT_ALONE,
  },
  {
    label: UserTrackProtectionType.CONSTRUCTION,
    value: TrackProtectionType.CONSTRUCTION,
  },
  {
    label: UserTrackProtectionType.RFN,
    value: TrackProtectionType.RFN,
  },
]

export enum UserMGPTType {
  NO_MGPT = 'Pas de technologie',
  MGPT_0 = 'MGPT Niveau 0',
  MGPT_1 = 'MGPT Niveau 1',
  MGPT_2 = 'MGPT Niveau 2',
}

export enum UserFGPTType {
  NO_FGPT = 'Pas de technologie',
  FGPT_1 = 'FGPT Niveau 1',
  FGPT_2 = 'FGPT Niveau 2'
}

export const MGPTType = {
  NO_MGPT: 'NO TECH',
  MGPT_0: 'MGPT 0',
  MGPT_1: 'MGPT 1',
  MGPT_2: 'MGPT 2',
}

export const FGPTType = {
  NO_FGPT: 'NO TECH',
  FGPT_1: 'FGPT 1',
  FGPT_2: 'FGPT 2',
}

export const FGPTTypeMap = [
  {
    label: UserMGPTType.NO_MGPT,
    value: MGPTType.NO_MGPT,
  },
  {
    label: UserFGPTType.FGPT_1,
    value: FGPTType.FGPT_1,
  },
  {
    label: UserFGPTType.FGPT_2,
    value: FGPTType.FGPT_2,
  },
]

export const S9MGPTTypeMap = [
  {
    label: UserMGPTType.NO_MGPT,
    value: MGPTType.NO_MGPT,
  },
  {
    label: UserMGPTType.MGPT_0,
    value: MGPTType.MGPT_0,
  },
  {
    label: UserMGPTType.MGPT_1,
    value: MGPTType.MGPT_1,
  },
  {
    label: UserMGPTType.MGPT_2,
    value: MGPTType.MGPT_2,
  },
  {
    label: UserFGPTType.FGPT_1,
    value: FGPTType.FGPT_1,
  },
  {
    label: UserFGPTType.FGPT_2,
    value: FGPTType.FGPT_2,
  },
]

export enum UserWorkTrainsType {
  ALLOWED = 'Autorisé',
  NOT_ALLOWED = 'Non Autorisé',
}

export enum BooleanType {
  True = 'true',
  False = 'false',
}

export const WorkTrainsTypeMap = [
  {
    label: UserWorkTrainsType.ALLOWED,
    value: BooleanType.True,
  },
  {
    label: UserWorkTrainsType.NOT_ALLOWED,
    value: BooleanType.False,
  },
]

export enum UserTrackProtectionGroupType {
  LINE = 'Ligne',
  STATION = 'Gare',
  BOTH = 'Les deux'
}

export enum TrackProtectionGroupType {
  LINE = 'LINE',
  STATION = 'STATION',
  BOTH = 'BOTH'
}

export const TrackProtectionGroupTypeMap = [
  {
    label: UserTrackProtectionGroupType.LINE,
    value: TrackProtectionGroupType.LINE,
  },
  {
    label: UserTrackProtectionGroupType.STATION,
    value: TrackProtectionGroupType.STATION,
  },
  {
    label: UserTrackProtectionGroupType.BOTH,
    value: TrackProtectionGroupType.BOTH,
  },
]

export enum UserBooleeanType {
  True = 'Possible',
  False = 'Impossible',
}

export const BooleanTypeMap = [
  {
    label: UserBooleeanType.True,
    value: BooleanType.True,
  },
  {
    label: UserBooleeanType.False,
    value: BooleanType.False,
  },
]

export enum ProtectionChild {
  composition = 'composition',
  associatedSubAreas = 'associatedSubAreas',
  responsibleSubAreas = 'responsibleSubAreas',
  extremities = 'extremities',
  incompatibleProtections = 'incompatibleProtections'
}

export type DeleteChildrenPayload = {
  id: string;
  field: ProtectionChild;
}
