import CloseIcon from '@mui/icons-material/Close'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import {
  Backdrop,
  IconButton, Paper, Popper,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import { ITEM_TYPES } from 'objects/types/const'
import { EPGComposition, Protection, ShortProtection } from 'objects/types/protections'
import { MIDI_URI, PROTECTIONS_SEARCH_URI } from 'objects/uri'
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { InstructionState } from 'reducers/instruction'
import { ObjOfArrOfStr, ObjOfStr } from 'types'
import Loader from '../Loader'
import SearchBar from '../SearchBar/SearchBar'
import SimpleButton, { ButtonStyle } from '../SimpleButton/SimpleButton'
import './CaptureAndSearch.scss'

type Props = {
  onClick: () => void;
  onOptionSelect: (protection: Protection) => void;
  filteredTypes?: ITEM_TYPES[];
  title: string;
  protections: Protection[] | EPGComposition[] | ShortProtection[];
  params?: ObjOfArrOfStr | ObjOfStr;
}

const defaultProps = {
  filteredTypes: [],
  params: {},
}
export default function CaptureAndSearch({
  onClick, onOptionSelect, filteredTypes, title, protections, params,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { isLoading } = useSelector((state: RootState) => state.detailsPanel)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const closeForm = () => {
    setAnchorEl(null)
    onClick()
  }

  const onSearchClick = (event:React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      closeForm()
    } else {
      setAnchorEl(event.currentTarget)
    }
    onClick()
  }

  useEffect(() => {
    if (!anchorEl) return
    closeForm()
  }, [protections])

  return (
    <>
      <SimpleButton
        title={title}
        onClick={onSearchClick as () => void}
        style={ButtonStyle.light}
      />

      <Popper placement="top-end" anchorEl={anchorEl} open={!!anchorEl} className="search-wrapper">
        <div className="position-relative">
          <Backdrop
            id="backdrop"
            open={isLoading}
          >
            <Loader />
          </Backdrop>

          <Paper className="paper">
            <div className="d-flex justify-content-between mb-4">
              <div className="title">{t('Details.composition.title')}</div>

              <IconButton className="close" onClick={closeForm}>
                <CloseIcon />
              </IconButton>

            </div>

            <div className="object-search">
              <SearchBar
                blurOnSelect
                autoFocus={false}
                onSelect={obj => {
                  if (!obj) return
                  onOptionSelect(obj as Protection)
                }}
                searchURI={`/${MIDI_URI}/${PROTECTIONS_SEARCH_URI}`}
                placeholder={t('Details.composition.searchPlaceholder')}
                labelFormatter={option => `${option.mainRepresentation || ('label' in option && option.label) || ''}`}
                params={{
                  ...params,
                  instruction: instruction.id || '',
                  ...{ ...filteredTypes?.length ? { instance_of: filteredTypes } : {} },
                }}
              />
            </div>

            <div className="map-tooltip">
              <div>
                <LightbulbIcon />
              </div>

              <div>
                {t('Details.merge.clickMap')}
              </div>
            </div>
          </Paper>
        </div>
      </Popper>

    </>
  )
}

CaptureAndSearch.defaultProps = defaultProps
