import { ReactElement } from 'react'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'
import { Layer, Source } from 'react-map-gl'
import getCSSValue from 'helpers'
import { AnyPaint } from 'mapbox-gl'

type Props = {
  visible: boolean;
  layer: ObjectLayer;
  textOffset: [number, number];
}

const textColor = '--color-medium-grey'

const layout: mapboxgl.AnyLayout = {
  'text-font': ['Open Sans Regular'],
  'symbol-placement': 'line',
  'text-size': 12,
  'text-allow-overlap': false,
  'text-field': '{label}',
}

const paint: AnyPaint = {
  'text-color': getCSSValue(textColor),
  'text-halo-width': 2,
  'text-halo-color': 'rgba(255,255,255,0.75)',
  'text-halo-blur': 1,
}

export default function SimpleNameLayer({ visible, layer, textOffset }: Props): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(layer)}
      source-layer={layer}
      id={layer}
    >
      <Layer
        id={`${layer}-layer-name`}
        type="symbol"
        layout={{
          ...layout,
          'text-offset': textOffset,
          visibility: visible ? 'visible' : 'none',
        }}
        paint={paint}
        source-layer={layer}
        minzoom={13}
        filter={['==', ['get', 'deletedAt'], null]}
      />
    </Source>
  )
}
