import { Polygon } from 'geojson'
import { Region } from '.'
import { GetPaginatedResponse } from '../../reducers/types'
import { ObjectInstruction } from './common'
import {
  ObjectKind, ObjectLayer, ObjectStatus,
} from './const'

export enum InstructionType {
  S6 = 'S6',
  S9 = 'S9',
  S11 = 'S11',
}

export const S6ObjectLayers: ObjectLayer[] = [
  ObjectLayer.Signal, ObjectLayer.TrackSection, ObjectLayer.Isolator, ObjectLayer.TrackNode, ObjectLayer.Tunnel,
]

export const S9ObjectLayers: ObjectLayer[] = [
  ObjectLayer.TrackProtection, ObjectLayer.TrackProtectionGroup,
]

export const S11ObjectLayers: ObjectLayer[] = [
  ObjectLayer.ElectricalElement, ObjectLayer.ElectricalProtectionGroup, ObjectLayer.Feeder,
  ObjectLayer.Sector, ObjectLayer.SubSector,
]

export const LayersByInstructionType = {
  [InstructionType.S6]: S6ObjectLayers,
  [InstructionType.S9]: [...S6ObjectLayers, ...S9ObjectLayers],
  [InstructionType.S11]: [...S6ObjectLayers, ...S11ObjectLayers],
}

export const CreatedObjectsByInstructionType = {
  [InstructionType.S6]: S6ObjectLayers,
  [InstructionType.S9]: S9ObjectLayers,
  [InstructionType.S11]: S11ObjectLayers,
}

export const InstructionByObjectKind = {
  [ObjectKind.TrackNode]: InstructionType.S6,
  [ObjectKind.Signal]: InstructionType.S6,
  [ObjectKind.TrackSection]: InstructionType.S6,
  [ObjectKind.Isolator]: InstructionType.S6,
  [ObjectKind.Tunnel]: InstructionType.S6,
  [ObjectKind.TrackProtection]: InstructionType.S9,
  [ObjectKind.TrackProtectionGroup]: InstructionType.S9,
  [ObjectKind.Feeder]: InstructionType.S11,
  [ObjectKind.ElectricalElement]: InstructionType.S11,
  [ObjectKind.ElectricalProtectionGroup]: InstructionType.S11,
  [ObjectKind.Sector]: InstructionType.S11,
  [ObjectKind.SubSector]: InstructionType.S11,
}

export type User = {
  sub: string;
}

export type InstructionItem = {
  id?: string;
  itemId?: string;
  itemType: string;
  mainRepresentation?: string;
  secondaryRepresentation?: string;
  detailUrl?: string;
  status: ObjectStatus;
  instructions: ObjectInstruction[];
  deletedAt: string;
  inConflict: boolean;
}

export type Instruction = {
  id: string;
  creationDate: string;
  updateDate: string;
  applicationDate: string;
  itemCount: number;
  name: string;
  reference: string;
  type: InstructionType;
  users: string[] | User[];
  version: string;
  versionDate: string;
  boundingBox?: Polygon;
  regions: Region[];
}

export type ObjectSummary = {
  inProgressObjects: number;
  itemCount: number;
  itemType: string;
  publishedObjects: number;
  todoObjects: number;
  validatedObjects: number;
  verifiedObjects: number;
}

export type InstructionDetails = Instruction & {
  publishedObjects: number;
  summary: ObjectSummary[];
}

export type InstructionResponse = GetPaginatedResponse & {
  results: InstructionItem[];
}

export enum TaskStatus {
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS'
}

export enum TaskType {
  revert = 'revert',
  updateTrackSection = 'update_track_section',
  deleteTrackSection = 'delete_track_section',
  updateInstructionDate= 'patch_application_date'
}

export type ExportStatus = {
  id: string;
  name: string;
  url: string;
  taskStatus: TaskStatus;
}
