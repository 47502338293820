import { useTranslation } from '@osrdata/app_core/dist/translation'
import { HTMLAttributes, ReactElement, ReactNode } from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { User } from 'services/cerbereTypes'

import './UserMultiSelect.scss'
import { Chip } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from 'Store'
import { InstructionState } from 'reducers/instruction'
import { loggedAsManager } from 'helpers/permissions'

type Props = {
  options: User[];
  label: string;
  placeholder: string;
  onChange: (users: User[]) => void;
  onBlur?: () => void;
  defaultValue?: User[];
  elevated?: boolean;
  loading?: boolean;
}

const defaultProps = {
  onBlur: () => {},
  defaultValue: undefined,
  elevated: false,
  loading: false,
}

export default function UserMultiSelect({
  options, label, placeholder, onChange, onBlur, defaultValue, elevated, loading,
}: Props): ReactElement {
  const { t } = useTranslation()
  const {
    administrators, supervisors, addingUsers, managers, roots,
  } = useSelector(
    (state: RootState) => state.instruction,
  ) as InstructionState
  const loggedUser = useSelector((state: RootState) => state.user)

  const getUserRole = (user: User) => {
    if (user.id === loggedUser.account.id) {
      return loggedAsManager() ? '(RG)' : '(A)'
    }

    if (roots.find(admin => admin.id === user.id)) {
      return '(A)'
    }

    if (administrators.find(admin => admin.id === user.id)) {
      return '(A)'
    }

    if (supervisors.find(admin => admin.id === user.id)) {
      return '(V)'
    }

    if (managers.find(manager => manager.id === user.id)) {
      return '(RG)'
    }

    return '(R)'
  }

  const formatUserLabel = (user: User): string => (
    `${user.firstName} ${user.lastName} - ${user.username.toUpperCase()} ${getUserRole(user)}`
  )
  const renderOptions = (
    props: HTMLAttributes<HTMLLIElement>, option: User, value: User[] | undefined, selected: boolean,
  ): ReactNode => !selected
    && ((value && !value.map(u => u.id).includes(option.id)) || value === undefined)
    && (
      <li {...props}>
        {formatUserLabel(option)}
      </li>
    )

  return (
    <>
      <Autocomplete
        multiple
        loading={loading}
        loadingText={t('Instruction.creation.userAssignation.loading')}
        id="operator-select"
        className={`user-multi-select ${elevated ? 'elevation-2' : ''}`}
        options={options}
        disableCloseOnSelect
        getOptionDisabled={() => addingUsers}
        noOptionsText={t('Instruction.creation.userAssignation.noOptionsText')}
        getOptionLabel={option => formatUserLabel(option)}
        renderOption={(props, option, { selected }) => renderOptions(props, option, defaultValue, selected)}
        renderTags={() => null}
        value={defaultValue}
        onChange={(_e, newUsers) => onChange(newUsers)}
        onBlur={onBlur}
        renderInput={inputParams => (
          <TextField
            variant="standard"
            {...inputParams}
            label={t(label)}
            placeholder={t(placeholder)}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...inputParams.InputProps,
              disableUnderline: true,
            }}
          />
        )}
      />
      <div className="chip-container">
        {defaultValue?.map(user => (
          <Chip
            disabled={loggedUser.account.id === user.id || addingUsers}
            key={user.id}
            label={formatUserLabel(user)}
            onDelete={() => onChange(defaultValue.filter(u => u.id !== user.id))}
          />
        ))}
      </div>
    </>
  )
}

UserMultiSelect.defaultProps = defaultProps
