import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Modal from '@mui/material/Modal'
import Paper from '@mui/material/Paper'

import { RootState, store } from 'Store'
import ActionButton from 'components/Common/ActionButton/ActionButton'
import CustomDatePicker from 'components/Common/Inputs/CustomDatePicker/CustomDatePicker'
import CustomTextField from 'components/Common/Inputs/CustomTextField'
import { CREATE_BUTTON_TEXT } from 'components/Panels/CreationPanel/utils'
import { InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { Area } from 'objects/types/protections'
import {
  AreasState, setEndDate, setLabel, setRegions, setStartDate, toggleOpenModal,
} from 'reducers/areas'

import { FormControl } from '@mui/material'
import RegionDropdown from 'components/Common/RegionDropdown/RegionDropdown'
import { Region } from 'objects/types'
import './AreaModal.scss'
import ButtonStatus from 'components/Common/buttonStatus'
import { isStringEmpty } from 'helpers'

type Props = {
  title: string;
  onButtonClick: () => void;
  buttonText: typeof CREATE_BUTTON_TEXT;
}

const INPUTS = (area: Partial<Area>) => [
  {
    onChange: (d: unknown) => { store.dispatch(setLabel(d as string)) },
    value: area.label || '',
    field: {
      key: 'label',
      path: 'label',
      label: 'Areas.details.label',
      parser: Parser.identity,
      type: InputTypes.TextField,
    },
  },
  {
    onChange: (d: unknown) => { store.dispatch(setStartDate(d as string)) },
    value: area.activityStartDate === undefined ? '' : area.activityStartDate,
    field: {
      key: 'activityStartDate',
      path: 'activityStartDate',
      label: 'Attributes.common.activityStartDate',
      parser: Parser.date,
      type: InputTypes.DatePicker,
      checkboxLabel: 'Attributes.common.activityStartDateCheckbox',
    },
  },
  {
    onChange: (d: unknown) => { store.dispatch(setEndDate(d as string)) },
    value: area.activityEndDate === undefined ? '' : area.activityEndDate,
    field: {
      key: 'activityEndDate',
      path: 'activityEndDate',
      label: 'Attributes.common.activityEndDate',
      parser: Parser.date,
      type: InputTypes.DatePicker,
      checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    },
  },
  {
    onChange: (d: unknown) => { store.dispatch(setRegions(d as Region[])) },
    value: '',
    field: {
      key: 'regions',
      path: 'regions',
      label: 'Attributes.common.regions',
      parser: Parser.date,
      type: InputTypes.Select,
    },
  },
]

export default function AreaModal({
  title, onButtonClick, buttonText,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { modal: { open, area, buttonStatus } } = useSelector((state: RootState) => state.areas) as AreasState

  return (
    <Modal open={open} onClose={() => dispatch(toggleOpenModal())} id="area-modal">
      <Paper>
        <div className="title d-flex justify-content-center align-items-center text-center">
          <h1>
            {t(title)}
          </h1>
        </div>
        <div>
          {INPUTS(area).map(input => {
            if (input.field.type === InputTypes.DatePicker) {
              return (
                <CustomDatePicker
                  key={input.field.key}
                  value={input.value}
                  field={input.field}
                  onChange={v => input.onChange(v)}
                />
              )
            }
            if (input.field.type === InputTypes.Select) {
              return (
                <div key={input.field.key} className="wrapper">
                  <FormControl fullWidth className="text-field">
                    <p className="select-label">{t(input.field.label)}</p>
                    <RegionDropdown
                      object={area}
                      setRegions={v => input.onChange(v as Region[])}
                    />
                  </FormControl>
                </div>
              )
            }
            return (
              <CustomTextField
                key={input.field.key}
                value={input.value}
                field={input.field}
                onChange={v => input.onChange(v)}
              />
            )
          })}
        </div>
        <div className="button-wrapper">
          <ActionButton
            title={buttonText}
            onClick={onButtonClick}
            status={!area.regions?.length || isStringEmpty(area.label) ? ButtonStatus.Disabled : buttonStatus}
          />
        </div>
      </Paper>
    </Modal>
  )
}
