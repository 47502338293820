/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Protection, ShortProtection, SubSector } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'

export const addChild = createAsyncThunk<SubSector, ShortProtection, ThunkApiConfig>(
  'subSector/addChild',
  async (newChild, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as SubSector

    const updatedSubSector = {
      id,
      checksum,
      composition: [...(composition.map(comp => ({ id: comp.id }))), {
        id: newChild.id,
      }],
    }

    try {
      const response: SubSector = await patch(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/${updatedSubSector.id}`,
        updatedSubSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteChild = createAsyncThunk<SubSector, string, ThunkApiConfig>(
  'subSector/deleteChild',
  async (deletedChildId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as SubSector

    const updatedSubSector: Partial<SubSector> = {
      id,
      checksum,
      composition: composition.flatMap(tp => (tp.id !== deletedChildId ? { id: tp.id } as Protection : [])),
    }

    try {
      const response: SubSector = await patch(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/${updatedSubSector.id}`,
        updatedSubSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
