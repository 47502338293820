import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import LineServices from 'objects/Lines/LineServices'
import { nestedObject } from 'types'

type AutoCompleteParams = {
  key: string;
  loading: boolean;
  values: nestedObject[];
}

export interface AutoCompleteState {
  autoCompletes: AutoCompleteParams[];
}

const initialState: AutoCompleteState = {
  autoCompletes: [],
}

const updateAutoCompleteHelper = (state: AutoCompleteState, newAutoComplete: AutoCompleteParams) => {
  const autoCompleteIndex = state.autoCompletes.findIndex(a => a.key === newAutoComplete.key)
  if (autoCompleteIndex === -1) {
    state.autoCompletes.push(newAutoComplete)
  } else {
    state.autoCompletes[autoCompleteIndex] = newAutoComplete
  }
}

export const autoCompleteSlice = createSlice({
  name: 'autoCompleteSlice',
  initialState,
  reducers: {
    resetAutoComplete: (state, action: PayloadAction<string | undefined>) => {
      state.autoCompletes = state.autoCompletes.filter(ac => ac.key !== action.payload)
    },
    updateAutoCompleteReducer: (state, action: PayloadAction<AutoCompleteParams>) => {
      updateAutoCompleteHelper(state, action.payload)
    },
  },
  extraReducers: builder => {
    // Autocomplete
    builder.addCase(LineServices.getAll.pending, state => {
      const autoComplete: AutoCompleteParams = {
        key: 'lines',
        loading: true,
        values: [],
      }
      updateAutoCompleteHelper(state, autoComplete)
    })
    builder.addCase(LineServices.getAll.fulfilled, (state, action) => {
      const { results, newLine } = action.payload
      const values = results.find(line => line.lineCode?.toLowerCase()
      === newLine.toLowerCase()) ? results : [{ lineCode: action.payload.newLine },
          ...results]
      const newAutoComplete: AutoCompleteParams = {
        key: 'lines',
        loading: false,
        values,
      }
      updateAutoCompleteHelper(state, newAutoComplete)
    })
    builder.addCase(LineServices.getTracks.pending, state => {
      const autoComplete: AutoCompleteParams = {
        key: 'tracks',
        loading: true,
        values: [],
      }
      updateAutoCompleteHelper(state, autoComplete)
    })
    builder.addCase(LineServices.getTracks.fulfilled, (state, action) => {
      const { results, newTrack, line } = action.payload
      const values = results.find(track => track.name.toLowerCase() === newTrack.toLowerCase()) ? results : [{
        name: newTrack,
        line,
      }, ...results]
      const newAutoComplete: AutoCompleteParams = {
        key: 'tracks',
        loading: false,
        values,
      }
      updateAutoCompleteHelper(state, newAutoComplete)
    })
  },
})

export const {
  updateAutoCompleteReducer: updateAutoComplete,
  resetAutoComplete,
} = autoCompleteSlice.actions

export default autoCompleteSlice.reducer
