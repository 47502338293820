import debounce from 'lodash/debounce'
import { RefObject } from 'react'
import { MapRef } from 'react-map-gl'

import { store } from 'Store'
import PanelNavigator from 'components/Panels/PanelNavigator'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import { ObjectKind, ObjectLayer } from 'objects/types/const'
import { enableGeoEditor } from 'reducers/geoEditor'
import { setLayers } from 'reducers/map'
import { createNewObject } from 'reducers/panels/creationPanel'
import { PanelName } from 'reducers/panels/panel'

import GeoEditorService from './GeoEditorService'
import { getBbox } from './helpers'

export enum Modes {
  deletePoint = 'deletePoint',
  draw = 'draw',
  grab = 'grab',
  select = 'select',
}

// Helpers
export const switchToGeoEditor = (id: string): void => {
  store.dispatch(GeoEditorService.getTrackSectionGeometry(id))
  store.dispatch(TrackSectionServices.getDetails({ id }))
  const { panelHistory } = store.getState().panel
  if (panelHistory[panelHistory.length - 1] === PanelName.creation) PanelNavigator.goBack()
}

export const switchToGeoCreator = (): void => {
  // Assuming we are always creating a Track Section
  store.dispatch(createNewObject(ObjectKind.TrackSection))
  store.dispatch(enableGeoEditor())
  store.dispatch(setLayers([ObjectLayer.TrackSection]))
  PanelNavigator.push(PanelName.creation)
}

// Clean previous mode cursors classes
export const cleanCursor = (): void => {
  const mapNodeClasses = document.querySelector('.mapboxgl-map+.overlays')?.classList
  Object.values(Modes).forEach(m => mapNodeClasses?.remove(m))
}

// Add new mode cursor class
export const updateCursor = (newMode: Modes): void => {
  cleanCursor()
  const mapNodeClasses = document.querySelector('.mapboxgl-map+.overlays')?.classList
  mapNodeClasses?.add(newMode)
}

// Get features inside current bbox
export const updateSnappingData = debounce((mapRef: RefObject<MapRef>) => {
  if (mapRef.current) {
    const currentBbox = getBbox(mapRef)
    store.dispatch(GeoEditorService.getTrackSectionsByBbox(currentBbox))
  }
}, 500)
