import { CaptureMapClickParams } from 'objects/attributes'
import { ObjectLayer } from 'objects/types/const'

// eslint-disable-next-line import/prefer-default-export
export const captureClickParams: CaptureMapClickParams[] = [{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.Sector,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.SubSector,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.ElectricalElement,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.Feeder,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.ElectricalProtectionGroup,
},
]
