import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import Loader from 'components/Common/Loader'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { loggedAsManager } from 'helpers/permissions'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetInstruction, toggleDisplayCreation } from 'reducers/instruction'
import InstructionCreation from 'views/admin/instructions/Creation/InstructionCreation'
import NewVersion from 'views/admin/instructions/Creation/NewVersion'
import InstructionsTable from './InstructionsTable/InstructionsTable'
import './index.scss'

export default function HomeDashboard(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { displayParameters: showParameters } = useSelector((state: RootState) => state.operator)
  const { displayNewVersion, loading, displayCreation } = useSelector((state: RootState) => state.instruction)
  return (
    <>
      <div id="instructions-tab" className={`d-flex h-100 ${showParameters ? 'position-relative' : ''}`}>
        {loading && showParameters && <Loader overlay />}
        <div style={{ flex: 1 }}>
          <>
            <div style={{ flex: 1 }}>
              <InstructionsTable />
            </div>
            {loggedAsManager() && (
            <div className="button-wrapper w-100 d-flex justify-content-center align-items-center">
              <SimpleButton
                style={ButtonStyle.primary}
                title={t('Instruction.button.createNewInstruction')}
                onClick={() => {
                  dispatch(resetInstruction())
                  dispatch(toggleDisplayCreation())
                }}
              />
            </div>
            )}

          </>

        </div>
        {displayCreation && <InstructionCreation />}
        {displayNewVersion && <NewVersion />}
      </div>
      <FeedbackSnackBar />
    </>
  )
}
