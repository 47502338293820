import { useTranslation } from '@osrdata/app_core/dist/translation'
import debounce from 'lodash/debounce'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import { RootState, store } from 'Store'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import Loader from 'components/Common/Loader'
import {
  AreasState, ModalMode, setModalMode, toggleOpenModal,
} from 'reducers/areas'
import AreaServices from 'services/AreaServices'
import AreaModal from './AreaModal'
import AreaTile from './AreaTile'
import './AreasTab.scss'
import SearchAreas from './SearchAreas'
import { MODE_BUTTON_ACTION, MODE_BUTTON_TEXT, MODE_TITLE } from './const'
import { AreaDeletion } from './types'

const debouncedGetAll = debounce((search: string, region: string) => store.dispatch(
  AreaServices.getAll({ search, region }),
), 500)

const emptyDeletion: AreaDeletion = { id: '' }

export default function AreasTab(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { selectedRegion } = useSelector((state: RootState) => state.regions)
  const {
    modal: { area, mode }, areas, loading, loadingArea,
  } = useSelector((state: RootState) => state.areas) as AreasState
  const [objectToDelete, setDeletion] = useState<AreaDeletion>(emptyDeletion)

  useEffect(() => {
    dispatch(AreaServices.getAll({ search: '', region: selectedRegion }))
  }, [selectedRegion])

  const onSearch = (s: string) => {
    setSearch(s)
    debouncedGetAll(s, selectedRegion)
  }

  const closeValidationPopup = () => {
    setDeletion(emptyDeletion)
  }

  const dialogAction = () => {
    if (objectToDelete.subarea) {
      dispatch(AreaServices.deleteSubArea({
        id: objectToDelete.id,
        subArea: objectToDelete.subarea,
      }))
    } else {
      dispatch(AreaServices.delete(objectToDelete.id))
    }
  }

  return (
    <div id="areas-tab" className="d-flex flex-column w-100 p-4">
      <div className="d-flex justify-content-between align-items-center">
        <SearchAreas value={search} onChange={onSearch} />
        <div className="button-wrapper">
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              dispatch(setModalMode(ModalMode.creation))
              dispatch(toggleOpenModal())
            }}
          >
            {t('Areas.newAreaButton')}
          </Button>
        </div>
      </div>
      {loading ? <Loader /> : (
        <div className="d-flex mt-4 overflow-auto">
          <Grid container spacing={3}>
            {areas.map(a => (
              <Grid item key={a.label} md={3} sm={6} xs={12}>
                <AreaTile area={a} loadingArea={loadingArea} setDeletion={setDeletion} />
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      <AreaModal
        title={MODE_TITLE[mode]}
        onButtonClick={() => MODE_BUTTON_ACTION[mode](area)}
        buttonText={MODE_BUTTON_TEXT[mode]}
      />
      <FeedbackSnackBar />

      <ConfirmationDialog
        title={objectToDelete.subarea ? t('Areas.details.deletion.subareaTitle') : t('Areas.details.deletion.title')}
        content={objectToDelete.subarea ? t('Areas.details.deletion.subareaWarning')
          : t('Areas.details.deletion.warning')}
        toggle={closeValidationPopup}
        open={!!objectToDelete.id}
        actionToConfirm={dialogAction}
      />
    </div>
  )
}
