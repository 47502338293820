import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import { RootState } from 'Store'
import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Extremities from 'objects/common/Extremities/Extremities'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { AttributesProps, ExtraKind } from 'objects/types/const'
import { ElectricalProtectionGroup } from 'objects/types/protections'
import { MapState } from 'reducers/map'
import { CollapsibleMenu } from 'reducers/types'

import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import Instructions from 'objects/common/Instructions/Instructions'
import { ProtectionChild } from 'objects/types/protections/const'
import Composition from './Composition/Composition'
import ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES from './ElectricalProtectionGroupAttributes'
import './ElectricalProtectionGroupDetails.scss'
import ElectricalProtectionGroupServices from './ElectricalProtectionGroupServices'

export default function ElectricalProtectionGroupDetails({ object }: AttributesProps): ReactElement | null {
  const EPG = object as ElectricalProtectionGroup
  const { captureClick } = useSelector(
    (state: RootState): MapState => state.map,
  )
  const editable = EPG.inInstruction

  return (
    <div className="electrical-element-details electrical-element-group-details object-attributes">
      <Attributes
        item={EPG}
        attributes={ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES(EPG)}
      />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Composition editable={editable} electricalElements={EPG.composition} />
        <Extremities
          editable={editable}
          type={ExtraKind.EPGExtremity}
          extremities={EPG.extremities}
          deleteExtremity={ElectricalProtectionGroupServices.deleteExtremity}
          deleteExtremities={ElectricalProtectionGroupServices.deleteElements}
          switchOrientation={
            ElectricalProtectionGroupServices.switchExtremityDirection
          }
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={EPG.responsibleSubAreas}
          deleteRSAAction={
            ElectricalProtectionGroupServices.deleteResponsibleSubArea
          }
          addRSAAction={ElectricalProtectionGroupServices.addResponsibleSubArea}
          deleteAll={ElectricalProtectionGroupServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={EPG.associatedSubAreas}
          deleteASAAction={
            ElectricalProtectionGroupServices.deleteAssociatedSubArea
          }
          addASAAction={ElectricalProtectionGroupServices.addAssociatedSubArea}
          deleteAll={ElectricalProtectionGroupServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          fieldType={ProtectionChild.incompatibleProtections}
          deleteAll={ElectricalProtectionGroupServices.deleteElements}
          linkedProtections={EPG.incompatibleProtections}
          deleteAction={
            ElectricalProtectionGroupServices.deleteIncompatibleObject
          }
          addIPAction={ElectricalProtectionGroupServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />

        <Instructions item={EPG} />
      </div>
      {captureClick !== undefined && <div className="capture-overlay" />}
    </div>
  )
}
