import {
  ADMIN_HOMEPATH, MANAGER_HOMEPATH, OPERATOR_HOMEPATH, SUPERVISOR_HOMEPATH,
} from 'Routes'
import { store } from 'Store'
import history from 'customHistory'

const ADMIN_PERMISSIONS = 'midi-ve::ADMIN'
const SUPERVISOR_PERMISSIONS = 'midi-ve::SUPERVISOR'
const MANAGER_PERMISSIONS = 'midi-ve::MANAGER'

export const isAdmin = (): boolean => {
  const { appPermissions } = store.getState().user

  return appPermissions.includes(ADMIN_PERMISSIONS)
}

export const isSupervisor = (): boolean => {
  const { appPermissions } = store.getState().user

  return appPermissions.includes(SUPERVISOR_PERMISSIONS) || isAdmin()
}

export const isManager = (): boolean => {
  const { appPermissions } = store.getState().user

  return appPermissions.includes(MANAGER_PERMISSIONS) || isAdmin()
}

export const loggedAsOperator = (): boolean => history.location.pathname.includes(OPERATOR_HOMEPATH)

export const loggedAsManager = (): boolean => history.location.pathname.includes(MANAGER_HOMEPATH)

export const loggedAsSupervisor = (): boolean => history.location.pathname.includes(SUPERVISOR_HOMEPATH)

export const loggedAsAdmin = (): boolean => history.location.pathname.includes(ADMIN_HOMEPATH)
