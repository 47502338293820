import { RootState, useAppSelector } from 'Store'
import getCSSValue from 'helpers'
import { deletedObjectsColor, selectedLinearObject } from 'objects/styles'
import { MidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl, lineFilter } from 'objects/utils'
import { ReactElement, useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { GeoEditorState } from 'reducers/geoEditor'
import { MapState } from 'reducers/map'
import { getStatusColorsByRole } from './utils'

type Props = {
  visible: boolean;
  layer: ObjectLayer;
  item: MidiObject | undefined;
}

export default function SimpleLineLayer({ visible, layer, item }: Props): ReactElement {
  const { active: showGeoEditor } = useAppSelector((state): GeoEditorState => state.TIVEditor)
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
  const { captureClick } = useSelector((state: RootState): MapState => state.map)

  useEffect(() => {
    if (item) setSelectedId(item.id)
    else setSelectedId(undefined)
  }, [item])

  const dimElement = selectedId
    ? ['any', ['!=', ['get', 'id'], selectedId], showGeoEditor]
    : showGeoEditor

  const isSelected = selectedId
    ? ['==', ['get', 'id'], selectedId]
    : false

  const layerFilter = lineFilter()

  return (
    <Source
      type="vector"
      url={formatLayerUrl(layer)}
      source-layer={layer}
      id={layer}
    >
      <Layer
        id={`${layer}-layer`}
        type="line"
        paint={{
          'line-color': ['case',
            ['!=', ['get', 'deletedAt'], null], getCSSValue(deletedObjectsColor),
            isSelected, getCSSValue(selectedLinearObject),
            getStatusColorsByRole(),
          ],
          'line-width': ['case', !selectedId || dimElement, 2, 3],
          'line-opacity': ['case',
            !!captureClick, 1,
            dimElement, 0.03,
            1,
          ],
        }}
        layout={{
          visibility: visible || (!visible && isSelected && captureClick) ? 'visible' : 'none',
          'line-sort-key': ['case', isSelected, 100000, ['-', ['get', 'status']]],
        }}
        source-layer={layer}
        filter={(!visible && isSelected) ? isSelected : layerFilter}
      />
    </Source>
  )
}
