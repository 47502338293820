import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Extremities from 'objects/common/Extremities/Extremities'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { AttributesProps, ExtraKind } from 'objects/types/const'
import { ReactElement } from 'react'

import Divider from '@mui/material/Divider'

import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import Instructions from 'objects/common/Instructions/Instructions'
import { Feeder } from 'objects/types/protections'
import { ProtectionChild } from 'objects/types/protections/const'
import { CollapsibleMenu } from 'reducers/types'
import FEEDER_ATTRIBUTES from './FeederAttributes'
import FeederServices from './FeederServices'

export default function FeederDetails({ object }: AttributesProps): ReactElement | null {
  const feeder = object as Feeder
  const {
    extremities, responsibleSubAreas, associatedSubAreas, incompatibleProtections,
  } = feeder
  const editable = feeder.inInstruction

  return (
    <div id="feeders-details" className="object-attributes">
      <Attributes item={feeder} attributes={FEEDER_ATTRIBUTES(feeder)} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <Extremities
          editable={editable}
          type={ExtraKind.FeederExtremity}
          extremities={extremities}
          deleteExtremity={FeederServices.deleteExtremity}
          deleteExtremities={FeederServices.deleteElements}
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={FeederServices.deleteResponsibleSubArea}
          addRSAAction={FeederServices.addResponsibleSubArea}
          deleteAll={FeederServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={FeederServices.deleteAssociatedSubArea}
          addASAAction={FeederServices.addAssociatedSubArea}
          deleteAll={FeederServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          deleteAll={FeederServices.deleteElements}
          fieldType={ProtectionChild.incompatibleProtections}
          linkedProtections={incompatibleProtections}
          deleteAction={FeederServices.deleteIncompatibleObject}
          addIPAction={FeederServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
        <Instructions item={feeder} />
      </div>

    </div>
  )
}
