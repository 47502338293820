/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask, DetailsPayload, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { Feeder } from 'objects/types/protections'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter,
  filterFields, filterNonEmptyFields, getUpdatedFields, isUpdatingObjectInstruction,
} from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addExtremity, deleteElements, deleteExtremity } from './extremities'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'

import FEEDER_ATTRIBUTES from '../FeederAttributes'

const getDetails = createAsyncThunk<Feeder, DetailsPayload, ThunkApiConfig>(
  'feeder/getDetails',
  async (params, thunkApi) => {
    try {
      const response: Feeder = await get(`/${MIDI_URI}/${ObjectURI.Feeders}/${params.id}`,
        { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Feeder>, string, ThunkApiConfig>(
  'feeder/getGeometry',
  async (id, thunkApi) => {
    const projectionId = store.getState().map.selectedProjection.id
    try {
      const response: Feature<Geometry, Feeder> = await get(
        `/chartis/v2/layer/${ObjectLayer.Feeder}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Feeder, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'feeder/create',
  async (newFeeder, thunkApi) => {
    try {
      const feeder = filterNonEmptyFields(newFeeder as unknown as Feeder, FEEDER_ATTRIBUTES())
      const response: Feeder = await post(
        `/${MIDI_URI}/${ObjectURI.Feeders}/`,
        feeder, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, Partial<Feeder>, ThunkApiConfig>(
  'feeder/updateById',
  async (updatedFeeder, thunkApi) => {
    try {
      const newFeeder = filterNonEmptyFields(updatedFeeder as unknown as Feeder, FEEDER_ATTRIBUTES())
      const updatingInstruction = isUpdatingObjectInstruction(newFeeder)
      const feeder = updatingInstruction ? { instructions: updatedFeeder.instructions }
        : filterFields(newFeeder, getUpdatedFields(FEEDER_ATTRIBUTES()))

      const response: Feeder = await patch(
        `/${MIDI_URI}/${ObjectURI.Feeders}/${updatedFeeder.id}`,
        feeder,
        {
          params: addInstructionParameter(updatingInstruction, feeder.instructions?.
            [feeder.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'feeder/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.Feeders}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Feeder, ValidationParams, ThunkApiConfig>(
  'feeder/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Feeder = await post(
        `/${MIDI_URI}/${ObjectURI.Feeders}/${id}/${operation}/`,
        {}, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'feeder/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.Feeders}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'feeder/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Feeders}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<Feeder, string, ThunkApiConfig>(
  'feeder/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Feeders}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const FeederServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addExtremity,
  deleteExtremity,
  deleteElements,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
  getHistory,
  revert,
  resetGaiaId,
}

export default FeederServices
