import { store } from 'Store'
import MAIN_API from 'config/config'
import history from 'customHistory'
import { InstructionState } from 'reducers/instruction'
import { MapState } from 'reducers/map'
import { ProjectionState } from 'reducers/projection'
import { FieldValue } from 'reducers/types'
import {
  StrOrNum, StrOrNumOrBool,
  nestedObject,
} from 'types'
import { Attribute } from './attributes'
import { NULL_STRING } from './types/const'
import { InstructionItem } from './types/instructions'
import { Protection } from './types/protections'
import { BooleanType } from './types/protections/const'

export const addInstructionParameter = (updatingInstruction = false, instructionId = ''): {instruction: string} => {
  // Case when we remove the last instruction, we take the previously remaining instruction
  // in case it's not the current one
  if (updatingInstruction && !instructionId) {
    const { item } = store.getState().detailsPanel
    return { instruction: item.instructions[0].id }
  }
  return {
    instruction: updatingInstruction ? instructionId || store.getState().instruction.instruction.id
      : store.getState().instruction.instruction.id,
  }
}

export const isUpdatingObjectInstruction = (object: InstructionItem) => object.instructions.length
!== store.getState().detailsPanel.item.instructions.length

export const formatLineChoice = (l: nestedObject | string | undefined): string => (
  l && typeof l !== 'string'
    ? `${l.lineCode}`
    : '')

export const formatTrackChoice = (o: nestedObject | string | undefined): string => (
  o && typeof o !== 'string' && o.name ? `${o.name}` : '')

export const formatExtremitySignalChoice = (o: nestedObject | undefined): string => {
  if (o?.mainRepresentation) {
    return o.mainRepresentation as string
  }
  if (typeof o === 'string') {
    return o
  }
  return 'Signal'
}

export const formatExtremityIsolatorChoice = (o: nestedObject | undefined): string => {
  if (o?.mainRepresentation) {
    return o.mainRepresentation as string
  }
  if (typeof o === 'string') {
    return o
  }
  return 'Joint de Zone'
}

export const formatExtremityTrackChoice = (): string => 'Voie'

export const formatIdentityChoice = (v: nestedObject | string | undefined): string => (
  v && typeof v === 'string'
    ? v
    : ''
)

export const fieldValueToString = (v: StrOrNumOrBool | null): StrOrNum => {
  switch (v) {
    case null:
      return NULL_STRING
    case false:
      return BooleanType.False
    case true:
      return BooleanType.True
    default:
      return v
  }
}

export const parseStringFieldValue = (v: FieldValue): FieldValue => {
  switch (v) {
    case NULL_STRING:
      return null
    case BooleanType.False:
    case BooleanType.True:
      return JSON.parse(v)
    default:
      return v
  }
}

export function getUpdatedFields<T>(attributes: Attribute[]): Array<keyof T> {
  return [
    ...attributes.filter(att => att.isEditable).map(att => att.key), 'checksum',
  ] as Array<keyof T>
}

export function filterFields<T>(
  partial: Partial<T>, fields: Array<keyof T>, excludedFields: Array<keyof T> = [],
): Partial<T> {
  return (Object.keys(partial) as Array<keyof T>)
    .filter(key => fields.includes(key) && !excludedFields.includes(key))
    .reduce((obj, key) => {
      const value = partial[key]
      if (value !== undefined) {
        return {
          ...obj,
          [key]: value,
        }
      }
      return obj
    }, {})
}

export const filterNonEmptyFields = (obj: Protection, fields: Attribute[]) => {
  const copy = { ...obj }

  // todo generic for other than S11
  fields.forEach(elem => {
    if (elem.removeIfNull && copy[elem.key as keyof Protection] === null) {
      delete copy[elem.key as keyof Protection]
    }
  })
  return copy
}

export const formatLayerUrl = (sourceLayer: string, refresh = false, removeParams = false): string => {
  const { selectedProjection } = store.getState().map as MapState
  const { projection } = store.getState().projection as ProjectionState
  const { instruction } = store.getState().instruction as InstructionState
  const endDateOperator = history.location.pathname.includes('/verification') ? 'gte_or_null' : 'gt_or_null'

  let layerUrl = `${
    MAIN_API.proxy
  }/chartis/v2/layer/${sourceLayer}`

  if (refresh) {
    layerUrl += '/mvt_tile/sch/?x={x}&y={y}&z={z}&'
  } else {
    layerUrl += '/mvt/sch/?'
  }

  if (removeParams) {
    return `${layerUrl}projectionId=${selectedProjection?.id || projection.originalProjection}`
  }

  if (instruction.applicationDate) {
    layerUrl += `activityStartDate__lte_or_null=${
      instruction.applicationDate
    }&activityEndDate__${endDateOperator}=${
      instruction.applicationDate
    }`
  }
  const params = instruction.applicationDate ? '&' : ''

  if (selectedProjection !== undefined) {
    layerUrl += `${params}projectionId=${selectedProjection.id}`
  } else if (projection && projection.originalProjection !== undefined) {
    layerUrl += `${params}projectionId=${projection.originalProjection}`
  }

  return layerUrl
}

export const supervisorFilter = () => {
  const { instruction } = store.getState().instruction

  if (!instruction.applicationDate) {
    return ['all', true]
  }

  return ['all',
    ['!', ['all', ['>=', ['get', 'status'], 30], ['in', instruction.applicationDate, ['get', 'activityEndDate']]]],
    ['!', ['all', ['in', instruction.applicationDate, ['get', 'activityEndDate']], ['==', ['get', 'deletedAt'], null]]]]
}

export const lineFilter = () => ['all', supervisorFilter(),
  ['all', ['!=', ['geometry-type'], 'Point'], ['!=', ['geometry-type'], 'MultiPoint']]]

export const EPGFilter = () => ['all', supervisorFilter(),
  ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']]]
