import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import Loader from 'components/Common/Loader'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { Instruction, TaskStatus, User } from 'objects/types/instructions'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminState } from 'reducers/admin'
import {
  InstructionState,
  resetExport,
  resetInstruction, setInstruction,
} from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import UserBadges from '../UserBadges'
import './InstructionDashboard.scss'
import Parameters from './Parameters/Parameters'
import ObjectStats from './Statistics/ObjectStats'

type Props = {
  instruction: Instruction;
}

const EXPORT_REFRESH_RATE = 2000

const GAIA_DAG = 'import_gaia'

export default function InstructionDashboard({ instruction }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { usersInfo, instructionDetails } = useSelector((state: RootState) => state.admin) as AdminState
  const {
    gaiaExportResult, loading,
    exportStatus, exportFile, dagStatus,
  } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { selectedRegion } = useSelector((state: RootState) => state.regions)
  const [displayParamsModal, setDisplayParamsModal] = useState(false)
  const fetchStatus = useRef<NodeJS.Timer | NodeJS.Timeout>()

  const fileName = `${instruction.name}-${
    new Date(instruction.applicationDate).toLocaleDateString('fr-FR')}`

  useEffect(() => {
    dispatch(InstructionServices.getDetails(instruction.id))
    if (!dagStatus.id) {
      dispatch(InstructionServices.getDagStatus(GAIA_DAG))
    }
  }, [])

  const clearFetchInterval = () => {
    clearInterval(fetchStatus.current as NodeJS.Timeout)
    fetchStatus.current = undefined
  }

  const checkStatus = (id: string) => {
    if (fetchStatus.current === undefined) {
      fetchStatus.current = (setInterval(() => dispatch(InstructionServices.getExportStatus(id)),
        EXPORT_REFRESH_RATE))
    }
  }

  useEffect(() => {
    if (!exportFile) return

    const url = window.URL.createObjectURL(
      new Blob([exportFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
    )

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.xlsx`)
    document.body.appendChild(link)
    link.click()

    dispatch(resetExport())
  }, [exportFile])

  useEffect(() => {
    if (gaiaExportResult) {
      checkStatus(gaiaExportResult)
    }
  }, [gaiaExportResult])

  useEffect(() => {
    if (exportStatus?.taskStatus !== TaskStatus.PENDING) {
      clearFetchInterval()
      if (exportStatus?.taskStatus === TaskStatus.SUCCESS) {
        dispatch(InstructionServices.getExportFile(exportStatus.id))
      }
    }
  }, [exportStatus])

  const exportDispatchedData = () => {
    dispatch(InstructionServices.exportDispatchedData(instruction.id))
  }

  useEffect(() => {
    setDisplayParamsModal(false)
  }, [selectedRegion])

  return (
    <div className="instruction-dashboard d-flex w-100">
      {loading && <Loader overlay />}
      <div className="parameters">
        <div>
          <h2>{`Utilisateurs (${instruction.users.length})`}</h2>
          <div className="my-3">
            <UserBadges userIds={instruction.users as User[]} usersInfo={usersInfo} />
          </div>
        </div>
        <div>
          <SimpleButton
            title={t('Instruction.dashboard.buttons.openParameters')}
            style={ButtonStyle.light}
            onClick={() => {
              dispatch(setInstruction(instructionDetails))
              setDisplayParamsModal(true)
            }}
          />
        </div>
      </div>
      <div style={{ paddingLeft: '20px' }} className="objects d-flex">
        <div style={{ overflow: 'auto', width: '100%' }}>
          <ObjectStats />
        </div>
      </div>
      <div className="overview d-flex flex-column">
        <div className="title">
          {t('Instruction.dashboard.titles.overview')}
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        >
          {instructionDetails.publishedObjects !== undefined && (
            <>
              <span style={{ fontSize: '32px', fontWeight: 800, color: '#3C3C3B' }}>
                {Math.floor(instructionDetails.publishedObjects)}
                %
              </span>
              <span>
                {t('Object.kpi.total')}
              </span>
            </>

          )}

        </div>
        <div>
          <SimpleButton
            title={t('Instruction.dashboard.buttons.exportToGaia')}
            style={ButtonStyle.rainbow}
            onClick={exportDispatchedData}
          />
          <div className="d-flex flex-wrap mt-2">
            {dagStatus.dateOfLastRun && (
            <span>
              {t('Instruction.dashboard.gaia.lastSync')}
              {new Date(dagStatus.dateOfLastRun).toLocaleDateString('fr-FR')}
            </span>
            )}

            {dagStatus.dateOfNextRun && (
            <span>
              {t('Instruction.dashboard.gaia.nextSync')}
              {new Date(dagStatus.dateOfNextRun).toLocaleDateString('fr-FR')}
            </span>
            )}
          </div>

        </div>
      </div>
      {displayParamsModal && (
        <Parameters
          onClose={() => {
            setDisplayParamsModal(false)
            dispatch(resetInstruction())
          }}
        />
      )}

      <FeedbackSnackBar />
    </div>
  )
}
