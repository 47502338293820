import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import { RootState } from 'Store'
import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Extremities from 'objects/common/Extremities/Extremities'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { AttributesProps, ExtraKind } from 'objects/types/const'
import { TrackProtectionGroup } from 'objects/types/protections'
import { MapState } from 'reducers/map'
import { CollapsibleMenu } from 'reducers/types'

import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import Instructions from 'objects/common/Instructions/Instructions'
import { ProtectionChild } from 'objects/types/protections/const'
import Composition from './Composition/Composition'
import TRACK_PROTECTION_GROUP_ATTRIBUTES from './TrackProtectionGroupAttributes'
import './TrackProtectionGroupDetails.scss'
import TrackProtectionGroupServices from './TrackProtectionGroupServices'

export default function TrackProtectionGroupDetails({ object }: AttributesProps): ReactElement | null {
  const TPG = object as TrackProtectionGroup
  const { captureClick } = useSelector((state: RootState): MapState => state.map)
  const editable = TPG.inInstruction

  return (
    <div className="track-protection-group-details object-attributes">
      <Attributes item={TPG} attributes={TRACK_PROTECTION_GROUP_ATTRIBUTES(TPG)} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Composition editable={editable} trackProtections={TPG.composition} />
        <Extremities
          editable={editable}
          type={ExtraKind.TPGExtremity}
          extremities={TPG.extremities}
          deleteExtremity={TrackProtectionGroupServices.deleteExtremity}
          deleteExtremities={TrackProtectionGroupServices.deleteElements}
          switchOrientation={TrackProtectionGroupServices.switchExtremityDirection}
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={TPG.responsibleSubAreas}
          deleteRSAAction={TrackProtectionGroupServices.deleteResponsibleSubArea}
          addRSAAction={TrackProtectionGroupServices.addResponsibleSubArea}
          deleteAll={TrackProtectionGroupServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={TPG.associatedSubAreas}
          deleteASAAction={TrackProtectionGroupServices.deleteAssociatedSubArea}
          addASAAction={TrackProtectionGroupServices.addAssociatedSubArea}
          deleteAll={TrackProtectionGroupServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          fieldType={ProtectionChild.incompatibleProtections}
          deleteAll={TrackProtectionGroupServices.deleteElements}
          linkedProtections={TPG.incompatibleProtections}
          deleteAction={TrackProtectionGroupServices.deleteIncompatibleObject}
          addIPAction={TrackProtectionGroupServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
        <Instructions item={TPG} />
      </div>
      {captureClick !== undefined && <div className="capture-overlay" />}
    </div>
  )
}
