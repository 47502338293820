import { Feature, Polygon } from '@nebula.gl/edit-modes'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import MapGL, { MapRef } from 'react-map-gl'
import { DrawPolygonMode, Editor } from 'react-map-gl-draw'
import { useDispatch, useSelector } from 'react-redux'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton, Tooltip } from '@mui/material'
import { RootState } from 'Store'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { EDITOR_MODES, EditorMode, EditorModeName } from 'components/GeoEditor/types'
import ObjectLayers from 'components/Map/ObjectLayers/ObjectLayers'
import Toolbar from 'components/Map/Toolbar/Toolbar'
import { INITIAL_LAYERS } from 'components/Map/const'
import mapStyle from 'components/Map/style_empty.json'
import {
  DEFAULT_VIEWPORT, MAX_ZOOM,
  addImagesToInstance,
  refreshTiles, transformRequest,
} from 'components/Map/utils'
import { InstructionType } from 'objects/types/instructions'
import {
  InstructionState, setActiveSubStep, setInstructionGeom, setPerimeterModification,
} from 'reducers/instruction'
import { MapState, setLayers, setLayersToUpdate } from 'reducers/map'
import InstructionServices from 'services/InstructionServices'

type UpdateEvent = {
  data: Feature[];
  editType: string;
}

type Props = {
  onClose: () => void;
}

export default function NewPerimeterMap({ onClose }: Props): ReactElement {
  const mapRef = useRef<MapRef>(null)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const editorRef = useRef<Editor>(null)
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const { instructionDetails } = useSelector((state: RootState) => state.admin)
  const [bbox, setBbox] = useState<Feature | undefined>(instructionDetails?.boundingBox ? {
    type: 'Feature',
    properties: {},
    geometry: instructionDetails.boundingBox,
  } : undefined)
  const [mode, setMode] = useState<EditorMode>(EDITOR_MODES[instructionDetails?.boundingBox
    ? EditorModeName.Edit : EditorModeName.DrawPolygon])
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { selectedProjection } = useSelector((state: RootState) => state.map) as MapState
  const [disableScroll, setDisableScroll] = useState(false)
  const { layers } = useSelector((state: RootState): MapState => state.map)

  useEffect(() => {
    dispatch(setLayers(INITIAL_LAYERS))
    dispatch(setLayersToUpdate(INITIAL_LAYERS))
    dispatch(setPerimeterModification(true))

    return () => { dispatch(setPerimeterModification(false)) }
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      addImagesToInstance(mapRef.current)
    }
  }, [layers])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [selectedProjection])

  const onUpdate = (params: UpdateEvent) => {
    if (params.editType !== 'addTentativePosition' && params.editType !== 'updateTentativeFeature') {
      if (mode instanceof DrawPolygonMode) {
        setMode(EDITOR_MODES[EditorModeName.Edit])
      }
      setBbox(params.data[0])
    }
  }

  const isButtonDisabled = bbox === undefined

  return (
    <div id="new-perimeter" className="d-flex w-100 flex-column h-100">
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="update-banner">
          <div className="d-flex justify-content-between align-items-center">
            <h1>{t('Instruction.creation.itemSelection.updateTitle')}</h1>
            <div className="return-wrapper">
              <Button variant="outlined" endIcon={<ArrowForwardIcon />} onClick={onClose}>
                <span>{t('Instruction.dashboard.parameters.closeNewPerimeter')}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100" style={{ flex: 1, position: 'relative' }}>
        <div className="reset-polygon-button">
          <Tooltip title="Réinitialiser le polygone" arrow>
            <IconButton
              onClick={() => {
                editorRef.current?.deleteFeatures(0)
                setMode(EDITOR_MODES[EditorModeName.DrawPolygon])
                setBbox(undefined)
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
        <MapGL
          {...viewport}
          ref={mapRef}
          transformRequest={transformRequest}
          maxZoom={MAX_ZOOM}
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => { setViewport(newViewport) }}
          clickRadius={10}
          preventStyleDiffing
          scrollZoom={!disableScroll}
        >
          <ObjectLayers hoveredEvent={undefined} />
          <Editor
            ref={editorRef}
            style={{ width: '100%', height: '100%' }}
            clickRadius={12}
            mode={mode}
            features={bbox ? [bbox] : undefined}
            onUpdate={onUpdate}
            editHandleShape="circle"
            selectedFeatureIndex={0}
            selectable
          />
          <Toolbar disableScroll={setDisableScroll} />
        </MapGL>
      </div>
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={isButtonDisabled}
          style={ButtonStyle.primary}
          title={t('Instruction.button.continue')}
          onClick={() => {
            dispatch(setInstructionGeom(bbox?.geometry as unknown as Polygon))
            dispatch(InstructionServices.getItemsInBbox({
              bbox: bbox?.geometry as Polygon,
              type: instruction.type as InstructionType,
              date: instruction.applicationDate || '',
              instructionId: instruction.id as string,
              projection_id: selectedProjection?.id || '',
              include_deleted: true,
            }))
            dispatch(setActiveSubStep(1))
          }}
        />
      </div>
    </div>
  )
}
