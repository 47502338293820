import { store } from 'Store'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import { CaptureMapClickParams, INDEX_PARAM_PLACEHOLDER } from 'objects/attributes'
import { GetDetailsServiceOfKind, HistoryServiceOfKind } from 'objects/services'
import { DetailsPayload, MidiObject } from 'objects/types'
import { ObjectKind, ObjectLayer } from 'objects/types/const'
import { ElectricalElement, TrackProtection } from 'objects/types/protections'
import { setCaptureClick, setCaptureClickIndex, setSelectedCaptureClickParamsIndex } from 'reducers/map'
import { Action } from 'redux'

export const cleanCaptureClick = (): void => {
  store.dispatch(setCaptureClick(undefined))
  store.dispatch(setCaptureClickIndex(undefined))
}

export const onCaptureTrackProtection = (tp: TrackProtection): void => {
  store.dispatch(TrackProtectionGroupServices.addTrackProtection(tp))
  cleanCaptureClick()
}

export const onCaptureS11Object = (ee: ElectricalElement): void => {
  store.dispatch(ElectricalProtectionGroupServices.addElectricalElement(ee))
  cleanCaptureClick()
}

export const onCaptureSectorObject = (ee: ElectricalElement): void => {
  store.dispatch(SectorServices.addChild(ee))
  cleanCaptureClick()
}

export const onCaptureSubSectorObject = (ee: ElectricalElement): void => {
  store.dispatch(SubSectorServices.addChild(ee))
  cleanCaptureClick()
}

export const getSelectedCaptureClickParam = (
  capture: CaptureMapClickParams | CaptureMapClickParams[], layer: ObjectLayer,
): CaptureMapClickParams => {
  const isArray = Array.isArray(capture)
  if (isArray) {
    store.dispatch(
      setSelectedCaptureClickParamsIndex(capture.findIndex(param => param.objectLayer === layer)),
    )
    return capture.find(param => param.objectLayer === layer) as CaptureMapClickParams
  }
  return capture
}
export const getSelectedMergeObject = (object: MidiObject, kind: ObjectKind, currentItem: MidiObject) => {
  store.dispatch(GetDetailsServiceOfKind[kind]({ id: object.id, merge: true }) as unknown as Action<DetailsPayload>)
  store.dispatch(HistoryServiceOfKind[kind]({ id: object.id, merge: true }))
  store.dispatch(HistoryServiceOfKind[kind]({ id: (currentItem as MidiObject).id }))
}

export const formatPath = (captureClickIndex: number | undefined, path: string | string[]): string | string [] => {
  // Replace placeholder by the actuel clicked index, if object is a nested array
  if (captureClickIndex !== undefined && typeof path !== 'string') {
    return path.map(pathEl => {
      if (pathEl === INDEX_PARAM_PLACEHOLDER) return String(captureClickIndex)
      return pathEl
    })
  }
  return path
}
