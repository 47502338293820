/* eslint-disable @typescript-eslint/no-explicit-any */
import { FeatureCollection, LineString, Polygon } from 'geojson'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Projection } from 'objects/types/projections'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'
import { ShortTrackSection } from 'objects/types'
import { filterFields } from 'objects/utils'
import { MIDI_URI } from 'objects/uri'

const getAll = createAsyncThunk<Projection[], undefined, ThunkApiConfig>(
  'projection/getAll',
  async (_unused, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${URI.projections}/`)
      return response.results as Projection[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDetails = createAsyncThunk<Projection, string, ThunkApiConfig>(
  'projection/getDetails',
  async (id: string, thunkApi) => {
    try {
      const response: Projection = await get(`/${MIDI_URI}/${URI.projections}/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Projection, Partial<Projection>, ThunkApiConfig>(
  'projection/create',
  async (newInstruction, thunkApi) => {
    try {
      const response: Projection = await post(`/${MIDI_URI}/${URI.projections}/`, newInstruction)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type GetItemsInBboxParams = {
  bbox: Polygon;
  originProjection: string;
  pageSize: number;
}

export const getItemsInBbox = createAsyncThunk<
  FeatureCollection<LineString, ShortTrackSection>, GetItemsInBboxParams, ThunkApiConfig
>(
  'projection/getItemsInBbox',
  async (params, thunkApi) => {
    try {
      const response: FeatureCollection<LineString, ShortTrackSection> = await get(
        '/chartis/v2/layer/midi_ve_track_section/geojson/sch/',
        { bpolygon: params.bbox, projectionId: params.originProjection, page_size: params.pageSize },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const UPDATABLE_FIELDS: Array<keyof Projection> = [
  'name', 'checksum',
]

const update = createAsyncThunk<Projection, Partial<Projection>, ThunkApiConfig>(
  'projection/update',
  async (updatedProjection, thunkApi) => {
    try {
      const response: Projection = await patch(
        `/${MIDI_URI}/${URI.projections}/${updatedProjection.slug}`,
        filterFields<Projection>(updatedProjection, UPDATABLE_FIELDS),
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'projection/delete',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${URI.projections}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ProjectionServices = {
  getAll,
  getDetails,
  create,
  update,
  getItemsInBbox,
  delete: deleteObject,
}

export default ProjectionServices
