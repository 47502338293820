/* eslint-disable react/destructuring-assignment */
import { auth } from '@osrdata/app_core'
import { ReactElement } from 'react'

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import DesignServicesIcon from '@mui/icons-material/DesignServices'

import {
  ADMIN_HOMEPATH, MANAGER_HOMEPATH, OPERATOR_HOMEPATH, SUPERVISOR_HOMEPATH,
} from 'Routes'
import { store } from 'Store'
import history from 'customHistory'
import { isAdmin, isManager, isSupervisor } from 'helpers/permissions'
import { setActiveTab as setAdminActiveTab } from 'reducers/admin'
import { setRole } from 'reducers/app'
import { resetInstruction } from 'reducers/instruction'
import { resetMap } from 'reducers/map'
import { resetOperator, setActiveTab as setOperatorActiveTab } from 'reducers/operator'
import { resetListPanel } from 'reducers/panels/listPanel'
import { resetPanel } from 'reducers/panels/panel'
import { resetSupervisor, setActiveTab as setSupervisorActiveTab } from 'reducers/supervisor'
import MidiRole from 'services/midiRoleTypes'
import { setSelectedRegion } from 'reducers/regions'

type MenuItemType = {
  label: string;
  icon?: ReactElement;
  onClick: () => void;
  show: boolean;
}

const resetReducers = () => {
  store.dispatch(resetPanel())
  store.dispatch(resetListPanel())
  store.dispatch(resetMap())
  store.dispatch(resetInstruction())
  store.dispatch(resetOperator())
  store.dispatch(resetSupervisor())
}

const ACCOUNT_MENU_ITEMS = (currentPath :string): MenuItemType[] => [
  {
    label: 'TopBar.goToOperator',
    icon: <ModeEditOutlinedIcon />,
    onClick: () => {
      history.push(OPERATOR_HOMEPATH)
      resetReducers()
      store.dispatch(setRole(MidiRole.operator))
      store.dispatch(setOperatorActiveTab(0))
    },
    show: (isSupervisor() || isManager()) && !currentPath.includes(OPERATOR_HOMEPATH),
  },
  {
    label: 'TopBar.goToManager',
    icon: <DesignServicesIcon />,
    onClick: () => {
      history.push(MANAGER_HOMEPATH)
      resetReducers()
      store.dispatch(setSelectedRegion('all'))
      store.dispatch(setRole(MidiRole.manager))
      store.dispatch(setOperatorActiveTab(0))
    },
    show: isManager() && !currentPath.includes(MANAGER_HOMEPATH),
  },
  {
    label: isAdmin() ? 'TopBar.goToSupervisor' : 'TopBar.backToSupervisor',
    icon: <DoneOutlinedIcon />,
    onClick: () => {
      history.push(SUPERVISOR_HOMEPATH)
      resetReducers()
      store.dispatch(setRole(MidiRole.supervisor))
      store.dispatch(setSupervisorActiveTab(0))
    },
    show: isSupervisor() && !currentPath.includes(SUPERVISOR_HOMEPATH),
  },
  {
    label: 'TopBar.backToAdmin',
    icon: <AccountBoxOutlinedIcon />,
    onClick: () => {
      history.push(ADMIN_HOMEPATH)
      store.dispatch(setRole(MidiRole.admin))
      store.dispatch(setAdminActiveTab(0))
    },
    show: isAdmin() && !currentPath.includes(ADMIN_HOMEPATH),
  },
  {
    label: 'TopBar.disconnect',
    icon: <PowerSettingsNewOutlinedIcon />,
    onClick: () => auth.logout()(store.dispatch),
    show: true,
  },
]

export default ACCOUNT_MENU_ITEMS
