import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, ReactNode } from 'react'

type Props = {
  open: boolean;
  toggle: () => void;
  actionToConfirm: () => void;
  title: ReactNode;
  content: ReactNode;
}
export default function ConfirmationDialog({
  open, toggle, actionToConfirm, title, content,
}: Props): ReactElement {
  const { t } = useTranslation()

  const confirmAction = () => {
    toggle()
    actionToConfirm()
  }

  return (
    <Dialog open={open}>

      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        {content}
      </DialogContent>

      <DialogActions>
        <Button onClick={toggle}>{t('Common.button.cancel')}</Button>
        <Button onClick={confirmAction}>{t('Common.button.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}
