import { ReactElement } from 'react'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Box,
} from '@mui/material'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import logo from 'assets/logos/logo-DGEXSOL-SNCF-colo.svg'

type Props = {
  handleOpen: () => void;
  isOpen: boolean;
}

export default function Cgu({ handleOpen, isOpen }: Props): ReactElement {
  const { t } = useTranslation()
  return (
    <Dialog
      open={!isOpen}
      disableEscapeKeyDown
    >
      <Box p={2} display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <img alt="" src={logo} width="200" />
        <DialogTitle>
          {t('CGU.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('CGU.contentText1')}
          </DialogContentText>
          <DialogContentText>
            <a
              href="https://digidocplus.sncf.fr/visionneusePdf/1702381270603qHBJVDp"
              target="_blank"
              rel="noreferrer"
            >
              {t('CGU.contentLink1')}

            </a>
          </DialogContentText>
        </DialogContent>
        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText>
            {t('CGU.contentText2')}
          </DialogContentText>
          <DialogContentText>
            <a
              href="https://sncf.sharepoint.com/sites/Les10CybeReflexes"
              target="_blank"
              rel="noreferrer"
            >
              {t('CGU.contentLink2')}
            </a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <SimpleButton
            style={ButtonStyle.primary}
            title={t('CGU.btn')}
            onClick={handleOpen}
          />
        </DialogActions>
      </Box>

    </Dialog>
  )
}
