import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'

import { RootState } from 'Store'
import RegionDropdown from 'components/Common/RegionDropdown/RegionDropdown'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { isStringEmpty } from 'helpers'
import { Instruction } from 'objects/types/instructions'
import {
  InstructionState, setActiveStep, setInstructionDate, setInstructionRef,
  setInstructionRegions,
  setInstructionVersion, setInstructionVersionDate,
} from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import './RefChoice.scss'

const INPUTS = (instruction: Partial<Instruction>) => [
  {
    label: 'Instruction.creation.refChoice.refInput.label',
    placeholder: 'Instruction.creation.refChoice.refInput.placeholder',
    value: instruction.reference,
    updateFunction: setInstructionRef,
  },
  {
    label: 'Instruction.creation.refChoice.versionInput.label',
    placeholder: 'Instruction.creation.refChoice.versionInput.placeholder',
    value: instruction.version,
    updateFunction: setInstructionVersion,
  },
  {
    label: 'Instruction.creation.refChoice.versionDateInput.label',
    placeholder: 'Instruction.creation.refChoice.versionDateInput.placeholder',
    value: instruction.versionDate,
    updateFunction: setInstructionVersionDate,
    type: 'date',
  },
  {
    label: 'Instruction.creation.refChoice.dateInput.label',
    placeholder: 'Instruction.creation.refChoice.dateInput.placeholder',
    value: instruction.applicationDate,
    updateFunction: setInstructionDate,
    type: 'date',
  },
  {
    label: 'Instruction.creation.refChoice.regionInput.label',
    placeholder: 'Instruction.creation.refChoice.regionInput.placeholder',
    value: instruction.regions,
    updateFunction: setInstructionDate,
    type: 'custom',
  },

]

export default function InstructionRefChoice(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { instruction, activeStep, loading } = useSelector((state: RootState) => state.instruction) as InstructionState
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    if (!instruction.regions?.length) {
      dispatch(setInstructionRegions([]))
    }
  }, [])

  const isButtonDisabled = isStringEmpty(instruction.reference)
    || isStringEmpty(instruction.applicationDate)
    || isStringEmpty(instruction.version)
    || !instruction.regions?.length
    || loading

  return (
    <div id="instruction-ref-choice" className="d-flex w-100 flex-column h-100">
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper text-center d-flex justify-content-center align-items-center">
            <h1>{t('Instruction.creation.refChoice.title')}</h1>
          </div>
          <div className="d-flex input-wrapper justify-content-around align-items-center w-100 flex-column">
            {INPUTS(instruction).map((input, index) => (
              <FormControl key={input.label} variant="standard" className="m-auto w-50 my-2">
                <InputLabel shrink>{t(input.label)}</InputLabel>
                {input.type === 'custom' ? (
                  <RegionDropdown
                    object={instruction}
                    setRegions={newRegion => dispatch(setInstructionRegions(newRegion))}
                  />
                )
                  : (
                    <>
                      <Input
                        type={input.type || 'text'}
                        autoFocus={index === 0}
                        placeholder={t(input.placeholder)}
                        className="light-input"
                        value={input.value}
                        onChange={e => {
                          setHasChanged(true)
                          dispatch(input.updateFunction(e.target.value))
                        }}
                        disableUnderline
                      />
                    </>
                  )}
              </FormControl>
            ))}
          </div>
        </div>
      </div>
      <div className="blank-footer" />
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={isButtonDisabled}
          style={ButtonStyle.primary}
          title={t('Instruction.button.continue')}
          onClick={() => {
            if (instruction.id) {
              if (hasChanged) dispatch(InstructionServices.update(instruction))
              dispatch(setActiveStep(activeStep + 1))
            } else {
              dispatch(InstructionServices.create(instruction))
            }
          }}
        />
      </div>
    </div>
  )
}
