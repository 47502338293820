import {
  createSlice,
  isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import GeoEditorService from 'components/GeoEditor/GeoEditorService'
import { Feature, Polygon } from 'geojson'
import { CaptureMapClickParams } from 'objects/attributes'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SignalServices from 'objects/Signals/SignalServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ExtraLayer, ObjectKind, ObjectLayer } from 'objects/types/const'
import { Projection } from 'objects/types/projections'
import { ResponseError } from 'types'

import { DEFAULT_PROJECTION_SLUG } from 'config/config'
import { findObjectKind, KIND_TO_LAYER } from 'objects/kind'
import { TaskStatus } from 'objects/types/instructions'
import ObjectServices from 'services/ObjectServices'
import ProjectionServices from 'services/ProjectionServices'
import { createFulfilledDisplayedObjMatcher, createFulfilledMatcher } from './matchers/createMatchers'
import { deleteFulfilledMatcher } from './matchers/deleteMatchers'
import { getGeomFulfilledMatcher, getGeomPendingMatcher } from './matchers/getGeomMatchers'
import allErrorsMatcher from './matchers/matchers'
import { revertFulfilledMatcher } from './matchers/restoreMatchers'
import { updateFulfilledMatcher } from './matchers/update'
import { validationFulfilledMatcher } from './matchers/validationMatchers'

const ALL_EXTRA_LAYERS = [ExtraLayer.Direction]

const ALL_OBJECT_LAYERS = [
  ObjectLayer.TrackSection, ObjectLayer.Signal, ObjectLayer.TrackProtection, ObjectLayer.Feeder, ObjectLayer.TrackNode,
  ObjectLayer.Tunnel,
]

const ALL_LINEAR_LAYERS = [ObjectLayer.TrackProtectionGroup,
  ObjectLayer.ElectricalProtectionGroup,
  ObjectLayer.ElectricalElement, ObjectLayer.ElectricalProtectionGroup]

const LINEAR_KIND = [ObjectKind.TrackProtectionGroup, ObjectKind.ElectricalProtectionGroup]

export interface MapState {
  isLoading: boolean;
  loadingMessage?: string;
  centeredFeature: Feature | undefined;
  layers: ObjectLayer[];
  layersToUpdate: Array<ObjectLayer | ExtraLayer>;
  hoveredPanelObject?: ShortMidiObject;
  error?: ResponseError;
  captureClick?: CaptureMapClickParams | CaptureMapClickParams[];
  selectedCaptureClickParamsIndex?: number; // used when captureClick is an array
  captureClickIndex?: number; // used for input groups with several capture clicks
  selectedProjection?: Projection;
  mergeCaptureClick: boolean;
  viewportBbox: Polygon | undefined;
}

export const initialState: MapState = {
  isLoading: false,
  centeredFeature: undefined,
  layers: [ObjectLayer.TrackSection, ObjectLayer.Signal],
  layersToUpdate: [],
  mergeCaptureClick: false,
  viewportBbox: undefined,
}

const addLayerToUpdate = (state: MapState, layers: Array<ObjectLayer | ExtraLayer>, setLayer = false) => {
  if (setLayer) state.layersToUpdate = layers
  else {
    layers.forEach(layer => {
      if (!state.layersToUpdate.includes(layer)) {
        state.layersToUpdate.push(layer)
      }
    })
  }
}

const updateLoading = (state: MapState, message: string | undefined = undefined) => {
  if (message) {
    state.isLoading = true
    state.loadingMessage = message
  } else {
    state.isLoading = false
    state.loadingMessage = undefined
  }
}

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    updateCenteredFeature: (state, action: PayloadAction<Feature | undefined>) => {
      state.centeredFeature = action.payload
    },
    addLayer: (state, action: PayloadAction<ObjectLayer>) => {
      if (!state.layers.includes(action.payload)) {
        state.layers.push(action.payload)
      }
    },
    removeLayer: (state, action: PayloadAction<ObjectLayer>) => {
      state.layers = state.layers.filter(l => l !== action.payload)
    },
    setLayers: (state, action: PayloadAction<ObjectLayer[]>) => {
      state.layers = action.payload
    },
    setLayersToUpdate: (state, action: PayloadAction<Array<ObjectLayer | ExtraLayer>>) => {
      state.layersToUpdate = action.payload
    },
    setHoveredPanelObject: (state, action: PayloadAction<ShortMidiObject | undefined>) => {
      state.hoveredPanelObject = action.payload
    },
    resetError: state => {
      state.error = undefined
    },
    setError: (state, action: PayloadAction<ResponseError>) => {
      state.error = action.payload
    },
    setCaptureClick: (state, action: PayloadAction<CaptureMapClickParams | CaptureMapClickParams[] | undefined>) => {
      state.captureClick = action.payload
    },
    setSelectedCaptureClickParamsIndex: (state, action: PayloadAction<number | undefined>) => {
      state.selectedCaptureClickParamsIndex = action.payload
    },
    setCaptureClickIndex: (state, action: PayloadAction<number | undefined>) => {
      state.captureClickIndex = action.payload
    },
    setSelectedProjection: (state, action: PayloadAction<Projection | undefined>) => {
      state.selectedProjection = action.payload
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
    },
    setMergeCaptureClick: (state, action: PayloadAction<boolean>) => {
      state.mergeCaptureClick = action.payload
    },
    setViewportBbox: (state, action: PayloadAction<Polygon | undefined>) => {
      state.viewportBbox = action.payload
    },
  },
  extraReducers: builder => {
    // GeoEditor
    builder.addCase(GeoEditorService.getTrackSectionGeometry.pending, state => {
      updateLoading(state, 'Map.Loader.switchingToEditor')
    })
    builder.addCase(GeoEditorService.updateTrackSectionGeometry.pending, state => {
      updateLoading(state, 'Map.Loader.updatingGeometry')
    })
    builder.addCase(GeoEditorService.getTrackSectionGeometry.fulfilled, (state, action) => {
      updateLoading(state)
      state.centeredFeature = action.payload
    })

    builder.addCase(ProjectionServices.getAll.fulfilled, (state, action) => {
      state.selectedProjection = action.payload.find(s => s.slug === DEFAULT_PROJECTION_SLUG)
    })

    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (state, action) => {
      if (action.payload.taskStatus === TaskStatus.FAILURE) {
        updateLoading(state)
        return
      }
      if (action.payload.taskStatus === TaskStatus.SUCCESS) {
        updateLoading(state)
        addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS, ObjectLayer.TrackProtectionGroup,
          ObjectLayer.ElectricalProtectionGroup,
          ObjectLayer.ElectricalElement, ObjectLayer.ElectricalProtectionGroup], true)
      }
    })

    builder.addMatcher(createFulfilledDisplayedObjMatcher, (state, action) => {
      const newKind = KIND_TO_LAYER[findObjectKind(action.payload)]
      if (newKind && !state.layers.includes(newKind)) {
        state.layers = [...state.layers, newKind]
      }
    })

    // Error Handling
    builder.addMatcher(allErrorsMatcher, state => {
      updateLoading(state)
    })

    // CenterFeature
    builder.addMatcher(getGeomFulfilledMatcher, (state, action) => {
      updateLoading(state)
      state.centeredFeature = action.payload
    })
    builder.addMatcher(getGeomPendingMatcher, (state, action) => {
      updateLoading(state, 'Map.Loader.fetchingGeometry')
      state.centeredFeature = action.payload
    })

    // Delete object
    builder.addMatcher(isAnyOf(
      TrackSectionServices.delete.pending,
      IsolatorServices.delete.pending,
      SignalServices.delete.pending,
      TrackProtectionServices.delete.pending,
    ), state => {
      updateLoading(state, 'Map.Loader.deletingObject')
    })
    builder.addMatcher(isAnyOf(deleteFulfilledMatcher, revertFulfilledMatcher), state => {
      updateLoading(state)
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS, ObjectLayer.TrackProtectionGroup,
        ObjectLayer.ElectricalProtectionGroup,
        ObjectLayer.ElectricalElement, ObjectLayer.ElectricalProtectionGroup], true)
    })

    // Create Object
    builder.addMatcher(createFulfilledMatcher, (state, action) => {
      updateLoading(state)
      state.selectedCaptureClickParamsIndex = undefined
      const layerKind = findObjectKind(action.payload as MidiObject)
      if (LINEAR_KIND.includes(layerKind)) {
        addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS, KIND_TO_LAYER[layerKind]], true)
      } else {
        addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
      }
    })

    // Update Object
    builder.addMatcher(updateFulfilledMatcher, (state, action) => {
      updateLoading(state)
      const layerKind = findObjectKind(action.payload as MidiObject)
      if (LINEAR_KIND.includes(layerKind)) {
        addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS, KIND_TO_LAYER[layerKind]], true)
      } else {
        addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS], true)
      }
      state.selectedCaptureClickParamsIndex = undefined
    })

    // (In)Validate object
    builder.addMatcher(isAnyOf(validationFulfilledMatcher, ObjectServices.mergeHistories.fulfilled), state => {
      state.mergeCaptureClick = false
      addLayerToUpdate(state, [...ALL_OBJECT_LAYERS, ...ALL_EXTRA_LAYERS, ...ALL_LINEAR_LAYERS], true)
    })
  },
})

export const {
  setIsLoading, updateCenteredFeature, addLayer, removeLayer, setLayers, setLayersToUpdate,
  setHoveredPanelObject, setCaptureClick, setCaptureClickIndex, setSelectedCaptureClickParamsIndex,
  reset: resetMap, setSelectedProjection, setMergeCaptureClick, setViewportBbox,
} = mapSlice.actions

export default mapSlice.reducer
