import { ChangeEvent, ReactElement, useEffect } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { MapState, setSelectedProjection } from 'reducers/map'
import ProjectionServices from 'services/ProjectionServices'
import { AdminState } from 'reducers/admin'
import { RootState } from 'Store'
import './ProjectionsMenu.scss'

type Props = {
  display: boolean;
  captureClick: (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export default function ProjectionsMenu({ display, captureClick }: Props): ReactElement {
  const dispatch = useDispatch()
  const { projectionsList } = useSelector((state: RootState): AdminState => state.admin)
  const { selectedProjection } = useSelector((state: RootState): MapState => state.map)

  useEffect(() => {
    dispatch(ProjectionServices.getAll())
  }, [])

  const onSelectProjection = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedProjection(projectionsList.find(s => s.id === event.target.value)))
  }

  return (
    <div
      id="projections-menu"
      className={`toolbar-menu ${display ? 'display' : ''}`}
      onClickCapture={e => captureClick(e)}
    >
      <RadioGroup
        value={selectedProjection?.id}
        onChange={onSelectProjection}
      >
        {projectionsList.map(projection => (
          <div key={projection.id} className="d-flex align-items-center">
            <FormControlLabel
              value={projection.id}
              control={<Radio checked={projection.id === selectedProjection?.id} />}
              label={projection.name}
            />
          </div>
        ))}
      </RadioGroup>
    </div>

  )
}
