/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask, DetailsPayload, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { Sector } from 'objects/types/protections'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter, filterFields, filterNonEmptyFields, getUpdatedFields, isUpdatingObjectInstruction,
} from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'
import SECTOR_ATTRIBUTES from '../SectorAttributes'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addChild, deleteChild } from './children'
import {
  addExtremity,
  deleteElements,
  deleteExtremity, switchExtremityDirection,
} from './extremities'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'

const getDetails = createAsyncThunk<Sector, DetailsPayload, ThunkApiConfig>(
  'sector/getDetails',
  async (params, thunkApi) => {
    try {
      const response: Sector = await get(`/${MIDI_URI}/${ObjectURI.Sectors}/${params.id}`,
        { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Sector>, string, ThunkApiConfig>(
  'sector/getGeometry',
  async (id, thunkApi) => {
    try {
      const projectionId = store.getState().map.selectedProjection.id
      const response: Feature<Geometry, Sector> = await get(
        `/chartis/v2/layer/${ObjectLayer.Sector}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Sector, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'sector/create',
  async (newSector, thunkApi) => {
    try {
      const sector = filterNonEmptyFields(newSector as unknown as Sector, SECTOR_ATTRIBUTES())
      const response: Sector = await post(
        `/${MIDI_URI}/${ObjectURI.Sectors}/`,
        sector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, Partial<Sector>, ThunkApiConfig>(
  'sector/updateById',
  async (updatedSector, thunkApi) => {
    try {
      const sector = filterNonEmptyFields(updatedSector as unknown as Sector, SECTOR_ATTRIBUTES())
      const updatingInstruction = isUpdatingObjectInstruction(sector)
      const newSector = updatingInstruction ? { instructions: updatedSector.instructions }
        : filterFields(sector, getUpdatedFields(SECTOR_ATTRIBUTES()))

      const response: Sector = await patch(
        `/${MIDI_URI}/${ObjectURI.Sectors}/${updatedSector.id}`,
        newSector,
        {
          params: addInstructionParameter(updatingInstruction, newSector.instructions?.
            [newSector.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'sector/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.Sectors}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Sector, ValidationParams, ThunkApiConfig>(
  'sector/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Sector = await post(
        `/${MIDI_URI}/${ObjectURI.Sectors}/${id}/${operation}/`,
        {}, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'sector/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.Sectors}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'sector/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Sectors}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<Sector, string, ThunkApiConfig>(
  'sector/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Sectors}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const SectorServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addIncompatibleObject,
  deleteIncompatibleObject,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addChild,
  deleteChild,
  addExtremity,
  switchExtremityDirection,
  deleteExtremity,
  deleteElements,
  getHistory,
  revert,
  resetGaiaId,
}

export default SectorServices
