import { get } from '@osrdata/app_core/dist/requests'
import {
  createAsyncThunk, createSlice,
  isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import { ShortMidiObject } from 'objects/types'
import { ObjectStatus } from 'objects/types/const'
import { InstructionItem, TaskStatus, TaskType } from 'objects/types/instructions'
import { MIDI_URI } from 'objects/uri'
import { createFulfilledDisplayedObjMatcher } from 'reducers/matchers/createMatchers'
import { deleteFulfilledMatcher } from 'reducers/matchers/deleteMatchers'
import { updateFulfilledMatcher } from 'reducers/matchers/update'
import { validationFulfilledMatcher } from 'reducers/matchers/validationMatchers'
import InstructionServices from 'services/InstructionServices'
import ObjectServices from 'services/ObjectServices'
import { ThunkApiConfig } from 'types'

export interface ListPanelState {
  items: InstructionItem[];
  isLoading: boolean;
  errorCode?: number;
  pageNumber: number;
  totalItemsCount: number;
  shouldRefresh: boolean;
  filterObjectType: string;
}

const initialState: ListPanelState = {
  items: [],
  isLoading: true,
  pageNumber: 1,
  totalItemsCount: 0,
  shouldRefresh: false,
  filterObjectType: '',
}

export type GetZoneObjectsParams = {
  id: string;
  page: number;
  status?: ObjectStatus;
  statusLTE?: ObjectStatus;
  statusGTE?: ObjectStatus;
  search?: string;
  filterObjectType?: string;
}

type GetZoneObjectsResponse = {
  totalItemsCount: number;
  items: InstructionItem[];
}

// TODO: Create a Zone/Projection service
export const getZoneObjects = createAsyncThunk<GetZoneObjectsResponse, GetZoneObjectsParams, ThunkApiConfig>(
  'listPanel/getZoneObjects',
  async (params, thunkApi) => {
    const queryParams = (({
      page, status, statusLTE, statusGTE, search, filterObjectType,
    }) => ({
      page, status, status__lte: statusLTE, status__gte: statusGTE, search, item_type: filterObjectType,
    }))(params)
    try {
      const response = await get(`/${MIDI_URI}/instructions/${params.id}/items/`, {
        ...queryParams,
      })
      return {
        totalItemsCount: response.count,
        items: response.results as InstructionItem[],
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

// TODO: better function naming
const getObjects = (state: ListPanelState, action: PayloadAction<GetZoneObjectsResponse>) => {
  state.isLoading = false
  if (state.pageNumber > 1) {
    state.items.push(...action.payload.items)
  } else {
    state.items = action.payload.items
  }
  state.totalItemsCount = action.payload.totalItemsCount
  state.errorCode = undefined
}

// TODO: move extraReducers elsewhere
export const listPanelSlice = createSlice({
  name: 'listPanel',
  initialState,
  reducers: {
    updateItems: (state, action: PayloadAction<ShortMidiObject[]>) => {
      state.items = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setShouldRefresh: (state, action: PayloadAction<boolean>) => {
      state.shouldRefresh = action.payload
    },
    resetPageNumber: state => {
      state.pageNumber = 1
    },
    resetTotalItemsCount: (state, action: PayloadAction<number>) => {
      state.totalItemsCount = action.payload
    },
    setFilterObjectType: (state, action: PayloadAction<string>) => {
      state.filterObjectType = action.payload
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(getZoneObjects.fulfilled, getObjects)
    builder.addCase(InstructionServices.getDetails.fulfilled, () => initialState)
    builder.addCase(getZoneObjects.pending, state => {
      if (state.pageNumber > 1) {
        state.isLoading = false
      } else {
        state.isLoading = true
      }
    })
    builder.addCase(getZoneObjects.rejected, (state, action) => {
      state.isLoading = false
      state.errorCode = action.payload?.code
    })
    builder.addCase(InstructionServices.removeItem.fulfilled, (state, action) => {
      state.items = state.items.filter(item => item.id !== action.meta.arg.itemId)
    })
    builder.addCase(ObjectServices.mergeHistories.fulfilled, (state, action) => {
      state.items = state.items.flatMap(item => {
        if (item.id === action.meta.arg.objectToMerge) {
          return []
        }
        if (item.id === action.payload.id) {
          return { ...item, inConflict: action.payload.inConflict }
        }
        return item
      })
    })
    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (state, action) => {
      if (action.payload.taskStatus !== TaskStatus.SUCCESS) return

      if (action.payload.taskName === TaskType.deleteTrackSection
        || action.payload.taskName === TaskType.updateTrackSection) {
        state.pageNumber = 1
        state.shouldRefresh = true
      }
    })
    builder.addMatcher(isAnyOf(
      createFulfilledDisplayedObjMatcher, updateFulfilledMatcher, deleteFulfilledMatcher,
      validationFulfilledMatcher,
    ), state => {
      state.pageNumber = 1
      state.shouldRefresh = true
    })
  },
})

export const {
  updateItems, setLoading, setPageNumber, setShouldRefresh,
  resetPageNumber, resetTotalItemsCount, reset: resetListPanel, setFilterObjectType,
} = listPanelSlice.actions

export default listPanelSlice.reducer
