import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from 'Store'
import { PORTAL_URL } from 'config/config'
import history from 'customHistory'

import logo from 'assets/logos/logo-DGEXSOL-full-white.svg'
import RegionSelect from 'components/RegionSelect/RegionSelect'
import { isAdmin, loggedAsAdmin, loggedAsManager } from 'helpers/permissions'
import AccountMenu from './AccountMenu'
import './TopBar.scss'

type Props = {
  appName: string;
  acceptedCGU: boolean;
}

export default function TopBar({ appName, acceptedCGU }: Props): ReactElement {
  const { homePath } = useSelector((state: RootState) => state.app)

  return (
    <div className="topbar d-flex align-items-center justify-content-between px-3">
      <header role="banner" className="d-none d-xl-flex">
        <div className="mastheader-logo">
          <a href={PORTAL_URL.url}><img alt={appName} src={logo} width="70" /></a>
        </div>
        <div
          className="d-flex align-items-center"
          aria-hidden
          role="button"
          onClick={() => history.replace(homePath || '/')}
        >
          <h1 className="text-uppercase pl-3 pt-1 mb-0 app-name">{appName}</h1>
        </div>
      </header>
      {((isAdmin() && loggedAsAdmin()) || loggedAsManager()) && acceptedCGU && (<RegionSelect />)}
      <AccountMenu />
    </div>
  )
}
