import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

import { isAnyOf } from '@reduxjs/toolkit'
import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'

export const getHistoryFulfilledMatcher = isAnyOf(
  ElectricalElementServices.getHistory.fulfilled,
  ElectricalProtectionGroupServices.getHistory.fulfilled,
  FeederServices.getHistory.fulfilled,
  IsolatorServices.getHistory.fulfilled,
  SectorServices.getHistory.fulfilled,
  SignalServices.getHistory.fulfilled,
  SubSectorServices.getHistory.fulfilled,
  TrackProtectionGroupServices.getHistory.fulfilled,
  TrackProtectionServices.getHistory.fulfilled,
  TrackSectionServices.getHistory.fulfilled,
  TrackNodeServices.getHistory.fulfilled,
  TunnelServices.getHistory.fulfilled,
)

export const getHistoryPendingMatcher = isAnyOf(
  ElectricalElementServices.getHistory.pending,
  ElectricalProtectionGroupServices.getHistory.pending,
  FeederServices.getHistory.pending,
  IsolatorServices.getHistory.pending,
  SectorServices.getHistory.pending,
  SignalServices.getHistory.pending,
  SubSectorServices.getHistory.pending,
  TrackProtectionGroupServices.getHistory.pending,
  TrackProtectionServices.getHistory.pending,
  TrackSectionServices.getHistory.pending,
  TrackNodeServices.getHistory.pending,
  TunnelServices.getHistory.pending,
)

export const getHistoryErrorMatcher = isAnyOf(
  ElectricalElementServices.getHistory.rejected,
  ElectricalProtectionGroupServices.getHistory.rejected,
  FeederServices.getHistory.rejected,
  IsolatorServices.getHistory.rejected,
  SectorServices.getHistory.rejected,
  SignalServices.getHistory.rejected,
  SubSectorServices.getHistory.rejected,
  TrackProtectionGroupServices.getHistory.rejected,
  TrackProtectionServices.getHistory.rejected,
  TrackSectionServices.getHistory.rejected,
  TrackNodeServices.getHistory.rejected,
  TunnelServices.getHistory.rejected,
)
