import { ReactElement } from 'react'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { AttributesProps, ExtraKind, ITEM_TYPES } from 'objects/types/const'
import { SubSector } from 'objects/types/protections'

import Extremities from 'objects/common/Extremities/Extremities'
import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import Instructions from 'objects/common/Instructions/Instructions'
import { ProtectionChild } from 'objects/types/protections/const'
import { CollapsibleMenu } from 'reducers/types'
import { captureClickParams } from 'objects/Sectors/const'
import SUB_SECTOR_ATTRIBUTES from './SubSectorAttributes'
import SubSectorServices from './SubSectorServices'

export default function SubSectorDetails({ object }: AttributesProps): ReactElement | null {
  const subSector = object as SubSector
  const {
    incompatibleProtections, associatedSubAreas, responsibleSubAreas, composition,
  } = subSector
  const editable = subSector.inInstruction

  return (
    <div id="sub-sectors-details" className="object-attributes">
      <Attributes item={subSector} attributes={SUB_SECTOR_ATTRIBUTES(subSector)} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <Extremities
          editable={editable}
          type={ExtraKind.SSExtremity}
          extremities={subSector.extremities}
          deleteExtremity={SubSectorServices.deleteExtremity}
          deleteExtremities={SubSectorServices.deleteElements}
          switchOrientation={
            SubSectorServices.switchExtremityDirection
          }
        />
        <LinkedProtections
          editable={editable}
          fieldType={ProtectionChild.composition}
          deleteAll={SubSectorServices.deleteElements}
          linkedProtections={composition}
          deleteAction={SubSectorServices.deleteChild}
          addIPAction={SubSectorServices.addChild}
          params={{
            title: 'Attributes.Protection.children',
            searchPlaceholder: 'Details.searchPlaceholder.electricalProtections',
            buttonLabel: 'Details.button.addElectricalProtection',
            filteredTypes: [ITEM_TYPES.feeder, ITEM_TYPES.electricalelement,
              ITEM_TYPES.electricalprotectiongroup, ITEM_TYPES.subsector, ITEM_TYPES.sector],
          }}
          type={CollapsibleMenu.composition}
          captureClickParams={captureClickParams}
          buttonTitle="Details.button.addElectricalProtection"
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={SubSectorServices.deleteResponsibleSubArea}
          addRSAAction={SubSectorServices.addResponsibleSubArea}
          deleteAll={SubSectorServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={SubSectorServices.deleteAssociatedSubArea}
          addASAAction={SubSectorServices.addAssociatedSubArea}
          deleteAll={SubSectorServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          fieldType={ProtectionChild.incompatibleProtections}
          deleteAll={SubSectorServices.deleteElements}
          linkedProtections={incompatibleProtections}
          deleteAction={SubSectorServices.deleteIncompatibleObject}
          addIPAction={SubSectorServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
        <Instructions item={subSector} />
      </div>
    </div>
  )
}
