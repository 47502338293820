import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { RootState } from 'Store'
import CaptureAndSearch from 'components/Common/CaptureAndSearch/CaptureAndSearch'
import { onCaptureTrackProtection } from 'components/Map/onFeatureClick/helpers'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { canEditObject } from 'components/Panels/DetailsPanel/utils'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { CaptureMapClickParams } from 'objects/attributes'
import DeleteButton from 'objects/common/Extremities/DeleteButton'
import { ITEM_TYPES, ObjectLayer } from 'objects/types/const'
import { TrackProtection } from 'objects/types/protections'
import { ProtectionChild } from 'objects/types/protections/const'
import { setMessage } from 'reducers/feedback'
import { disableGeoEditor, hideGeoEditor } from 'reducers/geoEditor'
import {
  MapState, addLayer, setCaptureClick, setLayers,
} from 'reducers/map'
import { DetailsPanelState, shouldRefresh, updateItem } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import TrackProtectionGroupServices from '../TrackProtectionGroupServices'
import CompositionContextMenu from './CompositionContextMenu'

type Props = {
  trackProtections: TrackProtection[];
  editable: boolean;
}

const captureClickParams: CaptureMapClickParams = {
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.TrackProtection,
}

export default function Composition({ trackProtections, editable }: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isCapturingClick, setIsCapturingClick] = useState(false)
  const { layers } = useSelector((state: RootState): MapState => state.map)
  const { displayHistory } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const [layersMemory, setMemory] = useState(layers)

  useEffect(() => {
    if (isCapturingClick) {
      setIsCapturingClick(false)
      dispatch(setLayers(layersMemory))
    }
  }, [trackProtections])

  const onClick = () => {
    if (!isCapturingClick) {
      setIsCapturingClick(true)
      dispatch(hideGeoEditor())
      setMemory(layers)
      dispatch(setCaptureClick(captureClickParams))
      dispatch(setLayers([ObjectLayer.TrackSection, captureClickParams.objectLayer]))
      dispatch(setMessage('Modification.helperText.trackProtectionGroupCaptureClick'))
    } else {
      setIsCapturingClick(false)
      dispatch(setLayers(layersMemory))
      dispatch(setCaptureClick(undefined))
    }
  }

  const onTileClick = (tp: TrackProtection) => {
    if (displayHistory) {
      return
    }
    dispatch(addLayer(captureClickParams.objectLayer))
    dispatch(disableGeoEditor())
    dispatch(updateItem(tp))
    dispatch(shouldRefresh(true))
    PanelNavigator.push(PanelName.details)
  }

  const formatActivityDates = (obj: TrackProtection) => `${(
    obj.activityStartDate && new Date(obj.activityStartDate).toLocaleDateString('fr-FR')) || '/'} 
    -
     ${(obj.activityEndDate && new Date(obj.activityEndDate).toLocaleDateString('fr-FR')) || '/'}`

  return (
    trackProtections ? (
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
          {`${t('Attributes.TrackProtectionGroup.composition')} (${
            trackProtections && trackProtections.length
          })`}
        </AccordionSummary>
        <AccordionDetails>
          {trackProtections && trackProtections.map(tp => (
            <ObjectTile
              key={tp.id}
              onClick={() => onTileClick(tp)}
              title={tp.mainRepresentation || tp.label}
              subtitle={t('Object.type.trackProtection')}
              activityDates={formatActivityDates(tp)}
              contextMenu={canEditObject() && editable ? <CompositionContextMenu tp={tp} /> : <></>}
            />
          ))}
          {canEditObject() && editable && (
          <div className="protection-buttons my-2 capture-button">
            <CaptureAndSearch
              onClick={onClick}
              filteredTypes={[ITEM_TYPES.trackprotection]}
              onOptionSelect={elem => onCaptureTrackProtection(elem as TrackProtection)}
              title={t('Details.button.addTrackProtectionToGroup')}
              protections={trackProtections}
            />

            {!!trackProtections.length && (
            <DeleteButton
              deleteAction={TrackProtectionGroupServices.deleteElements}
              field={ProtectionChild.composition}
            />
            )}

          </div>
          )}

        </AccordionDetails>
      </Accordion>
    ) : <></>
  )
}
