/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask, DetailsPayload, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { SubSector } from 'objects/types/protections'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter, filterFields, filterNonEmptyFields, getUpdatedFields, isUpdatingObjectInstruction,
} from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'
import SUB_SECTOR_ATTRIBUTES from '../SubSectorAttributes'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addChild, deleteChild } from './children'
import {
  addExtremity,
  deleteElements,
  deleteExtremity, switchExtremityDirection,
} from './extremities'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'

const getDetails = createAsyncThunk<SubSector, DetailsPayload, ThunkApiConfig>(
  'subSector/getDetails',
  async (params, thunkApi) => {
    try {
      const response: SubSector = await get(`/${MIDI_URI}/${ObjectURI.SubSectors}/${params.id}`,
        { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, SubSector>, string, ThunkApiConfig>(
  'subSector/getGeometry',
  async (id, thunkApi) => {
    try {
      const projectionId = store.getState().map.selectedProjection.id
      const response: Feature<Geometry, SubSector> = await get(
        `/chartis/v2/layer/${ObjectLayer.SubSector}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<SubSector, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'subSector/create',
  async (newSector, thunkApi) => {
    try {
      const subSector = filterNonEmptyFields(newSector as unknown as SubSector, SUB_SECTOR_ATTRIBUTES())
      const response: SubSector = await post(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/`,
        subSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, Partial<SubSector>, ThunkApiConfig>(
  'subSector/updateById',
  async (updatedSector, thunkApi) => {
    try {
      const subSector = filterNonEmptyFields(updatedSector as unknown as SubSector, SUB_SECTOR_ATTRIBUTES())
      const updatingInstruction = isUpdatingObjectInstruction(subSector)
      const newSubSector = updatingInstruction ? { instructions: updatedSector.instructions }
        : filterFields(subSector, getUpdatedFields(SUB_SECTOR_ATTRIBUTES()))

      const response: SubSector = await patch(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/${updatedSector.id}`,
        newSubSector,
        {
          params: addInstructionParameter(updatingInstruction, updatedSector.instructions?.
            [updatedSector.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'subSector/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.SubSectors}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<SubSector, ValidationParams, ThunkApiConfig>(
  'subSector/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: SubSector = await post(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/${id}/${operation}/`,
        {}, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'subSector/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.SubSectors}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'subSector/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.SubSectors}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<SubSector, string, ThunkApiConfig>(
  'subSector/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.SubSectors}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const SubSectorServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addIncompatibleObject,
  deleteIncompatibleObject,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addChild,
  deleteChild,
  addExtremity,
  switchExtremityDirection,
  deleteExtremity,
  deleteElements,
  getHistory,
  revert,
  resetGaiaId,
}

export default SubSectorServices
