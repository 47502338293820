/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask, DetailsPayload, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { TrackProtectionGroup } from 'objects/types/protections'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter, filterFields, getUpdatedFields, isUpdatingObjectInstruction,
} from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'
import TRACK_PROTECTION_GROUP_ATTRIBUTES from '../TrackProtectionGroupAttributes'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import { addTrackProtection, deleteTrackProtection } from './composition'
import {
  addExtremity,
  deleteElements,
  deleteExtremity,
  switchExtremityDirection,
} from './extremities'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'

const getDetails = createAsyncThunk<TrackProtectionGroup, DetailsPayload, ThunkApiConfig>(
  'trackProtectionGroup/getDetails',
  async (params, thunkApi) => {
    try {
      const response: TrackProtectionGroup = await get(`/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${params.id}`,
        { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, TrackProtectionGroup>, string, ThunkApiConfig>(
  'trackProtectionGroup/getGeometry',
  async (id, thunkApi) => {
    try {
      const projectionId = store.getState().map.selectedProjection.id
      const response: Feature<Geometry, TrackProtectionGroup> = await get(
        `/chartis/v2/layer/${ObjectLayer.TrackProtectionGroup}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<TrackProtectionGroup, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'trackProtectionGroup/create',
  async (newTrackProtectionGroup, thunkApi) => {
    try {
      const response: TrackProtectionGroup = await post(
        `/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/`,
        newTrackProtectionGroup, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, TrackProtectionGroup, ThunkApiConfig>(
  'trackProtectionGroup/updateById',
  async (updatedTrackProtectionGroup, thunkApi) => {
    try {
      const updatingInstruction = isUpdatingObjectInstruction(updatedTrackProtectionGroup)
      const newTPG = updatingInstruction ? { instructions: updatedTrackProtectionGroup.instructions }
        : filterFields(updatedTrackProtectionGroup, getUpdatedFields(TRACK_PROTECTION_GROUP_ATTRIBUTES()))

      const response: TrackProtectionGroup = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${updatedTrackProtectionGroup.id}`,
        newTPG,
        {
          params: addInstructionParameter(updatingInstruction, newTPG.instructions?.
            [newTPG.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'trackProtectionGroup/deleteById',
  async (id, thunkApi) => {
    try {
      // eslint-disable-next-line max-len
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<TrackProtectionGroup, ValidationParams, ThunkApiConfig>(
  'trackProtectionGroup/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: TrackProtectionGroup = await post(
        `/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${id}/${operation}/`, {},
        { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'trackProtectionGroup/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'trackProtectionGroup/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<TrackProtectionGroup, string, ThunkApiConfig>(
  'trackProtectionGroup/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.TrackProtectionGroups}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TrackProtectionGroupServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addTrackProtection,
  deleteTrackProtection,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
  addExtremity,
  deleteExtremity,
  deleteElements,
  switchExtremityDirection,
  getHistory,
  revert,
  resetGaiaId,
}

export default TrackProtectionGroupServices
