/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShortProtection, SubSector } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'

export const addIncompatibleObject = createAsyncThunk<SubSector, ShortProtection, ThunkApiConfig>(
  'subSector/addIncompatibleObject',
  async (newIncompatibleObject, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as SubSector

    const updatedSubSector = {
      id,
      checksum,
      incompatibleProtections: [...(incompatibleProtections || []), {
        id: newIncompatibleObject.id,
      }],
    }

    try {
      const response: SubSector = await patch(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/${updatedSubSector.id}`,
        updatedSubSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteIncompatibleObject = createAsyncThunk<SubSector, string, ThunkApiConfig>(
  'subSector/deleteIncompatibleObject',
  async (deletedTpId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, incompatibleProtections } = detailsPanelState.item as SubSector

    const updatedSubSector: Partial<SubSector> = {
      id,
      checksum,
      incompatibleProtections: incompatibleProtections.filter(tp => tp.id !== deletedTpId),
    }

    try {
      const response: SubSector = await patch(
        `/${MIDI_URI}/${ObjectURI.SubSectors}/${updatedSubSector.id}`,
        updatedSubSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
