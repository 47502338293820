/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask,
  DetailsPayload, Isolator, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import { addInstructionParameter, isUpdatingObjectInstruction } from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'

export const fetchIsolatorDetails = async (id: string): Promise<Isolator> => {
  const response: Isolator = await get(`/${MIDI_URI}/${ObjectURI.Isolators}/${id}`, { ...addInstructionParameter() })
  return response
}

const getDetails = createAsyncThunk<Isolator, DetailsPayload, ThunkApiConfig>(
  'isolator/getDetails',
  async (params, thunkApi) => {
    try {
      const response: Isolator = await fetchIsolatorDetails(params.id)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Isolator>, string, ThunkApiConfig>(
  'isolator/getGeometry',
  async (id: string, thunkApi) => {
    try {
      const projectionId = store.getState().map.selectedProjection.id
      const response: Feature<Geometry, Isolator> = await get(
        `/chartis/v2/layer/${ObjectLayer.Isolator}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Isolator, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'isolator/create',
  async (newIsolator: ObjOfStrOrNum | nestedObject, thunkApi) => {
    try {
      const response: Isolator = await post(`/${MIDI_URI}/${ObjectURI.Isolators}/`, newIsolator,
        { params: addInstructionParameter() })
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, Isolator, ThunkApiConfig>(
  'isolator/updateById',
  async (updatedIsolator: Isolator, thunkApi) => {
    try {
      const updatingInstruction = isUpdatingObjectInstruction(updatedIsolator)

      const response: Isolator = await patch(
        `/${MIDI_URI}/${ObjectURI.Isolators}/${updatedIsolator.id}`,
        updatedIsolator,
        {
          params: addInstructionParameter(updatingInstruction, updatedIsolator.instructions?.
            [updatedIsolator.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'isolator/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.Isolators}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<Isolator, ValidationParams, ThunkApiConfig>(
  'isolator/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: Isolator = await post(`/${MIDI_URI}/${ObjectURI.Isolators}/${id}/${operation}/`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'isolator/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.Isolators}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'isolator/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Isolators}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<Isolator, string, ThunkApiConfig>(
  'isolator/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Isolators}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const IsolatorServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  getHistory,
  revert,
  resetGaiaId,
}

export default IsolatorServices
