import { DateTime } from 'luxon'
import { Attribute, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { MidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatIdentityChoice } from 'objects/utils'

const ISOLATOR_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'trackName',
    path: ['kilometricPoint', 'trackSection', 'track', 'name'],
    label: 'Attributes.common.track',
    parser: Parser.identity,
    required: true,
    type: InputTypes.CaptureClick,
    isEditable: true,
    creation: {
      required: true,
      defaultValue: '',
    },
    captureClickHelper: 'Modification.helperText.trackCaptureClick',
    captureClickParams: {
      updateParams: [
        {
          clickedObjectPath: 'trackName',
          path: ['kilometricPoint', 'trackSection', 'track', 'name'],
        },
        {
          clickedObjectPath: 'id',
          path: ['kilometricPoint', 'trackSection', 'id'],
        },
      ],
      objectLayer: ObjectLayer.TrackSection,
      displayedValueFormatter: formatIdentityChoice,
    },
  },
  {
    key: 'kp',
    path: ['kilometricPoint', 'value'],
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default ISOLATOR_ATTRIBUTES
