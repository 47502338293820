import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import DisabledToolTip from 'components/Common/DisabledTooltip/DisabledTooltip'
import Loader from 'components/Common/Loader'
import { RevertServiceOfKind } from 'objects/services'
import { ObjectKind } from 'objects/types/const'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showHistory } from 'reducers/panels/detailsPanel'
import './HistoryModal.scss'
import { TabContentOfKind, TabKeys } from './utils'

type Props = {
  kind: ObjectKind;
  loading: boolean;
}

export default function HistoryModal({ kind, loading }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    item, historicItem, displayHistory, objectToMerge, revertLoading,
  } = useSelector((state: RootState) => state.detailsPanel)

  const handleClose = () => {
    dispatch(showHistory(false))
  }

  const handleRevert = () => {
    dispatch(RevertServiceOfKind[kind]({
      id: item.id,
      target: historicItem.id,
      async: true,
    }))
  }

  return (
    <Dialog open={displayHistory} onClose={handleClose} className="history-modal">
      <Backdrop className="backdrop" open={loading}>
        <Loader />
      </Backdrop>
      <DialogTitle>
        {historicItem?.mainRepresentation || ''}
      </DialogTitle>

      <DialogContent>
        {historicItem ? (
          TabContentOfKind[kind][TabKeys.attributes](historicItem)
        ) : <Loader />}
      </DialogContent>

      {Object.keys(objectToMerge).length === 0 && (
        <DialogActions>
          <Button onClick={handleClose}>{t('Common.button.cancel')}</Button>
          <DisabledToolTip
            title={t('Modification.notAllowed')}
            disabled={item.inInstruction}
          >
            <Button
              disabled={!item.inInstruction || revertLoading}
              onClick={handleRevert}
            >
              {t('Details.history.confirm')}
            </Button>
          </DisabledToolTip>
        </DialogActions>
      )}
    </Dialog>

  )
}
