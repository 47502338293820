import { ReactElement } from 'react'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { Tunnel } from 'objects/types'

import { Divider } from '@mui/material'
import Instructions from 'objects/common/Instructions/Instructions'
import { AttributesProps } from 'objects/types/const'
import TUNNEL_ATTRIBUTES from './TunnelAttributes'

export default function TunnelDetails({ object }: AttributesProps): ReactElement | null {
  const tunnel = object as Tunnel

  return (
    <div id="tunnel-details" className="object-attributes">
      <Attributes item={tunnel} attributes={TUNNEL_ATTRIBUTES(tunnel)} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Instructions item={tunnel} />
      </div>
    </div>
  )
}
