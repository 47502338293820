import { ReactElement } from 'react'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import InstructionsTable from './InstructionsTable/InstructionsTable'

export default function HomeDashboard(): ReactElement {
  return (
    <>
      <div id="instructions-tab" className="d-flex h-100">
        <div style={{ flex: 1 }}>
          <InstructionsTable />
        </div>
      </div>
      <FeedbackSnackBar />
    </>
  )
}
