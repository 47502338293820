import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { RootState } from 'Store'
import Loader from 'components/Common/Loader'
import SearchBar from 'components/Common/SimpleSearchBar'
import { debouncedInstruction } from 'helpers'
import { getUniqueIds } from 'helpers/users'
import { Instruction } from 'objects/types/instructions'
import InfiniteScroll from 'react-infinite-scroll-component'
import { setRole } from 'reducers/app'
import { resetPagination, setPageNumber } from 'reducers/instruction'
import { OperatorState } from 'reducers/operator'
import { resetInstructions } from 'reducers/supervisor'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'
import MidiRole from 'services/midiRoleTypes'
import { updateCenteredFeature } from 'reducers/map'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { COLUMNS } from './const'

export default function InstructionsTable(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { usersInfo, loading } = useSelector((state: RootState) => state.operator) as OperatorState
  const { pagination, page, detailsLoading } = useSelector((state: RootState) => state.instruction)
  const [search, setSearch] = useState('')

  const getNext = () => {
    dispatch(setPageNumber(page + 1))
  }

  useEffect(() => {
    dispatch(setRole(MidiRole.supervisor))
  }, [])

  useEffect(() => () => {
    dispatch(resetPagination())
    dispatch(resetInstructions())
  }, [])

  useEffect(() => {
    dispatch(InstructionServices.getAll({ page }))
  }, [page])

  const handleInstructionClick = (instruction: Instruction) => {
    dispatch(updateCenteredFeature(undefined))
    PanelNavigator.reset()
    dispatch(InstructionServices.getDetails(instruction.id))
  }

  useEffect(() => {
    const usersId = getUniqueIds(pagination.results, usersInfo)
    if (usersId.length) {
      dispatch(UserServices.getUsersListInfo((usersId)))
    }
  }, [pagination])

  const onSearch = (s: string) => {
    setSearch(s)
    if (page === 1) {
      debouncedInstruction(page, 'all', s)
    } else dispatch(resetPagination())
  }

  return (
    <div className="instructions-dashboard-user">
      <div className="search">
        {!detailsLoading && (
        <SearchBar
          onChange={onSearch}
          value={search}
        />
        )}
      </div>
      {loading && page === 1 ? (
        <div className="h-100 w-100">
          <Loader />
        </div>
      ) : (
        <TableContainer id="wrapper">
          <InfiniteScroll
            dataLength={pagination.results.length}
            next={getNext}
            hasMore={pagination.count > pagination.results.length}
            loader={<Loader />}
            scrollableTarget="wrapper"
            style={{ overflow: 'hidden' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {COLUMNS.map(column => (
                    <TableCell key={column.title}>{t(column.title)}</TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {pagination.results.map((instruction: Instruction) => (
                  <TableRow
                    key={instruction.id}
                    hover
                    className="instruction-row"
                  >
                    {COLUMNS.map(column => (
                      <TableCell
                        key={column.title}
                        onClick={() => { handleInstructionClick(instruction) }}
                      >
                        {column.formatter(instruction[column.propertyName], usersInfo)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
      )}
    </div>
  )
}
