enum URI {
  cerbereApi = 'cerbere',
  instructions = 'instructions',
  instructionItems = 'items',
  users = 'users',
  areas = 'areas',
  subAreas = 'sub-areas',
  projections = 'projections',
  regions = 'regions'
}

export default URI
