import { ArrayFormat, get } from '@osrdata/app_core/dist/requests'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { debounce } from 'lodash'
import {
  ReactElement, SyntheticEvent, useCallback, useEffect, useState,
} from 'react'

import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Popper from '@mui/material/Popper'
import TextField from '@mui/material/TextField'

import { ShortMidiObject } from 'objects/types'
import { Area, TrackProtection } from 'objects/types/protections'
import { ObjOfArrOfStr, ObjOfStr } from 'types'
import './SearchBar.scss'

export type SearchSelectedValueType = Area | TrackProtection | ShortMidiObject

const fetchResults = async (
  searchURI: string, value: string, params: ObjOfStr | ObjOfArrOfStr = {}, format: ArrayFormat,
): Promise<ShortMidiObject[] | Area[]> => {
  const response = await get(searchURI, {
    search: value,
    ...params,
  }, format)
  return response?.results || response
}

type Props = {
  onSelect: (selectedValue?: SearchSelectedValueType | undefined) => void;
  searchURI: string;
  placeholder: string;
  labelFormatter: (o: SearchSelectedValueType) => string;
  filteredIds?: string[];
  params?: ObjOfArrOfStr | ObjOfStr;
  blurOnSelect?: boolean;
  autoFocus?: boolean;
  paramsFormat?: ArrayFormat;
  disabled?: boolean;
  alwaysOpen?: boolean;

}

const defaultProps = {
  filteredIds: undefined,
  params: {},
  blurOnSelect: false,
  autoFocus: true,
  paramsFormat: ArrayFormat.repeat,
  disabled: false,
  alwaysOpen: false,
}

export default function SearchBar({
  onSelect, searchURI, placeholder, labelFormatter, filteredIds, params, blurOnSelect, autoFocus,
  paramsFormat, disabled, alwaysOpen,
}: Props): ReactElement {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [options, setOptions] = useState<SearchSelectedValueType[]>([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string | undefined>(undefined)

  const updateValues = useCallback(
    debounce(async (s: string | undefined, parameters: typeof params) => {
      if (s && s !== '') {
        const subAreas: SearchSelectedValueType[] = await fetchResults(searchURI, s, parameters,
          paramsFormat as ArrayFormat)
        setLoading(false)
        setOptions(
          filteredIds !== undefined
            ? subAreas.filter(a => !(filteredIds as string[]).includes(a.id))
            : subAreas,
        )
      } else {
        setOpen(false)
        setLoading(false)
      }
    }, 800),
    [],
  )

  useEffect(() => {
    if (search && search !== '') {
      setLoading(true)
      updateValues(search, params)
    } else {
      setOpen(false)
      setOptions([])
    }
  }, [search])

  return (
    <Autocomplete
      className="autocomplete-search"
      fullWidth
      open={open || (alwaysOpen && !!search)}
      disabled={disabled}
      onOpen={() => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      filterOptions={x => x}
      onChange={(_e: SyntheticEvent, newValue: ShortMidiObject) => {
        onSelect(newValue)
      }}
      getOptionLabel={option => labelFormatter(option)}
      options={options}
      loading={loading}
      value={null as unknown as ShortMidiObject}
      loadingText={t('Common.loading')}
      noOptionsText={t('Common.noResult')}
      disablePortal
      blurOnSelect={blurOnSelect}
      disableClearable
      PopperComponent={props => <Popper {...props} placement="bottom" />}
      renderInput={inputParams => (
        <TextField
          variant="standard"
          {...inputParams}
          value={search}
          placeholder={placeholder}
          onChange={e => setSearch(e.target.value)}
          InputLabelProps={{ shrink: true }}
          onBlur={() => onSelect()}
          autoFocus={autoFocus}
          InputProps={{
            ...inputParams.InputProps,
            className: 'input',
            disableUnderline: true,
            endAdornment: (
              <>
                {loading
                  ? (
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <CircularProgress color="inherit" size={20} />
                    </div>
                  )
                  : null}
              </>
            ),
            startAdornment: (
              <div className="start-icon-wrapper">
                <SearchIcon className="start-icon" />
              </div>
            ),
          }}
        />
      )}
    />
  )
}

SearchBar.defaultProps = defaultProps
