import { Accordion, Tooltip } from '@mui/material'
import { RootState } from 'Store'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DetailsPanelState, showHistory, updateItem } from 'reducers/panels/detailsPanel'
import { CollapsibleMenu } from 'reducers/types'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import SearchBar from 'components/Common/SearchBar/SearchBar'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { canEditObject } from 'components/Panels/DetailsPanel/utils'
import { loggedAsManager } from 'helpers/permissions'
import { findObjectKind } from 'objects/kind'
import { UpdateServiceOfKind } from 'objects/services'
import { ObjectInstruction } from 'objects/types/common'
import { Instruction, InstructionByObjectKind } from 'objects/types/instructions'
import { INSTRUCTIONS_SEARCH_URI, MIDI_URI } from 'objects/uri'
import { InstructionState } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import { toggleOpenCollapsible } from '../utils'
import './Instructions.scss'
import InstructionsContextMenu from './InstructionsContextMenu'

type Props = {
 item: MidiObject;
}

export default function Instructions({ item }: Props): ReactElement {
  const dispatch = useDispatch()
  const { instruction, detailsLoading } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { openMenus, item: currentItem } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const [instructionToRemove, setInstruction] = useState('')
  const { t } = useTranslation()
  const [displaySearch, setDisplaySearch] = useState(false)

  const resetInstruction = () => {
    setInstruction('')
  }

  const removeInstruction = () => {
    const kind = findObjectKind(item)
    const newItem = { ...item, instructions: item.instructions.filter(inst => inst.id !== instructionToRemove) }
    dispatch(UpdateServiceOfKind[kind](newItem as any))
  }

  const onCloseSearch = (value: unknown) => {
    setDisplaySearch(false)
    if (!value) return
    const kind = findObjectKind(item)
    const newItem = { ...item, instructions: [...item.instructions, value] }
    // todo refacto, ts infers never type and the UpdateServiceOfKind is only used once with variable coming from
    // the store so its type is any
    dispatch(UpdateServiceOfKind[kind](newItem as any))
  }

  const formatSearchLabel = (instr: Instruction | ObjectInstruction) => `${new Date(instr.applicationDate)
    .toLocaleDateString('fr-FR')} ${instr.name}`

  const onInstructionClick = (id: string, hasAccess: boolean) => () => {
    if (id !== instruction.id && hasAccess && !detailsLoading) {
      dispatch(showHistory(false))
      dispatch(InstructionServices.getDetails(id))
      if (item.id !== currentItem?.id) {
        dispatch(updateItem(item as ShortMidiObject))
      }
    }
  }

  return (

    <>
      <Accordion
        className="object-instructions"
        disableGutters
        expanded={openMenus.includes(CollapsibleMenu.instructions)}
        onChange={() => toggleOpenCollapsible(CollapsibleMenu.instructions)}
      >
        <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
          {`${t('Attributes.common.instructions')} (${
            item?.instructions?.length || 0
          })`}
        </AccordionSummary>
        <AccordionDetails>
          {item?.instructions?.map(instr => (
            <ObjectTile
              key={instr.id}
              onClick={onInstructionClick(instr.id, instr.isLinkedToUser)}
              title={formatSearchLabel(instr)}
              loading={detailsLoading}
              subtitle={instr.id === instruction.id ? t('Details.title.currentInstruction') : ''}
              contextMenu={loggedAsManager() && canEditObject() ? (
                <InstructionsContextMenu
                  action={() => setInstruction(instr.id)}
                />
              ) : null}
              optionalMenu={(
                <Tooltip title={instr.isLinkedToUser ? t('Details.button.linkedInstruction')
                  : t('Details.button.notLinkedInstruction')}
                >

                  <CheckCircleIcon className={instr.isLinkedToUser ? 'linked' : 'not-linked'} />
                </Tooltip>
              )}
            />
          ))}
          {loggedAsManager() && canEditObject() && (
          <div className="my-2">
            {displaySearch
              ? (
                <SearchBar
                  onSelect={onCloseSearch}
                  searchURI={`/${MIDI_URI}/${INSTRUCTIONS_SEARCH_URI}/`}
                  placeholder={t('Details.searchPlaceholder.areaOrSubArea')}
                  labelFormatter={(option: unknown) => formatSearchLabel(option as Instruction)}
                  params={{
                    instruction_type: InstructionByObjectKind[findObjectKind(item)] || '',
                    object: item.id,
                  }}
                />
              )
              : (
                <>
                  <SimpleButton
                    title={t('Details.button.addToInstruction')}
                    onClick={() => { setDisplaySearch(true) }}
                    style={ButtonStyle.light}
                  />
                </>
              )}
          </div>
          )}
        </AccordionDetails>
      </Accordion>
      <ConfirmationDialog
        title={t('Details.instructionsContextMenu.confirmDelete')}
        content={t('Details.instructionsContextMenu.content')}
        toggle={resetInstruction}
        open={!!instructionToRemove}
        actionToConfirm={removeInstruction}
      />
    </>
  )
}
