import { ReactElement } from 'react'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { TrackProtection } from 'objects/types/protections'

import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import Instructions from 'objects/common/Instructions/Instructions'
import { AttributesProps, ExtraKind } from 'objects/types/const'
import { ProtectionChild } from 'objects/types/protections/const'
import { CollapsibleMenu } from 'reducers/types'
import AssociatedSubAreas from '../common/AssociatedSubAreas/AssociatedSubAreas'
import Extremities from '../common/Extremities/Extremities'
import ResponsibleSubAreas from '../common/ResponsibleSubAreas/ResponsibleSubAreas'
import TRACK_PROTECTION_ATTRIBUTES from './TrackProtectionAttributes'
import TrackProtectionServices from './TrackProtectionServices'

export default function TrackProtectionDetails({ object }: AttributesProps): ReactElement | null {
  const trackProtection = object as TrackProtection
  const {
    extremities, associatedSubAreas, responsibleSubAreas, incompatibleProtections: incompatibleTrackProtections,
  } = trackProtection
  const editable = trackProtection.inInstruction

  return (
    <div className="object-attributes">
      <Attributes item={trackProtection} attributes={TRACK_PROTECTION_ATTRIBUTES(trackProtection)} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Extremities
          editable={editable}
          type={ExtraKind.TPExtremity}
          extremities={extremities}
          deleteExtremity={TrackProtectionServices.deleteExtremity}
          deleteExtremities={TrackProtectionServices.deleteElements}
          switchOrientation={TrackProtectionServices.switchExtremityDirection}
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={TrackProtectionServices.deleteResponsibleSubArea}
          addRSAAction={TrackProtectionServices.addResponsibleSubArea}
          deleteAll={TrackProtectionServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={TrackProtectionServices.deleteAssociatedSubArea}
          addASAAction={TrackProtectionServices.addAssociatedSubArea}
          deleteAll={TrackProtectionServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          fieldType={ProtectionChild.incompatibleProtections}
          deleteAll={TrackProtectionServices.deleteElements}
          linkedProtections={incompatibleTrackProtections}
          deleteAction={TrackProtectionServices.deleteIncompatibleObject}
          addIPAction={TrackProtectionServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
        <Instructions item={trackProtection} />
      </div>
    </div>
  )
}
