/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import ExtremityCreation from 'objects/common/Extremities/types'
import { KPOrientation, ObjectLayer } from 'objects/types/const'
import { TrackProtection } from 'objects/types/protections'
import { DeleteChildrenPayload } from 'objects/types/protections/const'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import {
  ObjOfStrOrNum,
  ThunkApiConfig, nestedObject,
} from 'types'

export const addExtremity = createAsyncThunk<TrackProtection, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'trackProtection/addExtremity',
  async (newExtremity, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as TrackProtection
    const {
      layerName, kilometricPoints, location, ...typedNewExtremity
    } = newExtremity as unknown as ExtremityCreation
    const { mainRepresentation: _, ...loc } = location
    let trackSectionId

    if ([ObjectLayer.Signal, ObjectLayer.Isolator].includes(layerName as ObjectLayer)) {
      trackSectionId = kilometricPoints?.[0].trackSection.id
    } else {
      trackSectionId = loc.trackSection
    }

    loc.value = kilometricPoints?.[0].value as string

    const newTypedExtremity = {
      ...typedNewExtremity,
      location: {
        ...loc,
        trackSection: trackSectionId,
      },
    } as ExtremityCreation
    const updatedTrackProtection = {
      id,
      checksum,
      extremities: [
        ...extremities.map(ext => ({ id: ext.id })),
        newTypedExtremity,
      ],
    }

    try {
      const response: TrackProtection = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        updatedTrackProtection, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteExtremity = createAsyncThunk<TrackProtection, number, ThunkApiConfig>(
  'trackProtection/deleteExtremityById',
  async (deletedExtremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as TrackProtection

    const updatedTrackProtection = {
      id,
      checksum,
      extremities: extremities.flatMap(extremity => (extremity.id !== deletedExtremityId
        ? ({ id: extremity.id }) : [])),
    }

    try {
      const response: TrackProtection = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        updatedTrackProtection, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteElements = createAsyncThunk<TrackProtection, DeleteChildrenPayload, ThunkApiConfig>(
  'trackProtection/deleteElements',
  async (params, thunkApi) => {
    const { id, field } = params
    const updatedTrackProtection = {
      id,
      [field]: [],
    }

    try {
      const response: TrackProtection = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        updatedTrackProtection, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const switchExtremityDirection = createAsyncThunk<TrackProtection, number, ThunkApiConfig>(
  'trackProtection/switchExtremityDirection',
  async (extremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as TrackProtection

    const updatedTrackProtection = {
      id,
      checksum,
      extremities: extremities.map(extremity => {
        if (extremity.id === extremityId) {
          return {
            ...extremity,
            direction: extremity.direction === KPOrientation.ASC
              ? KPOrientation.DESC
              : KPOrientation.ASC,
          }
        }
        return { id: extremity.id }
      }),
    }

    try {
      const response: TrackProtection = await patch(
        `/${MIDI_URI}/${ObjectURI.TrackProtections}/${updatedTrackProtection.id}`,
        updatedTrackProtection, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
