import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { RootState } from 'Store'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { canEditObject, formatActivityDates } from 'components/Panels/DetailsPanel/utils'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { KIND_TO_LAYER, findObjectKind } from 'objects/kind'
import Parser from 'objects/parser'
import { GetDetailsServiceOfKind } from 'objects/services'
import { MidiObject } from 'objects/types'
import { ExtraKind } from 'objects/types/const'
import { Extremity } from 'objects/types/protections'
import { DeleteChildrenPayload, ProtectionChild } from 'objects/types/protections/const'
import { addLayer } from 'reducers/map'
import { createNewObject } from 'reducers/panels/creationPanel'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import { CollapsibleMenu } from 'reducers/types'
import { GenericAction } from 'types'
import { toggleOpenCollapsible } from '../utils'
import DeleteButton from './DeleteButton'
import ExtremityContextMenu from './ExtremityContextMenu'

type Props = {
  type: ExtraKind;
  extremities: Extremity[];
  deleteExtremity: GenericAction<number>;
  deleteExtremities: GenericAction<DeleteChildrenPayload>;
  switchOrientation?: GenericAction<number>;
  editable: boolean;
}

const defaultProps = {
  switchOrientation: undefined,
}

export default function Extremities({
  type, extremities, deleteExtremity: deleteExtremityAction, deleteExtremities, switchOrientation, editable,
}: Props): ReactElement | null {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { openMenus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  if (!extremities) return null

  const fetchExtremity = (extremity: Extremity) => {
    if (extremity.detailUrl && extremity?.extremityObject?.id) {
      const kind = findObjectKind(extremity as unknown as MidiObject)
      dispatch(addLayer(KIND_TO_LAYER[kind]))
      dispatch(GetDetailsServiceOfKind[kind]({ id: extremity.extremityObject.id }))
    }
  }

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(CollapsibleMenu.extremities)}
      onChange={() => toggleOpenCollapsible(CollapsibleMenu.extremities)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t('Attributes.TrackProtection.extremities')} (${extremities && extremities.length})`}
      </AccordionSummary>
      <AccordionDetails>
        {extremities.map(extremity => (
          <ObjectTile
            key={extremity.id}
            onClick={() => fetchExtremity(extremity)}
            title={extremity.mainRepresentation}
            subtitle={extremity.direction ? String(Parser.orientationType(extremity.direction)) : undefined}
            contextMenu={canEditObject() && editable ? (
              <ExtremityContextMenu
                extremity={extremity}
                deleteAction={deleteExtremityAction}
                switchOrientationAction={switchOrientation}
              />
            ) : <></>}
            activityDates={extremity.extremityObject ? formatActivityDates(extremity.extremityObject) : ''}
          />
        ))}
        {canEditObject() && editable && (
        <div className="protection-buttons  my-2">
          <SimpleButton
            title={t('Details.button.addExtremity')}
            onClick={() => {
              dispatch(createNewObject(type))
              PanelNavigator.push(PanelName.creation)
            }}
            style={ButtonStyle.light}
          />

          {!!extremities.length && (
          <DeleteButton
            deleteAction={deleteExtremities}
            field={ProtectionChild.extremities}
          />
          )}

        </div>
        ) }

      </AccordionDetails>
    </Accordion>
  )
}

Extremities.defaultProps = defaultProps
