import { RootState } from 'Store'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { PanelName, PanelState } from 'reducers/panels/panel'
import CreationPanel from '../CreationPanel/CreationPanel'
import DetailPanel from '../DetailsPanel'
import ListPanel from '../ListPanel/ListPanel'
import ModificationPanel from '../ModificationPanel/ModificationPanel'
import './Panel.scss'

type Props = {
  panelName: PanelName;
}

export default function Panel({ panelName }: Props): ReactElement | null {
  const { panelHistory } = useSelector((state: RootState): PanelState => state.panel)

  const getPanel = () => {
    switch (panelName) {
      case PanelName.list:
        return <ListPanel />
      case PanelName.modification:
        return <ModificationPanel />
      case PanelName.creation:
        return <CreationPanel />
      case PanelName.details:
        return <DetailPanel />
      default:
        return null
    }
  }

  const display = panelHistory[panelHistory.length - 1] === panelName
    ? 'd-block'
    : 'd-none'

  return (
    <div className={`${display} panel-wrapper`}>
      {getPanel()}
    </div>
  )
}
