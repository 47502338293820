import { useTranslation } from '@osrdata/app_core/dist/translation'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'

import { RootState } from 'Store'
import { AutoCompleteState, resetAutoComplete } from 'reducers/autoCompletes'
import { nestedObject } from 'types'
import { InputDataType } from '../utils'
import './CustomAutoComplete.scss'
import CustomFormHelperText from './CustomFormHelperText'

const defaultProps = {
  error: false,
  helperText: undefined,
}

export default function CustomAutoComplete({
  field, value, onChange, error, helperText, disabled,
}: InputDataType): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { autoCompleteParams: params } = field
  const { autoCompletes } = useSelector((state: RootState): AutoCompleteState => state.autoCompletes)
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Array<nestedObject | string>>(params?.options || [])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string | undefined>(undefined)

  const updateValues = useCallback(
    debounce((s: string | undefined) => {
      if (params?.source) {
        if (s && s !== '') {
          dispatch(params?.source(s))
        } else {
          setOpen(false)
          dispatch(resetAutoComplete(params?.key))
        }
      }
    }, 800),
    [params],
  )

  useEffect(() => {
    const index = autoCompletes.findIndex(a => a.key === params?.key)

    if (index !== -1) {
      setOptions(autoCompletes[index].values)
      setLoading(autoCompletes[index].loading)
    } else {
      setOptions([])
    }
  }, [autoCompletes])

  useEffect(() => {
    if (search && search !== '') {
      if (params?.source) {
        setLoading(true)
        updateValues(search)
      } else if (params?.options) {
        setOptions(params.options.filter(o => o.toLowerCase().includes(search.toLowerCase())))
      }
    } else {
      setOpen(false)
      if (params?.options) setOptions(params.options)
      dispatch(resetAutoComplete(params?.key))
    }
  }, [search])

  const handleChange = (_e: SyntheticEvent, newValue: nestedObject | string | null | undefined) => {
    if (newValue) {
      onChange(newValue, field)
      if (params?.dependance) {
        dispatch(resetAutoComplete(params?.dependance))
      }
    }
  }

  return (
    <div className="autocomplete w-100 my-3 px-2">
      <Autocomplete
        id={`autocomplete-${field.key}`}
        fullWidth
        open={open}
        onOpen={() => { setOpen(true) }}
        onClose={() => { setOpen(false) }}
        value={value as nestedObject}
        filterOptions={x => x}
        onChange={handleChange}
        isOptionEqualToValue={(option, selectedValue) => {
          if (typeof option === 'string') return option === selectedValue
          return get(option, field.path) === get(selectedValue, field.path)
        }}
        getOptionLabel={option => params?.displayedValueFormatter(option) || get(option, field.path) || ''}
        options={options}
        loading={loading}
        loadingText={t('Common.loading')}
        noOptionsText={t('Common.noResult')}
        disablePortal
        disableClearable
        disabled={disabled}
        renderInput={inputParams => (
          <TextField
            variant="standard"
            {...inputParams}
            value={search}
            label={t(field.label)}
            onChange={e => setSearch(e.target.value)}
            error={error && !disabled}
            disabled={disabled}
            helperText={helperText && !disabled && <CustomFormHelperText helperText={helperText} />}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              ...inputParams.InputProps,
              className: 'input',
              disableUnderline: true,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {inputParams.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

CustomAutoComplete.defaultProps = defaultProps
