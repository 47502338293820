/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ElectricalStation, Zoe } from 'objects/types/protections'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { ThunkApiConfig } from 'types'

const getAll = createAsyncThunk<ElectricalStation[], void, ThunkApiConfig>(
  'electricalStation/getAll',
  async (_, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.ElectricalStation}`)
      return response.results
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getZoes = createAsyncThunk<Zoe[], string, ThunkApiConfig>(
  'electricalStation/getZoes',
  async (id, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.ElectricalStation}/${id}/${ObjectURI.Zoes}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ElectricalStationServices = {
  getAll,
  getZoes,
}

export default ElectricalStationServices
