import {
  createSlice,
  isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import set from 'lodash/set'

import ButtonStatus from 'components/Common/buttonStatus'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import { ExtraKind, ObjectKind } from 'objects/types/const'
import { createErrorMatcher, createFulfilledMatcher, createPendingMatcher } from 'reducers/matchers/createMatchers'
import { UpdateField } from 'reducers/types'
import { nestedObject, ObjOfStrOrNum, ResponseError } from 'types'
import SectorServices from 'objects/Sectors/SectorServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'

export interface CreationPanelState {
  newObjectKind?: ObjectKind | ExtraKind;
  newObject: ObjOfStrOrNum | nestedObject;
  initialValues?: UpdateField[];
  buttonStatus: ButtonStatus;
  hasError: boolean;
  error?: ResponseError;
  isLoading: boolean;
  errorByKey: {[key: string]: boolean};
}

const initialState: CreationPanelState = {
  newObjectKind: undefined,
  buttonStatus: ButtonStatus.Disabled,
  initialValues: undefined,
  newObject: {},
  hasError: false,
  error: undefined,
  isLoading: false,
  errorByKey: {},
}

const resetState = () => initialState

const setButtonLoading = (state: CreationPanelState) => {
  state.buttonStatus = ButtonStatus.Loading
  state.isLoading = true
}

const setError = (state: CreationPanelState, action: PayloadAction<ResponseError | undefined, string>) => {
  state.buttonStatus = ButtonStatus.Disabled
  state.error = action.payload
  state.hasError = true
  state.isLoading = false
}

export const creationPanelSlice = createSlice({
  name: 'creationPanel',
  initialState,
  reducers: {
    createNewObject: (state, action: PayloadAction<ObjectKind | ExtraKind>) => {
      state.newObjectKind = action.payload
    },
    setInitialValues: (state, action: PayloadAction<UpdateField[]>) => {
      state.initialValues = action.payload
    },
    updateNewObject: (state, action: PayloadAction<ObjOfStrOrNum | nestedObject>) => {
      state.newObject = action.payload
    },
    setNewObjectField: (state, action: PayloadAction<UpdateField>) => {
      const { value, path } = action.payload
      state.newObject = set(state.newObject, path, value)
    },
    resetButtonStatus: state => {
      state.buttonStatus = ButtonStatus.Base
    },
    setButtonStatus: (state, action: PayloadAction<ButtonStatus>) => {
      state.buttonStatus = action.payload
    },
    resetCreation: resetState,
    setHasError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload
      state.buttonStatus = action.payload
        ? ButtonStatus.Disabled
        : ButtonStatus.Base
    },
    setErrorByKey: (state, action: PayloadAction<{key: string; value: boolean}>) => {
      const { key, value } = action.payload
      state.errorByKey = {
        ...state.errorByKey,
        [key]: value,
      }
    },
  },
  extraReducers: builder => {
    // Pending
    builder.addMatcher(isAnyOf(
      createPendingMatcher,
      TrackProtectionServices.addExtremity.pending,
      TrackProtectionGroupServices.addExtremity.pending,
      ElectricalElementServices.addExtremity.pending,
      ElectricalProtectionGroupServices.addExtremity.pending,
      SectorServices.addExtremity.pending,
      SubSectorServices.addExtremity.pending,
    ), setButtonLoading)

    // Fulfilled
    builder.addMatcher(isAnyOf(
      createFulfilledMatcher,
      TrackProtectionServices.addExtremity.fulfilled,
      TrackProtectionGroupServices.addExtremity.fulfilled,
      ElectricalElementServices.addExtremity.fulfilled,
      ElectricalProtectionGroupServices.addExtremity.fulfilled,
      SectorServices.addExtremity.fulfilled,
      SubSectorServices.addExtremity.fulfilled,

    ), resetState)

    // Error
    builder.addMatcher(isAnyOf(
      createErrorMatcher,
      TrackProtectionServices.addExtremity.rejected,
      TrackProtectionGroupServices.addExtremity.rejected,
      ElectricalElementServices.addExtremity.rejected,
      ElectricalProtectionGroupServices.addExtremity.rejected,
      SectorServices.addExtremity.rejected,
      SubSectorServices.addExtremity.rejected,
    ), setError)
  },
})

export const {
  createNewObject, updateNewObject, resetCreation, resetButtonStatus, setNewObjectField, setHasError: setCreationError,
  setInitialValues, setButtonStatus, setErrorByKey,
} = creationPanelSlice.actions

export default creationPanelSlice.reducer
