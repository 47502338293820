import { loggedAsManager, loggedAsOperator } from 'helpers/permissions'
import { ObjectStatus } from 'objects/types/const'
import { addOpenMenu, DetailsPanelState, removeOpenMenu } from 'reducers/panels/detailsPanel'
import { CollapsibleMenu } from 'reducers/types'
import { store } from 'Store'

const toggleOpenCollapsible = (c: CollapsibleMenu): void => {
  const { openMenus } = store.getState().detailsPanel as DetailsPanelState
  if (openMenus.includes(c)) store.dispatch(removeOpenMenu(c))
  else store.dispatch(addOpenMenu(c))
}

export type RoleColors = {[key in ObjectStatus]: string}

export const OperatorColors: RoleColors = {
  [ObjectStatus.TODO]: 'red',
  [ObjectStatus.IN_PROGRESS]: 'blue',
  [ObjectStatus.VALIDATED]: 'green',
  [ObjectStatus.VERIFIED]: 'green',
  [ObjectStatus.DISPATCHED]: 'green',
}

export const SupervisorColors: RoleColors = {
  [ObjectStatus.TODO]: 'red',
  [ObjectStatus.IN_PROGRESS]: 'purple',
  [ObjectStatus.VALIDATED]: 'blue',
  [ObjectStatus.VERIFIED]: 'green',
  [ObjectStatus.DISPATCHED]: 'green',
}

const isTodo = ['==', ['get', 'status'], ObjectStatus.TODO]
const isInProgress = ['==', ['get', 'status'], ObjectStatus.IN_PROGRESS]
const isValidated = ['==', ['get', 'status'], ObjectStatus.VALIDATED]
const isVerified = ['==', ['get', 'status'], ObjectStatus.VERIFIED]
const isPublished = ['==', ['get', 'status'], ObjectStatus.DISPATCHED]

const getStatusColorsByRole = (): mapboxgl.Expression => {
  const colors = loggedAsOperator() || loggedAsManager() ? OperatorColors : SupervisorColors
  const colorExpression: mapboxgl.Expression = ['case',
    isTodo, colors[ObjectStatus.TODO],
    isInProgress, colors[ObjectStatus.IN_PROGRESS],
    isValidated, colors[ObjectStatus.VALIDATED],
    isVerified, colors[ObjectStatus.VERIFIED],
    isPublished, colors[ObjectStatus.DISPATCHED],
    '#fc03e3',
  ]
  return colorExpression
}

export { getStatusColorsByRole, toggleOpenCollapsible }
