import { Instruction, User as InstructionUser } from 'objects/types/instructions'
import { User as CerbereUser } from 'services/cerbereTypes'

const getUniqueIds = (instructions: Instruction[], knownUsers: CerbereUser[]): string[] => {
  const knonwIds = knownUsers.map(user => user.id)
  const filteredUsers: string[] = []
  instructions.forEach(instruction => {
    const newUsers = instruction.users.flatMap(user => ((knonwIds.includes((user as
      InstructionUser)?.sub) || filteredUsers.some(u => u === (user as InstructionUser)?.sub))
      ? [] : user.sub as string))
    filteredUsers.push(...newUsers)
  })
  return filteredUsers
}

// eslint-disable-next-line import/prefer-default-export
export { getUniqueIds }
