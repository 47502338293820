import CloseIcon from '@mui/icons-material/Close'
import HomeIcon from '@mui/icons-material/Home'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import { RootState } from 'Store'
import { Tab as TabType } from 'components/Common/TabsMenu/TabsMenu'
import Map from 'components/Map/Map'
import Panel from 'components/Panels/PanelWrapper/PanelWrapper'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  OperatorState,
  closeInstruction,
  displayParameters,
  setActiveTab,
} from 'reducers/operator'
import { resetPageNumber, resetTotalItemsCount } from 'reducers/panels/listPanel'
import { PanelState } from 'reducers/panels/panel'
import InstructionServices from 'services/InstructionServices'
import { RouteParams } from 'types'
import Parameters from 'views/admin/instructions/Parameters/Parameters'
import './Home.scss'
import HomeDashboard from './HomeTab'

interface ExtendedTab extends TabType {
  icon?: ReactElement;
}

const HOME_TABS: ExtendedTab[] = [
  {
    key: 'home',
    value: '0',
    icon: <HomeIcon htmlColor="white" />,
    label: '',
    component: <HomeDashboard />,
  },
]

export default function Home(): ReactElement {
  const dispatch = useDispatch()
  const {
    openInstructionsList, activeTab,
    displayParameters: showParameters,
  } = useSelector((state: RootState) => state.operator) as OperatorState
  const { panelHistory } = useSelector((state: RootState): PanelState => state.panel)
  const params = useParams<RouteParams>()

  const handleTabChange = (newValue: string) => {
    dispatch(setActiveTab(Number(newValue)))
    dispatch(resetPageNumber())
    dispatch(resetTotalItemsCount(0))
  }

  const fetchInstructions = () => {
    dispatch(InstructionServices.getObjectInstructions(params.id))
  }

  useEffect(() => {
    if (params.type && params.id) {
      fetchInstructions()
    }
  }, [])

  const handleTabClose = (id: string) => {
    dispatch(closeInstruction(id))
    dispatch(resetPageNumber())
    dispatch(resetTotalItemsCount(0))
  }

  const displayMap = () => (
    <>
      {panelHistory.map(panel => (
        <Panel key={panel} panelName={panel} />
      ))}

      <Map />
    </>
  )
  return (
    <div className="w-100" id="operator-home">
      <TabContext value={String(activeTab)}>
        <TabList aria-label="tabs menu">
          {HOME_TABS.map(tab => (
            <Tab
              key={tab.key}
              icon={tab.icon}
              label={tab.label}
              value={tab.value}
              className="tabs-menu-item"
              onClick={() => handleTabChange(String(0))}
            />
          ))}
          {openInstructionsList.map((instruction, index) => (
            <div
              key={instruction.id}
              role="button"
              tabIndex={-1}
              className={`
                d-flex justify-content-center align-items-center tab
                pl-3 pr-2 ${index + 1 === activeTab ? 'Mui-selected' : ''}
              `}
              onClick={() => handleTabChange(String(index + 1))}
            >
              <div className="tab-title">
                {instruction.name}
              </div>
              <div>
                <IconButton onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  handleTabClose(instruction.id)
                }}
                >
                  <CloseIcon className="close-icon" />
                </IconButton>
              </div>
            </div>
          ))}
        </TabList>
        {HOME_TABS.map(tab => (
          <TabPanel
            key={tab.key}
            value={tab.value}
          >
            {tab.component}
          </TabPanel>
        ))}
        {openInstructionsList.map((instruction, index) => (
          <TabPanel
            key={instruction.id}
            value={String(index + 1)}
          >
            <div className="d-flex">

              {showParameters && activeTab !== 0 ? (
                <Parameters onClose={() => {
                  dispatch(setActiveTab(0))
                  dispatch(closeInstruction(instruction.id))
                  dispatch(displayParameters())
                }}
                />
              ) : displayMap()}

            </div>
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}
