import ButtonStatus from 'components/Common/buttonStatus'
import set from 'lodash/set'
import { ExtraKind, ObjectKind } from 'objects/types/const'
import { AttributesOfKind } from 'objects/services'
import { parseStringFieldValue } from 'objects/utils'
import { nestedObject, ObjOfStrOrNum } from 'types'
import { store } from 'Store'

export const CREATE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Creation.button.base',
  [ButtonStatus.Disabled]: 'Creation.button.base',
  [ButtonStatus.Loading]: 'Creation.button.loading',
  [ButtonStatus.Success]: 'Creation.button.success',
}

export const ADD_EXTREMITY_BUTTON_TEXT = {
  ...CREATE_BUTTON_TEXT,
  [ButtonStatus.Base]: 'Creation.button.addExtremity',
  [ButtonStatus.Disabled]: 'Creation.button.addExtremity',
}

export const getCreationButtonText = (
  kind: ExtraKind | ObjectKind,
): (
  typeof CREATE_BUTTON_TEXT | typeof ADD_EXTREMITY_BUTTON_TEXT
  ) => {
  switch (kind) {
    case ExtraKind.TPExtremity:
    case ExtraKind.TPGExtremity:
      return ADD_EXTREMITY_BUTTON_TEXT
    default:
      return CREATE_BUTTON_TEXT
  }
}

export const TitleOfKind = {
  [ObjectKind.ElectricalElement]: 'Creation.title.electricalElement',
  [ObjectKind.ElectricalProtectionGroup]: 'Creation.title.electricalProtectionGroup',
  [ObjectKind.Feeder]: 'Creation.title.feeder',
  [ObjectKind.TrackSection]: 'Creation.title.trackSection',
  [ObjectKind.Isolator]: 'Creation.title.isolator',
  [ObjectKind.Sector]: 'Creation.title.sector',
  [ObjectKind.Signal]: 'Creation.title.signal',
  [ObjectKind.SubSector]: 'Creation.title.subSector',
  [ObjectKind.TrackNode]: 'Creation.title.trackNode',
  [ObjectKind.TrackProtection]: 'Creation.title.trackProtection',
  [ObjectKind.TrackProtectionGroup]: 'Creation.title.trackProtectionGroup',
  [ObjectKind.Tunnel]: 'Creation.title.tunnel',
  [ExtraKind.EEExtremity]: 'Creation.title.extremity',
  [ExtraKind.EPGExtremity]: 'Creation.title.extremity',
  [ExtraKind.FeederExtremity]: 'Creation.title.extremity',
  [ExtraKind.TPExtremity]: 'Creation.title.extremity',
  [ExtraKind.TPGExtremity]: 'Creation.title.extremity',
  [ExtraKind.SectorExtremity]: 'Creation.title.extremity',
  [ExtraKind.SSExtremity]: 'Creation.title.extremity',
}

export const buildItem = (kind: ObjectKind | ExtraKind): ObjOfStrOrNum | nestedObject => {
  const newObject: ObjOfStrOrNum | nestedObject = {}

  AttributesOfKind(store)[kind].filter(att => att.creation && att.creation.required && !att.shouldBeDisabled)
    .forEach(att => {
      const formattedValue = att.creation?.defaultValue !== undefined
        ? parseStringFieldValue(att.creation?.defaultValue)
        : att.creation?.defaultValue
      set(newObject, att.path, formattedValue)
    })

  return newObject
}
