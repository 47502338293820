import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import { Tooltip } from '@mui/material'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { STATUS_LABEL, getStatusIcon, noValidationKind } from 'components/Map/popupUtils'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { findObjectKind } from 'objects/kind'
import { ShortMidiObject } from 'objects/types'
import { ITEM_LAYER, ObjectLayer } from 'objects/types/const'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { disableGeoEditor } from 'reducers/geoEditor'
import { addLayer, setHoveredPanelObject } from 'reducers/map'
import { updateItem } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import './ObjectItem.scss'
import { getMainText, getSubText } from './utils'

type Props = {
  item: unknown;
}

export default function ObjectItem({ item }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const typedItem = item as ShortMidiObject

  const kind = findObjectKind(typedItem)

  const onItemClick = () => {
    const key = typedItem.itemType as keyof typeof ITEM_LAYER
    dispatch(disableGeoEditor())
    dispatch(addLayer(ITEM_LAYER[key] as unknown as ObjectLayer))
    dispatch(updateItem(typedItem))
    PanelNavigator.push(PanelName.details)
  }

  const getMoreStatus = () => {
    const icons = []

    if (typedItem.deletedAt) {
      icons.push(<Tooltip title={t('Details.title.deleted')}><DeleteOutlinedIcon /></Tooltip>)
    }

    if (typedItem.inConflict) {
      icons.push(<Tooltip title={t('Details.title.inConflict')}><ErrorIcon className="conflict" /></Tooltip>)
    }

    return icons
  }

  const moreStatus = getMoreStatus()

  const status = getStatusIcon(typedItem.status)

  return (
    <ListItemButton
      role={undefined}
      onClick={() => onItemClick()}
      className="list-item-wrapper elevation-2"
      onMouseEnter={() => {
        dispatch(setHoveredPanelObject(item as ShortMidiObject))
      }}
      onMouseLeave={() => {
        dispatch(setHoveredPanelObject(undefined))
      }}
    >
      <ListItemText id={typedItem.itemId}>
        <div className="list-text">{getMainText(typedItem, t)}</div>
        <div className="list-subtitle">{t(getSubText(typedItem))}</div>
      </ListItemText>

      {moreStatus}

      {status && !noValidationKind(kind) && (
        <Tooltip title={t(STATUS_LABEL[typedItem.status])}>
          <img
            src={status}
            alt="status icon"
            className="status-icon"
          />
        </Tooltip>
      )}

      {!moreStatus.length && !status && (<ArrowForwardIosIcon className="icon" />)}

    </ListItemButton>
  )
}
