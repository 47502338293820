import { CreatedObjectsByInstructionType, InstructionType } from 'objects/types/instructions'
import { ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'
import { GeoEditorState } from 'reducers/geoEditor'
import { InstructionState } from 'reducers/instruction'
import { PanelName } from 'reducers/panels/panel'
import { RootState } from 'Store'

import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { getActivePanel } from 'helpers'

import MENU_ITEMS from './items'
import './styles.scss'

export default function CreateButton(): ReactElement | null {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { active: showGeoEditor } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)

  if (showGeoEditor || getActivePanel() === PanelName.creation) {
    return null
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  return (
    <div id="create-button">
      <Button
        aria-controls={open ? 'create-context-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <div>
          <AddIcon />
        </div>
        <div className="title">
          {t('Map.createObjectButton')}
        </div>
      </Button>
      <Menu
        className="create-context-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={() => handleClose()}
      >
        {MENU_ITEMS
          .filter(item => CreatedObjectsByInstructionType[instruction.type as InstructionType].includes(item.key))
          .map(item => (
            <MenuItem key={item.key} onClick={() => handleClose(item.onClick)}>
              {t(item.label)}
            </MenuItem>
          ))}
      </Menu>
    </div>
  )
}
