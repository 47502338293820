import { isAnyOf } from '@reduxjs/toolkit'
import SectorServices from 'objects/Sectors/SectorServices'

export const updateSectorLinkedObjectsErrorMatcher = isAnyOf(
  SectorServices.addIncompatibleObject.rejected,
  SectorServices.deleteIncompatibleObject.rejected,
  SectorServices.addAssociatedSubArea.rejected,
  SectorServices.deleteAssociatedSubArea.rejected,
  SectorServices.addResponsibleSubArea.rejected,
  SectorServices.deleteResponsibleSubArea.rejected,
  SectorServices.addChild.rejected,
  SectorServices.deleteChild.rejected,
  SectorServices.deleteExtremity.rejected,
  SectorServices.switchExtremityDirection.rejected,
  SectorServices.addExtremity.rejected,
  SectorServices.deleteElements.rejected,
)

export const updateSectorLinkedObjectsFulfilledMatcher = isAnyOf(
  SectorServices.addIncompatibleObject.fulfilled,
  SectorServices.deleteIncompatibleObject.fulfilled,
  SectorServices.addAssociatedSubArea.fulfilled,
  SectorServices.deleteAssociatedSubArea.fulfilled,
  SectorServices.addResponsibleSubArea.fulfilled,
  SectorServices.deleteResponsibleSubArea.fulfilled,
  SectorServices.addChild.fulfilled,
  SectorServices.deleteChild.fulfilled,
  SectorServices.deleteExtremity.fulfilled,
  SectorServices.switchExtremityDirection.fulfilled,
  SectorServices.addExtremity.fulfilled,
  SectorServices.deleteElements.fulfilled,
)

export const updateSectorLinkedObjectsPendingMatcher = isAnyOf(
  SectorServices.addIncompatibleObject.pending,
  SectorServices.deleteIncompatibleObject.pending,
  SectorServices.addAssociatedSubArea.pending,
  SectorServices.deleteAssociatedSubArea.pending,
  SectorServices.addResponsibleSubArea.pending,
  SectorServices.deleteResponsibleSubArea.pending,
  SectorServices.addChild.pending,
  SectorServices.deleteChild.pending,
  SectorServices.deleteExtremity.pending,
  SectorServices.switchExtremityDirection.pending,
  SectorServices.addExtremity.pending,
  SectorServices.deleteElements.pending,
)
