import { useTranslation } from '@osrdata/app_core/dist/translation'
import GeoEditor, { onSave as saveNewGeometry, Tool } from '@osrdata/geoeditor'
import { GeoEditorState } from '@osrdata/geoeditor/dist/reducer'
import Loader from 'components/Common/Loader'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { ProjectionState, toggleOpenValidationModal } from 'reducers/projection'
import { RootState, store } from 'Store'
import saveIcon from 'assets/icons/save.svg'
import { MIDI_URI } from 'objects/uri'
import ProjectionValidationInfoModal from './ProjectionValidationInfoModal'
import './Linearisation.scss'

const customTools: Tool[] = [
  {
    key: 'save',
    label: 'Sauvegarder la représentation',
    icon: saveIcon,
    onClick: () => {
      saveNewGeometry()
      store.dispatch(toggleOpenValidationModal())
    },
  },
]

type Props = {
  onClose: () => void;
  absolute?: boolean;
}

const defaultProps = {
  absolute: false,
}
export default function Linearisation({ absolute, onClose }: Props): ReactElement {
  const { t } = useTranslation()
  const { projection } = useSelector((state: RootState) => state.projection) as ProjectionState
  const { isLoading } = useSelector((state: RootState) => state.geoEditor) as GeoEditorState

  return (
    <div className={`linearisation d-flex w-100 flex-column h-100 ${absolute ? 'absolute' : ''}`}>
      <div className="colored-title purple d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper d-flex justify-content-center text-center flex-column">
            {t('Projections.creation.linearization')}
          </div>
        </div>
      </div>

      <div className="w-100" style={{ flex: 1, position: 'relative' }}>
        {isLoading && (
          <Loader message={t('Projections.creation.gettingGeometry')} overlay />
        )}
        <GeoEditor
          configUrl={`/${MIDI_URI}/projections/${projection.slug}/geometries/`}
          onClose={onClose}
          customTools={customTools}
          enableDelete
          preventExtremityDeletion
        />
      </div>
      {!isLoading && (<ProjectionValidationInfoModal />)}
    </div>
  )
}

Linearisation.defaultProps = defaultProps
