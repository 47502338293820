import { ReactElement } from 'react'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import Extremities from 'objects/common/Extremities/Extremities'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import { AttributesProps, ExtraKind } from 'objects/types/const'

import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import Instructions from 'objects/common/Instructions/Instructions'
import { ElectricalElement } from 'objects/types/protections'
import { ProtectionChild } from 'objects/types/protections/const'
import { CollapsibleMenu } from 'reducers/types'
import ELECTRICAL_ELEMENT_ATTRIBUTES from './ElectricalElementAttributes'
import ElectricalElementServices from './ElectricalElementServices'

export default function ElectricalElementDetails({ object }: AttributesProps): ReactElement | null {
  const electricalElement = object as ElectricalElement
  const {
    extremities, responsibleSubAreas, associatedSubAreas, incompatibleProtections,
  } = electricalElement
  const editable = electricalElement.inInstruction

  return (
    <div id="electrical-element-details" className="object-attributes">
      <Attributes item={electricalElement} attributes={ELECTRICAL_ELEMENT_ATTRIBUTES(electricalElement)} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <Extremities
          editable={editable}
          type={ExtraKind.EEExtremity}
          extremities={extremities}
          deleteExtremity={ElectricalElementServices.deleteExtremity}
          deleteExtremities={ElectricalElementServices.deleteElements}
          switchOrientation={ElectricalElementServices.switchExtremityDirection}
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={ElectricalElementServices.deleteResponsibleSubArea}
          addRSAAction={ElectricalElementServices.addResponsibleSubArea}
          deleteAll={ElectricalElementServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={ElectricalElementServices.deleteAssociatedSubArea}
          addASAAction={ElectricalElementServices.addAssociatedSubArea}
          deleteAll={ElectricalElementServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          deleteAll={ElectricalElementServices.deleteElements}
          fieldType={ProtectionChild.incompatibleProtections}
          linkedProtections={incompatibleProtections}
          deleteAction={ElectricalElementServices.deleteIncompatibleObject}
          addIPAction={ElectricalElementServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
        />
        <Instructions item={electricalElement} />
      </div>
    </div>
  )
}
