/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import ExtremityCreation from 'objects/common/Extremities/types'
import { KPOrientation } from 'objects/types/const'
import { ElectricalElement } from 'objects/types/protections'
import { DeleteChildrenPayload } from 'objects/types/protections/const'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'

export const addExtremity = createAsyncThunk<ElectricalElement, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'electricalElement/addExtremity',
  async (newExtremity, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as ElectricalElement
    const {
      layerName: _layerName, kilometricPoints,
      ...typedNewExtremity
    } = newExtremity as unknown as ExtremityCreation

    const kp = kilometricPoints?.[0].value

    const formattedNewExtremity = {
      ...typedNewExtremity,
      location: { ...typedNewExtremity.location, value: kp },
    }

    const updatedElectricalElement = {
      id,
      checksum,
      extremities: [
        ...extremities.map(ext => ({ id: ext.id })),
        formattedNewExtremity,
      ],
    }

    try {
      const response: ElectricalElement = await patch(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        updatedElectricalElement, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteExtremity = createAsyncThunk<ElectricalElement, number, ThunkApiConfig>(
  'electricalElement/deleteExtremityById',
  async (deletedExtremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as ElectricalElement

    const updatedElectricalElement = {
      id,
      checksum,
      extremities: extremities.flatMap(extremity => (extremity.id !== deletedExtremityId ? { id: extremity.id } : [])),
    }

    try {
      const response: ElectricalElement = await patch(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        updatedElectricalElement, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteElements = createAsyncThunk<ElectricalElement, DeleteChildrenPayload, ThunkApiConfig>(
  'electricalElement/deleteElements',
  async (params, thunkApi) => {
    const { id, field } = params
    const updatedElectricalElement = {
      id,
      [field]: [],
    }

    try {
      const response: ElectricalElement = await patch(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/${id}`,
        updatedElectricalElement, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const switchExtremityDirection = createAsyncThunk<ElectricalElement, number, ThunkApiConfig>(
  'electricalElement/switchExtremityDirection',
  async (extremityId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, extremities } = detailsPanelState.item as ElectricalElement

    const updatedElectricalElement = {
      id,
      checksum,
      extremities: extremities.map(extremity => {
        if (extremity.id === extremityId) {
          return {
            ...extremity,
            direction: extremity.direction === KPOrientation.ASC
              ? KPOrientation.DESC
              : KPOrientation.ASC,
          }
        }
        return { id: extremity.id }
      }),
    }

    try {
      const response: ElectricalElement = await patch(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        updatedElectricalElement, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
