import dispatchedIcon from 'assets/icons/dispatched.svg'
import validatedIcon from 'assets/icons/in_verification.svg'
import isolatorIcon from 'assets/icons/isolator.png'
import s11Icon from 'assets/icons/s11.svg'
import s9Icon from 'assets/icons/s9.svg'
import signalIcon from 'assets/icons/signal.svg'
import feederIcon from 'assets/icons/feeder_icon.svg'
import trackIcon from 'assets/icons/track.svg'
import tunnelIcon from 'assets/icons/tunnel.svg'
import trackNodeIcon from 'assets/icons/trackNode.svg'
import verifiedIcon from 'assets/icons/verified.svg'
import {
  Isolator,
  ShortTrackSection,
  Signal,
  TrackSection,
} from 'objects/types'
import { ObjectKind, ObjectLayer, ObjectStatus } from 'objects/types/const'
import { Protection } from 'objects/types/protections'
import { FuncStrToStr } from 'types'
import { MapLayerObject } from './utils'

export const LAYERS_LABEL = {
  [ObjectLayer.ElectricalElement]: 'Object.type.electricalElement',
  [ObjectLayer.ElectricalProtectionGroup]: 'Object.type.electricalProtectionGroup',
  [ObjectLayer.Feeder]: 'Object.type.feeder',
  [ObjectLayer.Isolator]: 'Object.type.isolator',
  [ObjectLayer.Sector]: 'Object.type.sector',
  [ObjectLayer.Signal]: 'Object.type.signal',
  [ObjectLayer.SubSector]: 'Object.type.subSector',
  [ObjectLayer.TrackNode]: 'Object.type.trackNode',
  [ObjectLayer.TrackProtection]: 'Object.type.trackProtection',
  [ObjectLayer.TrackProtectionGroup]: 'Object.type.trackProtectionGroup',
  [ObjectLayer.TrackSection]: 'Object.type.trackSection',
  [ObjectLayer.Tunnel]: 'Object.type.tunnel',
}

export const STATUS_LABEL = {
  [ObjectStatus.TODO]: 'Object.status.toDo',
  [ObjectStatus.IN_PROGRESS]: 'Object.status.inProgress',
  [ObjectStatus.VALIDATED]: 'Object.status.validated',
  [ObjectStatus.VERIFIED]: 'Object.status.verified',
  [ObjectStatus.DISPATCHED]: 'Object.status.dispatched',
}

export const getStatusIcon = (status: ObjectStatus): string | undefined => {
  switch (Number(status)) {
    case ObjectStatus.VALIDATED:
      return validatedIcon
    case ObjectStatus.VERIFIED:
      return verifiedIcon
    case ObjectStatus.DISPATCHED:
      return dispatchedIcon
    case ObjectStatus.TODO:
    case ObjectStatus.IN_PROGRESS:
    default:
      return undefined
  }
}

export const getObjectPopupIcon = (object: MapLayerObject): string => {
  switch (object.sourceLayer) {
    case ObjectLayer.Isolator:
      return isolatorIcon
    case ObjectLayer.Signal:
      return signalIcon
    case ObjectLayer.TrackProtection:
    case ObjectLayer.TrackProtectionGroup:
      return s9Icon
    case ObjectLayer.ElectricalElement:
    case ObjectLayer.ElectricalProtectionGroup:
    case ObjectLayer.Sector:
    case ObjectLayer.SubSector:
      return s11Icon
    case ObjectLayer.Feeder:
      return feederIcon
    case ObjectLayer.TrackNode:
      return trackNodeIcon
    case ObjectLayer.Tunnel:
      return tunnelIcon
    default:
      return trackIcon
  }
}

export const getNoLabel = (layer: ObjectLayer): string => {
  switch (layer) {
    case (ObjectLayer.ElectricalElement):
      return 'Map.HoverPopupTitle.noLabelElectricalElement'
    case (ObjectLayer.ElectricalProtectionGroup):
      return 'Map.HoverPopupTitle.noLabelElectricalProtectionGroup'
    case (ObjectLayer.Feeder):
      return 'Map.HoverPopupTitle.noLabelFeeder'
    case (ObjectLayer.Sector):
      return 'Map.HoverPopupTitle.noLabelSector'
    case (ObjectLayer.SubSector):
      return 'Map.HoverPopupTitle.noLabelSubSector'
    case (ObjectLayer.TrackProtection):
      return 'Map.HoverPopupTitle.noLabelTrackProtection'
    case (ObjectLayer.TrackProtectionGroup):
      return 'Map.HoverPopupTitle.noLabelTrackProtectionGroup'
    default:
      return 'Map.HoverPopupTitle.noLabel'
  }
}

export const getTrackSectionTitle = (trackSection: ShortTrackSection, i18: FuncStrToStr): string => {
  const lineCode = trackSection?.track?.line?.lineCode || trackSection?.lineCode
  const name = trackSection?.track?.name || trackSection.trackCode
  const { endKp, startKp } = trackSection

  const emptyPk = () => (!startKp || !endKp)
  if (name === undefined && lineCode === undefined && emptyPk()) {
    return i18('Map.HoverPopupTitle.emptyTrackSection')
  }
  if (name === undefined) {
    return i18('Map.HoverPopupTitle.noTrack')
  }
  if (lineCode === undefined) {
    return i18('Map.HoverPopupTitle.noLine')
  }
  if (emptyPk()) {
    return i18('Map.HoverPopupTitle.noKp')
  }
  return (
    `${lineCode} ${name} ${trackSection.startKp}/${trackSection.endKp}  `
  )
}

export const getTitle = (object: MapLayerObject, i18: FuncStrToStr): string => {
  if (object.properties.mainRepresentation) {
    return object.properties.mainRepresentation
  }
  switch (object.sourceLayer) {
    case ObjectLayer.Isolator:
      return `${i18('Map.HoverPopupTitle.track')}
        ${(object.properties as Isolator).trackName} / 
        ${(object.properties as Isolator).kilometricPoint}`
    case ObjectLayer.Signal:
      return `${(object.properties as Signal).type}
      ${(object.properties as Signal).label}`
    case ObjectLayer.TrackProtection:
      return (object.properties as Protection).label
        ? (object.properties as Protection).label
        : getNoLabel(object.sourceLayer)
    case ObjectLayer.TrackSection:
      return getTrackSectionTitle(object.properties as TrackSection, i18)
    default:
      return ''
  }
}

export const hasNoStatus = (layer: ObjectLayer) => layer === ObjectLayer.TrackNode
|| layer === ObjectLayer.Tunnel

export const noValidationKind = (kind: ObjectKind) => [ObjectKind.TrackNode, ObjectKind.Tunnel].includes(kind)
