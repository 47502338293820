import { InputBaseComponentProps } from '@mui/material'
import { isStringEmpty } from 'helpers'
import { DateTime } from 'luxon'
import { Attribute, InputTypes } from 'objects/attributes'
import { FieldValue } from 'reducers/types'
import { nestedObject } from 'types'

export interface InputDataType {
  field: Attribute;
  value: string | nestedObject;
  onChange: (v: FieldValue, f: Attribute) => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  index?: number;
  noLabel?: boolean;
  multiline?: boolean;
  inputProps?: InputBaseComponentProps;
  maxRows?: number;
}

export enum ErrorTypes {
  requiredField = 'requiredField',
  invalidDateFormat = 'invalidDateFormat'
}

const NO_EMPTY_STRING_TYPES: Array<InputTypes | undefined> = [
  undefined, InputTypes.DatePicker,
]

export const ValidationErrors = {
  [ErrorTypes.requiredField]: 'Error.requiredField',
  [ErrorTypes.invalidDateFormat]: 'Error.invalidDateFormat',
}

export const validateField = (field: Attribute, value: string | nestedObject): string | undefined => {
  // If field is required
  if (field.required
    && typeof value === 'string'
    && isStringEmpty(value)
    && NO_EMPTY_STRING_TYPES.includes(field.type)) {
    return ValidationErrors[ErrorTypes.requiredField]
  }
  if (field.type === InputTypes.DatePicker && value !== null && !DateTime.fromISO(value as string).isValid) {
    return ValidationErrors[ErrorTypes.invalidDateFormat]
  }
  return undefined
}
