import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { LayerProps } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl, supervisorFilter } from 'objects/utils'
import {
  BASE_LAYOUT, SIGNAL_MIN_ZOOM, getIconImage, getIconSize, getMatSize, iconOffset,
} from './layerConfig'

const sourceLayer = ObjectLayer.Signal

export default function SignalsLayer({ visible }: LayerProps): ReactElement {
  const layerFilter = supervisorFilter()
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer-mat`}
        type="symbol"
        layout={{
          ...BASE_LAYOUT,
          'icon-image': 'MATG',
          'icon-size': getMatSize(),
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{}}
        source-layer={sourceLayer}
        minzoom={SIGNAL_MIN_ZOOM}
        filter={layerFilter}
      />

      <Layer
        id={`${sourceLayer}-layer`}
        type="symbol"
        layout={{
          ...BASE_LAYOUT,
          'icon-offset': iconOffset,
          'icon-image': getIconImage(),
          'icon-size': getIconSize(),
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{}}
        source-layer={sourceLayer}
        minzoom={SIGNAL_MIN_ZOOM}
        filter={layerFilter}
      />
    </Source>
  )
}
