import { isAnyOf } from '@reduxjs/toolkit'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'

export const updateSubSectorLinkedObjectsErrorMatcher = isAnyOf(
  SubSectorServices.addIncompatibleObject.rejected,
  SubSectorServices.deleteIncompatibleObject.rejected,
  SubSectorServices.addAssociatedSubArea.rejected,
  SubSectorServices.deleteAssociatedSubArea.rejected,
  SubSectorServices.addResponsibleSubArea.rejected,
  SubSectorServices.deleteResponsibleSubArea.rejected,
  SubSectorServices.addChild.rejected,
  SubSectorServices.deleteChild.rejected,
  SubSectorServices.deleteExtremity.rejected,
  SubSectorServices.switchExtremityDirection.rejected,
  SubSectorServices.addExtremity.rejected,
  SubSectorServices.deleteElements.rejected,
)

export const updateSubSectorLinkedObjectsFulfilledMatcher = isAnyOf(
  SubSectorServices.addIncompatibleObject.fulfilled,
  SubSectorServices.deleteIncompatibleObject.fulfilled,
  SubSectorServices.addAssociatedSubArea.fulfilled,
  SubSectorServices.deleteAssociatedSubArea.fulfilled,
  SubSectorServices.addResponsibleSubArea.fulfilled,
  SubSectorServices.deleteResponsibleSubArea.fulfilled,
  SubSectorServices.addChild.fulfilled,
  SubSectorServices.deleteChild.fulfilled,
  SubSectorServices.deleteExtremity.fulfilled,
  SubSectorServices.switchExtremityDirection.fulfilled,
  SubSectorServices.addExtremity.fulfilled,
  SubSectorServices.deleteElements.fulfilled,
)

export const updateSubSectorLinkedObjectsPendingMatcher = isAnyOf(
  SubSectorServices.addIncompatibleObject.pending,
  SubSectorServices.deleteIncompatibleObject.pending,
  SubSectorServices.addAssociatedSubArea.pending,
  SubSectorServices.deleteAssociatedSubArea.pending,
  SubSectorServices.addResponsibleSubArea.pending,
  SubSectorServices.deleteResponsibleSubArea.pending,
  SubSectorServices.addChild.pending,
  SubSectorServices.deleteChild.pending,
  SubSectorServices.deleteExtremity.pending,
  SubSectorServices.switchExtremityDirection.pending,
  SubSectorServices.addExtremity.pending,
  SubSectorServices.deleteElements.pending,
)
