import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import AreaServices from 'services/AreaServices'
import InstructionServices from 'services/InstructionServices'

import { isAnyOf } from '@reduxjs/toolkit'
import ProjectionServices from 'services/ProjectionServices'
import ObjectServices from 'services/ObjectServices'
import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'

export const deleteFulfilledMatcher = isAnyOf(
  AreaServices.delete.fulfilled,
  ElectricalElementServices.delete.fulfilled,
  ElectricalProtectionGroupServices.delete.fulfilled,
  FeederServices.delete.fulfilled,
  InstructionServices.deleteItem.fulfilled,
  IsolatorServices.delete.fulfilled,
  SectorServices.delete.fulfilled,
  SignalServices.delete.fulfilled,
  ProjectionServices.delete.fulfilled,
  SubSectorServices.delete.fulfilled,
  TrackProtectionGroupServices.delete.fulfilled,
  TrackProtectionServices.delete.fulfilled,
  TrackNodeServices.delete.fulfilled,
  TunnelServices.delete.fulfilled,
)

export const deletePendingMatcher = isAnyOf(
  AreaServices.delete.pending,
  ElectricalElementServices.delete.pending,
  ElectricalProtectionGroupServices.delete.pending,
  FeederServices.delete.pending,
  IsolatorServices.delete.pending,
  SectorServices.delete.pending,
  SignalServices.delete.pending,
  ProjectionServices.delete.pending,
  SubSectorServices.delete.pending,
  TrackProtectionServices.delete.pending,
  TrackProtectionGroupServices.delete.pending,
  TrackSectionServices.delete.pending,
  TrackNodeServices.delete.pending,
  TunnelServices.delete.pending,
)

export const deleteErrorMatcher = isAnyOf(
  AreaServices.delete.rejected,
  AreaServices.deleteSubArea.rejected,
  ElectricalElementServices.delete.rejected,
  ElectricalProtectionGroupServices.delete.rejected,
  FeederServices.delete.rejected,
  InstructionServices.deleteItem.rejected,
  IsolatorServices.delete.rejected,
  SectorServices.delete.rejected,
  SignalServices.delete.rejected,
  ProjectionServices.delete.rejected,
  SubSectorServices.delete.rejected,
  TrackProtectionGroupServices.delete.rejected,
  TrackProtectionServices.delete.rejected,
  TrackSectionServices.delete.rejected,
  ObjectServices.mergeHistories.rejected,
  TrackNodeServices.delete.rejected,
  TunnelServices.delete.rejected,
)
