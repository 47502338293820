import { feature } from '@turf/helpers'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import MapGL, { MapEvent, MapRef } from 'react-map-gl'
import { Editor } from 'react-map-gl-draw'
import { useDispatch, useSelector } from 'react-redux'

import GeoEditor from 'components/GeoEditor/GeoEditor'
import { snapOnHover } from 'components/GeoEditor/helpers/snapping'
import { Modes } from 'components/GeoEditor/utils'
import HoverPopup from 'components/Map/Hover/HoverPopup'
import MapLoader from 'components/Map/MapLoader'

import { RootState } from 'Store'
import { GeoEditorState } from 'reducers/geoEditor'
import { InstructionState } from 'reducers/instruction'
import {
  MapState, addLayer, setLayers, setViewportBbox,
} from 'reducers/map'
import { DetailsPanelState, updateItem } from 'reducers/panels/detailsPanel'

import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ArrayFormat } from '@osrdata/app_core/dist/types'
import SearchBar from 'components/Common/SearchBar/SearchBar'
import { getBbox } from 'components/GeoEditor/helpers'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { instructionByObjectKind } from 'helpers'
import { loggedAsSupervisor } from 'helpers/permissions'
import { debounce } from 'lodash'
import ElectricalStationServices from 'objects/ElectricalStation/ElectricalStationServices'
import { KIND_TO_LAYER, findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { MidiObject } from 'objects/types'
import { ExtraKind, ITEM_LAYER, ObjectLayer } from 'objects/types/const'
import { InstructionType, LayersByInstructionType } from 'objects/types/instructions'
import { MIDI_URI, OBJECTS_SEARCH_URI } from 'objects/uri'
import { useParams } from 'react-router-dom'
import { setError, setMessage } from 'reducers/feedback'
import { CreationPanelState } from 'reducers/panels/creationPanel'
import { PanelName } from 'reducers/panels/panel'
import { RouteParams } from 'types'
import FeedbackSnackBar from '../Common/FeedbackSnackBar/FeedbackSnackBar'
import CreateButton from './CreateButton'
import FeatureClickPopup from './FeatureClick/FeatureClickPopup'
import Legend from './Legend/Legend'
import './Map.scss'
import ObjectLayers from './ObjectLayers/ObjectLayers'
import Toolbar from './Toolbar/Toolbar'
import { INITIAL_LAYERS } from './const'
import onFeatureClick from './onFeatureClick'
import mapStyle from './style_empty.json'
import {
  DEFAULT_VIEWPORT,
  FeatureClickEvent,
  INVALID_GEOM_TYPES,
  MAX_ZOOM,
  addImagesToInstance,
  centerMap, refreshTiles,
  transformRequest,
} from './utils'

export default function Map(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const mapRef = useRef<MapRef | null>(null)
  const {
    isLoading, centeredFeature, layers, layersToUpdate, loadingMessage, selectedProjection, captureClick,
  } = useSelector((state: RootState): MapState => state.map)
  const { active: showGeoEditor, mode } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)
  const { item, objectToMerge } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const { newObjectKind } = useSelector((state: RootState): CreationPanelState => state.creationPanel)
  const geoEditorRef = useRef<Editor>(null)
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const [hoveredEvent, setHoveredEvent] = useState<MapEvent | undefined>(undefined)
  const [clickEvent, setClickEvent] = useState<FeatureClickEvent | undefined>(undefined)
  const [disableHover, setDisableHover] = useState(false)
  const [disableScroll, setDisableScroll] = useState(false)
  const params = useParams<RouteParams>()

  useEffect(() => {
    if (instruction.type !== InstructionType.S6) {
      dispatch(ElectricalStationServices.getAll())
    }

    if (params.type) {
      const layer = ITEM_LAYER[params.type] as unknown as ObjectLayer
      dispatch(setLayers([...new Set([...INITIAL_LAYERS, layer])]))
      return
    }

    if (!item) {
      dispatch(setLayers(INITIAL_LAYERS))
      return
    }

    const objectLayerKey = instruction.type as keyof typeof LayersByInstructionType
    dispatch(setLayers(layers.filter(layer => (LayersByInstructionType[objectLayerKey] as
      ObjectLayer[]).includes(layer))))
    dispatch(setViewportBbox((getBbox(mapRef))))
  }, [])

  useEffect(() => {
    if (mapRef.current) {
      addImagesToInstance(mapRef.current)
      if (instruction.boundingBox) {
        centerMap(feature(instruction.boundingBox), viewport, setViewport, mapRef.current, 0)
      }
    }
  }, [instruction.boundingBox])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [layersToUpdate])

  useEffect(() => {
    if (centeredFeature && centeredFeature.geometry
      && !INVALID_GEOM_TYPES.includes(centeredFeature.geometry.type) && mapRef.current) {
      centerMap(centeredFeature, viewport, setViewport, mapRef.current)
    }
  }, [centeredFeature])

  useEffect(() => {
    setClickEvent(undefined)
  }, [item])

  useEffect(() => {
    refreshTiles(mapRef)
  }, [selectedProjection])

  const onHover = (e: MapEvent) => {
    // Hover used for snapping in GeoEditor
    if (showGeoEditor) snapOnHover(e, geoEditorRef)
    // Store event when not in GeoEditor
    else if (e.features && e.features.length === 1 && !disableHover) {
      setHoveredEvent(e)
    } else {
      setHoveredEvent(undefined)
    }
  }

  const onClick = (e: MapEvent) => {
    e.preventDefault()
    if (e.features?.length === 1) {
      // workaround to prevent click behind popup
      if ((e.srcEvent.target as HTMLElement)?.className !== 'overlays') {
        return
      }
      onFeatureClick(e.features[0])
    } else {
      const event = e.features?.length ? e as FeatureClickEvent : undefined
      setClickEvent(event)
    }
  }

  useEffect(() => () => {
    dispatch(updateItem())
    dispatch(setError())
    dispatch(setMessage(''))
    dispatch(setViewportBbox(undefined))
  }, [])

  const enableDragAndRotate = !showGeoEditor || (showGeoEditor && mode === Modes.grab)

  const isAddingExtremity = () => Object.values(ExtraKind).includes(newObjectKind as ExtraKind) && !captureClick

  const onObjectSearch = (object: MidiObject | undefined) => {
    if (!object) return
    const type = findObjectKind(object)
    dispatch(addLayer(KIND_TO_LAYER[type]))
    PanelNavigator.push(PanelName.details)
    dispatch(GetDetailsServiceOfKind[type]({ id: object.id }))
    dispatch(GetGeometryServiceOfKind[type](object.id))
  }

  const refreshViewport = () => {
    if (item) {
      const kind = findObjectKind(item)
      if (instructionByObjectKind(kind) === InstructionType.S6) return
      dispatch(setViewportBbox((getBbox(mapRef))))
    }
  }

  const updateViewport = debounce(refreshViewport, 3500)

  return (
    <>
      <div className={`map-wrapper position-relative ${isAddingExtremity() || objectToMerge.id ? 'blur' : ''}`}>
        <MapGL
          {...viewport}
          ref={mapRef}
          transformRequest={transformRequest}
          maxZoom={MAX_ZOOM}
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => {
            updateViewport()
            setViewport(newViewport)
          }}
          onHover={onHover}
          onMouseOut={() => setHoveredEvent(undefined)}
          onClick={onClick}
          clickRadius={10}
          interactiveLayerIds={showGeoEditor
            ? [...layers.map(l => `${l}-layer`), 'snapping-points', 'snapping-lines', '']
            : layers.flatMap(l => (l === ObjectLayer.ElectricalProtectionGroup
              ? [`${l}-layer`, `${l}-point-layer`] : `${l}-layer`))}
          preventStyleDiffing
          dragPan={enableDragAndRotate}
          dragRotate={enableDragAndRotate}
          scrollZoom={!disableScroll}
        >
          <ObjectLayers hoveredEvent={hoveredEvent} />
          {!clickEvent && (
          <HoverPopup event={hoveredEvent} />
          )}
          {clickEvent && (
          <FeatureClickPopup event={clickEvent} setEvent={setClickEvent} />
          )}

          <GeoEditor geoEditorRef={geoEditorRef} mapRef={mapRef} />
          <Toolbar disableHover={setDisableHover} disableScroll={setDisableScroll} />
        </MapGL>

        {!loggedAsSupervisor() && (<CreateButton />)}
        {!showGeoEditor && (
        <div className="object-search" style={{ left: loggedAsSupervisor() ? '20px' : '200px' }}>
          <SearchBar
            blurOnSelect
            autoFocus={false}
            onSelect={obj => onObjectSearch(obj as MidiObject)}
            searchURI={`/${MIDI_URI}/${OBJECTS_SEARCH_URI}`}
            placeholder={t('Map.Search.title')}
            labelFormatter={option => `${option.mainRepresentation}`}
            params={{
              instruction_types: [...new Set([instruction.type as InstructionType, InstructionType.S6])],
              projection: selectedProjection?.slug || '',
              bbox: JSON.stringify(getBbox(mapRef)),
              instruction: instruction.id || '',
            }}
            paramsFormat={ArrayFormat.comma}
          />
        </div>
        )}
        {isLoading && <MapLoader message={loadingMessage} />}

        {instruction.type !== InstructionType.S6 && (
          <Legend />
        )}

      </div>
      <FeedbackSnackBar />
    </>
  )
}
