/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Region } from 'objects/types'
import { MIDI_URI } from 'objects/uri'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const getAll = createAsyncThunk<Region[], undefined, ThunkApiConfig>(
  'region/getDetails',
  async (_, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${URI.regions}/`)
      return response.results
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getDetails = createAsyncThunk<Region, undefined, ThunkApiConfig>(
  'region/getDetails',
  async (_, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${URI.regions}/`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const RegionServices = {
  getAll,
  getDetails,
}

export default RegionServices
