import { ReactElement, useEffect, useState } from 'react'
import { MapEvent } from 'react-map-gl'
import { useSelector } from 'react-redux'

import SimpleLineLayer from 'objects/common/SimpleLineLayer'
import SimpleNameLayer from 'objects/common/SimpleNameLayer'
import FeedersLayer from 'objects/Feeders/FeedersLayer'
import IsolatorsLayer from 'objects/Isolators/IsolatorsLayer'
import { findObjectKind, KIND_TO_LAYER } from 'objects/kind'
import SignalsLayer from 'objects/Signals/SignalsLayer'
import TrackSectionsLayer from 'objects/TrackSections/TrackSectionsLayer'
import { MidiObject, ShortMidiObject } from 'objects/types'
import {
  ExtraLayer, ExtraLayerSource, ObjectLayer,
} from 'objects/types/const'
import { MapState } from 'reducers/map'
import { RootState } from 'Store'
import DirectionsLayer from 'objects/TrackSections/DirectionsLayer'
import StationsLayer from 'objects/Stations/StationsLayer'
import TrackNodesLayer from 'objects/TrackNodes/TrackNodesLayer'
import TunnelsLayer from 'objects/Tunnels/TunnelsLayer'
import ElectricalProtectionGroupPoint from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupPoint'
import HoverLayer from '../Hover/HoverLayer'

type Props = {
  hoveredEvent: MapEvent | undefined;
};

type NameLayerParam = {
  layer: ObjectLayer;
  textOffset: [number, number];
}
// This array is sorted, layers order matters
const LINE_LAYERS = [
  ObjectLayer.TrackProtection, ObjectLayer.TrackProtectionGroup, ObjectLayer.ElectricalElement,
  ObjectLayer.ElectricalProtectionGroup, ObjectLayer.Sector, ObjectLayer.SubSector,

]

const NAME_LAYERS: NameLayerParam[] = [
  {
    layer: ObjectLayer.TrackProtection,
    textOffset: [0, 1],
  },
  {
    layer: ObjectLayer.ElectricalElement,
    textOffset: [0, -1],
  },
]

export default function ObjectLayers({ hoveredEvent }: Props): ReactElement {
  const { layers, hoveredPanelObject } = useSelector(
    (state: RootState): MapState => state.map,
  )
  const { item } = useSelector((state: RootState) => state.detailsPanel)

  const [hoveredObject, setHoveredObject] = useState<
    MidiObject | ShortMidiObject | undefined
  >(undefined)
  const [hoveredLayer, setHoveredLayer] = useState<ObjectLayer | undefined>(
    undefined,
  )

  useEffect(() => {
    if (hoveredEvent && hoveredEvent.features !== undefined) {
      const newHoveredObject: MidiObject = hoveredEvent.features[0].properties
      setHoveredObject(newHoveredObject)
      setHoveredLayer(hoveredEvent.features[0].sourceLayer)
    } else if (hoveredPanelObject) {
      setHoveredObject(hoveredPanelObject)
      const kind = findObjectKind(hoveredPanelObject)
      setHoveredLayer(KIND_TO_LAYER[kind])
    } else {
      setHoveredObject(undefined)
      setHoveredLayer(undefined)
    }
  }, [hoveredEvent, hoveredPanelObject])

  const getHoveredSourceLayer = () => {
    if (hoveredLayer) return hoveredLayer
    if (item) return KIND_TO_LAYER[findObjectKind(item)]
    return undefined
  }
  return (
    <>

      <TunnelsLayer
        item={item}
        visible={layers.includes(ObjectLayer.Tunnel)}
      />

      <DirectionsLayer visible={layers.includes(ExtraLayerSource[ExtraLayer.Direction])} />

      <TrackSectionsLayer
        visible={layers.includes(ObjectLayer.TrackSection)}
      />

      <SignalsLayer
        visible={layers.includes(ObjectLayer.Signal)}
      />

      <IsolatorsLayer
        visible={layers.includes(ObjectLayer.Isolator)}
      />

      <HoverLayer
        item={hoveredObject || item}
        sourceLayer={getHoveredSourceLayer()}
      />

      <FeedersLayer
        item={hoveredObject || item}
        visible={layers.includes(ObjectLayer.Feeder)}
      />

      <StationsLayer />

      <TrackNodesLayer
        visible={layers.includes(ObjectLayer.TrackNode)}
      />

      <ElectricalProtectionGroupPoint
        visible={layers.includes(ObjectLayer.ElectricalProtectionGroup)}
        item={item}
      />

      {LINE_LAYERS.map(layer => (
        <SimpleLineLayer
          key={layer}
          visible={layers.includes(layer)}
          layer={layer}
          item={item}
        />
      ))}

      {NAME_LAYERS.map(({ layer, textOffset }) => (
        <SimpleNameLayer
          key={layer}
          visible={layers.includes(layer)}
          layer={layer}
          textOffset={textOffset}
        />
      ))}
    </>
  )
}
