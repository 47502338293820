/* eslint-disable @typescript-eslint/no-explicit-any */
import { Geometry } from '@nebula.gl/edit-modes'
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Feature } from '@turf/helpers'
import { store } from 'Store'
import {
  AsyncTask,
  DetailsPayload, ObjectHistory, RevertPayload, Tunnel,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import { addInstructionParameter, isUpdatingObjectInstruction } from 'objects/utils'
import { ThunkApiConfig } from 'types'

const getDetails = createAsyncThunk<Tunnel, DetailsPayload, ThunkApiConfig>(
  'tunnel/getDetails',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.Tunnel}/${params.id}`,
        { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Tunnel, Partial<Tunnel>, ThunkApiConfig>(
  'tunnel/create',
  async (newTunnel, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Tunnel}/`, newTunnel,
        { params: addInstructionParameter() })
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, Tunnel, ThunkApiConfig>(
  'tunnel/updateById',
  async (updatedTunnel: Tunnel, thunkApi) => {
    try {
      const updatingInstruction = isUpdatingObjectInstruction(updatedTunnel)
      const response = await patch(`/${MIDI_URI}/${ObjectURI.Tunnel}/${updatedTunnel.id}`, updatedTunnel,
        {
          params: addInstructionParameter(updatingInstruction, updatedTunnel.instructions?.
            [updatedTunnel.instructions.length - 1]?.id),
        })
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'tunnel/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.Tunnel}/${id}/`, { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, Tunnel>, string, ThunkApiConfig>(
  'tunnel/getGeometry',
  async (id, thunkApi) => {
    try {
      const projectionId = store.getState().map.selectedProjection.id
      const response: Feature<Geometry, Tunnel> = await get(
        `/chartis/v2/layer/${ObjectLayer.Tunnel}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'tunnel/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.Tunnel}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'tunnel/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Tunnel}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<Tunnel, string, ThunkApiConfig>(
  'tunnel/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.Tunnel}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const TunnelServices = {
  getDetails,
  create,
  update,
  getGeometry,
  delete: deleteObject,
  getHistory,
  revert,
  resetGaiaId,
}

export default TunnelServices
