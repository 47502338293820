import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { TaskStatus, TaskType } from 'objects/types/instructions'
import InstructionServices from 'services/InstructionServices'
import ObjectServices from 'services/ObjectServices'
import { ResponseError } from 'types'
import { createFulfilledMatcher, projectionCreationError } from './matchers/createMatchers'
import { deleteFulfilledMatcher } from './matchers/deleteMatchers'
import { getGeomErrorMatcher } from './matchers/getGeomMatchers'
import allErrorsMatcher from './matchers/matchers'
import { updateFulfilledMatcher } from './matchers/update'
import { validationFulfilledMatcher } from './matchers/validationMatchers'

export enum FeedbackType {
  validation = 'validation',
  merge = 'merge',
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string | undefined>) => {
      state.message = action.payload || ''
    },
  },
  extraReducers: builder => {
    // Specific error handling for projection creation
    builder.addCase(projectionCreationError, (state, action) => {
      if (action.payload) {
        state.feedback = {
          ...action.payload,
          additionalNestedFields: ['name'],
        }
      }
    })

    builder.addCase(InstructionServices.validate.fulfilled, state => {
      state.feedback = {
        code: 200,
        data: {
          type: FeedbackType.validation,
        },
      }
    })
    builder.addCase(InstructionServices.removeItem.fulfilled, state => {
      state.feedback = { code: 200, data: {} }
    })

    builder.addCase(ObjectServices.mergeHistories.fulfilled, state => {
      state.feedback = {
        code: 200,
        data: {
          type: FeedbackType.merge,
        },
      }
    })
    builder.addCase(InstructionServices.update.fulfilled, (state, action) => {
      if (!action.meta.arg.async) {
        state.feedback = { code: 200, data: {} }
      }
    })
    builder.addCase(InstructionServices.getExportStatus.fulfilled, (state, action) => {
      if (action.payload.taskStatus === TaskStatus.FAILURE) {
        state.feedback = { code: 400, data: { customError: 'Échec de l\'export' } }
      }
    })
    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (state, action) => {
      if (action.payload.taskStatus === TaskStatus.SUCCESS) {
        if (action.payload.taskName === TaskType.revert) {
          state.feedback = { code: 200, data: {} }
          return
        }

        if (action.payload.taskName === TaskType.deleteTrackSection) {
          state.feedback = { code: 204, data: {} }
          return
        }

        if (action.payload.taskName === TaskType.updateTrackSection) {
          state.feedback = { code: 200, data: {} }
          return
        }
        if (action.payload.taskName === TaskType.updateInstructionDate) {
          state.feedback = { code: 200, data: {} }
          return
        }
      }

      if (action.payload.taskStatus === TaskStatus.FAILURE) {
        state.feedback = { code: 400, data: action.payload }
      }
    })
    builder.addCase(InstructionServices.getExportStatus.rejected, state => {
      state.feedback = { code: 400, data: { customError: 'Échec de l\'export' } }
    })

    // Error Handling
    builder.addMatcher(allErrorsMatcher, (state, action) => {
      state.feedback = action.payload
    })

    // Delete Handling
    builder.addMatcher(deleteFulfilledMatcher, state => {
      state.feedback = { code: 204, data: {} }
    })

    // Create Handling
    builder.addMatcher(createFulfilledMatcher, state => {
      state.feedback = { code: 201, data: {} }
    })

    // Update Handling
    builder.addMatcher(isAnyOf(updateFulfilledMatcher), state => {
      state.feedback = { code: 200, data: {} }
    })

    builder.addMatcher(validationFulfilledMatcher, state => {
      state.feedback = {
        code: 200,
        data: {
          type: FeedbackType.validation,
        },
      }
    })

    builder.addMatcher(getGeomErrorMatcher, (state, action) => {
      if (action.payload && 'code' in action.payload && action.payload.code === 404) {
        state.feedback = { code: 400, data: { chartis: 'Géométrie de l\'objet non trouvée' } }
      }
    })
  },
})

export const { setError, setMessage } = feedbackSlice.actions

export default feedbackSlice.reducer
