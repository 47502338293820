import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  HTMLInputTypeAttribute, ReactElement,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'

import { RootState } from 'Store'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import { InstructionState } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import './CustomTextField.scss'

export enum InputStyle {
  light = 'light',
  filled = 'grey',
  outlined = 'outlined',
}
export type Input = {
  label: string;
  value: unknown;
  type?: HTMLInputTypeAttribute;
  updateFunction: (v: string) => void;
  placeholder: string;
  needConfirmation?: boolean;
  confirmTitle?: string;
  confirmContent?: string;
}

type Props = {
  input: Input;
  autoFocus?: boolean;
  style?: InputStyle;
  onBlur?: () => void;
  dense?: boolean;
}

const defaultProps = {
  autoFocus: false,
  style: InputStyle.outlined,
  onBlur: () => {},
  dense: false,
}

export default function CustomTextField({
  input, autoFocus, style, onBlur, dense,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [open, setConfirmation] = useState(false)
  const { instruction } = useSelector(((state: RootState) => state.instruction as InstructionState))

  const toggle = () => {
    setConfirmation(prev => !prev)
  }

  const getClassname = () => {
    switch (style) {
      case InputStyle.filled:
        return 'filled-input elevation-2'
      case InputStyle.light:
        return 'light-input'
      case InputStyle.outlined:
      default:
        return 'outline-input'
    }
  }

  const updateInput = async (value: unknown) => {
    dispatch(input.updateFunction(value as string))
  }

  // Todo generic
  const confirmAction = () => {
    dispatch(InstructionServices.update({ ...instruction, async: true }))
    setConfirmation(false)
  }

  const blur = () => {
    if (!input.needConfirmation && onBlur) {
      onBlur()
      return
    }

    toggle()
  }

  return (
    <>
      <FormControl key={input.label} variant="standard" className={`text-input w-100 ${dense ? '' : 'my-3'}`}>
        <InputLabel shrink>{t(input.label)}</InputLabel>
        <Input
          type={input.type || 'text'}
          autoFocus={autoFocus}
          placeholder={t(input.placeholder)}
          className={getClassname()}
          value={input.value}
          onChange={e => updateInput(e.target.value)}
          disableUnderline
          onBlur={blur}
        />
      </FormControl>

      {input.needConfirmation && input.confirmContent && input.confirmTitle && (
      <ConfirmationDialog
        title={t(input.confirmTitle)}
        content={t(input.confirmContent)}
        toggle={toggle}
        open={open}
        actionToConfirm={confirmAction}
      />
      )}

    </>
  )
}

CustomTextField.defaultProps = defaultProps
