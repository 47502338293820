import { ReactElement, ReactNode } from 'react'

import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import './ObjectItem.scss'

type Props = {
  onClick: () => void;
  title: string;
  subtitle?: string | undefined;
  contextMenu: ReactNode;
  optionalMenu?: ReactNode;
  loading?: boolean;
  activityDates?: string | undefined;
  headerComponent?: ReactNode;
}

const defaultProps = {
  subtitle: undefined,
  optionalMenu: undefined,
  loading: false,
  activityDates: undefined,
  headerComponent: <></>,
}

export default function ObjectTile({
  onClick, title, subtitle, contextMenu, optionalMenu, loading, activityDates, headerComponent,
}: Props): ReactElement {
  return (
    <ListItem
      secondaryAction={(
        <div>
          {optionalMenu}
          {contextMenu}
        </div>
      )}
      className={`list-item-wrapper elevation-2 my-2 pl-0 ${optionalMenu && contextMenu ? ' multiple' : ''}`}
      disablePadding
    >
      {headerComponent}
      <ListItemButton
        role={undefined}
        disabled={loading}
        onClick={onClick}
      >

        <ListItemText>
          <div className="list-text" title={title}>{title}</div>
          {subtitle && (<div className="list-subtitle">{subtitle}</div>)}
          {activityDates && (<div className="list-subtitle">{activityDates}</div>)}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  )
}

ObjectTile.defaultProps = defaultProps
