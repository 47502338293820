import {
  ChangeEvent, ReactElement, ReactNode,
} from 'react'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'

import info from 'assets/icons/warning.svg'

import './RawTextField.scss'
import { InputBaseProps } from '@mui/material'

type Props = {
  label: string | undefined;
  value: string | number | unknown;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string | undefined;
  endAdornment?: ReactNode;
  disabled?: boolean;
}

const defaultProps = {
  disabled: false,
  error: false,
  helperText: undefined,
  onChange: () => {},
  endAdornment: null,
}

export default function RawTextField({
  label, value, onChange, error, helperText, endAdornment, disabled, ...props
}: Props & InputBaseProps): ReactElement {
  const { t } = useTranslation()

  return (
    <FormControl variant="standard" fullWidth className="text-field">
      {label && (
        <InputLabel shrink error={error && !disabled} disabled={disabled}>
          {t(label)}
        </InputLabel>
      )}
      <Input
        {...props}
        value={value}
        onChange={onChange}
        fullWidth
        error={error && !disabled}
        className={`input ${props.multiline ? 'area' : ''}`}
        disableUnderline
        endAdornment={endAdornment}
        disabled={disabled}
      />
      {helperText && !disabled && (
        <FormHelperText error={error} className="helper d-flex">
          <div>
            <img src={info} alt="info icon" className="helper-icon mr-2" />
          </div>
          <div className="text-wrapper">
            {t(helperText)}
          </div>
        </FormHelperText>
      )}
    </FormControl>
  )
}

RawTextField.defaultProps = defaultProps
