import { persistCombineReducers } from 'redux-persist'
import { createFilter } from 'redux-persist-transform-filter'
import storage from 'redux-persist/lib/storage' // defaults to localStorage

import { auth } from '@osrdata/app_core'
import { reducer as geoEditor } from '@osrdata/geoeditor'
import admin from './admin'
import app from './app'
import areas from './areas'
import autoCompletes from './autoCompletes'
import feedback from './feedback'
import TIVEditor from './geoEditor'
import instruction from './instruction'
import map from './map'
import operator from './operator'
import creationPanel from './panels/creationPanel'
import detailsPanel from './panels/detailsPanel'
import listPanel from './panels/listPanel'
import modificationPanel from './panels/modificationPanel'
import panel from './panels/panel'
import projection from './projection'
import supervisor from './supervisor'
import regions from './regions'

const user = auth.reducer

const userWhiteList = [
  'account',
]

const saveUserFilter = createFilter(
  'user',
  userWhiteList,
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
}

const rootReducer = {
  user,
  app,
  areas,
  map,
  feedback,
  TIVEditor,
  panel,
  detailsPanel,
  listPanel,
  creationPanel,
  modificationPanel,
  autoCompletes,
  admin,
  operator,
  supervisor,
  projection,
  instruction,
  geoEditor,
  regions,
}

const persistedRootReducer = persistCombineReducers(persistConfig, rootReducer)

export default persistedRootReducer
