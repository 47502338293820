/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { ValidationParams } from 'components/Panels/DetailsPanel/utils'
import { Feature, Geometry } from 'geojson'
import {
  AsyncTask, DetailsPayload, ObjectHistory, RevertPayload,
} from 'objects/types'
import { UpdateObject } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { ElectricalElement, Protection } from 'objects/types/protections'
import ObjectURI, { MIDI_URI, RESET_GAIA_URI } from 'objects/uri'
import {
  addInstructionParameter, filterFields, filterNonEmptyFields, getUpdatedFields, isUpdatingObjectInstruction,
} from 'objects/utils'
import { ObjOfStrOrNum, ThunkApiConfig, nestedObject } from 'types'
import ELECTRICAL_ELEMENT_ATTRIBUTES from '../ElectricalElementAttributes'
import { addAssociatedSubArea, deleteAssociatedSubArea } from './associatedSubAreas'
import {
  addExtremity,
  deleteElements,
  deleteExtremity, switchExtremityDirection,
} from './extremities'
import { addIncompatibleObject, deleteIncompatibleObject } from './incompatibleObjects'
import { addResponsibleSubArea, deleteResponsibleSubArea } from './responsibleSubAreas'

const getDetails = createAsyncThunk<ElectricalElement, DetailsPayload, ThunkApiConfig>(
  'electricalElement/getDetails',
  async (params, thunkApi) => {
    try {
      const response: ElectricalElement = await get(`/${MIDI_URI}/${ObjectURI.ElectricalElements}/${params.id}`,
        { ...addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getGeometry = createAsyncThunk<Feature<Geometry, ElectricalElement>, string, ThunkApiConfig>(
  'electricalElement/getGeometry',
  async (id, thunkApi) => {
    try {
      const projectionId = store.getState().map.selectedProjection.id
      const response: Feature<Geometry, ElectricalElement> = await get(
        `/chartis/v2/layer/${ObjectLayer.ElectricalElement}/geojson_feature/sch/`,
        { id, projectionId },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<ElectricalElement, ObjOfStrOrNum | nestedObject, ThunkApiConfig>(
  'electricalElement/create',
  async (newElectricalElement, thunkApi) => {
    try {
      const newEE = filterNonEmptyFields(newElectricalElement as unknown as Protection, ELECTRICAL_ELEMENT_ATTRIBUTES())
      const response: ElectricalElement = await post(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/`,
        newEE, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<UpdateObject, ElectricalElement, ThunkApiConfig>(
  'electricalElement/updateById',
  async (updatedElectricalElement, thunkApi) => {
    try {
      const updatedEE = filterNonEmptyFields(updatedElectricalElement as unknown as Protection,
        ELECTRICAL_ELEMENT_ATTRIBUTES())
      const updatingInstruction = isUpdatingObjectInstruction(updatedElectricalElement)
      const newEE = updatingInstruction ? { instructions: updatedElectricalElement.instructions }
        : filterFields(updatedEE, getUpdatedFields(ELECTRICAL_ELEMENT_ATTRIBUTES()))

      const response: ElectricalElement = await patch(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/${updatedElectricalElement.id}`,
        newEE,
        {
          params: addInstructionParameter(updatingInstruction, newEE.instructions?.
            [newEE.instructions.length - 1]?.id),
        },
      )
      return { item: response, updatingInstruction }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'electricalElement/deleteById',
  async (id: string, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${ObjectURI.ElectricalElements}/${id}`,
        { params: addInstructionParameter() })
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const validate = createAsyncThunk<ElectricalElement, ValidationParams, ThunkApiConfig>(
  'electricalElement/validate',
  async ({ id, operation }, thunkApi) => {
    try {
      const response: ElectricalElement = await post(
        `/${MIDI_URI}/${ObjectURI.ElectricalElements}/${id}/${operation}/`,
        {}, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getHistory = createAsyncThunk<ObjectHistory[], DetailsPayload, ThunkApiConfig>(
  'electricalElement/getHistory',
  async (params, thunkApi) => {
    try {
      const response = await get(`/${MIDI_URI}/${ObjectURI.ElectricalElements}/${params.id}/history`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const revert = createAsyncThunk<AsyncTask, RevertPayload, ThunkApiConfig>(
  'electricalElement/revert',
  async ({ id, target, async }, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.ElectricalElements}/${id}/revert/${target}`, {},
        { params: { async } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const resetGaiaId = createAsyncThunk<ElectricalElement, string, ThunkApiConfig>(
  'electricalElement/resetGaiaId',
  async (id, thunkApi) => {
    try {
      const response = await post(`/${MIDI_URI}/${ObjectURI.ElectricalElements}/${id}/${RESET_GAIA_URI}`, {},
        { params: addInstructionParameter() })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const ElectricalElementServices = {
  getDetails,
  getGeometry,
  create,
  update,
  validate,
  delete: deleteObject,
  addExtremity,
  switchExtremityDirection,
  deleteExtremity,
  deleteElements,
  addResponsibleSubArea,
  deleteResponsibleSubArea,
  addAssociatedSubArea,
  deleteAssociatedSubArea,
  addIncompatibleObject,
  deleteIncompatibleObject,
  getHistory,
  revert,
  resetGaiaId,
}

export default ElectricalElementServices
