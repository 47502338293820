/* eslint-disable @typescript-eslint/no-explicit-any */
import { get } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { Line, ShortTrack, Track } from 'objects/types'
import { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'
import { ThunkApiConfig } from 'types'
import { LineSearch, TrackSearch } from './types'

export const fetchTrackDetails = async (id: string): Promise<Track> => {
  const response: Track = await get(`/${MIDI_URI}/tracks/${id}`)
  return response
}

const getAll = createAsyncThunk<LineSearch, string, ThunkApiConfig>(
  'lines/getAll',
  async (search: string, thunkApi) => {
    try {
      const res = await get(`/${MIDI_URI}/lines`, { search, ...addInstructionParameter() })
      return { results: res.results as Line[], newLine: search }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getTracks = createAsyncThunk<TrackSearch, string, ThunkApiConfig>(
  'lines/getTracks',
  async (search: string, thunkApi) => {
    try {
      const { newObject } = store.getState().creationPanel
      const { item } = store.getState().modificationPanel
      const line = newObject?.track?.line || item?.track?.line
      let results
      if (!line.id) {
        results = []
      } else {
        const response = await get(`/${MIDI_URI}/lines/${line.id}/tracks`, { search, ...addInstructionParameter() })
        results = response.results
      }

      return { results: results as ShortTrack[], line, newTrack: search }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const LineServices = {
  getAll, getTracks,
}

export default LineServices
