import { ReactElement } from 'react'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { TrackNode } from 'objects/types'

import { Divider } from '@mui/material'
import Instructions from 'objects/common/Instructions/Instructions'
import { AttributesProps } from 'objects/types/const'
import TRACK_NODE_ATTRIBUTES from './TrackNodeAttributes'

export default function TrackNodeDetails({ object }: AttributesProps): ReactElement | null {
  const trackNode = object as TrackNode
  return (
    <div id="signal-details" className="object-attributes">
      <Attributes item={trackNode} attributes={TRACK_NODE_ATTRIBUTES(trackNode)} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Instructions item={trackNode} />
      </div>
    </div>
  )
}
