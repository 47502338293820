import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'

import { ExtraLayer, ExtraLayerSource } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'
import stationLayerProps from './StationsLayout'

const sourceLayer = ExtraLayerSource[ExtraLayer.Station]

export default function StationsLayer(): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer, false, true)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        {...stationLayerProps.label}
        id={`${sourceLayer}-label`}
        layout={{
          ...stationLayerProps.label.layout,
        }}
        source-layer={sourceLayer}
      />
    </Source>
  )
}
