import { store } from 'Store'
import { MapLayerObject } from 'components/Map/utils'
import { debounce } from 'lodash'
import { MidiObject } from 'objects/types'
import { ITEM_TYPES, ObjectKind } from 'objects/types/const'
import { InstructionDetails, InstructionType, LayersByInstructionType } from 'objects/types/instructions'
import { PanelName, PanelState } from 'reducers/panels/panel'
import InstructionServices from 'services/InstructionServices'
import { EMPTY_INSTRUCTION } from './const'

const getCSSValue = (name: string): string => getComputedStyle(document.documentElement).getPropertyValue(name)

export const getActivePanel = (): PanelName => {
  const { panelHistory } = store.getState().panel as PanelState
  return panelHistory[panelHistory.length - 1]
}

export const isStringEmpty = (s: string | undefined): boolean => (
  s === undefined || s.trim().length === 0
)

export const filterDuplicateFeatures = (key: keyof MidiObject, type: InstructionType,
  features: MapLayerObject[]): MapLayerObject[] => {
  const filteredFeatures = features.filter(
    (f: MapLayerObject) => LayersByInstructionType[type].includes(f.sourceLayer),
  )
  const uniqueFeatures = [...new Map(filteredFeatures.map(item => [item.properties[key], item])).values()]
  return uniqueFeatures
}

export const debouncedInstruction = debounce(
  (page: number, region = 'all', search: string) => store.dispatch(InstructionServices.getAll({
    page,
    region,
    search,
  })), 500,
)

const instructionByType = (kind: ITEM_TYPES) => {
  if ([ITEM_TYPES.electricalelement, ITEM_TYPES.electricalprotectiongroup,
    ITEM_TYPES.feeder, ITEM_TYPES.sector, ITEM_TYPES.subsector].includes(kind)) {
    return InstructionType.S11
  }
  if ([ITEM_TYPES.trackprotection, ITEM_TYPES.trackprotectiongroup].includes(kind)) {
    return InstructionType.S9
  }
  return InstructionType.S6
}

export const instructionByObjectKind = (kind: ObjectKind) => {
  if ([ObjectKind.ElectricalElement, ObjectKind.ElectricalProtectionGroup,
    ObjectKind.Feeder, ObjectKind.Sector, ObjectKind.SubSector].includes(kind)) {
    return InstructionType.S11
  }
  if ([ObjectKind.TrackProtection, ObjectKind.TrackProtectionGroup].includes(kind)) {
    return InstructionType.S9
  }
  return InstructionType.S6
}

export const buildFakeInstruction = (type: ITEM_TYPES): InstructionDetails => {
  const instructionType = instructionByType(type)
  return ({
    ...EMPTY_INSTRUCTION,
    type: instructionType,
    name: instructionType,
  })
}

export default getCSSValue
