import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

import { isAnyOf } from '@reduxjs/toolkit'
import FeederServices from 'objects/Feeders/FeederServices'
import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'

export const getGeomFulfilledMatcher = isAnyOf(
  ElectricalElementServices.getGeometry.fulfilled,
  ElectricalProtectionGroupServices.getGeometry.fulfilled,
  IsolatorServices.getGeometry.fulfilled,
  SectorServices.getGeometry.fulfilled,
  SignalServices.getGeometry.fulfilled,
  SubSectorServices.getGeometry.fulfilled,
  TrackProtectionGroupServices.getGeometry.fulfilled,
  TrackProtectionServices.getGeometry.fulfilled,
  TrackSectionServices.getGeometry.fulfilled,
  FeederServices.getGeometry.fulfilled,
  TrackNodeServices.getGeometry.fulfilled,
  TunnelServices.getGeometry.fulfilled,
)

export const getGeomPendingMatcher = isAnyOf(
  ElectricalElementServices.getGeometry.pending,
  ElectricalProtectionGroupServices.getGeometry.pending,
  IsolatorServices.getGeometry.pending,
  SectorServices.getGeometry.pending,
  SignalServices.getGeometry.pending,
  SubSectorServices.getGeometry.pending,
  TrackProtectionGroupServices.getGeometry.pending,
  TrackProtectionServices.getGeometry.pending,
  TrackSectionServices.getGeometry.pending,
  FeederServices.getGeometry.pending,
  TunnelServices.getGeometry.pending,
)

export const getGeomErrorMatcher = isAnyOf(
  ElectricalElementServices.getGeometry.rejected,
  ElectricalProtectionGroupServices.getGeometry.rejected,
  IsolatorServices.getGeometry.rejected,
  SectorServices.getGeometry.rejected,
  SignalServices.getGeometry.rejected,
  SubSectorServices.getGeometry.rejected,
  TrackProtectionGroupServices.getGeometry.rejected,
  TrackProtectionServices.getGeometry.rejected,
  TrackSectionServices.getGeometry.rejected,
  FeederServices.getGeometry.rejected,
  TunnelServices.getGeometry.rejected,
)
