import { ReactElement } from 'react'

import { store } from 'Store'
import ContextMenu, {
  ContextMenuItem,
} from 'components/Common/ContextMenu/ContextMenu'
import { EPGComposition } from 'objects/types/protections'
import ElectricalProtectionGroupServices from '../ElectricalProtectionGroupServices'

const COMPOSITION_CONTEXT_MENU_ITEMS = (
  ee: EPGComposition,
): ContextMenuItem[] => [
  {
    key: 'delete',
    label: 'Details.groupCompositionContextMenu.delete',
    onClick: () => {
      store.dispatch(
        ElectricalProtectionGroupServices.deleteElectricalElement(ee.id),
      )
    },
    show: true,
  },
]

type Props = {
  ee: EPGComposition;
};

export default function CompositionContextMenu({ ee }: Props): ReactElement {
  return <ContextMenu menuItems={COMPOSITION_CONTEXT_MENU_ITEMS(ee)} />
}
