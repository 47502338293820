import { ReactElement } from 'react'

import Paper from '@mui/material/Paper'

import ContextMenu from 'components/Common/ContextMenu/ContextMenu'
import { Area } from 'objects/types/protections'
import './AreaTile.scss'
import SubAreas from './SubAreas'
import { AREA_CONTEXT_MENU } from './const'
import { AreaDeletion } from './types'

type Props = {
  area: Area;
  loadingArea: string;
  setDeletion: (arg: AreaDeletion) => void;
}

export default function AreaTile({ area, loadingArea, setDeletion }: Props): ReactElement {
  return (
    <Paper className="area-tile">
      <div className="d-flex justify-content-between align-items-center">
        <div className="title">{area.label}</div>
        <div>
          <ContextMenu menuItems={AREA_CONTEXT_MENU(area, setDeletion)} />
        </div>
      </div>
      <SubAreas
        setDeletion={setDeletion}
        areaId={area.id}
        subAreas={area.subAreas || []}
        loadingArea={loadingArea}
      />
    </Paper>
  )
}
