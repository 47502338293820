import ActionButton from 'components/Common/ActionButton/ActionButton'
import ButtonStatus from 'components/Common/buttonStatus'
import { MidiObject } from 'objects/types'
import { ObjectStatus } from 'objects/types/const'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import MidiRole from 'services/midiRoleTypes'
import { RootState } from 'Store'
import { getValidateButtonText, ValidationOperation } from '../utils'

type Props = {
  onValidate: (o: string) => void;
}

export default function OperatorButtons({ onValidate }: Props): ReactElement | null {
  const { item, buttonStatus, objectToMerge } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const typedItem = item as MidiObject

  const status = objectToMerge.id ? ButtonStatus.Disabled : buttonStatus

  return (
    <ActionButton
      title={getValidateButtonText(MidiRole.operator)}
      onClick={() => onValidate(ValidationOperation.validate)}
      status={typedItem.status && typedItem.status >= ObjectStatus.VALIDATED ? ButtonStatus.Success : status}
    />
  )
}
