import { useTranslation } from '@osrdata/app_core/dist/translation'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { RootState } from 'Store'
import CaptureAndSearch from 'components/Common/CaptureAndSearch/CaptureAndSearch'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { getSubText } from 'components/ObjectItem/utils'
import { canEditObject } from 'components/Panels/DetailsPanel/utils'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { CaptureMapClickParams } from 'objects/attributes'
import { findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { ITEM_TYPES, ObjectLayer } from 'objects/types/const'
import { ShortProtection } from 'objects/types/protections'
import { DeleteChildrenPayload, ProtectionChild } from 'objects/types/protections/const'
import { setMessage } from 'reducers/feedback'
import { hideGeoEditor } from 'reducers/geoEditor'
import { InstructionState } from 'reducers/instruction'
import {
  MapState, addLayer, setCaptureClick, setLayers,
} from 'reducers/map'
import { setLoading } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import { CollapsibleMenu } from 'reducers/types'
import {
  GenericAction,
  ThunkApiConfig,
} from 'types'
import DeleteButton from '../Extremities/DeleteButton'
import { toggleOpenCollapsible } from '../utils'
import IPContextMenu from './LPContextMenu'

type Props = {
  linkedProtections: ShortProtection[];
  deleteAction: GenericAction;
  addIPAction: (sp: ShortProtection) => AsyncThunkAction<unknown, ShortProtection, ThunkApiConfig>;
  type: CollapsibleMenu;
  params?: {
    title: string;
    searchPlaceholder: string;
    buttonLabel: string;
    filteredTypes?: ITEM_TYPES[];
  };
  editable: boolean;
  deleteAll: GenericAction<DeleteChildrenPayload>;
  fieldType: ProtectionChild;
  captureClickParams: CaptureMapClickParams[];
  buttonTitle: string;
} & typeof defaultProps

const defaultProps = {
  params: {
    title: 'Attributes.TrackProtection.incompatibleObjects',
    searchPlaceholder: 'Details.searchPlaceholder.protection',
    buttonLabel: 'Details.button.addIncompatibleObject',
  },
}

export default function LinkedProtections({
  linkedProtections, deleteAction, addIPAction, params, type, editable, deleteAll, fieldType,
  captureClickParams, buttonTitle,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    selectedProjection, viewportBbox,
  } = useSelector((state: RootState): MapState => state.map)
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const { openMenus } = useSelector((state: RootState) => state.detailsPanel)

  const usedIP = linkedProtections || []

  const [isCapturingClick, setIsCapturingClick] = useState(false)
  const { layers } = useSelector((state: RootState): MapState => state.map)
  const [layersMemory, setMemory] = useState(layers)

  useEffect(() => {
    if (isCapturingClick) {
      setIsCapturingClick(false)
      dispatch(setLayers(layersMemory))
    }
  }, [linkedProtections])

  const onClick = () => {
    if (!isCapturingClick) {
      setIsCapturingClick(true)
      dispatch(hideGeoEditor())
      setMemory(layers)
      dispatch(setCaptureClick(captureClickParams))
      dispatch(setLayers([ObjectLayer.TrackSection, ...captureClickParams.map(obj => obj.objectLayer)]))
      dispatch(setMessage('Modification.helperText.electricalProtectionGroupCaptureClick'))
    } else {
      setIsCapturingClick(false)
      dispatch(setLayers(layersMemory))
      dispatch(setCaptureClick(undefined))
    }
  }

  const formatActivityDates = (obj: ShortProtection) => `${(
    obj.activityStartDate && new Date(obj.activityStartDate).toLocaleDateString('fr-FR')) || '/'} 
    -
     ${(obj.activityEndDate && new Date(obj.activityEndDate).toLocaleDateString('fr-FR')) || '/'}`

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(type)}
      onChange={() => toggleOpenCollapsible(type)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t(params.title)} (${
          usedIP && usedIP.length
        })`}
      </AccordionSummary>
      <AccordionDetails>
        {usedIP && usedIP.map(sp => (
          <ObjectTile
            key={sp.id}
            onClick={() => {
              const kind = findObjectKind(sp)
              dispatch(addLayer(ObjectLayer[kind]))
              dispatch(setLoading(true))
              dispatch(GetDetailsServiceOfKind[kind]({ id: sp.id }))
              PanelNavigator.push(PanelName.details)
              dispatch(GetGeometryServiceOfKind[kind](sp.id))
            }}
            title={sp.mainRepresentation || ''}
            subtitle={t(getSubText(sp))}
            activityDates={formatActivityDates(sp)}
            contextMenu={canEditObject() && editable ? <IPContextMenu deleteAction={deleteAction} sp={sp} /> : <></>}
          />
        ))}
        {canEditObject() && editable && (
        <div className="protection-buttons  my-2">
          <CaptureAndSearch
            onClick={onClick}
            filteredTypes={params.filteredTypes || []}
            onOptionSelect={elem => dispatch(addIPAction(elem))}
            title={t(buttonTitle)}
            protections={linkedProtections}
            params={{
              instruction: instruction.id || '',
              projection: selectedProjection?.slug || '',
              bbox: JSON.stringify(viewportBbox) || JSON.stringify({ type: 'Polygon', coordinates: [] }),
            }}
          />

          {!!linkedProtections?.length && (
          <DeleteButton
            deleteAction={deleteAll}
            field={fieldType}
          />
          )}

        </div>
        )}

      </AccordionDetails>
    </Accordion>
  )
}

LinkedProtections.defaultProps = defaultProps
