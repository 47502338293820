import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import './ProjectionsTab.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActiveStep, setActiveSubStep, setProjection, ProjectionState, toggleDisplayCreation,
} from 'reducers/projection'
import { RootState } from 'Store'
import ProjectionsTable from './ProjectionsTable/ProjectionsTable'
import ProjectionCreation from './ProjectionCreation/ProjectionCreation'

export default function ProjectionsTab(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { displayCreation, showLinearization } = useSelector((state: RootState) => state.projection) as ProjectionState

  return (
    <div id="projections-tab" className="d-flex h-100">
      <div style={{ flex: 1 }}>
        <ProjectionsTable />
      </div>
      {!showLinearization && (
      <div className="button-wrapper w-100 d-flex justify-content-center align-items-center">
        <SimpleButton
          style={ButtonStyle.primary}
          title={t('Projections.button.createNewProjection')}
          onClick={() => {
            dispatch(setProjection({}))
            dispatch(setActiveStep(0))
            dispatch(setActiveSubStep(0))
            dispatch(toggleDisplayCreation())
          }}
        />
      </div>
      )}
      {displayCreation && <ProjectionCreation />}
      <FeedbackSnackBar />
    </div>
  )
}
