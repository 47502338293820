import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GeoEditorService from 'components/GeoEditor/GeoEditorService'
import { cleanCursor, Modes, updateCursor } from 'components/GeoEditor/utils'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import {
  EditorModeName, GeoEditorFeature, LineStringFeature, ModeConfig,
} from 'components/GeoEditor/types'
import { LineString, Point, Feature } from 'geojson'
import { TrackSection } from 'objects/types'
import ObjectServices from 'services/ObjectServices'
import { TaskStatus, TaskType } from 'objects/types/instructions'

type additionalProps = {
  properties: {
    renderType: string;
  };
  id?: number;
}

export interface GeoEditorState {
  active: boolean;
  object?: GeoEditorFeature;
  mode: Modes;
  editorLayerModeName: EditorModeName;
  modeConfig: ModeConfig;
  snappingFeatures: LineStringFeature[];
  snappedGeometry?: Feature<Point | LineString, TrackSection>;
  snappedMouseCoords?: [number, number];
}

const initialState: GeoEditorState = {
  active: false,
  object: undefined,
  mode: Modes.grab,
  editorLayerModeName: EditorModeName.Edit,
  modeConfig: {},
  snappingFeatures: [],
}

export const geoEditorSlice = createSlice({
  name: 'TIVEditor',
  initialState,
  reducers: {
    enable: state => {
      state.active = true
      updateCursor(state.mode)
    },
    hide: state => {
      cleanCursor()
      state.active = false
    },
    disable: () => {
      cleanCursor()
      return initialState
    },
    updateObject: (state, action: PayloadAction<GeoEditorFeature>) => {
      state.object = action.payload
    },
    updateMode: (state, action: PayloadAction<Modes>) => {
      state.mode = action.payload
    },
    setEditorLayerMode: (state, action: PayloadAction<EditorModeName>) => {
      state.editorLayerModeName = action.payload
    },
    setModeConfig: (state, action: PayloadAction<ModeConfig>) => {
      state.modeConfig = action.payload
    },
    setSnappingFeatures: (state, action: PayloadAction<LineStringFeature[]>) => {
      state.snappingFeatures = action.payload
    },
    setSnappedGeometry: (state, action: PayloadAction<Feature<Point | LineString, TrackSection> | undefined>) => {
      state.snappedGeometry = action.payload
    },
    setSnappedMouseCoords: (state, action: PayloadAction<[number, number]>) => {
      state.snappedMouseCoords = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(GeoEditorService.getTrackSectionGeometry.fulfilled, (state, action) => {
      state.active = true
      state.object = {
        ...action.payload,
        properties: {
          ...action.payload.properties,
          renderType: 'LineString',
        },
        id: 0,
      } as (GeoEditorFeature & additionalProps)
    })
    builder.addCase(TrackSectionServices.create.fulfilled, () => {
      cleanCursor()
      return initialState
    })
    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (_, action) => {
      if (action.payload.taskStatus !== TaskStatus.SUCCESS) return

      if (action.payload.taskName === TaskType.deleteTrackSection) {
        cleanCursor()
      }
    })
    builder.addCase(GeoEditorService.getTrackSectionsByBbox.fulfilled, (state, action) => {
      state.snappingFeatures = action.payload
    })
  },
})

export const {
  enable: enableGeoEditor,
  disable: disableGeoEditor,
  hide: hideGeoEditor,
  updateObject: updateGeoEditorObject,
  updateMode: updateGeoEditorMode,
  setEditorLayerMode,
  setModeConfig: setGeoEditorModeConfig,
  setSnappedGeometry,
  setSnappedMouseCoords,
} = geoEditorSlice.actions

export default geoEditorSlice.reducer
