import { Fragment, ReactElement } from 'react'
import { useSelector } from 'react-redux'

import { ProjectionState } from 'reducers/projection'
import { RootState } from 'Store'
import ColorLoading, { Colors } from '../../../../instructions/common/ColorLoading'
import SelectMap from './SelectMap'
import './ItemsSelection.scss'

type Props = {
  selectMapTitle: string;
  selectMapSubtitle?: string;
}

const defaultProps = {
  selectMapSubtitle: undefined,
}

export default function ItemsSelection({ selectMapTitle, selectMapSubtitle }: Props): ReactElement {
  const { activeSubStep } = useSelector((state: RootState) => state.projection) as ProjectionState

  const SUB_STEPS = [
    {
      key: 0,
      component: <SelectMap
        title={selectMapTitle}
        subtitle={selectMapSubtitle}
      />,
    },
    {
      key: 1,
      component: <ColorLoading message="Projections.creation.getTracks" color={Colors.purple} />,
    },
  ]

  return (
    <div id="items-selection" className="d-flex w-100 flex-column h-100">
      {SUB_STEPS.map(subStep => (activeSubStep === subStep.key
        ? (
          <Fragment key={subStep.key}>
            {subStep.component}
          </Fragment>
        )
        : null))}
    </div>
  )
}

ItemsSelection.defaultProps = defaultProps
