import { store } from 'Store'
import { ContextMenuItem } from 'components/Common/ContextMenu/ContextMenu'
import ButtonStatus from 'components/Common/buttonStatus'
import { DateTime } from 'luxon'
import { Area } from 'objects/types/protections'
import {
  ModalMode, setModalMode, setSelectedArea, toggleOpenModal,
} from 'reducers/areas'
import AreaServices from 'services/AreaServices'
import { AreaDeletion } from './types'

export const CREATE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Areas.details.creationButton.base',
  [ButtonStatus.Disabled]: 'Areas.details.creationButton.base',
  [ButtonStatus.Loading]: 'Areas.details.creationButton.loading',
  [ButtonStatus.Success]: 'Areas.details.creationButton.success',
}

export const UPDATE_BUTTON_TEXT = {
  [ButtonStatus.Base]: 'Areas.details.updateButton.base',
  [ButtonStatus.Disabled]: 'Areas.details.updateButton.base',
  [ButtonStatus.Loading]: 'Areas.details.updateButton.loading',
  [ButtonStatus.Success]: 'Areas.details.updateButton.success',
}

export const MODE_BUTTON_TEXT = {
  [ModalMode.creation]: CREATE_BUTTON_TEXT,
  [ModalMode.modification]: UPDATE_BUTTON_TEXT,
}

export const MODE_TITLE = {
  [ModalMode.creation]: 'Areas.details.createTitle',
  [ModalMode.modification]: 'Areas.details.updateTitle',
}

export const AREA_CONTEXT_MENU = (area: Area,
  setArea: (arg: AreaDeletion) => void): ContextMenuItem[] => [
  {
    key: 'updateArea',
    label: 'Modifier le poste',
    onClick: () => {
      store.dispatch(setSelectedArea(area))
      store.dispatch(setModalMode(ModalMode.modification))
      store.dispatch(toggleOpenModal())
    },
    show: true,
  },
  {
    key: 'terminateArea',
    label: 'Décommissionner le poste',
    onClick: () => {
      store.dispatch(AreaServices.update({
        id: area.id,
        activityEndDate: DateTime.now().toFormat('yyyy-MM-dd'),
        checksum: area.checksum,
      }))
    },
    show: true,
  },
  {
    key: 'deleteArea',
    label: 'Supprimer le poste',
    onClick: () => {
      setArea({ id: area.id })
    },
    show: true,
  },
]

export const MODE_BUTTON_ACTION = {
  [ModalMode.creation]: (area: Partial<Area>): void => {
    store.dispatch(AreaServices.create(area))
  },
  [ModalMode.modification]: (area: Partial<Area>): void => {
    store.dispatch(AreaServices.update(area))
  },
}
