import { store } from 'Store'
import { DateTime } from 'luxon'
import ElectricalStationServices from 'objects/ElectricalStation/ElectricalStationServices'
import { Attribute, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { MidiObject } from 'objects/types'
import { FGPTTypeMap } from 'objects/types/protections/const'

const ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'upmre',
    path: 'upmre',
    label: 'Attributes.Protection.upmre',
    parser: Parser.identity,
    type: InputTypes.Numeric,
    removeIfNull: true,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'technology',
    path: 'technology',
    label: 'Attributes.TrackProtection.technology',
    parser: Parser.mgpt,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: FGPTTypeMap,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'electricalStation',
    path: 'electricalStation',
    label: 'Attributes.ElectricalElement.electricalStation',
    parser: Parser.electricalStationParser,
    isEditable: true,
    removeIfNull: true,
    required: true,
    type: InputTypes.Select,
    values: store.getState().instruction.electricalStations,
    creation: {
      required: true,
      defaultValue: null,
    },
    dependency: {
      path: 'zoe',
      source: ElectricalStationServices.getZoes,
      key: ['electricalStation', 'id'],
    },
  },
  {
    key: 'zoe',
    path: 'zoe',
    label: 'Attributes.ElectricalElement.zoe',
    parser: Parser.zoeParser,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: store.getState().instruction.zoes,
    removeIfNull: true,
    creation: {
      required: true,
      defaultValue: null,
    },
    shouldBeDisabled: {
      path: ['electricalStation'],
      values: [null, ''],
    },
  },
  {
    key: 'securityTimeForAgreement',
    path: 'securityTimeForAgreement',
    label: 'Attributes.TrackProtection.securityTimeForAgreement',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'securityTimeForRestitution',
    path: 'securityTimeForRestitution',
    label: 'Attributes.TrackProtection.securityTimeForRestitution',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    type: InputTypes.Numeric,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'comment',
    path: 'comment',
    label: 'Attributes.common.comment',
    parser: Parser.identity,
    type: InputTypes.TextArea,
    isEditable: true,
    required: false,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES
