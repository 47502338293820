import { MidiObject } from 'objects/types'
import { Instruction } from 'objects/types/instructions'
import { AppDispatch } from 'Store'
import { nestedObject, ObjOfStrOrNum, StrOrNum } from 'types'

export type DispatchFunction = (dispatch: AppDispatch) => void

export type FieldValue = string | number | nestedObject | null | MidiObject | ObjOfStrOrNum[] | boolean;

export interface UpdateField {
  path: Array<string> | string;
  value: FieldValue;
}

export enum CollapsibleMenu {
  composition = 'composition',
  children = 'children',
  extremities = 'extremities',
  responsibleSubAreas = 'responsibleSubAreas',
  associatedSubAreas = 'associatedAreas',
  incompatibleObjects = 'incompatibleObjects',
  instructions = 'instructions'
}

type Pagination = {
  count: number;
  next: string;
  previous: string;
}
// maybe move
export type GetPaginatedResponse = Pagination & {
  results: MidiObject[];
}

export type PaginatedInstructions = Pagination & {
  results: Instruction[];
}

export type SelectValue = {
  label: string;
  value: StrOrNum;
}

export type RemoveItem = {
  instructionId: string;
  itemId: string;
}

export type MergeHistory = {
  historyIdToKeep: string;
  historyIdToDelete: string;
  instruction: string;
  objectToMerge: string;
}
