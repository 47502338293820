import { Button } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { store } from 'Store'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { ObjectKind } from 'objects/types/const'
import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'
import { resetHistory, setLoading } from 'reducers/panels/detailsPanel'
import { GetPaginatedResponse } from 'reducers/types'
import ObjectServices from 'services/ObjectServices'

type Props = {
  itemId: string;
  kind: ObjectKind;
}

export default function ObjectConflict({ itemId, kind }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClick = () => {
    store.dispatch(ObjectServices.getConflicts({ id: itemId, kind })).then(res => {
      if (res.type.endsWith('fulfilled')) {
        const { results } = res.payload as GetPaginatedResponse
        if (!results.length) {
          dispatch(setLoading(false))
          return
        }

        const detailUrl = results[results.length - 1].detailUrl.split('/').filter(el => el)
        const id = detailUrl[detailUrl.length - 1]

        dispatch(resetHistory())
        dispatch(GetDetailsServiceOfKind[kind]({ id }))
        dispatch(GetGeometryServiceOfKind[kind](id))
      }
    })
  }

  return (
    <Button
      className="merge conflict"
      variant="contained"
      onClick={handleClick}
    >
      {t('Details.button.seeOther')}
    </Button>
  )
}
