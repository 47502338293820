import { ReactElement, useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'

import { RootState, useAppSelector } from 'Store'
import getCSSValue from 'helpers'
import { deletedObjectsColor, selectedLinearObject } from 'objects/styles'
import { MidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'
import { useSelector } from 'react-redux'
import { GeoEditorState } from 'reducers/geoEditor'
import { MapState } from 'reducers/map'

const sourceLayer = ObjectLayer.Tunnel

type Props = {
  visible: boolean;
  item: MidiObject | undefined;
}

export default function TunnelsLayer({ visible, item }: Props): ReactElement {
  const { active: showGeoEditor } = useAppSelector((state): GeoEditorState => state.TIVEditor)
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)
  const { captureClick } = useSelector((state: RootState): MapState => state.map)

  useEffect(() => {
    if (item) setSelectedId(item.id)
    else setSelectedId(undefined)
  }, [item])

  const dimElement = selectedId
    ? ['any', ['!=', ['get', 'id'], selectedId], showGeoEditor]
    : showGeoEditor

  const isSelected = selectedId
    ? ['==', ['get', 'id'], selectedId]
    : false

  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        type="line"
        paint={{
          'line-color': ['case',
            ['!=', ['get', 'deletedAt'], null], getCSSValue(deletedObjectsColor),
            isSelected, getCSSValue(selectedLinearObject),
            'gray',
          ],
          'line-width': 10,
          'line-opacity': ['case',
            !!captureClick, 0.4,
            dimElement, 0.3,
            0.4,
          ],
        }}
        layout={{ visibility: visible ? 'visible' : 'none' }}
        source-layer={sourceLayer}
      />

    </Source>
  )
}
