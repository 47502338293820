import {
  Checkbox,
  ListItemText, MenuItem,
  Select, SelectChangeEvent, Tooltip,
} from '@mui/material'
import { RootState } from 'Store'
import { Region } from 'objects/types'
import { Instruction } from 'objects/types/instructions'
import { Area } from 'objects/types/protections'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import './RegionDropdown.scss'
import { useTranslation } from '@osrdata/app_core/dist/translation'

type Props = {
  setRegions: (regions: Region[]) => void;
  object: Partial<Instruction> | Area;
  preventEmpty?: boolean;
}

const defaultProps = {
  preventEmpty: false,
}

export default function RegionDropdown({ setRegions, object, preventEmpty }: Props): ReactElement {
  const { t } = useTranslation()
  const { regions } = useSelector((state: RootState) => state.regions)

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const newRegions = (event.target.value as string[])
      .map((region: string) => regions.find((item: Region) => item.trigram === region))
    setRegions(newRegions)
  }

  const regionsTrigram = object.regions?.map(region => regions.find(
    (item: Region) => item.id === region.id,
  ).trigram)

  const lastRegionSelected = (region: Region) => (preventEmpty && object.regions?.length === 1
    && object.regions[0].id === region.id)

  return (
    <Select
      className="region-dropdown light-input"
      multiple
      value={regionsTrigram || []}
      onChange={handleChange}
      renderValue={selected => selected.map(item => regions.find(
        (region: Region) => region.trigram === item,
      )?.label).join(', ')}
    >
      {regions.map((region: Region) => (lastRegionSelected(region) ? (
        <Tooltip key={region.trigram} title={t('Instruction.dashboard.parameters.minRegion')} placement="right">
          <span>
            <MenuItem
              key={region.trigram}
              value={region.trigram}
              disabled
            >
              <Checkbox
                checked={object.regions
                  ? object?.regions?.findIndex(item => item.id === region.id) > -1 : false}
              />
              <ListItemText primary={region.label} />
            </MenuItem>
          </span>
        </Tooltip>

      ) : (
        <MenuItem
          className="test"
          key={region.trigram}
          value={region.trigram}
        >
          <Checkbox
            checked={object.regions
              ? object?.regions?.findIndex(item => item.id === region.id) > -1 : false}
          />
          <ListItemText primary={region.label} />
        </MenuItem>
      )))}
    </Select>
  )
}

RegionDropdown.defaultProps = defaultProps
