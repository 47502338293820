import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'

import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import info from 'assets/icons/warning.svg'

import { Backdrop } from '@mui/material'
import { RootState } from 'Store'
import Loader from 'components/Common/Loader'
import { fieldValueToString } from 'objects/utils'
import { useSelector } from 'react-redux'
import { SelectValue } from 'reducers/types'
import { ObjOfStr } from 'types'
import { InputDataType } from '../utils'
import './CustomSelect.scss'

export default function CustomSelect({
  field, value, onChange, error, helperText, disabled,
}: InputDataType): ReactElement | null {
  const { t } = useTranslation()
  // todo remove temporary fix
  const { zoes, zoesLoading } = useSelector((state: RootState) => state.instruction)

  if (field.values === undefined) return null

  const values = field.key === 'zoe' ? zoes as SelectValue[] : field.values

  const currentValue = typeof value === 'object' ? value?.id || value?.value : value

  const onValueChange = (e: SelectChangeEvent<string | number>) => {
    if ((typeof value === 'object' || value === undefined)
    && (field.key === 'zoe' || field.key === 'electricalStation')) {
      const vals = field.key === 'zoe' ? values : field.values
      const selectedObject = vals?.find(elem => elem.value === e.target.value)
      onChange({ label: selectedObject?.label, id: selectedObject?.value } as ObjOfStr, field)
    } else {
      onChange(e.target.value, field)
    }
  }

  return (
    <div key={field.key} className="w-100 my-3 px-2">
      <FormControl fullWidth key={field.key} error={error && !disabled} variant="standard">
        <InputLabel shrink disabled={disabled}>{t(field.label)}</InputLabel>
        <Backdrop
          open={field.key === 'zoe' && zoesLoading}
          className="select-backdrop"
        >
          <Loader />
        </Backdrop>
        <Select
          value={value === undefined ? '' : fieldValueToString(currentValue as string) as string}
          onChange={e => onValueChange(e)}
          className="select"
          disableUnderline
          disabled={disabled || (field.key === 'zoe' && zoesLoading)}
        >
          {values.map(option => (
            <MenuItem
              key={option.label}
              value={fieldValueToString(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && !disabled && (
          <FormHelperText error={error} className="helper d-flex">
            <div>
              <img src={info} alt="info icon" className="helper-icon mr-2" />
            </div>
            <div className="text-wrapper">
              {t(helperText)}
            </div>
          </FormHelperText>
        )}
      </FormControl>
    </div>
  )
}
