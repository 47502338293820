import {
  createSlice, isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import { Projection } from 'objects/types/projections'
import { TrackSection } from 'objects/types'
import ProjectionServices from 'services/ProjectionServices'

export interface ProjectionState {
  displayCreation: boolean;
  activeStep: number;
  activeSubStep: number; // Used when there are several screens for 1 step
  projection: Partial<Projection>;
  items: TrackSection[]; // detailed list of the instruction's items
  totalItems: number; // detailed list of the instruction's items
  loading: boolean;
  openModal: boolean;
  openValidationModal: boolean;
  showLinearization: boolean;
}

const initialState: ProjectionState = {
  displayCreation: false,
  activeStep: 0,
  activeSubStep: 0,
  projection: {},
  loading: false,
  items: [],
  totalItems: 0,
  openModal: false,
  openValidationModal: false,
  showLinearization: false,
}

export const projection = createSlice({
  name: 'projection',
  initialState,
  reducers: {
    reset: () => initialState,
    toggleDisplayCreation: state => {
      state.displayCreation = !state.displayCreation
    },
    setProjection: (state, action: PayloadAction<Partial<Projection>>) => {
      state.projection = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setActiveSubStep: (state, action: PayloadAction<number>) => {
      state.activeSubStep = action.payload
    },
    setProjectionLabel: (state, action: PayloadAction<string>) => {
      state.projection.name = action.payload
    },
    setProjectionOrigin: (state, action: PayloadAction<string>) => {
      state.projection.originalProjection = action.payload
    },
    setItems: (state, action: PayloadAction<TrackSection[]>) => {
      state.items = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    toggleOpenModal: state => {
      state.openModal = !state.openModal
    },
    toggleOpenValidationModal: state => {
      state.openValidationModal = !state.openValidationModal
    },
    toggleShowLinearization: state => {
      state.showLinearization = !state.showLinearization
    },
  },
  extraReducers: builder => {
    builder.addCase(ProjectionServices.create.fulfilled, (state, action) => {
      state.projection = action.payload
    })
    builder.addCase(ProjectionServices.getDetails.fulfilled, (state, action) => {
      state.projection = action.payload
    })
    builder.addCase(ProjectionServices.getItemsInBbox.fulfilled, (state, action) => {
      state.loading = false
      state.projection.trackSectionIds = action.payload.features.map(f => f.properties.id)
    })
    builder.addCase(ProjectionServices.getItemsInBbox.pending, state => {
      state.loading = true
    })
    builder.addCase(ProjectionServices.getItemsInBbox.rejected, state => {
      state.loading = false
    })
    builder.addCase(ProjectionServices.update.fulfilled, state => {
      state.openModal = !state.openModal
    })
    builder.addMatcher(isAnyOf(
      ProjectionServices.create.rejected,
      ProjectionServices.getItemsInBbox.rejected,
    ), state => {
      if (state.activeSubStep > 0) state.activeSubStep -= 1
      state.openValidationModal = false
    })
  },
})

export const {
  toggleDisplayCreation,
  setProjection,
  setActiveStep,
  setActiveSubStep,
  setProjectionLabel,
  setItems,
  setLoading,
  reset: resetProjection,
  setProjectionOrigin,
  toggleOpenModal,
  toggleOpenValidationModal,
  toggleShowLinearization,
} = projection.actions

export default projection.reducer
