import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'

import getCSSValue from 'helpers'
import { SubArea } from 'objects/types/protections'
import AreaServices from 'services/AreaServices'

import Loader from 'components/Common/Loader'
import SubAreaLine from './SubAreaLine'
import './SubAreas.scss'
import { AreaDeletion } from './types'

type Props = {
  areaId: string;
  subAreas: SubArea[];
  loadingArea: string;
  setDeletion: (arg: AreaDeletion) => void;
}

export default function SubAreas({
  areaId, subAreas: subareas, loadingArea, setDeletion,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [displayNewline, setDisplayNewline] = useState(false)

  const onAddClick = () => {
    if (!displayNewline) {
      setDisplayNewline(true)
    }
  }

  useEffect(() => {
    if (displayNewline) {
      setDisplayNewline(false)
    }
  }, [subareas])

  const removeSubarea = (subarea: SubArea) => () => {
    setDeletion({ id: areaId, subarea })
  }
  return (
    <>
      {loadingArea === areaId ? <Loader />
        : (
          <div className="subareas">
            <div className="d-flex justify-content-between align-items-center">
              <div className="title">
                {t('Areas.subAreasTitle')}
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <IconButton onClick={() => onAddClick()}>
                  <AddIcon htmlColor={getCSSValue('--color-carbone')} />
                </IconButton>
              </div>
            </div>
            <div className="subareas-list">
              {subareas.map(subarea => (
                <SubAreaLine
                  key={subarea.id}
                  subarea={subarea}
                  onBlur={e => {
                    if (e.target.value !== '' && subarea.label !== e.target.value) {
                      dispatch(AreaServices.updateSubArea({
                        id: areaId,
                        subArea: {
                          ...subarea,
                          label: e.target.value,
                        },
                      }))
                    }
                  }}
                  onRemove={removeSubarea(subarea)}
                />
              ))}
              {displayNewline && (
              <SubAreaLine
                onBlur={e => {
                  if (e.target.value !== '') {
                    dispatch(AreaServices.addSubArea({
                      id: areaId,
                      subArea: {
                        label: e.target.value,
                      },
                    }))
                  } else {
                    setDisplayNewline(false)
                  }
                }}
                onRemove={() => setDisplayNewline(false)}
              />
              )}
            </div>
          </div>
        )}
    </>
  )
}
