import { ReactElement } from 'react'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { Isolator } from 'objects/types'

import { Divider } from '@mui/material'
import Instructions from 'objects/common/Instructions/Instructions'
import { AttributesProps } from 'objects/types/const'
import ISOLATOR_ATTRIBUTES from './IsolatorAttributes'

export default function IsolatorDetails({ object }: AttributesProps): ReactElement | null {
  const isolator = object as Isolator
  return (
    <div id="isolator-details" className="object-attributes">
      <Attributes item={isolator} attributes={ISOLATOR_ATTRIBUTES(isolator)} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Instructions item={isolator} />
      </div>

    </div>
  )
}
