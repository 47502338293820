import { DateTime } from 'luxon'
import { Attribute, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { MidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatIdentityChoice } from 'objects/utils'

const TUNNEL_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'line',
    path: ['line', 'lineCode'],
    errorPath: 'line',
    label: 'Attributes.common.line',
    parser: Parser.identity,
    required: true,
    type: InputTypes.CaptureClick,
    isEditable: true,
    creation: {
      required: true,
      defaultValue: '',
    },
    captureClickHelper: 'Modification.helperText.lineCaptureClick',
    captureClickParams: {
      updateParams: [
        {
          clickedObjectPath: 'lineCode',
          path: ['line', 'lineCode'],
        },
        {
          clickedObjectPath: 'lineId',
          path: ['line', 'id'],
        },
      ],
      objectLayer: ObjectLayer.TrackSection,
      displayedValueFormatter: formatIdentityChoice,
    },
  },
  {
    key: 'kp',
    path: 'startKp',
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'label',
    path: 'label',
    label: 'Attributes.common.label',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'length',
    path: 'length',
    label: 'Attributes.Tunnel.length',
    parser: Parser.identity,
    type: InputTypes.Numeric,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default TUNNEL_ATTRIBUTES
