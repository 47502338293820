import { useTranslation } from '@osrdata/app_core/dist/translation'
import { Fragment, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'

import { RootState } from 'Store'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import {
  ProjectionState,
  resetProjection,
  setActiveStep,
  setActiveSubStep,
} from 'reducers/projection'
import ProjectionServices from 'services/ProjectionServices'
import ItemsSelection from './ItemsSelection/ItemsSelection'
import ProjectionNameChoice from './NameChoice/NameChoice'
import OriginProjection from './OriginProjection/OriginProjection'
import './ProjectionCreation.scss'

export const CREATION_STEPS = [
  {
    activeStep: 0,
    view: <ProjectionNameChoice title="Quel nom pour votre nouvelle représentation schématique ?" />,
  },
  {
    activeStep: 1,
    view: <OriginProjection title="Choisissez la représentation de départ" />,
  },
  {
    activeStep: 2,
    view: <ItemsSelection
      selectMapTitle="Définissez le périmètre de votre représentation"
    />,
  },
]

type Props = {
  steps?: typeof CREATION_STEPS;
} & typeof defaultProps

const defaultProps = {
  steps: CREATION_STEPS,
}

export default function ProjectionCreation({ steps }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { activeStep, projection } = useSelector((state: RootState) => state.projection) as ProjectionState

  useEffect(() => {
    if (projection.trackSectionIds !== undefined) {
      dispatch(ProjectionServices.create(projection))
    }
  }, [projection.trackSectionIds])

  const onReturn = () => {
    if (activeStep === 0) dispatch(resetProjection())
    else {
      dispatch(setActiveStep(activeStep - 1))
      dispatch(setActiveSubStep(0))
    }
  }

  const onClose = () => {
    dispatch(resetProjection())
  }

  const getBackButtonText = () => (activeStep === 0
    ? 'Instruction.button.returnToHome'
    : 'Instruction.button.returnToPreviousStep')

  return (
    <>
      <div id="object-creation">
        <div className="w-100 stepper-bar purple">
          {activeStep <= 2 && (
            <div className="return-wrapper">
              <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onReturn}>
                <span>{t(getBackButtonText())}</span>
              </Button>
            </div>
          )}
          <div className="stepper-wrapper">
            <Stepper activeStep={activeStep}>
              {steps.map(step => (
                <Step key={step.activeStep}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
          </div>
          {activeStep > 2 && (
          <div className="close-wrapper">
            <IconButton onClick={onClose}>
              <CloseIcon className="close-icon" />
            </IconButton>
          </div>
          )}
        </div>
        {steps.map(step => (activeStep === step.activeStep
          ? (
            <Fragment key={step.activeStep}>
              {step.view}
            </Fragment>
          )
          : null))}
      </div>
      <FeedbackSnackBar />
    </>
  )
}

ProjectionCreation.defaultProps = defaultProps
