import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import { ObjectSummary } from 'objects/types/instructions'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

export default function ObjectStats(): ReactElement {
  const { t } = useTranslation()
  const { instructionDetails } = useSelector((state: RootState) => state.admin)

  return (
    <>
      {instructionDetails.summary?.map((itemSummary: ObjectSummary) => (
        <>
          <div key={itemSummary.itemType}>
            <h1 style={{ fontSize: '20px' }}>
              {`${itemSummary.itemType} ${itemSummary.itemCount}
          ${t('Instruction.creation.itemSelection.objects')} `}
            </h1>
            <span style={{ color: '#fd7b62' }}>
              {`${t('Object.kpi.toDo')}
            ${(itemSummary.todoObjects).toFixed(1)}%`}

            </span>
            <br />
            <span style={{ color: '#faad3b' }}>
              {`${t('Object.kpi.inProgress')} 
          ${(itemSummary.inProgressObjects).toFixed(1)}%`}

            </span>
            <br />
            <span style={{ color: 'purple' }}>
              {` ${t('Object.kpi.validated')} 
          ${(itemSummary.validatedObjects).toFixed(1)}%`}

            </span>
            <br />
            <span style={{ color: '#256eff' }}>
              {` ${t('Object.kpi.verified')} 
          ${(itemSummary.verifiedObjects).toFixed(1)}%`}

            </span>
            <br />
            <span style={{ color: '#04e762' }}>
              {` ${t('Object.kpi.dispatched')} 
          ${(itemSummary.publishedObjects).toFixed(1)}%`}

            </span>
          </div>
          <br />
        </>
      ))}
    </>
  )
}
