import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import ActionButton from 'components/Common/ActionButton/ActionButton'
import ButtonStatus from 'components/Common/buttonStatus'
import { MidiObject } from 'objects/types'
import { ObjectStatus } from 'objects/types/const'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import MidiRole from 'services/midiRoleTypes'
import { RootState } from 'Store'
import {
  getValidateButtonText, SUPERVISOR_REFUSE_DELETION_BUTTON_TEXT,
  ValidationOperation,
} from '../utils'

type Props = {
  onValidate: (o: string) => void;
}

export default function SupervisorButtons({ onValidate }: Props): ReactElement {
  const { item, buttonStatus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const typedItem = item as MidiObject
  if (typedItem.status !== undefined && typedItem.status !== ObjectStatus.VALIDATED) {
    return (
      <ActionButton
        title={getValidateButtonText(MidiRole.supervisor)}
        onClick={() => {}}
        status={typedItem.status > ObjectStatus.VALIDATED ? ButtonStatus.Success : ButtonStatus.Disabled}
      />
    )
  }

  return (
    <>
      <ActionButton
        title={getValidateButtonText(MidiRole.supervisor, Boolean(typedItem.deletedAt))}
        onClick={() => onValidate(ValidationOperation.verify)}
        status={buttonStatus}
      />
      {typedItem?.deletedAt && (
        <>
          <div className="my-2" />
          <ActionButton
            title={SUPERVISOR_REFUSE_DELETION_BUTTON_TEXT}
            onClick={() => onValidate(ValidationOperation.undelete)}
            status={buttonStatus}
          />
        </>
      )}
      {!typedItem?.deletedAt && (
        <>
          <div className="my-2" />
          <ActionButton
            title={getValidateButtonText()}
            onClick={() => onValidate(ValidationOperation.refuse)}
            status={buttonStatus}
          />
        </>
      )}
      <div className="my-2" />
    </>
  )
}
