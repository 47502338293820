/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  deleteRequest, get, patch, post,
} from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { Area } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { MIDI_URI } from 'objects/uri'
import { store } from 'Store'
import URI from '../uri'
import {
  addSubArea, getAllSubAreas, updateSubArea, deleteSubArea,
} from './subareas'

const getAll = createAsyncThunk<Area[], {search: string; region: string}, ThunkApiConfig>(
  'area/getAll',
  async ({ search, region }, thunkApi) => {
    try {
      const searchParam = search ? { search } : {}
      const regionParam = region !== 'all' && region !== undefined ? { region_trigram: region } : {}
      const response = await get(`/${MIDI_URI}/${URI.areas}/`, {
        ...searchParam, ...regionParam,
      })
      return response.results as Area[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const create = createAsyncThunk<Area, Partial<Area>, ThunkApiConfig>(
  'area/create',
  async (newArea, thunkApi) => {
    try {
      const response: Area = await post(`/${MIDI_URI}/${URI.areas}/`, newArea)
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const update = createAsyncThunk<{area: Area; selectedRegion: string}, Partial<Area>, ThunkApiConfig>(
  'area/updateById',
  async (updatedArea, thunkApi) => {
    try {
      const { selectedRegion } = store.getState().regions
      const response: Area = await patch(`/${MIDI_URI}/${URI.areas}/${updatedArea.id}`, updatedArea)
      return { area: response, selectedRegion }
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const deleteObject = createAsyncThunk<string, string, ThunkApiConfig>(
  'area/deleteById',
  async (id, thunkApi) => {
    try {
      await deleteRequest(`/${MIDI_URI}/${URI.areas}/${id}`)
      return id
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const AreaServices = {
  getAll,
  create,
  update,
  delete: deleteObject,
  addSubArea,
  updateSubArea,
  getAllSubAreas,
  deleteSubArea,
}

export default AreaServices
