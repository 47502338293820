import StraightIcon from '@mui/icons-material/Straight'
import { Button } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { loggedAsSupervisor } from 'helpers/permissions'
import { findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, HistoryServiceOfKind } from 'objects/services'
import { MidiObject } from 'objects/types'
import { AttributesProps } from 'objects/types/const'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DetailsPanelState, showHistory } from 'reducers/panels/detailsPanel'
import ObjectServices from 'services/ObjectServices'
import './History.scss'
import { formatActivityDates } from './utils'

export default function History({ object }: AttributesProps): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { history, objectToMerge } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const [isExtending, setExtend] = useState(false)
  const objectToExtend = useRef('')

  const toggleConfirmationPopup = () => setExtend(!isExtending)

  const typedItem = object as MidiObject
  const kind = findObjectKind(typedItem)

  useEffect(() => {
    if (object) {
      dispatch(HistoryServiceOfKind[kind]({ id: typedItem.id }))
    }
  }, [])

  const handleHistoryClick = (id :string) => {
    dispatch(showHistory(true))
    dispatch(GetDetailsServiceOfKind[kind]({ id }))
  }

  const extendObject = () => {
    setExtend(false)
    dispatch(ObjectServices.extend(objectToExtend.current))
  }

  return (
    <div className="history">
      {history.map((oldObject, index) => (
        <ObjectTile
          headerComponent={index && !objectToMerge.id && !loggedAsSupervisor() ? (
            <Button
              className="extend"
              startIcon={<StraightIcon />}
              endIcon={<StraightIcon />}
              onClick={() => {
                toggleConfirmationPopup()
                objectToExtend.current = oldObject.id
              }}
            >
              {t(('Details.button.extend'))}
            </Button>
          ) : <></>}
          key={oldObject.id}
          onClick={() => handleHistoryClick(oldObject.id)}
          title={oldObject.mainRepresentation}
          subtitle={formatActivityDates(oldObject)}
          contextMenu={<></>}
        />
      ))}

      <ConfirmationDialog
        title={t('Details.title.validateExtend')}
        content={t('Details.content.validateExtend')}
        toggle={toggleConfirmationPopup}
        open={isExtending}
        actionToConfirm={extendObject}
      />

    </div>
  )
}
