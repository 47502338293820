import ErrorOverlay from 'components/Common/ErrorOverlay'
import { findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ObjectKind, URIObjectKind } from 'objects/types/const'
import {
  ReactElement, SyntheticEvent, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeedbackState } from 'reducers/feedback'
import { disableGeoEditor } from 'reducers/geoEditor'
import {
  DetailsPanelState, resetOpenMenus, resetTask, updateItem,
} from 'reducers/panels/detailsPanel'
import { RootState } from 'Store'

import { noValidationKind } from 'components/Map/popupUtils'
import { loggedAsSupervisor } from 'helpers/permissions'
import { TaskStatus, TaskType } from 'objects/types/instructions'
import { MapState } from 'reducers/map'
import { goBack, PanelName, PanelState } from 'reducers/panels/panel'
import ObjectServices from 'services/ObjectServices'
import PanelNavigator from '../PanelNavigator'
import PanelTemplate from '../PanelTemplate/PanelTemplate'
import './DetailsPanel.scss'
import DetailsPanelButtons from './DetailsPanelButtons'
import DetailsPanelContent from './DetailsPanelContent'
import DetailsPanelHeader from './DetailsPanelHeader'
import {
  DETAILS_PANEL_TABS, removeParamsFromUrl, shouldDisplayTab, TabKeys,
} from './utils'

const CRITICAL_ERROR_CODES = [403, 404, 409, 500]

export default function DetailPanel(): ReactElement | null {
  const dispatch = useDispatch()
  const {
    item, isLoading, shouldRefresh, objectToMerge, fetchAsyncId, asyncTask,
  } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const { feedback } = useSelector((state: RootState): FeedbackState => state.feedback)
  const { panelHistory } = useSelector((state: RootState): PanelState => state.panel)
  const { selectedProjection } = useSelector((state: RootState): MapState => state.map)
  const initialKind = item ? findObjectKind(item) : ObjectKind.TrackSection
  const [kind, setKind] = useState<ObjectKind>(initialKind)

  const [value, setValue] = useState(DETAILS_PANEL_TABS[0].value)
  const [tabs, setTabs] = useState(DETAILS_PANEL_TABS)
  const fetchStatus = useRef<NodeJS.Timer | NodeJS.Timeout>()

  const getNewInfo = (newKind: ObjectKind, newItem: ShortMidiObject) => {
    dispatch(GetDetailsServiceOfKind[newKind]({ id: newItem.itemId || newItem.id }))
    dispatch(GetGeometryServiceOfKind[newKind](newItem.itemId || newItem.id))
  }

  const updateInfo = () => {
    if (item !== undefined) {
      const newKind = findObjectKind(item)
      if (kind !== newKind || shouldRefresh) {
        const newTabs = DETAILS_PANEL_TABS.filter(tab => shouldDisplayTab(tab, newKind))
        setKind(newKind)
        setTabs(newTabs)
        setValue(newTabs[0].value)
        getNewInfo(newKind, item)
      }
    }
  }

  useEffect(() => {
    if (item) {
      getNewInfo(kind, item)
    }
    return () => {
      removeParamsFromUrl()
      dispatch(resetOpenMenus())
    }
  }, [])

  useEffect(() => {
    if (item) {
      getNewInfo(kind, item)
    }
  }, [selectedProjection])

  useEffect(() => {
    updateInfo()
    setValue(DETAILS_PANEL_TABS[0].value)
  }, [kind, item, shouldRefresh])

  // todo refacto
  const clearFetchInterval = () => {
    // todo array of action where refresh is not needed
    if (asyncTask.taskStatus === TaskStatus.SUCCESS && asyncTask.taskName !== TaskType.deleteTrackSection) {
      const type = URIObjectKind[asyncTask.objectType]
      dispatch(GetDetailsServiceOfKind[type]({ id: asyncTask.objectId }))
      dispatch(GetGeometryServiceOfKind[type](asyncTask.objectId))
    }

    dispatch(resetTask())
    clearInterval(fetchStatus.current as NodeJS.Timeout)
    fetchStatus.current = undefined

    if (asyncTask.taskStatus === TaskStatus.SUCCESS) {
      if (asyncTask.taskName === TaskType.deleteTrackSection) {
        dispatch(goBack())
        return
      }

      if (asyncTask.taskName === TaskType.updateTrackSection) {
        if (panelHistory[panelHistory.length - 1] === PanelName.modification) {
          dispatch(goBack())
        }
      }
    }
  }

  const checkStatus = () => {
    if (fetchStatus.current === undefined) {
      fetchStatus.current = (setInterval(() => dispatch(ObjectServices.getTaskStatus(fetchAsyncId)),
        2000))
      return
    }

    if (asyncTask.taskStatus !== TaskStatus.PENDING) { clearFetchInterval() }
  }

  useEffect(() => {
    if (asyncTask.id && fetchAsyncId) {
      checkStatus()
    }
  }, [asyncTask])

  useEffect(() => {
    if (fetchAsyncId) {
      checkStatus()
    }
  }, [fetchAsyncId])

  useEffect(() => () => {
    dispatch(disableGeoEditor())
  }, [])

  if (item === undefined) return null

  const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const onReturn = () => {
    dispatch(disableGeoEditor())

    dispatch(updateItem(undefined))
    PanelNavigator.goBack()
  }

  const renderContent = () => (feedback && CRITICAL_ERROR_CODES.includes(feedback.code)
    ? <ErrorOverlay code={feedback.code} />
    : (
      <DetailsPanelContent
        kind={kind}
        value={value}
        tabs={tabs}
        handleTabChange={handleTabChange}
      />
    ))

  const supervisorAndDeletedObject = () => {
    if (!item) return false
    const typedItem = item as MidiObject

    return typedItem.deletedAt && loggedAsSupervisor()
  }

  return (
    <PanelTemplate
      id="details-panel"
      className={supervisorAndDeletedObject() ? 'reduced' : 'basic'}
      onClickReturn={onReturn}
      headerContent={<DetailsPanelHeader kind={kind} />}
      footerButton={(isLoading && !objectToMerge.id) || noValidationKind(findObjectKind(item))
        || value === DETAILS_PANEL_TABS.find(tab => tab.key === TabKeys.history)?.value
        ? undefined
        : <DetailsPanelButtons kind={kind} />}
    >
      {renderContent()}
    </PanelTemplate>
  )
}
