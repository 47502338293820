import { useTranslation } from '@osrdata/app_core/dist/translation'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { RootState } from 'Store'
import SearchBar, { SearchSelectedValueType } from 'components/Common/SearchBar/SearchBar'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { getSubText } from 'components/ObjectItem/utils'
import { canEditObject } from 'components/Panels/DetailsPanel/utils'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind } from 'objects/services'
import { ITEM_TYPES, ObjectLayer } from 'objects/types/const'
import { ShortProtection } from 'objects/types/protections'
import { DeleteChildrenPayload, ProtectionChild } from 'objects/types/protections/const'
import { MIDI_URI, PROTECTIONS_SEARCH_URI } from 'objects/uri'
import { InstructionState } from 'reducers/instruction'
import { MapState, addLayer } from 'reducers/map'
import { setLoading } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import { CollapsibleMenu } from 'reducers/types'
import { GenericAction, ThunkApiConfig } from 'types'
import DeleteButton from '../Extremities/DeleteButton'
import IpContextMenu from '../LinkedProtections/LPContextMenu'
import { toggleOpenCollapsible } from '../utils'

type Props = {
  linkedProtections: ShortProtection[];
  deleteAction: GenericAction;
  addIPAction: (sp: ShortProtection) => AsyncThunkAction<unknown, ShortProtection, ThunkApiConfig>;
  type: CollapsibleMenu;
  params?: {
    title: string;
    searchPlaceholder: string;
    buttonLabel: string;
    filteredTypes?: ITEM_TYPES[];
  };
  editable: boolean;
  deleteAll: GenericAction<DeleteChildrenPayload>;
  fieldType: ProtectionChild;
} & typeof defaultProps

const defaultProps = {
  params: {
    title: 'Attributes.TrackProtection.incompatibleObjects',
    searchPlaceholder: 'Details.searchPlaceholder.protection',
    buttonLabel: 'Details.button.addIncompatibleObject',
  },
}
const labelFormatter = (sp: SearchSelectedValueType): string => {
  if (sp?.mainRepresentation) {
    return sp.mainRepresentation
  }
  if (sp !== undefined && 'label' in sp && sp.label !== undefined) {
    const label = `${sp.label}`
    return label
  }
  return ''
}

export default function IncompatibleProtections({
  linkedProtections, deleteAction, addIPAction, params, type, editable, deleteAll, fieldType,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    selectedProjection, viewportBbox,
  } = useSelector((state: RootState): MapState => state.map)
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState
  const [displaySearch, setDisplaySearch] = useState(false)
  const { item, openMenus } = useSelector((state: RootState) => state.detailsPanel)

  const usedIP = linkedProtections || []

  const onCloseSearch = (value: SearchSelectedValueType | undefined = undefined) => {
    setDisplaySearch(false)
    if (value !== undefined) dispatch(addIPAction(value as ShortProtection))
  }

  const getSearchParams = () => (params.filteredTypes
    ? {
      instance_of: params.filteredTypes,
    }
    : undefined
  )

  const formatActivityDates = (obj: ShortProtection) => `${(
    obj.activityStartDate && new Date(obj.activityStartDate).toLocaleDateString('fr-FR')) || '/'} 
    -
     ${(obj.activityEndDate && new Date(obj.activityEndDate).toLocaleDateString('fr-FR')) || '/'}`

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(type)}
      onChange={() => toggleOpenCollapsible(type)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t(params.title)} (${
          usedIP && usedIP.length
        })`}
      </AccordionSummary>
      <AccordionDetails>
        {usedIP && usedIP.map(sp => (
          <ObjectTile
            key={sp.id}
            onClick={() => {
              const kind = findObjectKind(sp)
              dispatch(addLayer(ObjectLayer[kind]))
              dispatch(setLoading(true))
              dispatch(GetDetailsServiceOfKind[kind]({ id: sp.id }))
              PanelNavigator.push(PanelName.details)
              dispatch(GetGeometryServiceOfKind[kind](sp.id))
            }}
            title={sp.mainRepresentation || ''}
            subtitle={t(getSubText(sp))}
            activityDates={formatActivityDates(sp)}
            contextMenu={canEditObject() && editable ? <IpContextMenu deleteAction={deleteAction} sp={sp} /> : <></>}
          />
        ))}
        {canEditObject() && editable && (
        <div className="protection-buttons  my-2">
          {displaySearch
            ? (
              <SearchBar
                onSelect={onCloseSearch}
                searchURI={`/${MIDI_URI}/${PROTECTIONS_SEARCH_URI}/`}
                placeholder={t(params.searchPlaceholder)}
                labelFormatter={(sp: SearchSelectedValueType) => labelFormatter(sp)}
                filteredIds={[item.id] as string[]}
                params={{
                  ...getSearchParams(),
                  instruction: instruction.id || '',
                  projection: selectedProjection?.slug || '',
                  bbox: JSON.stringify(viewportBbox) || JSON.stringify({ type: 'Polygon', coordinates: [] }),
                }}
              />
            )
            : (
              <>
                <SimpleButton
                  title={t(params.buttonLabel)}
                  onClick={() => { setDisplaySearch(true) }}
                  style={ButtonStyle.light}
                />

                {!!linkedProtections.length && (
                <DeleteButton
                  deleteAction={deleteAll}
                  field={fieldType}
                />
                )}

              </>
            )}
        </div>
        )}

      </AccordionDetails>
    </Accordion>
  )
}

IncompatibleProtections.defaultProps = defaultProps
