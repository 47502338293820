import { ReactElement } from 'react'

import { InputDataType } from '../utils'
import RawTextField from '../CustomTextField/RawTextField'

export default function CustomNumericField({
  field, value, onChange, error, helperText, disabled, noLabel,
}: InputDataType): ReactElement {
  return (
    <div key={field.key} className={`w-100 px-2 text-field ${noLabel ? 'no-label' : 'my-3'}`}>
      <RawTextField
        value={value}
        label={noLabel ? undefined : field.label}
        onChange={e => {
          const val = e.target.value === '' ? null : +e.target.value
          onChange(val, field)
        }}
        error={error}
        helperText={helperText}
        disabled={disabled}
        inputProps={{ min: 0 }}
        type="number"
      />
    </div>
  )
}
