import { store } from 'Store'
import { DateTime } from 'luxon'
import { StrOrNum } from 'types'
import { InstructionState } from 'reducers/instruction'
import { ParserFunction } from './attributes'
import { Track } from './types'
import {
  DirectionType, DirectionTypeMap, KPOrientation, KPOrientationMap,
  TrackType, TrackTypeMap, UserTrackNode,
} from './types/const'
import { ElectricalStation, Protection, Zoe } from './types/protections'
import {
  BooleanTypeMap,
  S9MGPTTypeMap, TrackProtectionGroupType, TrackProtectionGroupTypeMap,
  TrackProtectionType, TrackProtectionTypeMap, WorkTrainsTypeMap,
} from './types/protections/const'
import { fieldValueToString } from './utils'

const identity: ParserFunction<StrOrNum> = self => self

const dateParser: ParserFunction<string> = rawDate => {
  if (rawDate === null) return 'Attributes.common.noDate'
  if (DateTime.fromISO(rawDate).isValid) return DateTime.fromISO(rawDate).toFormat('dd/MM/yyyy')
  return rawDate
}

const trackTypeParser: ParserFunction<TrackType> = rawType => (
  TrackTypeMap.find(type => type.value === rawType)?.label || ''
)

const directionTypeParser: ParserFunction<DirectionType> = rawType => (
  DirectionTypeMap.find(type => type.value === rawType)?.label || ''
)

const trackProtectionTypeParser: ParserFunction<TrackProtectionType> = rawType => (
  TrackProtectionTypeMap.find(type => type.value === rawType)?.label || ''
)

const trackProtectionGroupTypeParser: ParserFunction<TrackProtectionGroupType> = rawType => (
  TrackProtectionGroupTypeMap.find(type => type.value === rawType)?.label || ''
)

const mgptParser: ParserFunction<StrOrNum> = rawMgpt => (
  S9MGPTTypeMap.find(type => type.value === fieldValueToString(rawMgpt))?.label || ''
)

const workTrainsParser: ParserFunction<boolean> = rawValue => (
  WorkTrainsTypeMap.find(type => type.value === String(rawValue))?.label || ''
)

const booleanParser: ParserFunction<boolean> = rawValue => (
  BooleanTypeMap.find(type => type.value === String(rawValue))?.label || ''
)

const orientationTypeParser: ParserFunction<KPOrientation> = rawValue => (
  KPOrientationMap.find(type => type.value === rawValue)?.label || ''
)

const sectorParser: ParserFunction<Protection> = p => p?.label || ''

const trackParser: ParserFunction<Track> = track => (track ? track.name : '')

const lineParser: ParserFunction<Track> = track => (track ? track.lineCode : '')

const trackNode: ParserFunction<keyof typeof UserTrackNode> = nodeType => (nodeType ? UserTrackNode[nodeType] : '')

const electricalStationParser: ParserFunction<ElectricalStation> = rawValue => (
  (store.getState().instruction as InstructionState)
    .electricalStations.find(es => es?.label === rawValue?.label)?.label || ''
)

const zoeParser: ParserFunction<Zoe> = rawValue => rawValue?.label || ''

const Parser = {
  boolean: booleanParser,
  date: dateParser,
  directionType: directionTypeParser,
  identity,
  orientationType: orientationTypeParser,
  mgpt: mgptParser,
  sector: sectorParser,
  trackProtectionType: trackProtectionTypeParser,
  trackProtectionGroupType: trackProtectionGroupTypeParser,
  trackType: trackTypeParser,
  workTrains: workTrainsParser,
  track: trackParser,
  line: lineParser,
  trackNode,
  electricalStationParser,
  zoeParser,
}

export default Parser
