export const MAIN_API = {
  proxy: process.env.REACT_APP_GATEWAY_URL || '',
  api: process.env.REACT_APP_USE_LOCAL_BACKEND
    ? `http://${window.location.hostname}:8000`
    : process.env.REACT_APP_GATEWAY_URL,
  version: '0.0.1.20200408.1',
}

export const PORTAL_URL = {
  url: process.env.REACT_APP_PORTAL_URL || 'https://dev.dgexsol.fr',
}

export const DEFAULT_PROJECTION_SLUG = 'pmsig'

export default MAIN_API
