import { ReactElement } from 'react'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { Signal } from 'objects/types'

import { Divider } from '@mui/material'
import Instructions from 'objects/common/Instructions/Instructions'
import { AttributesProps } from 'objects/types/const'
import SIGNAL_ATTRIBUTES from './SignalAttributes'

export default function SignalDetails({ object }: AttributesProps): ReactElement | null {
  const signal = object as Signal
  return (
    <div id="signal-details" className="object-attributes">
      <Attributes item={signal} attributes={SIGNAL_ATTRIBUTES(signal)} />

      <Divider className="my-2" />

      <div id="linked-objects">
        <Instructions item={signal} />
      </div>
    </div>
  )
}
