import { useTranslation } from '@osrdata/app_core/dist/translation'
import { AsyncThunkAction } from '@reduxjs/toolkit'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { RootState } from 'Store'
import SearchBar from 'components/Common/SearchBar/SearchBar'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { canEditObject } from 'components/Panels/DetailsPanel/utils'
import { toggleOpenCollapsible } from 'objects/common/utils'
import { Area } from 'objects/types/protections'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import { CollapsibleMenu } from 'reducers/types'
import { GenericAction, ThunkApiConfig } from 'types'
import { ShortMidiObject } from 'objects/types'
import { DeleteChildrenPayload, ProtectionChild } from 'objects/types/protections/const'
import ASAContextMenu from './ASAContextMenu'
import DeleteButton from '../Extremities/DeleteButton'

type Props = {
  associatedSubAreas: Area[];
  deleteASAAction: GenericAction;
  deleteAll: GenericAction<DeleteChildrenPayload>;
  addASAAction: (a: Area) => AsyncThunkAction<unknown, Area, ThunkApiConfig>;
  editable: boolean;
}

export default function AssociatedSubAreas({
  associatedSubAreas, deleteASAAction, addASAAction, editable, deleteAll,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const { openMenus } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const dispatch = useDispatch()
  const [displaySearch, setDisplaySearch] = useState(false)

  if (!associatedSubAreas) return null

  const onCloseSearch = (value: Area | ShortMidiObject | undefined = undefined) => {
    setDisplaySearch(false)
    if (value !== undefined) dispatch(addASAAction(value as Area))
  }

  return (
    <Accordion
      disableGutters
      expanded={openMenus.includes(CollapsibleMenu.associatedSubAreas)}
      onChange={() => toggleOpenCollapsible(CollapsibleMenu.associatedSubAreas)}
    >
      <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
        {`${t('Attributes.TrackProtection.associatedSubAreas')} (${associatedSubAreas.length})`}
      </AccordionSummary>
      <AccordionDetails>
        {associatedSubAreas.map(asa => (
          <ObjectTile
            key={asa.id}
            onClick={() => {}}
            title={asa.label}
            subtitle={asa.areaLabel || ''}
            contextMenu={canEditObject()
              && editable ? <ASAContextMenu asa={asa} deleteAction={deleteASAAction} /> : <></>}
          />
        ))}
        {canEditObject() && editable && (
        <div className="protection-buttons my-2">
          {displaySearch
            ? (
              <SearchBar
                onSelect={onCloseSearch}
                searchURI={`/${MIDI_URI}/${ObjectURI.SubAreas}/`}
                placeholder={t('Details.searchPlaceholder.areaOrSubArea')}
                labelFormatter={option => `${(option as Area).label} / ${(option as Area).areaLabel}`}
              />
            )
            : (
              <>
                <SimpleButton
                  title={t('Details.button.addAssociatedSubArea')}
                  onClick={() => { setDisplaySearch(true) }}
                  style={ButtonStyle.light}
                />
                {!!associatedSubAreas.length && (
                <DeleteButton
                  deleteAction={deleteAll}
                  field={ProtectionChild.associatedSubAreas}
                />
                )}

              </>
            )}
        </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}
