import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState } from 'Store'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { InstructionState } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import { User as CerbereUser } from 'services/cerbereTypes'
import { getInitialSelectedUsers } from '../Parameters/Attributions'
import UserMultiSelect from '../common/UserMultiSelect'
import './SelectFields.scss'

export default function SelectFields(): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    administrators, operators, supervisors, managers, instruction, loading, roots,
  } = useSelector(
    (state: RootState) => state.instruction,
  ) as InstructionState

  const [selectedUsers, setSelectedUsers] = useState<CerbereUser[]>([])

  useEffect(() => {
    setSelectedUsers(previousUsers => [...getInitialSelectedUsers(instruction,
      [...roots, ...administrators, ...supervisors, ...managers, ...operators],
      previousUsers)])
  }, [administrators, roots, supervisors, managers, operators])

  return (
    <>
      <div className="colored-title d-flex justify-content-center align-items-center">
        <div className="w-75 mx-auto">
          <div className="title-wrapper text-center d-flex justify-content-center">
            <h1>{t('Instruction.creation.userAssignation.title')}</h1>
          </div>
          <div className="d-flex input-wrapper justify-content-around align-items-center w-100 flex-column">
            <div className="m-auto w-75 my-3">
              <div className="select-fields my-5">
                <UserMultiSelect
                  loading={loading}
                  options={([...new Map([...roots, ...administrators, ...supervisors, ...managers, ...operators]
                    .map(v => [v.id, v])).values()].filter(user => !selectedUsers
                    .some((u: CerbereUser) => u.id === user.id)))}
                  label="Instruction.creation.userAssignation.userSelect.label"
                  placeholder={loading ? 'Instruction.creation.userAssignation.loading'
                    : 'Instruction.creation.userAssignation.userSelect.placeholder'}
                  onChange={users => {
                    setSelectedUsers(users)
                  }}
                  defaultValue={selectedUsers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blank-footer" />
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          style={ButtonStyle.primary}
          disabled={loading}
          title={t('Instruction.button.continue')}
          onClick={() => dispatch(InstructionServices.addUsers({
            ...instruction,
            users: [
              ...selectedUsers.map((o:CerbereUser) => o.id),
            ],
          }))}
        />
      </div>
    </>
  )
}
