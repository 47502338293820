import { ReactElement, SyntheticEvent, useState } from 'react'

import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

import TabsMenu, { Tab as TabType } from 'components/Common/TabsMenu/TabsMenu'
import InstructionsTab from './InstructionsTab/InstructionsTab'
import AreasTab from './AreasTab/AreasTab'
import ProjectionsTab from './ProjectionsTab/ProjectionsTab'
import './HomeTab.scss'

const DASHBOARD_TABS: TabType[] = [
  {
    key: 'instructions',
    label: 'Mes consignes',
    value: '1',
    component: <InstructionsTab />,
  },
  {
    key: 'schematicRepresentations',
    label: 'Mes représentations schématiques',
    value: '2',
    component: <ProjectionsTab />,
  },
  {
    key: 'areas',
    label: 'Périmètres métiers',
    value: '3',
    component: <AreasTab />,
  },
]

export default function HomeDashboard(): ReactElement {
  const [value, setValue] = useState('1')

  const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <div id="admin-home-dashboard">
      <TabContext value={value}>
        <TabsMenu tabs={DASHBOARD_TABS} handleTabChange={handleTabChange} />
        {DASHBOARD_TABS.map(tab => (
          <TabPanel
            key={tab.key}
            value={tab.value}
            className="tab-panel flex-column"
          >
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}
