import {
  Isolator, MidiObject, ShortMidiObject,
  Signal,
  TrackNode,
  TrackSection,
  Tunnel,
} from './types'
import { ITEM_TYPES, ObjectKind, ObjectLayer } from './types/const'
import {
  Feeder, Sector, SubSector, TrackProtection, TrackProtectionGroup,
} from './types/protections'
import ObjectURI from './uri'

export const isElectricalElement = (obj: MidiObject | ShortMidiObject): obj is TrackProtectionGroup => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.electricalelement)
  || obj.detailUrl?.includes(ObjectURI.ElectricalElements)
  || ('protectionType' in obj && obj.protectionType === ITEM_TYPES.electricalelement)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.electricalelement)
)
export const isElectricalProtectionGroup = (obj: MidiObject | ShortMidiObject): obj is TrackProtectionGroup => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.electricalprotectiongroup)
   || obj.detailUrl?.includes(ObjectURI.ElectricalProtectionGroups)
   || ('protectionType' in obj && obj.protectionType === ITEM_TYPES.electricalprotectiongroup)
   || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.electricalprotectiongroup)
)
export const isFeeder = (obj: MidiObject | ShortMidiObject): obj is Feeder => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.feeder) || obj.detailUrl?.includes(ObjectURI.Feeders)
  || ('protectionType' in obj && obj.protectionType === ITEM_TYPES.feeder)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.feeder)
)
export const isIsolator = (obj: MidiObject | ShortMidiObject): obj is Isolator => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.isolator) || obj.detailUrl?.includes(ObjectURI.Isolators)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.isolator)
)

export const isSector = (obj: MidiObject | ShortMidiObject): obj is Sector => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.sector) || (obj.detailUrl?.includes(ObjectURI.Sectors)
    && !obj.detailUrl?.includes(ObjectURI.SubSectors))
    || ('protectionType' in obj && obj.protectionType === ITEM_TYPES.sector)
    || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.sector)
)
export const isSignal = (obj: MidiObject | ShortMidiObject): obj is Signal => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.signal) || obj.detailUrl?.includes(ObjectURI.Signals)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.signal)
)
export const isSubSector = (obj: MidiObject | ShortMidiObject): obj is SubSector => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.subsector) || obj.detailUrl?.includes(ObjectURI.SubSectors)
  || ('protectionType' in obj && obj.protectionType === ITEM_TYPES.subsector)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.subsector)
)
export const isTrackProtection = (obj: MidiObject | ShortMidiObject): obj is TrackProtection => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.trackprotection)
  || obj.detailUrl?.includes(ObjectURI.TrackProtections)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.trackprotection)
)
export const isTrackProtectionGroup = (obj: MidiObject | ShortMidiObject): obj is TrackProtectionGroup => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.trackprotectiongroup)
  || obj.detailUrl?.includes(ObjectURI.TrackProtectionGroups)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.trackprotectiongroup)
)
export const isTrackSection = (obj: MidiObject | ShortMidiObject): obj is TrackSection => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.tracksection) || obj.detailUrl?.includes(ObjectURI.TrackSections)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.tracksection)
)
export const isTrackNode = (obj: MidiObject | ShortMidiObject): obj is TrackNode => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.tracknode) || obj.detailUrl?.includes(ObjectURI.TrackNode)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.tracknode)
)

export const isTunnel = (obj: MidiObject | ShortMidiObject): obj is Tunnel => (
  ('itemType' in obj && obj.itemType === ITEM_TYPES.tunnel) || obj.detailUrl?.includes(ObjectURI.Tunnel)
  || ('midiObjectType' in obj && obj.midiObjectType === ITEM_TYPES.tunnel)
)

export const findObjectKind = (unknownItem: MidiObject | ShortMidiObject): ObjectKind => {
  switch (true) {
    case isElectricalElement(unknownItem):
      return ObjectKind.ElectricalElement
    case isElectricalProtectionGroup(unknownItem):
      return ObjectKind.ElectricalProtectionGroup
    case isFeeder(unknownItem):
      return ObjectKind.Feeder
    case isIsolator(unknownItem):
      return ObjectKind.Isolator
    case isSector(unknownItem):
      return ObjectKind.Sector
    case isSignal(unknownItem):
      return ObjectKind.Signal
    case isSubSector(unknownItem):
      return ObjectKind.SubSector
    case isTrackProtection(unknownItem):
      return ObjectKind.TrackProtection
    case isTrackProtectionGroup(unknownItem):
      return ObjectKind.TrackProtectionGroup
    case isTrackNode(unknownItem):
      return ObjectKind.TrackNode
    case isTunnel(unknownItem):
      return ObjectKind.Tunnel
    case isTrackSection(unknownItem):
    default:
      return ObjectKind.TrackSection
  }
}

export const KIND_TO_LAYER = {
  [ObjectKind.ElectricalElement]: ObjectLayer.ElectricalElement,
  [ObjectKind.ElectricalProtectionGroup]: ObjectLayer.ElectricalProtectionGroup,
  [ObjectKind.Feeder]: ObjectLayer.Feeder,
  [ObjectKind.Isolator]: ObjectLayer.Isolator,
  [ObjectKind.Sector]: ObjectLayer.Sector,
  [ObjectKind.Signal]: ObjectLayer.Signal,
  [ObjectKind.SubSector]: ObjectLayer.SubSector,
  [ObjectKind.TrackProtection]: ObjectLayer.TrackProtection,
  [ObjectKind.TrackProtectionGroup]: ObjectLayer.TrackProtectionGroup,
  [ObjectKind.TrackSection]: ObjectLayer.TrackSection,
  [ObjectKind.TrackNode]: ObjectLayer.TrackNode,
  [ObjectKind.Tunnel]: ObjectLayer.Tunnel,
}

export const LAYER_TO_KIND = {
  [ObjectLayer.ElectricalElement]: ObjectKind.ElectricalElement,
  [ObjectLayer.ElectricalProtectionGroup]: ObjectKind.ElectricalProtectionGroup,
  [ObjectLayer.Feeder]: ObjectKind.Feeder,
  [ObjectLayer.Isolator]: ObjectKind.Isolator,
  [ObjectLayer.Sector]: ObjectKind.Sector,
  [ObjectLayer.Signal]: ObjectKind.Signal,
  [ObjectLayer.SubSector]: ObjectKind.SubSector,
  [ObjectLayer.TrackProtection]: ObjectKind.TrackProtection,
  [ObjectLayer.TrackProtectionGroup]: ObjectKind.TrackProtectionGroup,
  [ObjectLayer.TrackSection]: ObjectKind.TrackSection,
  [ObjectLayer.TrackNode]: ObjectKind.TrackNode,
  [ObjectLayer.Tunnel]: ObjectKind.Tunnel,
}
