/* eslint-disable @typescript-eslint/no-explicit-any */
import { patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ShortProtection, Sector, Protection } from 'objects/types/protections'
import { ThunkApiConfig } from 'types'
import { store } from 'Store'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import ObjectURI, { MIDI_URI } from 'objects/uri'
import { addInstructionParameter } from 'objects/utils'

export const addChild = createAsyncThunk<Sector, ShortProtection, ThunkApiConfig>(
  'sector/addChild',
  async (newChild, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as Sector

    const updatedSector = {
      id,
      checksum,
      composition: [...(composition.map(comp => ({ id: comp.id }))), {
        id: newChild.id,
      }],
    }

    try {
      const response: Sector = await patch(
        `/${MIDI_URI}/${ObjectURI.Sectors}/${updatedSector.id}`,
        updatedSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const deleteChild = createAsyncThunk<Sector, string, ThunkApiConfig>(
  'sector/deleteChild',
  async (deletedChildId, thunkApi) => {
    const detailsPanelState = store.getState().detailsPanel as DetailsPanelState
    const { id, checksum, composition } = detailsPanelState.item as Sector

    const updatedSector: Partial<Sector> = {
      id,
      checksum,
      composition: composition.flatMap(tp => (tp.id !== deletedChildId ? ({ id: tp.id }) as Protection : [])),
    }

    try {
      const response: Sector = await patch(
        `/${MIDI_URI}/${ObjectURI.Sectors}/${updatedSector.id}`,
        updatedSector, { params: addInstructionParameter() },
      )
      return response
    } catch (e: any) { // will be similar to AxiosResponse type
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
