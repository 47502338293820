import {
  createSlice,
  isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import { Polygon } from 'geojson'
import ElectricalStationServices from 'objects/ElectricalStation/ElectricalStationServices'
import { AsyncResponse, Region } from 'objects/types'
import {
  ExportStatus,
  Instruction, InstructionDetails, InstructionItem, InstructionType, TaskStatus,
} from 'objects/types/instructions'
import { ElectricalStation, Zoe } from 'objects/types/protections'
import { Role, User } from 'services/cerbereTypes'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'
import { Dag } from 'objects/types/common'
import ObjectServices from 'services/ObjectServices'
import { PaginatedInstructions, SelectValue } from './types'
import { EMPTY_TASK } from './panels/detailsPanel'

const EMPTY_PAGINATION = {
  next: '', previous: '', results: [], count: 0,
}
export interface InstructionState {
  displayCreation: boolean;
  displayNewVersion: boolean;
  activeStep: number;
  activeSubStep: number; // Used when there are several screens for 1 step
  instruction: Partial<InstructionDetails>;
  items: InstructionItem[]; // detailed list of the instruction's items
  totalItems: number; // detailed list of the instruction's items
  loading: boolean;
  supervisors: User[];
  operators: User[];
  administrators: User[];
  managers: User[];
  hasError: boolean;
  gaiaExportResult: string;
  perimeterModification: boolean;
  pagination: PaginatedInstructions;
  page: number;
  detailsLoading: boolean;
  addingUsers: boolean;
  electricalStations: SelectValue[];
  zoes: SelectValue[];
  zoesLoading: boolean;
  exportStatus?: ExportStatus;
  exportFile?: ArrayBuffer;
  roots: User[];
  dagStatus: Dag;
  fetchAsyncId: string;
  asyncTask: AsyncResponse;
}

const initialState: InstructionState = {
  displayCreation: false,
  displayNewVersion: false,
  activeStep: 0,
  activeSubStep: 0,
  instruction: {},
  loading: false,
  administrators: [],
  supervisors: [],
  operators: [],
  managers: [],
  items: [],
  totalItems: 0,
  hasError: false,
  perimeterModification: false,
  pagination: EMPTY_PAGINATION,
  page: 1,
  detailsLoading: false,
  addingUsers: false,
  electricalStations: [],
  zoes: [],
  zoesLoading: false,
  gaiaExportResult: '',
  roots: [],
  dagStatus: {
    dateOfLastRun: '', dateOfNextRun: '', name: '', id: '', creationDate: '', updateDate: '',
  },
  fetchAsyncId: '',
  asyncTask: EMPTY_TASK,
}

export const instruction = createSlice({
  name: 'instruction',
  initialState,
  reducers: {
    reset: state => ({ ...initialState, pagination: state.pagination, page: state.page }),
    toggleDisplayCreation: state => {
      state.displayCreation = !state.displayCreation
    },
    toggleDisplayNewVersion: state => {
      state.displayNewVersion = !state.displayNewVersion
    },
    setInstruction: (state, action: PayloadAction<Partial<Instruction>>) => {
      state.instruction = action.payload
    },
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setActiveSubStep: (state, action: PayloadAction<number>) => {
      state.activeSubStep = action.payload
    },
    setInstructionType: (state, action: PayloadAction<InstructionType>) => {
      state.instruction.type = action.payload
    },
    setInstructionName: (state, action: PayloadAction<string>) => {
      state.instruction.name = action.payload
    },
    setInstructionRef: (state, action: PayloadAction<string>) => {
      state.instruction.reference = action.payload
    },
    setInstructionVersion: (state, action: PayloadAction<string>) => {
      state.instruction.version = action.payload
    },
    setInstructionVersionDate: (state, action: PayloadAction<string>) => {
      state.instruction.versionDate = action.payload
    },
    setInstructionDate: (state, action: PayloadAction<string>) => {
      state.instruction.applicationDate = action.payload
    },
    setInstructionRegions: (state, action: PayloadAction<Region[]>) => {
      state.instruction.regions = action.payload
    },
    setInstructionGeom: (state, action: PayloadAction<Polygon>) => {
      state.instruction.boundingBox = action.payload
    },
    setItems: (state, action: PayloadAction<InstructionItem[]>) => {
      state.items = action.payload
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPerimeterModification: (state, action: PayloadAction<boolean>) => {
      state.perimeterModification = action.payload
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    resetPagination: state => {
      state.page = 1
      state.pagination = EMPTY_PAGINATION
    },
    resetExport: state => {
      state.gaiaExportResult = ''
      state.exportStatus = undefined
      state.exportFile = undefined
    },
    resetTask: state => {
      state.asyncTask = EMPTY_TASK
      state.fetchAsyncId = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.create.fulfilled, (state, action) => {
      state.pagination.results = [action.payload, ...state.pagination.results]
      state.instruction = action.payload
      state.activeStep += 1
      state.loading = false
    })
    builder.addCase(InstructionServices.getItemsInBbox.fulfilled, (state, { payload }) => {
      state.items = payload
      state.activeSubStep += 1
    })

    builder.addCase(InstructionServices.getAll.fulfilled, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.pagination = action.payload
      } else {
        state.pagination = { ...action.payload, results: [...state.pagination.results, ...action.payload.results] }
      }
    })
    // Add items
    builder.addCase(InstructionServices.addItems.pending, state => {
      state.activeSubStep = 3
    })
    builder.addCase(InstructionServices.addItems.fulfilled, state => {
      state.activeStep += 1
      state.activeSubStep = 0
      state.loading = false
    })

    // Add users
    builder.addCase(InstructionServices.addUsers.pending, state => {
      if (state.displayCreation || state.displayNewVersion) {
        state.activeSubStep = 1
      }

      state.addingUsers = true
    })
    builder.addCase(InstructionServices.addUsers.fulfilled, (state, action) => {
      if (state.displayCreation || state.displayNewVersion) {
        state.activeSubStep = 2
      }
      state.instruction = action.payload

      state.addingUsers = false
    })
    builder.addCase(InstructionServices.delete.fulfilled, (state, action) => {
      state.loading = false
      state.pagination.results = state.pagination.results.filter(instr => instr.id !== action.meta.arg)
      state.pagination.count -= 1
    })
    builder.addCase(UserServices.getGroups.pending, state => {
      state.loading = true
    })
    builder.addCase(UserServices.getGroups.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(UserServices.getGroups.rejected, state => {
      state.loading = false
    })
    builder.addCase(UserServices.getGroupMembers.pending, state => {
      state.loading = true
    })
    builder.addCase(UserServices.getGroupMembers.fulfilled, (state, action) => {
      state.loading = false
      switch (action.payload.role) {
        case Role.operator:
          state.operators = action.payload.results
          break
        case Role.manager:
          state.managers = action.payload.results
          break
        case Role.supervisor:
          state.supervisors = action.payload.results
          break
        case Role.admin:
          state.administrators = action.payload.results
          break
        case Role.root:
          state.roots = action.payload.results
          break
        default:
          break
      }
    })
    builder.addCase(UserServices.getGroupMembers.rejected, state => {
      state.loading = false
    })
    builder.addCase(
      InstructionServices.getItemsDetailsList.fulfilled, (state, action) => {
        state.totalItems = action.payload.totalItems
        state.items = action.payload.items
        state.loading = false
      },
    )

    builder.addCase(InstructionServices.getItemsDetailsList.rejected, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.getDetails.fulfilled, (state, action: PayloadAction<Partial<Instruction>>) => {
      state.instruction = action.payload
      state.hasError = false
      state.loading = false
      state.detailsLoading = false
    })

    builder.addCase(InstructionServices.getDetails.pending, state => {
      state.loading = true
      state.detailsLoading = true
    })

    builder.addCase(InstructionServices.exportDispatchedData.fulfilled, (
      state, action,
    ) => {
      state.gaiaExportResult = action.payload.gaiaExportResult
    })
    builder.addCase(InstructionServices.getExportStatus.fulfilled, (
      state, action,
    ) => {
      if (action.payload.taskStatus !== TaskStatus.PENDING) {
        state.loading = false
      }
      state.exportStatus = action.payload
    })
    builder.addCase(InstructionServices.getExportStatus.rejected, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.getExportFile.fulfilled, (state, action) => {
      state.exportFile = action.payload
    })
    builder.addCase(InstructionServices.validate.pending, state => {
      state.loading = true
    })
    builder.addCase(InstructionServices.validate.fulfilled, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.validate.rejected, state => {
      state.loading = false
    })
    builder.addCase(ElectricalStationServices.getAll.fulfilled, (state, { payload }) => {
      state.electricalStations = payload.map((elem: ElectricalStation) => ({ label: elem.label, value: elem.id }))
    })
    builder.addCase(ElectricalStationServices.getZoes.pending, state => {
      state.zoesLoading = true
    })
    builder.addCase(ElectricalStationServices.getZoes.fulfilled, (state, { payload }) => {
      state.zoes = payload?.map((elem: Zoe) => ({ label: elem.label, value: elem.id }))
      state.zoesLoading = false
    })
    builder.addCase(ElectricalStationServices.getZoes.rejected, state => {
      state.zoesLoading = false
    })
    builder.addCase(InstructionServices.removeItem.fulfilled, (state, action) => {
      state.items = state.items.filter(item => item.id !== action.meta.arg.itemId)
    })
    builder.addCase(InstructionServices.getObjectInstructions.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload.length) {
        state.instruction = { ...action.payload[0] }
      }
    })
    builder.addCase(InstructionServices.getDagStatus.fulfilled, (state, action) => {
      state.dagStatus = action.payload
    })
    builder.addCase(InstructionServices.create.rejected, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.update.rejected, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.update.fulfilled, (state, action) => {
      if (!action.meta.arg.async) {
        state.loading = false
        state.hasError = false
        state.detailsLoading = false
      }
    })
    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (state, action) => {
      if (action.payload.taskStatus !== TaskStatus.PENDING) {
        state.loading = false
        state.hasError = false
        state.detailsLoading = false
      }
    })
    builder.addCase(InstructionServices.exportDispatchedData.rejected, state => {
      state.loading = false
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.getDetails.rejected,
      InstructionServices.updateItems.rejected,
    ), state => {
      state.loading = false
      state.hasError = true
      state.detailsLoading = false
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.addItems.pending,
      InstructionServices.updateItems.pending,
      InstructionServices.getItemsDetailsList.pending,
      InstructionServices.deleteItem.pending,
      InstructionServices.exportDispatchedData.pending,
      InstructionServices.update.pending,
      InstructionServices.create.pending,
    ), state => {
      state.loading = true
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.addUsers.fulfilled,
      InstructionServices.updateItems.fulfilled,
    ), (state, action) => {
      state.instruction = action.payload
      state.loading = false
      state.hasError = false
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.getItemsInBbox.rejected,
      InstructionServices.addItems.rejected,
      InstructionServices.addUsers.rejected,
      InstructionServices.deleteItem.rejected,
    ), state => {
      state.activeSubStep -= 1
      state.loading = false
      state.hasError = true
    })
    builder.addMatcher(isAnyOf(
      InstructionServices.addUsers.fulfilled, InstructionServices.addItems.fulfilled,
    ), (state, action) => {
      state.pagination = {
        ...state.pagination,
        results: state.pagination.results.map(instr => (
          instr.id === action.payload.id
            ? action.payload
            : instr
        )),
      }
    })
  },
})

export const {
  toggleDisplayCreation,
  toggleDisplayNewVersion,
  setInstruction,
  setActiveStep,
  setActiveSubStep,
  setInstructionType,
  setInstructionName,
  setInstructionDate,
  setInstructionRegions,
  setInstructionGeom,
  setInstructionRef,
  setInstructionVersion,
  setInstructionVersionDate,
  setItems,
  setLoading,
  reset: resetInstruction,
  setPerimeterModification,
  setPageNumber,
  resetPagination,
  resetExport,
  resetTask,
} = instruction.actions

export default instruction.reducer
