import { ReactElement } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'

import { RootState } from 'Store'
import getCSSValue from 'helpers'
import { hoveredObjectColor } from 'objects/styles'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'
import { GeoEditorState } from 'reducers/geoEditor'

type Props = {
  item: ShortMidiObject | MidiObject | undefined;
  sourceLayer: ObjectLayer | undefined;
}

export default function HoverLayer({ item, sourceLayer }: Props): ReactElement | null {
  const { active: isGeoEditorActive } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { item: selectedItem } = useSelector((state: RootState) => state.detailsPanel)
  const { layers } = useSelector((state: RootState) => state.map)

  const getId = (): string => {
    if (item) {
      if (item.itemId) return item.itemId
      if (item.id) return item.id
      return ''
    }
    return ''
  }

  if (!isGeoEditorActive) {
    return (
      <>
        {(layers).map((layer:string) => (
          <Source
            key={layer}
            type="vector"
            url={formatLayerUrl(layer)}
            source-layer={layer}
            id={`hover-source-${layer}`}
          >
            <Layer
              type="line"
              paint={{
                'line-color': getCSSValue(hoveredObjectColor),
                'line-width': sourceLayer === ObjectLayer.Tunnel ? 10 : 3,
              }}
              id={`hover-layer-${layer}`}
              filter={['all', ['==', ['get', 'deletedAt'], null], ['==', ['get', 'id'], getId()]]}
              source-layer={layer}
              layout={{ visibility: layer === sourceLayer && item?.id !== selectedItem?.id ? 'visible' : 'none' }}
              source={sourceLayer}
            />
          </Source>
        ))}
      </>
    )
  }

  return null
}
