import { useTranslation } from '@osrdata/app_core/dist/translation'
import { loggedAsSupervisor } from 'helpers/permissions'
import { OperatorColors, RoleColors, SupervisorColors } from 'objects/common/utils'
import { ReactElement } from 'react'
import { STATUS_LABEL } from '../popupUtils'
import './Legend.scss'

export default function Legend(): ReactElement {
  const { t } = useTranslation()
  const colorsRole = loggedAsSupervisor() ? SupervisorColors : OperatorColors

  return (
    <div className="legend">
      {Object.entries(STATUS_LABEL).map(([key, label]) => (
        <div key={key} className="wrapper">
          <div className="color" style={{ backgroundColor: colorsRole[key as unknown as keyof RoleColors] }} />
          {t(label)}
        </div>
      ))}
    </div>

  )
}
