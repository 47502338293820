import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { GroupListItem, User } from 'services/cerbereTypes'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'
import {
  Instruction, InstructionDetails, TaskStatus, TaskType,
} from 'objects/types/instructions'
import { Area } from 'objects/types/protections'
import { Projection } from 'objects/types/projections'
import ProjectionServices from 'services/ProjectionServices'
import ObjectServices from 'services/ObjectServices'
import { AsyncResponse, AsyncTask } from 'objects/types'
import { EMPTY_TASK } from './panels/detailsPanel'

export interface AdminState {
  activeTab: number;
  loading: boolean;
  instructionsList: Instruction[];
  openInstructionsList: Instruction[];
  projectionsList: Projection[];
  groups: GroupListItem[];
  usersInfo: User[];
  areas: Area[];
  instructionDetails: InstructionDetails;
  fetchAsyncId: string;
  asyncTask: AsyncResponse;
}

const initialState: AdminState = {
  activeTab: 0,
  loading: false,
  instructionsList: [],
  openInstructionsList: [],
  projectionsList: [],
  groups: [],
  usersInfo: [],
  areas: [],
  instructionDetails: {} as InstructionDetails,
  fetchAsyncId: '',
  asyncTask: EMPTY_TASK,
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload
    },
    openInstruction: (state, action: PayloadAction<Instruction>) => {
      const tabIndex = state.openInstructionsList.map(inst => inst.id).indexOf(action.payload.id)
      if (tabIndex === -1) {
        state.openInstructionsList.push(action.payload)
        state.activeTab = state.openInstructionsList.length
      } else {
        state.activeTab = tabIndex + 1
      }
    },
    closeInstruction: (state, action: PayloadAction<string>) => {
      const deletedInstIndex = state.openInstructionsList.map(inst => inst.id).indexOf(action.payload)
      if (deletedInstIndex + 1 === state.activeTab && deletedInstIndex === state.openInstructionsList.length - 1) {
        state.activeTab -= 1
      }

      state.openInstructionsList = state.openInstructionsList.filter(inst => inst.id !== action.payload)
    },
    resetInstructions: state => {
      state.instructionsList = []
    },
    resetInstructionDetails: state => {
      state.instructionDetails = {} as InstructionDetails
    },
    resetOpenInstruction: state => {
      state.openInstructionsList = []
      state.activeTab = 0
    },
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = [...state.instructionsList, ...action.payload.results]
    })
    builder.addCase(InstructionServices.create.fulfilled, (state, action) => {
      state.instructionsList.push(action.payload)
    })
    builder.addCase(InstructionServices.delete.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = state.instructionsList.filter(instruction => instruction.id !== action.meta.arg)
      state.openInstructionsList = state.openInstructionsList.filter(instruction => instruction.id !== action.meta.arg)
    })
    builder.addCase(InstructionServices.delete.rejected, state => {
      state.loading = false
    })
    builder.addCase(InstructionServices.getDetails.fulfilled, (state, action) => {
      state.instructionDetails = action.payload
    })
    builder.addCase(InstructionServices.getDetails.pending, state => {
      state.instructionDetails = {} as InstructionDetails
    })
    builder.addCase(InstructionServices.getDetails.rejected, state => {
      state.instructionDetails = {} as InstructionDetails
    })
    builder.addCase(InstructionServices.getItemsInBbox.fulfilled, (state, action) => {
      if (state.instructionDetails) {
        state.instructionDetails.boundingBox = action.meta.arg.bbox
      }
    })
    builder.addCase(ProjectionServices.create.fulfilled, (state, action) => {
      state.projectionsList.push(action.payload)
    })
    builder.addCase(ProjectionServices.update.fulfilled, (state, action) => {
      state.projectionsList = state.projectionsList.map(projection => (
        projection.id === action.payload.id
          ? action.payload
          : projection
      ))
    })
    builder.addCase(ProjectionServices.delete.fulfilled, (state, action) => {
      state.loading = false
      state.projectionsList = state.projectionsList.filter(projection => projection.slug !== action.meta.arg)
    })
    builder.addCase(ProjectionServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.projectionsList = action.payload
    })
    builder.addCase(UserServices.getGroups.fulfilled, (state, action) => {
      state.groups = action.payload
    })
    builder.addCase(UserServices.getUsersListInfo.fulfilled, (state, action) => {
      state.usersInfo.push(...action.payload)
    })
    builder.addCase(InstructionServices.update.fulfilled, (state, action) => {
      if (!action.meta.arg.async) {
        const payload = action.payload as Instruction
        state.loading = false
        if (action.meta.arg.id === state.instructionDetails.id) {
          state.instructionDetails = { ...state.instructionDetails, ...payload }
        }
        state.instructionsList = state.instructionsList.map(instruction => (
          instruction.id === payload.id
            ? payload
            : instruction
        ))
        state.openInstructionsList = state.openInstructionsList.map(instruction => (
          instruction.id === payload.id
            ? payload
            : instruction
        ))
        return
      }
      const payload = action.payload as AsyncTask
      state.fetchAsyncId = payload.midiObjectProcessResult
    })
    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (state, action) => {
      state.asyncTask = action.payload
      if (action.payload.taskName === TaskType.updateInstructionDate
        && action.payload.taskStatus !== TaskStatus.PENDING) {
        state.loading = false
      }
    })

    builder.addMatcher(isAnyOf(
      InstructionServices.getAll.pending, InstructionServices.delete.pending,
    ), state => {
      state.loading = true
    })
    builder.addMatcher(isAnyOf(
      InstructionServices.addUsers.fulfilled, InstructionServices.addItems.fulfilled,
    ), (state, action) => {
      if (action.meta.arg.id === state.instructionDetails.id) {
        state.instructionDetails = { ...state.instructionDetails, ...action.payload }
      }
      state.instructionsList = state.instructionsList.map(instruction => (
        instruction.id === action.payload.id
          ? action.payload
          : instruction
      ))
      state.openInstructionsList = state.openInstructionsList.map(instruction => (
        instruction.id === action.payload.id
          ? action.payload
          : instruction
      ))
    })
  },
})

export const {
  openInstruction,
  closeInstruction,
  setActiveTab,
  resetInstructions,
  resetInstructionDetails,
  resetOpenInstruction,
} = adminSlice.actions

export default adminSlice.reducer
