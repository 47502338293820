import { DateTime } from 'luxon'
import LineServices from 'objects/Lines/LineServices'
import { Attribute, InputTypes } from 'objects/attributes'
import Parser from 'objects/parser'
import { MidiObject } from 'objects/types'
import { TrackTypeMap } from 'objects/types/const'
import { formatLineChoice, formatTrackChoice } from 'objects/utils'

const TRACK_SECTION_ATTRIBUTES = (item?: MidiObject,
  defaultValues?: {[key: string]: string | undefined}): Array<Attribute> => ([
  {
    key: 'startKp',
    path: ['startKp'],
    label: 'Attributes.common.startKp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'endKp',
    path: ['endKp'],
    label: 'Attributes.common.endKp',
    parser: Parser.identity,
    isEditable: true,
    required: true,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'track',
    path: ['track', 'line'],
    label: 'Attributes.Track.lineCode',
    updatePath: ['track', 'line'],
    parser: Parser.line,
    isEditable: true,
    type: InputTypes.Autocomplete,
    autoCompleteParams: {
      key: 'lines',
      displayedValueFormatter: formatLineChoice,
      source: LineServices.getAll,
      dependance: 'tracks',
    },
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'track',
    path: ['track'],
    label: 'Attributes.common.track',
    parser: Parser.track,
    isEditable: true,
    required: true,
    type: InputTypes.Autocomplete,
    autoCompleteParams: {
      key: 'tracks',
      displayedValueFormatter: formatTrackChoice,
      source: LineServices.getTracks,
    },
    creation: {
      required: true,
      defaultValue: '',
    },
    shouldBeDisabled: {
      path: ['track', 'line', 'lineCode'],
      values: [undefined, ''],
    },
  },
  {
    key: 'type',
    path: ['track', 'trackType'],
    label: 'Attributes.Track.type',
    parser: Parser.trackType,
    isEditable: true,
    required: true,
    type: InputTypes.Select,
    values: TrackTypeMap,
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'activityStartDate',
    path: 'activityStartDate',
    label: 'Attributes.common.activityStartDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    creation: {
      required: true,
      defaultValue: defaultValues?.activityStartDate || DateTime.now().toISODate(),
    },
  },
  {
    key: 'activityEndDate',
    path: 'activityEndDate',
    label: item?.deletedAt ? 'Attributes.common.activityEndDateExclude'
      : 'Attributes.common.activityEndVersionDate',
    parser: Parser.date,
    isEditable: false,
    required: true,
    type: InputTypes.DatePicker,
    checkboxLabel: 'Attributes.common.activityEndDateCheckbox',
    creation: {
      required: true,
      defaultValue: null,
    },
  },
  {
    key: 'gaiaId',
    path: 'gaiaId',
    isEditable: false,
    label: 'Attributes.common.gaiaId',
    parser: Parser.identity,
  },
])

export default TRACK_SECTION_ATTRIBUTES
