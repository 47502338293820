import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Divider from '@mui/material/Divider'

import { RootState } from 'Store'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import Attributes from 'components/Panels/DetailsPanel/Attributes'
import { TrackSection } from 'objects/types'
import { enableGeoEditor } from 'reducers/geoEditor'
import { MapState } from 'reducers/map'

import Instructions from 'objects/common/Instructions/Instructions'
import { AttributesProps } from 'objects/types/const'
import TRACK_SECTION_ATTRIBUTES from './TrackSectionAttributes'

export default function TrackSectionDetails({ object }: AttributesProps): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { centeredFeature } = useSelector((state: RootState): MapState => state.map)
  const [hasGeom, setHasGeom] = useState<boolean | undefined>(true)
  const trackSection = object as TrackSection

  useEffect(() => {
    setHasGeom(centeredFeature && centeredFeature.geometry && centeredFeature.geometry.type !== null)
  }, [centeredFeature])

  return (
    <div id="track-section-details" className="object-attributes">
      <Attributes item={trackSection as TrackSection} attributes={TRACK_SECTION_ATTRIBUTES(trackSection)} />

      <Divider className="my-2" />
      {!hasGeom && (
        <>

          <div className="my-3 mx-4">
            <SimpleButton
              title={t('Details.button.createGeom')}
              onClick={() => {
                dispatch(enableGeoEditor())
              }}
              style={ButtonStyle.light}
            />
          </div>
        </>
      )}
      <div id="linked-objects">
        <Instructions item={trackSection as TrackSection} />
      </div>
    </div>
  )
}
