import { Attribute, InputTypes, LAYER_NAME } from 'objects/attributes'
import Parser from 'objects/parser'
import {
  KPOrientation, KPOrientationMap, ObjectLayer,
  OrphanExtremityTypeMap,
} from 'objects/types/const'
import {
  formatExtremityIsolatorChoice, formatExtremitySignalChoice,
  formatIdentityChoice,
} from 'objects/utils'

export enum ExtremityType {
  orphan = 'orphan',
  signal = 'signal',
  isolator = 'isolator',
}

const EXTREMITY_PARAMS = {
  [ExtremityType.orphan]: {
    updateParams: [
      {
        clickedObjectPath: 'trackName',
        path: ['kilometricPoints', '0', 'trackSection', 'track', 'name'],
      },
      {
        clickedObjectPath: 'mainRepresentation',
        path: ['location', 'trackSection', 'track', 'name'],
      },
      {
        clickedObjectPath: 'id',
        path: ['location', 'trackSection', 'id'],
      },
      {
        clickedObjectPath: 'mainRepresentation',
        path: ['location', 'mainRepresentation'],
      },
      {
        clickedObjectPath: 'id',
        path: ['location', 'trackSection'],
      },
      {
        clickedObjectPath: LAYER_NAME,
        path: LAYER_NAME,
      },
    ],
    objectLayer: ObjectLayer.TrackSection,
    displayedValueFormatter: formatIdentityChoice,
  },
  [ExtremityType.signal]: {
    updateParams: [
      {
        clickedObjectPath: 'mainRepresentation',
        path: ['location', 'mainRepresentation'],
      },
      {
        clickedObjectPath: 'kilometricPoints',
        path: ['kilometricPoints'],
      },
      {
        clickedObjectPath: 'id',
        path: ['extremityObject', 'id'],
      },
      {
        clickedObjectPath: 'extremityType',
        path: ['extremityObject', 'extremityType'],
      },
      {
        clickedObjectPath: LAYER_NAME,
        path: LAYER_NAME,
      },
    ],
    objectLayer: ObjectLayer.Signal,
    displayedValueFormatter: formatExtremitySignalChoice,
  },
  [ExtremityType.isolator]: {
    updateParams: [
      {
        clickedObjectPath: 'mainRepresentation',
        path: ['location', 'mainRepresentation'],
      },
      {
        clickedObjectPath: 'id',
        path: ['extremityObject', 'id'],
      },
      {
        clickedObjectPath: 'kilometricPoint',
        path: ['kilometricPoints', '0'],
      },
      {
        clickedObjectPath: 'extremityType',
        path: ['extremityObject', 'extremityType'],
      },
      {
        clickedObjectPath: LAYER_NAME,
        path: LAYER_NAME,
      },
    ],
    objectLayer: ObjectLayer.Isolator,
    displayedValueFormatter: formatExtremityIsolatorChoice,
  },
}

const getCaptureClickHelper = (extremity: boolean): string => (extremity
  ? 'Modification.helperText.orphanIsolatorSignalCaptureClick'
  : 'Modification.helperText.orphanCaptureClick')

const getExtremityAttributes = (
  extremityTypes: ExtremityType[], hasOrientation = true, hasExtremityType = false,
): Array<Attribute> => [
  {
    key: 'extremity',
    path: ['location', 'mainRepresentation'],
    updatePath: ['location', 'mainRepresentation'],
    label: hasExtremityType ? 'Attributes.common.object' : 'Attributes.common.trackSection',
    isEditable: true,
    parser: Parser.identity,
    type: InputTypes.CaptureClick,
    creation: {
      required: true,
      defaultValue: '',
    },
    captureClickHelper: getCaptureClickHelper(hasExtremityType),
    captureClickParams: extremityTypes.map(t => EXTREMITY_PARAMS[t]),
    captureOnOpen: false,
  },
  {
    key: 'kilometricPoints',
    path: 'kilometricPoints',
    label: 'Attributes.common.kp',
    parser: Parser.identity,
    isEditable: true,
    isArray: true,
    hideHeader: true,
    hideBottomDivider: true,
    groupTitle: 'Attributes.Signal.positions',
    shouldBeDisabled: {
      path: LAYER_NAME,
      values: [ObjectLayer.Isolator, ObjectLayer.Signal, undefined],
    },
    elements: [
      {
        key: 'trackName',
        path: ['trackSection', 'track', 'name'],
        updatePath: ['location', 'trackSection', 'track', 'name'],
        label: 'Attributes.common.track',
        parser: Parser.identity,
        isEditable: false,
        required: true,
        creation: {
          required: true,
          defaultValue: '',
        },
        shouldBeDisabled: {
          path: LAYER_NAME,
          values: [ObjectLayer.Isolator, ObjectLayer.Signal, ObjectLayer.TrackSection, undefined],
        },
      },
      {
        key: 'kp',
        path: 'value',
        updatePath: ['kilometricPoints', '0', 'value'],
        isEditable: true,
        label: 'Attributes.common.kp',
        parser: Parser.identity,
      },
    ],
    defaultElement: {},
    required: true,
    creation: {
      required: true,
      defaultValue: [{}],
    },
  },
  {
    key: 'extremityType',
    path: 'extremityObject',
    updatePath: ['extremityObject', 'extremityType'],
    label: 'Attributes.common.type',
    isEditable: hasExtremityType,
    parser: Parser.identity,
    type: InputTypes.Select,
    values: OrphanExtremityTypeMap,
    shouldBeDisabled: {
      path: LAYER_NAME,
      values: [ObjectLayer.Isolator, ObjectLayer.Signal, undefined],
    },
    creation: {
      required: true,
      defaultValue: '',
    },
  },
  {
    key: 'direction',
    path: 'direction',
    label: 'Attributes.common.orientation',
    isEditable: hasOrientation,
    parser: Parser.identity,
    type: InputTypes.Select,
    values: KPOrientationMap,
    creation: {
      required: true,
      defaultValue: KPOrientation.ASC,
    },
  },
]

export default getExtremityAttributes
