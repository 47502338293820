import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'

import ArrowForward from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'

import { ReactComponent as GroupIcon } from 'assets/icons/group.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as MapIcon } from 'assets/icons/map.svg'
import FeedbackSnackBar from 'components/Common/FeedbackSnackBar/FeedbackSnackBar'
import getCSSValue from 'helpers'

import { RootState } from 'Store'
import { TaskStatus, TaskType } from 'objects/types/instructions'
import { useDispatch, useSelector } from 'react-redux'
import { InstructionState, resetTask } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import ObjectServices from 'services/ObjectServices'
import Attributions from './Attributions'
import GeneralInfo from './GeneralInfo'
import NewPerimeter from './NewPerimeter'
import './Parameters.scss'

const TABS = (displayNewPerimeter: (show: boolean) => void) => [
  {
    key: 0,
    title: 'Instruction.dashboard.parameters.generalInfo',
    icon: InfoIcon,
    component: (
      <GeneralInfo />
    ),
  },
  {
    key: 1,
    title: 'Instruction.dashboard.parameters.perimeter',
    icon: MapIcon,
    component: (
      <NewPerimeter onClose={() => displayNewPerimeter(false)} />
    ),
  },
  {
    key: 2,
    title: 'Instruction.dashboard.parameters.attributions',
    icon: GroupIcon,
    component: (
      <Attributions />
    ),
  },
]

type Props = {
  onClose: () => void;
}

export default function Parameters({ onClose }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { asyncTask, fetchAsyncId } = useSelector((state: RootState) => state.admin)
  const [selectedTab, setSelectedTab] = useState(0)
  const [showNewPerimeterFlow, setShowNewPerimeterFlow] = useState(false)
  const { instruction } = useSelector(((state: RootState) => state.instruction as
  InstructionState))
  const fetchStatus = useRef<NodeJS.Timer | NodeJS.Timeout>()

  useEffect(() => {
    setShowNewPerimeterFlow(selectedTab === 1)
  }, [selectedTab])

  useEffect(() => {
    if (!showNewPerimeterFlow) setSelectedTab(0)
  }, [showNewPerimeterFlow])

  const clearFetchInterval = () => {
    if (asyncTask.taskStatus !== TaskStatus.PENDING && asyncTask.taskName === TaskType.updateInstructionDate) {
      if (asyncTask.taskStatus === TaskStatus.FAILURE) {
        dispatch(InstructionServices.getDetails(instruction.id || ''))
      } else {
        dispatch(InstructionServices.getDetails(asyncTask.objectId))
      }
    }
    dispatch(resetTask())
    clearInterval(fetchStatus.current as NodeJS.Timeout)
    fetchStatus.current = undefined
  }

  const checkStatus = () => {
    if (fetchStatus.current === undefined) {
      fetchStatus.current = (setInterval(() => dispatch(ObjectServices.getTaskStatus(fetchAsyncId)),
        2000))
      return
    }

    if (asyncTask.taskStatus !== TaskStatus.PENDING) { clearFetchInterval() }
  }

  useEffect(() => {
    if (asyncTask.id && fetchAsyncId) {
      checkStatus()
    }
  }, [asyncTask])

  useEffect(() => {
    if (fetchAsyncId) {
      checkStatus()
    }
  }, [fetchAsyncId])

  return (
    <>
      <div className="instruction-parameters">
        <Paper className="content-wrapper">
          <div className="title-wrapper">
            <div className="title">
              {t('Instruction.dashboard.parameters.title')}
            </div>
            <div>
              <Button variant="outlined" endIcon={<ArrowForward />} onClick={onClose} className="return-button">
                <span>{t('Instruction.dashboard.parameters.close')}</span>
              </Button>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            {TABS(setShowNewPerimeterFlow).map((tab, i) => (
              <Button
                key={tab.key}
                className={`tab ${i === selectedTab ? 'selected' : ''}`}
                onClick={() => setSelectedTab(i)}
              >
                <div className="icon-wrapper">
                  <tab.icon fill={i === selectedTab ? 'white' : getCSSValue('--color-blue-500')} />
                </div>
                <div className="title">
                  {t(tab.title)}
                </div>
              </Button>
            ))}
          </div>
          <div className="content">
            {TABS(setShowNewPerimeterFlow)[selectedTab].component}
          </div>
        </Paper>
      </div>
      <FeedbackSnackBar />
    </>
  )
}
