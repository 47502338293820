import { isAnyOf } from '@reduxjs/toolkit'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'

export const updateEPGLinkedObjectsErrorMatcher = isAnyOf(
  ElectricalProtectionGroupServices.deleteExtremity.rejected,
  ElectricalProtectionGroupServices.switchExtremityDirection.rejected,
  ElectricalProtectionGroupServices.addResponsibleSubArea.rejected,
  ElectricalProtectionGroupServices.deleteResponsibleSubArea.rejected,
  ElectricalProtectionGroupServices.deleteElectricalElement.rejected,
  ElectricalProtectionGroupServices.addAssociatedSubArea.rejected,
  ElectricalProtectionGroupServices.deleteAssociatedSubArea.rejected,
  ElectricalProtectionGroupServices.addIncompatibleObject.rejected,
  ElectricalProtectionGroupServices.deleteIncompatibleObject.rejected,
  ElectricalProtectionGroupServices.addElectricalElement.rejected,
  ElectricalProtectionGroupServices.deleteElements.rejected,
)

export const updateEPGLinkedObjectsFulfilledMatcher = isAnyOf(
  ElectricalProtectionGroupServices.deleteExtremity.fulfilled,
  ElectricalProtectionGroupServices.switchExtremityDirection.fulfilled,
  ElectricalProtectionGroupServices.addResponsibleSubArea.fulfilled,
  ElectricalProtectionGroupServices.deleteResponsibleSubArea.fulfilled,
  ElectricalProtectionGroupServices.deleteElectricalElement.fulfilled,
  ElectricalProtectionGroupServices.addAssociatedSubArea.fulfilled,
  ElectricalProtectionGroupServices.deleteAssociatedSubArea.fulfilled,
  ElectricalProtectionGroupServices.addIncompatibleObject.fulfilled,
  ElectricalProtectionGroupServices.deleteIncompatibleObject.fulfilled,
  ElectricalProtectionGroupServices.addElectricalElement.fulfilled,
  ElectricalProtectionGroupServices.deleteElements.fulfilled,
)

export const updateEPGLinkedObjectsPendingMatcher = isAnyOf(
  ElectricalProtectionGroupServices.deleteExtremity.pending,
  ElectricalProtectionGroupServices.switchExtremityDirection.pending,
  ElectricalProtectionGroupServices.addResponsibleSubArea.pending,
  ElectricalProtectionGroupServices.deleteResponsibleSubArea.pending,
  ElectricalProtectionGroupServices.deleteElectricalElement.pending,
  ElectricalProtectionGroupServices.addAssociatedSubArea.pending,
  ElectricalProtectionGroupServices.deleteAssociatedSubArea.pending,
  ElectricalProtectionGroupServices.addIncompatibleObject.pending,
  ElectricalProtectionGroupServices.deleteIncompatibleObject.pending,
  ElectricalProtectionGroupServices.addElectricalElement.pending,
  ElectricalProtectionGroupServices.deleteElements.pending,
)
