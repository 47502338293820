import { MidiObject } from '.'

export enum ITEM_TYPES {
  electricalelement = 'electricalelement',
  electricalprotectiongroup = 'electricalprotectiongroup',
  feeder = 'feeder',
  tracksection = 'tracksection',
  isolator = 'isolator',
  sector = 'sector',
  subsector = 'subsector',
  signal = 'signal',
  tracknode = 'tracknode',
  trackprotection = 'trackprotection',
  trackprotectiongroup = 'trackprotectiongroup',
  tunnel = 'tunnel'
}

export enum ObjectKind {
  ElectricalElement = 'ElectricalElement',
  ElectricalProtectionGroup = 'ElectricalProtectionGroup',
  Feeder = 'Feeder',
  TrackSection = 'TrackSection',
  Isolator = 'Isolator',
  Sector = 'Sector',
  SubSector = 'SubSector',
  Signal = 'Signal',
  TrackProtection = 'TrackProtection',
  TrackProtectionGroup = 'TrackProtectionGroup',
  TrackNode = 'TrackNode',
  Tunnel = 'Tunnel'
}

type URIKind = {
  [key in ITEM_TYPES]: ObjectKind;
}

export const URIObjectKind: URIKind = {
  [ITEM_TYPES.electricalelement]: ObjectKind.ElectricalElement,
  [ITEM_TYPES.electricalprotectiongroup]: ObjectKind.ElectricalProtectionGroup,
  [ITEM_TYPES.feeder]: ObjectKind.Feeder,
  [ITEM_TYPES.tracksection]: ObjectKind.TrackSection,
  [ITEM_TYPES.isolator]: ObjectKind.Isolator,
  [ITEM_TYPES.sector]: ObjectKind.Sector,
  [ITEM_TYPES.subsector]: ObjectKind.SubSector,
  [ITEM_TYPES.signal]: ObjectKind.Signal,
  [ITEM_TYPES.tracknode]: ObjectKind.TrackNode,
  [ITEM_TYPES.trackprotection]: ObjectKind.TrackProtection,
  [ITEM_TYPES.trackprotectiongroup]: ObjectKind.TrackProtectionGroup,
  [ITEM_TYPES.tunnel]: ObjectKind.Tunnel,

}

export enum ExtraKind {
  EEExtremity = 'EEExtremity',
  EPGExtremity = 'EPGExtremity',
  FeederExtremity = 'FeederExtremity',
  TPExtremity = 'TPExtremity',
  TPGExtremity = 'TPGExtremity',
  SectorExtremity = 'SectorExtremity',
  SSExtremity = 'SSExtremity'
}

export enum ObjectLayer {
  ElectricalElement = 'midi_ve_electrical_element',
  ElectricalProtectionGroup = 'midi_ve_electrical_protection_group',
  Feeder = 'midi_ve_feeder',
  Isolator = 'midi_ve_isolator',
  Sector = 'midi_ve_sector',
  SubSector = 'midi_ve_sub_sector',
  Signal = 'midi_ve_signal',
  TrackProtection = 'midi_ve_track_protection',
  TrackProtectionGroup = 'midi_ve_track_protection_group',
  TrackSection = 'midi_ve_track_section',
  TrackNode = 'midi_ve_track_node',
  Tunnel = 'midi_ve_tunnel'
}

export enum ITEM_LAYER {
  electricalelement = ObjectLayer.ElectricalElement,
  electricalprotectiongroup = ObjectLayer.ElectricalProtectionGroup,
  feeder = ObjectLayer.Feeder,
  tracksection = ObjectLayer.TrackSection,
  isolator = ObjectLayer.Isolator,
  sector = ObjectLayer.Sector,
  subsector = ObjectLayer.SubSector,
  signal = ObjectLayer.Signal,
  trackprotection = ObjectLayer.TrackProtection,
  trackprotectiongroup = ObjectLayer.TrackProtectionGroup,
  tracknode = ObjectLayer.TrackNode,
  tunnel = ObjectLayer.Tunnel
}

export const NULL_STRING = 'nullString'

export enum ObjectStatus {
  TODO = 0,
  IN_PROGRESS = 10,
  VALIDATED = 20,
  VERIFIED = 30,
  DISPATCHED = 40,
}

export enum KPOrientation {
  ASC = 'C',
  DESC = 'D',
}

export enum UserKPOrientation {
  ASC = 'Croissant',
  DESC = 'Décroissant',
}

export const KPOrientationMap = [
  {
    label: UserKPOrientation.ASC,
    value: KPOrientation.ASC,
  },
  {
    label: UserKPOrientation.DESC,
    value: KPOrientation.DESC,
  },
]

export enum OrphanExtremityType {
  CHEVRON = 'CHEVRON',
  PANCARTE = 'PANCARTE',
  TAQUET = 'TAQUET',
  GARAGE = 'GARAGE',
  POINTE = 'POINTE',
  HEURTOIR = 'HEURTOIR',
  AUTRE = 'AUTRE',
  SIGNAL = 'SIGNAL',
  JOINT = 'JOINT',
}

export enum UserOrphanExtremityType {
  CHEVRON = 'Chevron',
  PANCARTE = 'Pancarte',
  TAQUET = 'Taquet dérailleur',
  GARAGE = 'Garage franc',
  POINTE = 'Pointe d\'aiguille',
  HEURTOIR = 'Heurtoir',
  AUTRE = 'Autre',
  SIGNAL = 'Signal',
  JOINT = 'Joint isolant',
}

export const OrphanExtremityTypeMap = [
  {
    label: UserOrphanExtremityType.CHEVRON,
    value: OrphanExtremityType.CHEVRON,
  },
  {
    label: UserOrphanExtremityType.PANCARTE,
    value: OrphanExtremityType.PANCARTE,
  },
  {
    label: UserOrphanExtremityType.TAQUET,
    value: OrphanExtremityType.TAQUET,
  },
  {
    label: UserOrphanExtremityType.GARAGE,
    value: OrphanExtremityType.GARAGE,
  },
  {
    label: UserOrphanExtremityType.POINTE,
    value: OrphanExtremityType.POINTE,
  },
  {
    label: UserOrphanExtremityType.HEURTOIR,
    value: OrphanExtremityType.HEURTOIR,
  },
  {
    label: UserOrphanExtremityType.AUTRE,
    value: OrphanExtremityType.AUTRE,
  },
  {
    label: UserOrphanExtremityType.SIGNAL,
    value: OrphanExtremityType.SIGNAL,
  },
  {
    label: UserOrphanExtremityType.JOINT,
    value: OrphanExtremityType.JOINT,
  },
]

export enum UserTrackType {
  MAIN = 'VP',
  SERV = 'VS',
}

export enum TrackType {
  MAIN = 'VP',
  SERV = 'VS',
}

export const TrackTypeMap = [
  {
    label: UserTrackType.MAIN,
    value: TrackType.MAIN,
  },
  {
    label: UserTrackType.SERV,
    value: TrackType.SERV,
  },
]

export enum UserTrackNode {
  AS = 'Aiguille simple',
  AC = 'Aiguille conjuguée',
  TJS = 'Traversée jonction simple',
  TJD = 'Traversée jonction double'
}

export enum TrackNodeType {
  AS = 'AS',
  AC = 'AC',
  TJS = 'TJS',
  TJD = 'TJD'
}

export const TrackNodeTypeMap = [
  {
    label: UserTrackNode.AS,
    value: TrackNodeType.AS,
  },
  {
    label: UserTrackNode.AC,
    value: TrackNodeType.AC,
  },
  {
    label: UserTrackNode.TJS,
    value: TrackNodeType.TJS,
  },
  {
    label: UserTrackNode.TJD,
    value: TrackNodeType.TJD,
  },
]

export enum UserDirectionType {
  ASC = 'Croissant',
  DES = 'Décroissant',
}

export enum DirectionType {
  ASC = 'C',
  DES = 'D',
}

export const DirectionTypeMap = [
  {
    label: UserDirectionType.ASC,
    value: DirectionType.ASC,
  },
  {
    label: UserDirectionType.DES,
    value: DirectionType.DES,
  },
]

export enum ExtraLayer {
  Direction = 'directions',
  Station = 'station'
}

export const StationLayer = 'midi_ve_station'

export const ExtraLayerSource = {
  [ExtraLayer.Direction]: ObjectLayer.TrackSection,
  [ExtraLayer.Station]: StationLayer,
}

export type AttributesProps = {
  object: MidiObject;
}
