export const ALL_SIGNAL_TYPES = [
  'CARRE',
  'ATC',
  'SG HEURT',
  'CV',
  'GA',
  'D',
  'REP TGV',
  'SIG FICTIF',
]

export const BIG_SIGNAL_ICONS = [
  'ATC',
  'BP DIS',
  'CAB E',
  'CHEVRON BAS',
  'CAB R',
  'CAB S',
  'CC EXE',
  'CC FIN',
  'DD',
  'DEPOT',
  'G',
  'GA', // A tester en petit
  'GABARIT',
  'LGR',
  'LIMITETS',
  'LM',
  'R',
  'REP TGV',
  'REV',
  'SIFFLER',
  'Z',
]

export const EXISTING_SIGNAL_PANELS = [
  'A',
  'ATC',
  'BP DIS',
  'CAB E',
  'CAB R',
  'CAB S',
  'CARRE',
  'CC EXE',
  'CC FIN',
  'CHEVRON BAS',
  'CHEVRON HAUT',
  'CV',
  'D',
  'DD',
  'DEPOT',
  'G',
  'GA',
  'GABARIT',
  'ID',
  'JAL ARR E',
  'JAL ARR',
  'JAL MAN',
  'LGR',
  'LIMITETS',
  'LM',
  'M Cv',
  'R',
  'REP TGV',
  'REV',
  'S',
  'SIFFLER',
  'TECS D',
  'TECS G',
  'TSCS D',
  'TSCS G',
  'VIDE',
  'VIDEN',
  'VL A M M S Cv',
  'VL A M S Cv',
  'VL A RR S S C',
  'VL A S C',
  'VL A S S C',
  'VL A',
  'VL R A S C',
  'VL S',
  'Z',
]
