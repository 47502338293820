import { Layer, Source } from 'react-map-gl'
import { ReactElement } from 'react'

import { MapIconNames } from 'components/Map/icons'
import { ExtraLayer, ExtraLayerSource } from 'objects/types/const'
import { formatLayerUrl } from 'objects/utils'

const sourceLayer = ExtraLayerSource[ExtraLayer.Direction]

type Props = {
  visible: boolean;
}
export default function DirectionsLayer({ visible }: Props): ReactElement {
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={ExtraLayer.Direction}
    >
      <Layer
        id={`${ExtraLayer.Direction}-layer`}
        type="symbol"
        layout={{
          'symbol-placement': 'line',
          'symbol-spacing': 400,
          'icon-allow-overlap': true,
          'icon-rotate': 45,
          'icon-rotation-alignment': 'map',
          'icon-size': ['step', ['zoom'],
            0, 11,
            0.2,
          ],
          'icon-image': ['case',
            ['!=', ['get', 'deletedAt'], null], MapIconNames.deleted,
            ['==', ['get', 'trackType'], 'VP'], MapIconNames.main,
            MapIconNames.serv,
          ],
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{}}
        source-layer={sourceLayer}
      />
    </Source>
  )
}
