import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import AreaServices from 'services/AreaServices'

import { isAnyOf } from '@reduxjs/toolkit'
import ProjectionServices from 'services/ProjectionServices'
import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'

export const createErrorMatcher = isAnyOf(
  AreaServices.addSubArea.rejected,
  AreaServices.create.rejected,
  ElectricalElementServices.create.rejected,
  ElectricalProtectionGroupServices.create.rejected,
  FeederServices.create.rejected,
  IsolatorServices.create.rejected,
  SectorServices.create.rejected,
  SignalServices.create.rejected,
  SubSectorServices.create.rejected,
  TrackProtectionGroupServices.create.rejected,
  TrackProtectionServices.create.rejected,
  TrackSectionServices.create.rejected,
  TrackNodeServices.create.rejected,
  TunnelServices.create.rejected,
)

// Omitting objects for which we don't display a details panel
export const createFulfilledDisplayedObjMatcher = isAnyOf(
  ElectricalElementServices.create.fulfilled,
  ElectricalProtectionGroupServices.create.fulfilled,
  FeederServices.create.fulfilled,
  IsolatorServices.create.fulfilled,
  SectorServices.create.fulfilled,
  SignalServices.create.fulfilled,
  SubSectorServices.create.fulfilled,
  TrackProtectionServices.create.fulfilled,
  TrackProtectionGroupServices.create.fulfilled,
  TrackSectionServices.create.fulfilled,
  TrackNodeServices.create.fulfilled,
  TunnelServices.create.fulfilled,
)

export const createFulfilledMatcher = isAnyOf(
  AreaServices.addSubArea.fulfilled,
  AreaServices.create.fulfilled,
  createFulfilledDisplayedObjMatcher,
  ProjectionServices.create.fulfilled,
)

export const createPendingMatcher = isAnyOf(
  ElectricalElementServices.create.pending,
  ElectricalProtectionGroupServices.create.pending,
  FeederServices.create.pending,
  IsolatorServices.create.pending,
  SectorServices.create.pending,
  SignalServices.create.pending,
  ProjectionServices.create.pending,
  SubSectorServices.create.pending,
  TrackProtectionGroupServices.create.pending,
  TrackProtectionServices.create.pending,
  TrackSectionServices.create.pending,
  TrackNodeServices.create.pending,
  TunnelServices.create.pending,
)

export const projectionCreationError = ProjectionServices.create.rejected
