import { getTrackSectionTitle } from 'components/Map/popupUtils'
import { findObjectKind } from 'objects/kind'
import {
  Isolator,
  ShortMidiObject,
  Signal,
  TrackSection,
} from 'objects/types'
import { ObjectKind } from 'objects/types/const'
import { Protection, TrackProtection } from 'objects/types/protections'
import { FuncStrToStr } from 'types'

const getIsolatorText = (item: Isolator) => `Joint de Zone ${item.kilometricPoint?.trackSection?.track?.name || ''}`
const getSignalText = (item: Signal) => `${item.type} ${item.label}`
const getTrackProtectionText = (item: TrackProtection) => (item.label.includes('ZEP')
  ? item.label
  : `ZEP ${item.label}`)
const getProtectionText = (item: Protection) => item.label

export const getMainText = (item: ShortMidiObject, t: FuncStrToStr): string => {
  if (item?.mainRepresentation) return item?.mainRepresentation
  switch (findObjectKind(item)) {
    case ObjectKind.TrackSection:
      return getTrackSectionTitle(item as TrackSection, t)
    case ObjectKind.Isolator:
      return getIsolatorText(item as Isolator)
    case ObjectKind.Signal:
      return getSignalText(item as Signal)
    case ObjectKind.TrackProtection:
      return getTrackProtectionText(item as TrackProtection)
    case ObjectKind.TrackNode:
      return t('Object.type.trackNode')
    case ObjectKind.Tunnel:
      return ('Object.type.tunnel')
    case ObjectKind.ElectricalElement:
    case ObjectKind.ElectricalProtectionGroup:
    case ObjectKind.Feeder:
    case ObjectKind.Sector:
    case ObjectKind.SubSector:
    case ObjectKind.TrackProtectionGroup:
      return getProtectionText(item as Protection)
    default:
      return ''
  }
}

export const getSubText = (item: ShortMidiObject): string => {
  if (item?.secondaryRepresentation) return item?.secondaryRepresentation
  switch (findObjectKind(item)) {
    case ObjectKind.Feeder:
      return 'Object.type.feeder'
    case ObjectKind.TrackSection:
      return 'Object.type.trackSection'
    case ObjectKind.Isolator:
      return 'Object.type.isolator'
    case ObjectKind.Signal:
      return 'Object.type.signal'
    case ObjectKind.TrackProtection:
      return 'Object.type.trackProtection'
    case ObjectKind.TrackProtectionGroup:
      return 'Object.type.trackProtectionGroup'
    case ObjectKind.Sector:
      return 'Object.type.sector'
    case ObjectKind.SubSector:
      return 'Object.type.subSector'
    case ObjectKind.ElectricalElement:
      return 'Object.type.electricalElement'
    case ObjectKind.ElectricalProtectionGroup:
      return 'Object.type.electricalProtectionGroup'
    case ObjectKind.TrackNode:
      return 'Object.type.trackNode'
    case ObjectKind.Tunnel:
      return 'Object.type.tunnel'
    default:
      return ''
  }
}
