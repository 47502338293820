import { ReactElement, SyntheticEvent } from 'react'
import { useSelector } from 'react-redux'

import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'

import { RootState } from 'Store'
import Loader from 'components/Common/Loader'
import TabsMenu from 'components/Common/TabsMenu/TabsMenu'
import { loggedAsSupervisor } from 'helpers/permissions'
import { MidiObject } from 'objects/types'
import { ObjectKind } from 'objects/types/const'
import { DetailsPanelState } from 'reducers/panels/detailsPanel'
import DetailsMergeForm from './DetailsMergeForm/DetailsMergeForm'
import './DetailsPanelContent.scss'
import DetailsTitle from './DetailsTitle'
import HistoryModal from './HistoryModal'
import { DetailPanelTab, TabContentOfKind } from './utils'

type Props = {
  kind: ObjectKind;
  value: string;
  tabs: DetailPanelTab[];
  handleTabChange: (e: SyntheticEvent, v: string) => void;
}

export default function DetailsPanelContent({
  kind, value, tabs, handleTabChange,
}: Props): ReactElement {
  const {
    isLoading, displayHistory, item, objectToMerge, revertLoading,
  } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)

  const usedKind = kind

  return (
    <>
      {displayHistory && <HistoryModal kind={kind} loading={revertLoading} />}
      {isLoading && !displayHistory && !objectToMerge.id && <Loader overlay />}
      <div className="padded">
        <DetailsTitle />

        {!loggedAsSupervisor() && (<DetailsMergeForm />)}

      </div>
      <div id="details-panel-content" className={`d-flex flex-column ${objectToMerge.id ? 'blur' : ''}`}>
        <TabContext value={value}>
          <TabsMenu tabs={tabs} handleTabChange={handleTabChange} />
          {tabs.map(tab => (
            <TabPanel
              key={tab.key}
              value={tab.value}
              className="tab-panel flex-column"
            >
              {TabContentOfKind[usedKind][tab.key](item as MidiObject)}
            </TabPanel>
          ))}
        </TabContext>
      </div>
    </>
  )
}
