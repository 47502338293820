import { useTranslation } from '@osrdata/app_core/dist/translation'
import get from 'lodash/get'
import { ReactElement, useState } from 'react'

import DeleteSweepIcon from '@mui/icons-material/DeleteSweep'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import editButton from 'assets/icons/edit_button.svg'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import DisabledToolTip from 'components/Common/DisabledTooltip/DisabledTooltip'
import { Attribute } from 'objects/attributes'
import { findObjectKind } from 'objects/kind'
import Parser from 'objects/parser'
import { ResetGaiaServiceOfKind } from 'objects/services'
import { MidiObject } from 'objects/types'
import { useDispatch } from 'react-redux'
import { PanelName } from 'reducers/panels/panel'
import { ObjOfStr, ObjOfStrOrNum, StrOrNum } from 'types'
import PanelNavigator from '../PanelNavigator'
import './Attributes.scss'
import { canEditObject } from './utils'

type Props = {
  attributes: Array<Attribute>;
  item: MidiObject;
}

export default function Attributes({ item, attributes }: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [reset, setReset] = useState(false)
  const onClickModify = () => {
    PanelNavigator.push(PanelName.modification)
  }

  const displayData = (rawData: ObjOfStrOrNum & StrOrNum, att: Attribute) => (att.parser
    ? att.parser(rawData)
    : rawData
  )

  const attributeLine = (o: MidiObject | ObjOfStr, att: Attribute) => {
    const formattedValue = displayData(get(o, att.path), att) as string
    return formattedValue === '' && att.hideIfEmpty
      ? null
      : (
        <ListItem className="d-flex justify-space-between" key={att.key} dense>
          <div className="key" style={{ flex: 1 }}>{t(att.label)}</div>
          <div className="value text-right" style={{ flex: 1 }}>
            {t(formattedValue)}
          </div>
        </ListItem>
      )
  }

  // Going through each array item, each item has its own attributes definition,
  // in the `elements` props of the parent attribute
  const renderNestedArray = (att: Attribute) => {
    if (get(item, att.path) !== undefined) {
      return get(item, att.path).map((el: ObjOfStr) => (
        <>
          {att.elements?.map(nestedAtt => attributeLine(el, nestedAtt))}
          <Divider className="my-2 nested-array-divider" light />
        </>
      ))
    }
    return null
  }

  const toggleReset = () => {
    setReset(prev => !prev)
  }

  const resetGaiaId = () => {
    const kind = findObjectKind(item)
    dispatch(ResetGaiaServiceOfKind[kind](item.id))
  }

  if (!item) return <></>

  return (
    <div className="attributes-container">
      <List className="list">
        {attributes.filter(a => !a.hideDetails).map(att => (att.isArray
          ? renderNestedArray(att)
          : attributeLine(item, att)
        ))}
      </List>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0 last-modification">
          {`Dernière modification le ${Parser.date(item.updateDate)}`}
        </h3>
        {canEditObject() && (
          <>
            <DisabledToolTip
              title={t('Modification.notAllowed')}
              disabled={item.inInstruction}
            >
              <IconButton disabled={!item.inInstruction} onClick={onClickModify} className="modify-button">
                <img src={editButton} alt="edit button" />
              </IconButton>

            </DisabledToolTip>

            <DisabledToolTip
              title={item.inInstruction ? t('Modification.resetGaia') : t('Modification.notAllowed')}
              disabled={false}
            >
              <IconButton
                disabled={!item.inInstruction || !item.gaiaId}
                onClick={toggleReset}
              >
                <DeleteSweepIcon />
              </IconButton>
            </DisabledToolTip>
          </>
        )}

        <ConfirmationDialog
          title={t('Modification.resetGaia')}
          content={t('Modification.resetGaiaConfirm')}
          toggle={toggleReset}
          open={reset}
          actionToConfirm={resetGaiaId}
        />
      </div>
    </div>
  )
}
