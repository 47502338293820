import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Region } from 'objects/types'
import RegionServices from 'services/RegionServices'

export interface RegionState {
  regions: Region[];
  selectedRegion: string;
}

const initialState: RegionState = {
  regions: [],
  selectedRegion: 'all',
}

export const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setSelectedRegion: (state, action: PayloadAction<string>) => {
      state.selectedRegion = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(RegionServices.getAll.fulfilled, (state, { payload }) => {
      state.regions = payload
    })
  },
})

export const {
  setSelectedRegion,
} = regionsSlice.actions

export default regionsSlice.reducer
