import { isAnyOf } from '@reduxjs/toolkit'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'

export const updateEELinkedObjectsErrorMatcher = isAnyOf(
  ElectricalElementServices.deleteExtremity.rejected,
  ElectricalElementServices.switchExtremityDirection.rejected,
  ElectricalElementServices.addResponsibleSubArea.rejected,
  ElectricalElementServices.deleteResponsibleSubArea.rejected,
  ElectricalElementServices.addAssociatedSubArea.rejected,
  ElectricalElementServices.deleteAssociatedSubArea.rejected,
  ElectricalElementServices.addIncompatibleObject.rejected,
  ElectricalElementServices.deleteIncompatibleObject.rejected,
  ElectricalElementServices.deleteElements.rejected,
)

export const updateEELinkedObjectsFulfilledMatcher = isAnyOf(
  ElectricalElementServices.deleteExtremity.fulfilled,
  ElectricalElementServices.switchExtremityDirection.fulfilled,
  ElectricalElementServices.addResponsibleSubArea.fulfilled,
  ElectricalElementServices.deleteResponsibleSubArea.fulfilled,
  ElectricalElementServices.addAssociatedSubArea.fulfilled,
  ElectricalElementServices.deleteAssociatedSubArea.fulfilled,
  ElectricalElementServices.addIncompatibleObject.fulfilled,
  ElectricalElementServices.deleteIncompatibleObject.fulfilled,
  ElectricalElementServices.deleteElements.fulfilled,
)

export const updateEELinkedObjectsPendingMatcher = isAnyOf(
  ElectricalElementServices.deleteExtremity.pending,
  ElectricalElementServices.switchExtremityDirection.pending,
  ElectricalElementServices.addResponsibleSubArea.pending,
  ElectricalElementServices.deleteResponsibleSubArea.pending,
  ElectricalElementServices.addAssociatedSubArea.pending,
  ElectricalElementServices.deleteAssociatedSubArea.pending,
  ElectricalElementServices.addIncompatibleObject.pending,
  ElectricalElementServices.deleteIncompatibleObject.pending,
  ElectricalElementServices.deleteElements.pending,
)
