import { ReactElement } from 'react'

import HomeAdmin from 'views/admin/Home'
import HomeOperator from 'views/operator/Home'
import HomeSupervisor from 'views/supervisor/Home'

export const OPERATOR_HOMEPATH = '/accueil'
export const MANAGER_HOMEPATH = '/redacteur'
export const ADMIN_HOMEPATH = '/approbation'
export const SUPERVISOR_HOMEPATH = '/verification'

export type RouteType = {
  path: string;
  component: () => ReactElement;
}

const MAP_PARAMS = '/:type/:id'

export const OPERATOR_ROUTES: Array<RouteType> = [
  {
    path: OPERATOR_HOMEPATH,
    component: HomeOperator,
  },
  {
    path: `${OPERATOR_HOMEPATH}${MAP_PARAMS}`,
    component: HomeOperator,
  },
]
export const MANAGER_ROUTES: Array<RouteType> = [
  ...OPERATOR_ROUTES,
  {
    path: MANAGER_HOMEPATH,
    component: HomeOperator,
  },
  {
    path: `${MANAGER_HOMEPATH}${MAP_PARAMS}`,
    component: HomeOperator,
  },
]

export const SUPERVISOR_ROUTES = [
  ...OPERATOR_ROUTES,
  {
    path: SUPERVISOR_HOMEPATH,
    component: HomeSupervisor,
  },
  {
    path: `${SUPERVISOR_HOMEPATH}${MAP_PARAMS}`,
    component: HomeSupervisor,
  },
]

export const ADMIN_ROUTES = [
  ...SUPERVISOR_ROUTES,
  ...MANAGER_ROUTES,
  {
    path: ADMIN_HOMEPATH,
    component: HomeAdmin,
  },
]
