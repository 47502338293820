import { SxProps } from '@mui/material'
import { ITEM_TYPES, ObjectLayer } from 'objects/types/const'

export const editButtonStyle: SxProps = {
  fontSize: '12px',
  borderRadius: '23px',
}

export const ALL_FILTER = {
  key: '',
  title: 'Tous',
}

export const OBJECTS_TYPE = [
  {
    key: ITEM_TYPES.tracksection,
    layer: ObjectLayer.TrackSection,
    title: 'Layers.trackSections',
  },
  {
    key: ITEM_TYPES.isolator,
    layer: ObjectLayer.Isolator,
    title: 'Layers.isolators',
  },
  {
    key: ITEM_TYPES.signal,
    layer: ObjectLayer.Signal,
    title: 'Layers.signals',
  },
  {
    key: ITEM_TYPES.trackprotection,
    layer: ObjectLayer.TrackProtection,
    title: 'Layers.trackProtections',
  },
  {
    key: ITEM_TYPES.tracknode,
    layer: ObjectLayer.TrackNode,
    title: 'Layers.trackNodes',
  },
  {
    key: ITEM_TYPES.trackprotectiongroup,
    layer: ObjectLayer.TrackProtectionGroup,
    title: 'Layers.trackProtectionGroups',
  },
  {
    key: ITEM_TYPES.electricalelement,
    layer: ObjectLayer.ElectricalElement,
    title: 'Layers.electricalElements',
  },
  {
    key: ITEM_TYPES.electricalprotectiongroup,
    layer: ObjectLayer.ElectricalProtectionGroup,
    title: 'Layers.electricalProtectionGroups',
  },
  {
    key: ITEM_TYPES.sector,
    layer: ObjectLayer.Sector,
    title: 'Layers.sectors',
  },
  {
    key: ITEM_TYPES.subsector,
    layer: ObjectLayer.SubSector,
    title: 'Layers.subSectors',
  },
  {
    key: ITEM_TYPES.feeder,
    layer: ObjectLayer.Feeder,
    title: 'Layers.feeders',
  },
  {
    key: ITEM_TYPES.tunnel,
    layer: ObjectLayer.Tunnel,
    title: 'Layers.tunnels',
  },
]
