import { ReactElement, useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'

import { RootState } from 'Store'
import getCSSValue from 'helpers'
import { mainTracksColor, selectedLinearObject, servTracksColor } from 'objects/styles'
import { LayerProps } from 'objects/types'
import { ObjectLayer } from 'objects/types/const'
import { formatLayerUrl, supervisorFilter } from 'objects/utils'
import { GeoEditorState } from 'reducers/geoEditor'
import trackSectionsPaint from './TrackSectionsLayout'

const sourceLayer = ObjectLayer.TrackSection

type Props = LayerProps
export default function TrackSectionsLayer({ visible }: Props): ReactElement {
  const { active: showGeoEditor } = useSelector((state: RootState): GeoEditorState => state.TIVEditor)
  const { item } = useSelector((state: RootState) => state.detailsPanel)
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  useEffect(() => {
    setSelectedId(item?.id)
  }, [item])

  const dimElement = selectedId
    ? ['any', ['!=', ['get', 'id'], selectedId], showGeoEditor]
    : showGeoEditor

  const isSelected = selectedId
    ? ['==', ['get', 'id'], selectedId]
    : false

  const getTrackTypeColor = ['case', ['==', ['get', 'trackType'], 'VP'], getCSSValue(mainTracksColor),
    getCSSValue(servTracksColor)]

  const layerFilter = supervisorFilter()
  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={sourceLayer}
    >
      <Layer
        id={`${sourceLayer}-layer`}
        type="line"
        paint={{
          'line-color': ['case', isSelected, getCSSValue(selectedLinearObject), getTrackTypeColor],
          'line-width': ['case',
            ['==', ['get', 'trackType'], 'MAIN'], 2,
            1,
          ],
          'line-opacity': ['case',
            dimElement, 0.2,
            1,
          ],
        }}
        layout={{ visibility: visible ? 'visible' : 'none' }}
        filter={layerFilter}
        source-layer={sourceLayer}
      />

      <Layer
        {...trackSectionsPaint.lineCode}
        layout={{
          ...trackSectionsPaint.lineCode.layout,
          visibility: visible ? 'visible' : 'none',
        }}
        filter={layerFilter}
        source-layer={sourceLayer}
      />

      <Layer
        {...trackSectionsPaint.trackCode}
        layout={{
          ...trackSectionsPaint.trackCode.layout,
          visibility: visible ? 'visible' : 'none',
        }}
        filter={layerFilter}
        source-layer={sourceLayer}
      />
    </Source>
  )
}
