import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import HomeIcon from '@mui/icons-material/Home'
import CloseIcon from '@mui/icons-material/Close'
import TabContext from '@mui/lab/TabContext'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabList from '@mui/lab/TabList'
import IconButton from '@mui/material/IconButton'
// import { get } from '@osrdata/app_core/dist/requests'

import { Tab as TabType } from 'components/Common/TabsMenu/TabsMenu'
import { AdminState, closeInstruction, setActiveTab } from 'reducers/admin'
import { RootState } from 'Store'
import HomeDashboard from './HomeTab'
import InstructionDashboard from './instructions/InstructionDashboard'
import './Home.scss'

interface ExtendedTab extends TabType {
  icon?: ReactElement;
}

const HOME_TABS: ExtendedTab[] = [
  {
    key: 'home',
    value: '0',
    icon: <HomeIcon htmlColor="white" />,
    label: '',
    component: <HomeDashboard />,
  },
]

export default function Home(): ReactElement {
  const dispatch = useDispatch()
  const { openInstructionsList, activeTab } = useSelector((state: RootState) => state.admin) as AdminState

  const handleTabChange = (newValue: string) => {
    dispatch(setActiveTab(Number(newValue)))
  }

  return (
    <div className="w-100" id="admin-home">
      <TabContext value={String(activeTab)}>
        <TabList aria-label="tabs menu">
          {HOME_TABS.map(tab => (
            <Tab
              key={tab.key}
              icon={tab.icon}
              label={tab.label}
              value={tab.value}
              className="tabs-menu-item"
              onClick={() => handleTabChange(String(0))}
            />
          ))}
          {openInstructionsList.map((instruction, index) => (
            <div
              key={instruction.id}
              role="button"
              tabIndex={-1}
              className={`
                d-flex justify-content-center align-items-center tab
                pl-3 pr-2 ${index + 1 === activeTab ? 'Mui-selected' : ''}
              `}
              onClick={() => handleTabChange(String(index + 1))}
            >
              <div className="tab-title">
                {instruction.name}
              </div>
              <div>
                <IconButton onClick={e => {
                  e.stopPropagation()
                  e.preventDefault()
                  dispatch(closeInstruction(instruction.id))
                }}
                >
                  <CloseIcon className="close-icon" />
                </IconButton>
              </div>
            </div>
          ))}
        </TabList>
        {HOME_TABS.map(tab => (
          <TabPanel
            key={tab.key}
            value={tab.value}
          >
            {tab.component}
          </TabPanel>
        ))}
        {openInstructionsList.map((instruction, index) => (
          <TabPanel
            key={instruction.id}
            value={String(index + 1)}
          >
            <InstructionDashboard instruction={instruction} />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}
