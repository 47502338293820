import { Feature } from '@nebula.gl/edit-modes'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { Polygon } from 'geojson'
import {
  ReactElement, useRef, useState,
} from 'react'
import MapGL from 'react-map-gl'
import { DrawPolygonMode, Editor } from 'react-map-gl-draw'
import { useDispatch, useSelector } from 'react-redux'

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { RootState } from 'Store'
import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { EDITOR_MODES, EditorMode, EditorModeName } from 'components/GeoEditor/types'
import mapStyle from 'components/Map/style_empty.json'
import { DEFAULT_VIEWPORT, MAX_ZOOM, transformRequest } from 'components/Map/utils'
import TrackSectionsLayer from 'objects/TrackSections/TrackSectionsLayer'
import { ProjectionState, toggleOpenValidationModal } from 'reducers/projection'
import ProjectionServices from 'services/ProjectionServices'
import ProjectionValidationInfoModal from '../ProjectionValidationInfoModal'
import './ItemsSelection.scss'

type UpdateEvent = {
  data: Feature[];
  editType: string;
}

type Props = {
  title: string;
  subtitle?: string;
}

const defaultProps = {
  subtitle: undefined,
}

export default function SelectMap({ title, subtitle }: Props): ReactElement {
  const dispatch = useDispatch()
  const editorRef = useRef<Editor>(null)
  const { t } = useTranslation()
  const [viewport, setViewport] = useState(DEFAULT_VIEWPORT)
  const [bbox, setBbox] = useState<Feature | undefined>()
  const [mode, setMode] = useState<EditorMode>(EDITOR_MODES[EditorModeName.DrawPolygon])
  const { projection } = useSelector((state: RootState) => state.projection) as ProjectionState

  const onUpdate = (params: UpdateEvent) => {
    if (params.editType !== 'addTentativePosition' && params.editType !== 'updateTentativeFeature') {
      if (mode instanceof DrawPolygonMode) setMode(EDITOR_MODES[EditorModeName.Edit])
      setBbox(params.data[0])
    }
  }

  const isButtonDisabled = bbox === undefined

  return (
    <>
      <div className="colored-title purple d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper d-flex justify-content-center text-center flex-column">
            <h1>{t(title)}</h1>
            {subtitle && <h3>{t(subtitle)}</h3>}
          </div>
        </div>
      </div>
      <div className="w-100" style={{ flex: 1, position: 'relative' }}>
        <div className="reset-polygon-button">
          <Tooltip title="Réinitialiser le polygone" arrow>
            <IconButton
              onClick={() => {
                editorRef.current?.deleteFeatures(0)
                setMode(EDITOR_MODES[EditorModeName.DrawPolygon])
                setBbox(undefined)
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>
        <MapGL
          {...viewport}
          transformRequest={transformRequest}
          maxZoom={MAX_ZOOM}
          width="100%"
          height="100%"
          mapStyle={mapStyle}
          onViewportChange={(newViewport: typeof DEFAULT_VIEWPORT) => { setViewport(newViewport) }}
          clickRadius={10}
          preventStyleDiffing
        >
          <TrackSectionsLayer visible />
          <Editor
            ref={editorRef}
            style={{ width: '100%', height: '100%' }}
            clickRadius={12}
            mode={mode}
            features={undefined}
            onUpdate={onUpdate}
            editHandleShape="circle"
            selectedFeatureIndex={0}
            selectable
          />
        </MapGL>
      </div>
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={isButtonDisabled}
          style={ButtonStyle.primary}
          title={t('Projections.button.create')}
          onClick={() => {
            dispatch(ProjectionServices.getItemsInBbox({
              bbox: bbox?.geometry as Polygon,
              originProjection: projection.originalProjection as string,
              pageSize: 100000,
            }))
            dispatch(toggleOpenValidationModal())
          }}
        />
      </div>
      <ProjectionValidationInfoModal />
    </>
  )
}

SelectMap.defaultProps = defaultProps
