import CloseIcon from '@mui/icons-material/Close'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton, Paper, Popper,
} from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { RootState, store } from 'Store'
import Loader from 'components/Common/Loader'
import SearchBar, { SearchSelectedValueType } from 'components/Common/SearchBar/SearchBar'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { getSubText } from 'components/ObjectItem/utils'
import { findObjectKind } from 'objects/kind'
import { GetDetailsServiceOfKind, GetGeometryServiceOfKind, HistoryServiceOfKind } from 'objects/services'
import { ObjectHistory } from 'objects/types'
import { MIDI_URI, OBJECTS_SEARCH_URI } from 'objects/uri'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InstructionState } from 'reducers/instruction'
import { setMergeCaptureClick } from 'reducers/map'
import {
  resetHistory, setLoading, setObjectToMerge, showHistory,
} from 'reducers/panels/detailsPanel'
import { GetPaginatedResponse } from 'reducers/types'
import ObjectServices from 'services/ObjectServices'
import { formatActivityDates } from '../utils'
import './DetailsMergeForm.scss'
import ObjectConflict from './ObjectConflict'
import ObjectTitle from './ObjectTitle'

export default function DetailsMergeForm() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    item, isLoading, objectToMerge, history, merging,
  } = useSelector((state: RootState) => state.detailsPanel)
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openPop, setOpen] = useState(false)

  const kind = findObjectKind(item)

  const closeMergeForm = () => {
    setOpen(false)
    dispatch(setObjectToMerge({}))
    setAnchorEl(null)
    dispatch(setMergeCaptureClick(false))
  }

  useEffect(() => () => {
    closeMergeForm()
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      closeMergeForm()
    } else {
      dispatch(setMergeCaptureClick(true))
      setAnchorEl(event.currentTarget)
    }
  }

  const handleSelectObject = (obj: SearchSelectedValueType | undefined) => {
    if (obj?.id) {
      dispatch(setObjectToMerge({ id: obj.id, mainRepresentation: obj.mainRepresentation }))
      dispatch(GetDetailsServiceOfKind[kind]({ id: obj.id, merge: true }))
      dispatch(HistoryServiceOfKind[kind]({ id: obj.id, merge: true }))
      setOpen(true)
      if (!history.length) {
        dispatch(HistoryServiceOfKind[kind]({ id: item.id }))
      }
    }
  }

  useEffect(() => {
    if (objectToMerge.id && ('historyId' in objectToMerge)) {
      setOpen(true)
    }
  }, [objectToMerge])

  const showHistoricItem = (id :string) => {
    dispatch(showHistory(true))
    dispatch(GetDetailsServiceOfKind[kind]({ id }))
  }

  const mergeObjects = () => {
    store.dispatch(ObjectServices.mergeHistories({
      historyIdToDelete: objectToMerge.historyId,
      historyIdToKeep: item.historyId,
      instruction: instruction.id as string,
      objectToMerge: objectToMerge.id,
    })).then(response => {
      if (!response.type.endsWith('rejected')) {
        setAnchorEl(null)
        setOpen(false)
      }
    })
  }

  const mergeConflict = () => {
    store.dispatch(ObjectServices.getConflicts({ id: item.id, kind })).then(res => {
      if (res.type.endsWith('fulfilled')) {
        const { results } = res.payload as GetPaginatedResponse
        if (!results.length) {
          dispatch(setLoading(false))
          return
        }

        const detailUrl = results[results.length - 1].detailUrl.split('/').filter(el => el)
        const id = detailUrl[detailUrl.length - 1]

        dispatch(resetHistory())
        dispatch(GetDetailsServiceOfKind[kind]({ id, merge: true }))
        dispatch(HistoryServiceOfKind[kind]({ id, merge: true }))
        dispatch(GetGeometryServiceOfKind[kind](id))
        dispatch(HistoryServiceOfKind[kind]({ id: item.id }))
      }
    })
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <div className="header-buttons">
        <Button className="merge" variant="contained" onClick={handleClick}>{t('Details.button.merge')}</Button>

        {item.inConflict && (<ObjectConflict itemId={item.id} kind={kind} />)}

      </div>
      <Popper anchorEl={anchorEl} open={open} className="merge-wrapper">
        <Backdrop className="backdrop" open={isLoading}>
          <Loader />
        </Backdrop>
        <Paper className="paper">
          <div className="d-flex justify-content-between mb-4">
            <div className="title">{t('Details.merge.select')}</div>

            <IconButton className="close" disabled={merging} onClick={closeMergeForm}>
              <CloseIcon />
            </IconButton>

          </div>

          <div className="object-search">
            <SearchBar
              alwaysOpen
              disabled={merging}
              blurOnSelect
              autoFocus={false}
              onSelect={obj => handleSelectObject(obj)}
              searchURI={`/${MIDI_URI}/${OBJECTS_SEARCH_URI}`}
              placeholder={t('Details.merge.searchPlaceholder')}
              labelFormatter={option => `${option.mainRepresentation}`}
              params={{
                exclude_id: item.id,
                instruction: instruction.id as string,
                object_type: kind.toLowerCase(),
              }}
            />
          </div>

          <div className="map-tooltip">
            <div>
              <LightbulbIcon />
            </div>

            <div>
              {t('Details.merge.clickMap')}
            </div>
          </div>

          {item.inConflict && (
          <Button
            className="merge conflict"
            variant="contained"
            onClick={mergeConflict}
          >

            {t('Details.button.mergeConflict')}
          </Button>
          )}

        </Paper>

      </Popper>

      <Popper open={(openPop && !isLoading) || objectToMerge.id} className="merge-info">

        <Paper className="paper d-flex flex-column">
          <div className="d-flex justify-content-between mb-4">
            <div className="title">{t('Details.merge.objectsMerge')}</div>

            <IconButton
              className="close"
              disabled={merging}
              onClick={() => {
                setOpen(false)
                dispatch(setObjectToMerge({}))
              }}
            >
              <CloseIcon />
            </IconButton>

          </div>

          <div className="info">
            <div className="container-div">

              <div className="main-representation">
                {t('Details.merge.keptObject')}
              </div>
              <div className="history">
                <ObjectTitle object={item} />
                {history?.map((currentObj: ObjectHistory) => (
                  <ObjectTile
                    key={currentObj.id}
                    onClick={() => showHistoricItem(currentObj.id)}
                    title={currentObj.mainRepresentation}
                    subtitle={formatActivityDates(currentObj)}
                    contextMenu={<></>}
                  />
                ))}
              </div>
            </div>

            <div className="container-div">
              <div className="main-representation bis">{t('Details.merge.deletedObject')}</div>
              <div className="history">
                <ObjectTitle type={t(getSubText(item))} object={objectToMerge} />
                {objectToMerge?.historyList?.map((hist: ObjectHistory) => (
                  <ObjectTile
                    key={hist.id}
                    onClick={() => showHistoricItem(hist.id)}
                    title={hist.mainRepresentation}
                    subtitle={formatActivityDates(hist)}
                    contextMenu={<></>}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-4">
            <Button
              className="merge"
              variant="contained"
              onClick={mergeObjects}
              disabled={merging}
              endIcon={merging ? <CircularProgress size={20} /> : <></>}
            >
              {t('Details.merge.merge')}
            </Button>
          </div>
        </Paper>
      </Popper>

    </>
  )
}
