import { LayerProperties } from 'objects/types/common'

const minZoom = 13

const trackNodesProps: LayerProperties = {
  node: {
    type: 'circle',
    minzoom: minZoom,
    paint: {
      'circle-color': 'green',
      'circle-radius': 5,
    },
  },
  label: {
    type: 'symbol',
    minzoom: minZoom,
    paint: {
      'text-color': 'green',
      'text-halo-width': 2,
      'text-halo-color': 'rgba(255,255,255,0.9)',
    },
    layout: {
      'text-font': ['Open Sans Regular'],
      'text-size': 12,
      'text-allow-overlap': true,
      'text-field': '{mainRepresentation}',
      'text-pitch-alignment': 'viewport',
      'text-offset': [0, -1],
      'text-anchor': 'center',
    },
  },
}

export default trackNodesProps
