/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteRequest, get, patch } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { store } from 'Store'
import { Polygon } from 'geojson'
import {
  Instruction, InstructionItem,
  InstructionType,
} from 'objects/types/instructions'
import { MIDI_URI } from 'objects/uri'
import URI from 'services/uri'
import { ThunkApiConfig } from 'types'

const updateItemsHelper = async (id: string, items: InstructionItem[], boundingBox: Polygon) => {
  const response: Instruction = await patch(
    `/${MIDI_URI}/${URI.instructions}/${id}`,
    { items, boundingBox },
  )
  return response
}

type GetItemsInBboxParams = {
  bbox: Polygon;
  type: InstructionType;
  date: string;
  projection_id: string;
  include_deleted: boolean;
  instructionId: string;
}

export const getItemsInBbox = createAsyncThunk<InstructionItem[], GetItemsInBboxParams, ThunkApiConfig>(
  'instruction/getItemsInBbox',
  async (params, thunkApi) => {
    const { projection_id, ...parameters } = params
    const projection = projection_id ? { projection_id } : {}
    try {
      const response = await get(
        `/${MIDI_URI}/${URI.instructions}/${params.instructionId}/bbox`,
        { ...parameters, ...projection },
      )
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type AddItemsParams = {
  id: string;
  items: InstructionItem[];
  boundingBox: Polygon;
}

export const addItems = createAsyncThunk<Instruction, AddItemsParams, ThunkApiConfig>(
  'instruction/addItems',
  async (params, thunkApi) => {
    try {
      return await updateItemsHelper(params.id, params.items, params.boundingBox)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

export const updateItems = createAsyncThunk<Instruction, AddItemsParams, ThunkApiConfig>(
  'instruction/updateItems',
  async (params, thunkApi) => {
    try {
      return await updateItemsHelper(params.id, params.items, params.boundingBox)
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type ItemDetailsParams = {
  id: string;
  page?: number;
  search?: string;
}

type ItemDetailsResponse = {
  items: InstructionItem[];
  totalItems: number;
}

export const getItemsDetailsList = createAsyncThunk<ItemDetailsResponse, ItemDetailsParams, ThunkApiConfig>(
  'instruction/getItemsDetailsList',
  async (params, thunkApi) => {
    try {
      const response = await get(
        `/${MIDI_URI}/${URI.instructions}/${params.id}/${URI.instructionItems}`, {
          page: params.page || 1,
          search: params.search || '',
        },
      )
      return {
        items: response.results as InstructionItem[],
        totalItems: response.count as number,
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type DeleteItemParams = {
  id: string;
  itemId: string;
}

export const deleteItem = createAsyncThunk<string, DeleteItemParams, ThunkApiConfig>(
  'instruction/deleteItem',
  async (params, thunkApi) => {
    try {
      await deleteRequest(
        `/${MIDI_URI}/${URI.instructions}/${params.id}/${URI.instructionItems}/${params.itemId}`,
      )
      store.dispatch(getItemsDetailsList({
        id: params.id,
      }))
      return params.id
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
