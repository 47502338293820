import { store as reduxStore } from 'Store'
import ELECTRICAL_ELEMENT_ATTRIBUTES from 'objects/ElectricalElements/ElectricalElementAttributes'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES from
  'objects/ElectricalProtectionGroups/ElectricalProtectionGroupAttributes'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FEEDER_ATTRIBUTES from 'objects/Feeders/FeederAttributes'
import FeederServices from 'objects/Feeders/FeederServices'
import ISOLATOR_ATTRIBUTES from 'objects/Isolators/IsolatorAttributes'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SECTOR_ATTRIBUTES from 'objects/Sectors/SectorAttributes'
import SectorServices from 'objects/Sectors/SectorServices'
import SIGNAL_ATTRIBUTES from 'objects/Signals/SignalAttributes'
import SignalServices from 'objects/Signals/SignalServices'
import SUB_SECTOR_ATTRIBUTES from 'objects/SubSectors/SubSectorAttributes'
import TRACK_PROTECTION_GROUP_ATTRIBUTES from 'objects/TrackProtectionGroups/TrackProtectionGroupAttributes'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TRACK_PROTECTION_ATTRIBUTES from 'objects/TrackProtections/TrackProtectionAttributes'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TRACK_SECTION_ATTRIBUTES from 'objects/TrackSections/TrackSectionAttributes'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import getExtremityAttributes, { ExtremityType } from 'objects/common/Extremities/ExtremityAttributes'
import { ExtraKind, ObjectKind } from 'objects/types/const'
import { InstructionState } from 'reducers/instruction'
import SubSectorServices from './SubSectors/SubSectorServices'
import { Attribute } from './attributes'
import TrackNodeServices from './TrackNodes/TrackNodeServices'
import TRACK_NODE_ATTRIBUTES from './TrackNodes/TrackNodeAttributes'
import TunnelServices from './Tunnels/TunnelServices'
import TUNNEL_ATTRIBUTES from './Tunnels/TunnelAttributes'

const ELECTRICAL_ELEMENT_EXTREMITIES = [ExtremityType.orphan]
const ELECTRICAL_PROTECTION_GROUP_EXTREMITIES = [ExtremityType.orphan]
const FEEDER_EXTREMITIES = [ExtremityType.orphan]
const TRACK_PROTECTIONS_EXTREMITIES = [ExtremityType.orphan, ExtremityType.isolator, ExtremityType.signal]
const TRACK_PROTECTION_GROUP_EXTREMITIES = [ExtremityType.orphan]
const SECTOR_EXTREMITIES = [ExtremityType.orphan]
const SUB_SECTOR_EXTREMITIES = [ExtremityType.orphan]

export const AttributesOfKind = (store: typeof reduxStore): {[key: string]: Attribute[]} => ({
  [ObjectKind.ElectricalElement]: ELECTRICAL_ELEMENT_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.ElectricalProtectionGroup]: ELECTRICAL_PROTECTION_GROUP_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.Feeder]: FEEDER_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.Isolator]: ISOLATOR_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.Sector]: SECTOR_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.Signal]: SIGNAL_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.SubSector]: SUB_SECTOR_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.TrackNode]: TRACK_NODE_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.TrackProtection]: TRACK_PROTECTION_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.TrackProtectionGroup]: TRACK_PROTECTION_GROUP_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ObjectKind.TrackSection]: TRACK_SECTION_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
  [ExtraKind.EEExtremity]: getExtremityAttributes(ELECTRICAL_ELEMENT_EXTREMITIES),
  [ExtraKind.EPGExtremity]: getExtremityAttributes(ELECTRICAL_PROTECTION_GROUP_EXTREMITIES),
  [ExtraKind.FeederExtremity]: getExtremityAttributes(FEEDER_EXTREMITIES, false),
  [ExtraKind.TPExtremity]: getExtremityAttributes(TRACK_PROTECTIONS_EXTREMITIES, true, true),
  [ExtraKind.TPGExtremity]: getExtremityAttributes(TRACK_PROTECTION_GROUP_EXTREMITIES),
  [ExtraKind.SectorExtremity]: getExtremityAttributes(SECTOR_EXTREMITIES),
  [ExtraKind.SSExtremity]: getExtremityAttributes(SUB_SECTOR_EXTREMITIES),
  [ObjectKind.Tunnel]: TUNNEL_ATTRIBUTES(undefined,
    { activityStartDate: (store.getState().instruction as InstructionState)?.instruction?.applicationDate }),
})

export const GetGeometryServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.getGeometry,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.getGeometry,
  [ObjectKind.Feeder]: FeederServices.getGeometry,
  [ObjectKind.Isolator]: IsolatorServices.getGeometry,
  [ObjectKind.Sector]: SectorServices.getGeometry,
  [ObjectKind.Signal]: SignalServices.getGeometry,
  [ObjectKind.SubSector]: SubSectorServices.getGeometry,
  [ObjectKind.TrackNode]: TrackNodeServices.getGeometry,
  [ObjectKind.TrackProtection]: TrackProtectionServices.getGeometry,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.getGeometry,
  [ObjectKind.TrackSection]: TrackSectionServices.getGeometry,
  [ObjectKind.Tunnel]: TunnelServices.getGeometry,
}

export const GetDetailsServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.getDetails,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.getDetails,
  [ObjectKind.Feeder]: FeederServices.getDetails,
  [ObjectKind.Isolator]: IsolatorServices.getDetails,
  [ObjectKind.Sector]: SectorServices.getDetails,
  [ObjectKind.Signal]: SignalServices.getDetails,
  [ObjectKind.SubSector]: SubSectorServices.getDetails,
  [ObjectKind.TrackNode]: TrackNodeServices.getDetails,
  [ObjectKind.TrackProtection]: TrackProtectionServices.getDetails,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.getDetails,
  [ObjectKind.TrackSection]: TrackSectionServices.getDetails,
  [ObjectKind.Tunnel]: TunnelServices.getDetails,
}

export const CreateServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.create,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.create,
  [ObjectKind.Feeder]: FeederServices.create,
  [ObjectKind.Isolator]: IsolatorServices.create,
  [ObjectKind.Sector]: SectorServices.create,
  [ObjectKind.Signal]: SignalServices.create,
  [ObjectKind.SubSector]: SubSectorServices.create,
  [ObjectKind.TrackNode]: TrackNodeServices.create,
  [ObjectKind.TrackProtection]: TrackProtectionServices.create,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.create,
  [ObjectKind.TrackSection]: TrackSectionServices.create,
  [ExtraKind.EEExtremity]: ElectricalElementServices.addExtremity,
  [ExtraKind.EPGExtremity]: ElectricalProtectionGroupServices.addExtremity,
  [ExtraKind.FeederExtremity]: FeederServices.addExtremity,
  [ExtraKind.TPExtremity]: TrackProtectionServices.addExtremity,
  [ExtraKind.TPGExtremity]: TrackProtectionGroupServices.addExtremity,
  [ExtraKind.SectorExtremity]: SectorServices.addExtremity,
  [ExtraKind.SSExtremity]: SubSectorServices.addExtremity,
  [ObjectKind.Tunnel]: TunnelServices.create,
}

export const UpdateServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.update,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.update,
  [ObjectKind.Feeder]: FeederServices.update,
  [ObjectKind.Isolator]: IsolatorServices.update,
  [ObjectKind.Sector]: SectorServices.update,
  [ObjectKind.Signal]: SignalServices.update,
  [ObjectKind.SubSector]: SubSectorServices.update,
  [ObjectKind.TrackNode]: TrackNodeServices.update,
  [ObjectKind.TrackProtection]: TrackProtectionServices.update,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.update,
  [ObjectKind.TrackSection]: TrackSectionServices.update,
  [ObjectKind.Tunnel]: TunnelServices.update,
}

export const DeleteServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.delete,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.delete,
  [ObjectKind.Feeder]: FeederServices.delete,
  [ObjectKind.Isolator]: IsolatorServices.delete,
  [ObjectKind.Sector]: SectorServices.delete,
  [ObjectKind.Signal]: SignalServices.delete,
  [ObjectKind.SubSector]: SubSectorServices.delete,
  [ObjectKind.TrackNode]: TrackNodeServices.delete,
  [ObjectKind.TrackProtection]: TrackProtectionServices.delete,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.delete,
  [ObjectKind.TrackSection]: TrackSectionServices.delete,
  [ObjectKind.Tunnel]: TunnelServices.delete,
}

export const ValidateServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.validate,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.validate,
  [ObjectKind.Feeder]: FeederServices.validate,
  [ObjectKind.Isolator]: IsolatorServices.validate,
  [ObjectKind.Sector]: SectorServices.validate,
  [ObjectKind.Signal]: SignalServices.validate,
  [ObjectKind.SubSector]: SubSectorServices.validate,
  [ObjectKind.TrackProtection]: TrackProtectionServices.validate,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.validate,
  [ObjectKind.TrackSection]: TrackSectionServices.validate,
}

export const HistoryServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.getHistory,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.getHistory,
  [ObjectKind.Feeder]: FeederServices.getHistory,
  [ObjectKind.Isolator]: IsolatorServices.getHistory,
  [ObjectKind.Sector]: SectorServices.getHistory,
  [ObjectKind.Signal]: SignalServices.getHistory,
  [ObjectKind.SubSector]: SubSectorServices.getHistory,
  [ObjectKind.TrackProtection]: TrackProtectionServices.getHistory,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.getHistory,
  [ObjectKind.TrackSection]: TrackSectionServices.getHistory,
  [ObjectKind.TrackNode]: TrackNodeServices.getHistory,
  [ObjectKind.Tunnel]: TunnelServices.getHistory,
}

export const RevertServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.revert,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.revert,
  [ObjectKind.Feeder]: FeederServices.revert,
  [ObjectKind.Isolator]: IsolatorServices.revert,
  [ObjectKind.Sector]: SectorServices.revert,
  [ObjectKind.Signal]: SignalServices.revert,
  [ObjectKind.SubSector]: SubSectorServices.revert,
  [ObjectKind.TrackProtection]: TrackProtectionServices.revert,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.revert,
  [ObjectKind.TrackSection]: TrackSectionServices.revert,
  [ObjectKind.TrackNode]: TrackNodeServices.revert,
  [ObjectKind.Tunnel]: TunnelServices.revert,
}

export const ResetGaiaServiceOfKind = {
  [ObjectKind.ElectricalElement]: ElectricalElementServices.resetGaiaId,
  [ObjectKind.ElectricalProtectionGroup]: ElectricalProtectionGroupServices.resetGaiaId,
  [ObjectKind.Feeder]: FeederServices.resetGaiaId,
  [ObjectKind.Isolator]: IsolatorServices.resetGaiaId,
  [ObjectKind.Sector]: SectorServices.resetGaiaId,
  [ObjectKind.Signal]: SignalServices.resetGaiaId,
  [ObjectKind.SubSector]: SubSectorServices.resetGaiaId,
  [ObjectKind.TrackProtection]: TrackProtectionServices.resetGaiaId,
  [ObjectKind.TrackProtectionGroup]: TrackProtectionGroupServices.resetGaiaId,
  [ObjectKind.TrackSection]: TrackSectionServices.resetGaiaId,
  [ObjectKind.TrackNode]: TrackNodeServices.resetGaiaId,
  [ObjectKind.Tunnel]: TunnelServices.resetGaiaId,
}
