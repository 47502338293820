import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'

import SimpleButton, { ButtonStyle } from 'components/Common/SimpleButton/SimpleButton'
import { ProjectionState, setActiveStep, setProjectionLabel } from 'reducers/projection'
import { RootState } from 'Store'
import { isStringEmpty } from 'helpers'
import './NameChoice.scss'

type Props = {
  title: string;
  subtitle?: string;
}

const defaultProps = {
  subtitle: undefined,
}

export default function ProjectionNameChoice({
  title, subtitle,
}: Props): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { projection, activeStep } = useSelector((state: RootState) => state.projection) as ProjectionState

  return (
    <div id="name-choice" className="d-flex w-100 flex-column h-100">
      <div className="colored-title purple d-flex justify-content-center align-items-center">
        <div className="w-50 mx-auto">
          <div className="title-wrapper d-flex justify-content-center text-center flex-column">
            <h1>{t(title)}</h1>
            {subtitle && <h3>{t(subtitle)}</h3>}
          </div>
          <div className="d-flex input-wrapper justify-content-around align-items-center w-100">
            <FormControl variant="standard" className="m-auto w-50">
              <InputLabel shrink>{t('Projections.creation.inputLabel')}</InputLabel>
              <Input
                required
                autoFocus
                className="light-input"
                value={projection?.name}
                onChange={e => dispatch(setProjectionLabel(e.target.value))}
                disableUnderline
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className="blank-footer" />
      <div className="button-wrapper w-100 d-flex justify-content-center algin-items-center">
        <SimpleButton
          disabled={isStringEmpty(projection?.name)}
          style={ButtonStyle.primary}
          title={t('Instruction.button.continue')}
          onClick={() => { dispatch(setActiveStep(activeStep + 1)) }}
        />
      </div>
    </div>
  )
}

ProjectionNameChoice.defaultProps = defaultProps
