/* eslint-disable @typescript-eslint/no-explicit-any */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkApiConfig } from 'types'
import { GroupListItem, Role, User } from './cerbereTypes'
import URI from './uri'

type GetGroupMembersParams = {
  id: string;
  role: Role;
}
type GetGroupMembersRes = {
  role: Role;
  results: User[];
}

const getAll = createAsyncThunk<User[], undefined, ThunkApiConfig>(
  'users/getAll',
  async (_unused, thunkApi) => {
    try {
      const response = await get(`/${URI.cerbereApi}/${URI.users}/`)
      return response.results as User[]
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type GetGroupsResponse = {
  count: number;
  results: GroupListItem[];
}

const getGroups = createAsyncThunk<GroupListItem[], undefined, ThunkApiConfig>(
  'users/getGroups',
  async (_unused, thunkApi) => {
    try {
      const response: GetGroupsResponse = await get(`/${URI.cerbereApi}/groups/`)
      return response.results
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

type GetGroupMembersResponse = {
  count: number;
  results: User[];
}

const getGroupMembers = createAsyncThunk<GetGroupMembersRes, GetGroupMembersParams, ThunkApiConfig>(
  'users/getGroupMembers',
  async ({ id, role }, thunkApi) => {
    try {
      const response: GetGroupMembersResponse = await get(`/${URI.cerbereApi}/groups/${id}/members/`,
        { page_size: 500 })
      return {
        results: response.results,
        role,
      } as GetGroupMembersRes
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const getUsersListInfo = createAsyncThunk<User[], string[], ThunkApiConfig>(
  'users/getUsersListInfo',
  async (users, thunkApi) => {
    try {
      const response: User[] = await post(`/${URI.cerbereApi}/users/simple/`, [
        ...users,
      ])
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const UserServices = {
  getAll,
  getGroups,
  getGroupMembers,
  getUsersListInfo,
}

export default UserServices
