import {
  Group, GroupListItem, Role, User,
} from 'services/cerbereTypes'
import UserServices from 'services/UserServices'
import { store } from 'Store'

const GROUP_NAME = 'midi-ve'

enum TRANSLATED_GROUP_NAME {
  operator = 'Rédacteur',
  manager = 'Rédacteur gestionnaire de consigne',
  supervisor = 'Vérificateur',
  administrator = 'Approbateur',
  root = 'Root'

}

const getRoleGroup = (groups: Group[], role: Role): Group | undefined => {
  const translatedName = role.toLowerCase() as keyof typeof TRANSLATED_GROUP_NAME
  let roleGroup = groups
    .find(group => group.name.toLowerCase().includes(
      TRANSLATED_GROUP_NAME[translatedName].toLowerCase(),
    ))
  // return id if we found a matching group
  if (roleGroup !== undefined) return roleGroup

  // if not, search in children if there are any
  let i = 0
  while (i < groups.length) {
    if (groups[i].children.length > 0) {
      roleGroup = getRoleGroup(groups[i].children, role)
      if (roleGroup !== undefined) {
        break
      }
    }
    i += 1
  }

  return roleGroup
}

const getRoleGroupId = (groups: GroupListItem[], appName: string, role: Role): string | undefined => {
  const appNameGroup = groups.find(group => group.service_name === appName)
  if (appNameGroup) {
    const roleGroup = getRoleGroup(appNameGroup.groups, role)
    // return id if we found a matching group
    if (roleGroup !== undefined) return roleGroup.id
  }
  return undefined
}

export const getOperators = (groups: GroupListItem[]): void => {
  const operatorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.operator)
  if (operatorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: operatorGroupId, role: Role.operator,
    }))
  }
}

export const getSupervisors = (groups: GroupListItem[]): void => {
  const supervisorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.supervisor)
  if (supervisorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: supervisorGroupId, role: Role.supervisor,
    }))
  }
}

export const getAdministrators = (groups: GroupListItem[]): void => {
  const administratorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.admin)
  if (administratorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: administratorGroupId, role: Role.admin,
    }))
  }
}

export const getRoots = (groups: GroupListItem[]): void => {
  const administratorGroupId = getRoleGroupId(groups, GROUP_NAME, Role.root)
  if (administratorGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: administratorGroupId, role: Role.root,
    }))
  }
}

export const getManagers = (groups: GroupListItem[]): void => {
  const managerGroupId = getRoleGroupId(groups, GROUP_NAME, Role.manager)
  if (managerGroupId) {
    store.dispatch(UserServices.getGroupMembers({
      id: managerGroupId, role: Role.manager,
    }))
  }
}

export const belongsToGroup = (userList: User[], user: User): boolean => userList.some(({ id }) => id === user.id)
