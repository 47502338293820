import { isAnyOf } from '@reduxjs/toolkit'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'

export const resetGaiaIdFulfilledMatcher = isAnyOf(
  ElectricalElementServices.resetGaiaId.fulfilled,
  ElectricalProtectionGroupServices.resetGaiaId.fulfilled,
  FeederServices.resetGaiaId.fulfilled,
  IsolatorServices.resetGaiaId.fulfilled,
  SectorServices.resetGaiaId.fulfilled,
  SignalServices.resetGaiaId.fulfilled,
  SubSectorServices.resetGaiaId.fulfilled,
  TrackProtectionGroupServices.resetGaiaId.fulfilled,
  TrackProtectionServices.resetGaiaId.fulfilled,
  TrackSectionServices.resetGaiaId.fulfilled,
  TrackNodeServices.resetGaiaId.fulfilled,
  TunnelServices.resetGaiaId.fulfilled,
)

export const resetGaiaIdPendingMatcher = isAnyOf(
  ElectricalElementServices.resetGaiaId.pending,
  ElectricalProtectionGroupServices.resetGaiaId.pending,
  FeederServices.resetGaiaId.pending,
  IsolatorServices.resetGaiaId.pending,
  SectorServices.resetGaiaId.pending,
  SignalServices.resetGaiaId.pending,
  SubSectorServices.resetGaiaId.pending,
  TrackProtectionGroupServices.resetGaiaId.pending,
  TrackProtectionServices.resetGaiaId.pending,
  TrackSectionServices.resetGaiaId.pending,
  TrackNodeServices.resetGaiaId.pending,
  TunnelServices.resetGaiaId.pending,
)

export const resetGaiaIdRejectedMatcher = isAnyOf(
  ElectricalElementServices.resetGaiaId.rejected,
  ElectricalProtectionGroupServices.resetGaiaId.rejected,
  FeederServices.resetGaiaId.rejected,
  IsolatorServices.resetGaiaId.rejected,
  SectorServices.resetGaiaId.rejected,
  SignalServices.resetGaiaId.rejected,
  SubSectorServices.resetGaiaId.rejected,
  TrackProtectionGroupServices.resetGaiaId.rejected,
  TrackProtectionServices.resetGaiaId.rejected,
  TrackSectionServices.resetGaiaId.rejected,
  TrackNodeServices.resetGaiaId.rejected,
  TunnelServices.resetGaiaId.rejected,
)
