import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement } from 'react'
import { useSelector } from 'react-redux'

import Chip from '@mui/material/Chip'

import { RootState } from 'Store'
import { STATUS_LABEL, noValidationKind } from 'components/Map/popupUtils'
import { getMainText, getSubText } from 'components/ObjectItem/utils'
import { findObjectKind } from 'objects/kind'
import { MidiObject } from 'objects/types'
import { ObjectStatus } from 'objects/types/const'
import { InstructionState } from 'reducers/instruction'
import './DetailsTitle.scss'

export default function DetailsTitle(): ReactElement {
  const { t } = useTranslation()
  const { item } = useSelector((state: RootState) => state.detailsPanel)
  const { instruction } = useSelector((state: RootState): InstructionState => state.instruction)

  const typedItem = item as MidiObject

  const getStatus = () => {
    const kind = findObjectKind(typedItem)
    if (noValidationKind(kind)) {
      return <></>
    }

    const status = STATUS_LABEL[typedItem.status]

    let className
    switch (typedItem.status) {
      case ObjectStatus.DISPATCHED:
        className = 'approved'
        break
      case ObjectStatus.VERIFIED:
        className = 'verified'
        break
      case ObjectStatus.VALIDATED:
        className = 'validated'
        break
      default:
        className = 'todo'
        break
    }

    return (
      <Chip
        className={className}
        label={t(status)}
      />
    )
  }

  return (
    <div className="details-title d-flex align-items-center flex-wrap">
      <div className="title" title={getMainText(item, t)}>
        {getMainText(item, t)}
      </div>
      <div className="subtitle">
        {t(getSubText(item))}
      </div>
      <div className="object-infos">
        {getStatus()}

        {(typedItem.gaiaId === null) && (
        <Chip
          className="conflict"
          label={t('Details.title.outsideGaia')}
        />
        )}
        {typedItem.inConflict && (
        <Chip
          className="conflict"
          label={t('Details.title.inConflict')}
        />
        )}
        {typedItem.deletedAt && (
        <Chip
          className="deleted"
          label={t('Details.title.deleted')}
        />
        )}
        {(typedItem.inInstruction === false || (typedItem.instructions
        && !typedItem.instructions.find(instr => instr.id === instruction.id))) && (
        <Chip
          className="no-instruction"
          label={t('Details.title.outsideInstruction')}
        />
        )}
      </div>
    </div>
  )
}
