import { RootState } from 'Store'
import { Instruction } from 'objects/types/instructions'
import { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  InstructionState, setInstructionDate, setInstructionName, setInstructionRef,
  setInstructionVersion, setInstructionVersionDate,
} from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import CustomTextField, { Input, InputStyle } from 'views/admin/common/CustomTextField'

import { InputLabel } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import Loader from 'components/Common/Loader'
import RegionDropdown from 'components/Common/RegionDropdown/RegionDropdown'
import './GeneralInfo.scss'
import { loggedAsManager } from 'helpers/permissions'

const INPUTS = (instruction: Partial<Instruction>) => [
  {
    label: 'Instruction.creation.nameChoice.inputLabel',
    placeholder: 'Instruction.creation.nameChoice.inputLabel',
    value: instruction.name,
    updateFunction: setInstructionName,
  },
  {
    label: 'Instruction.creation.refChoice.refInput.label',
    placeholder: 'Instruction.creation.refChoice.refInput.placeholder',
    value: instruction.reference,
    updateFunction: setInstructionRef,
  },
  {
    label: 'Instruction.creation.refChoice.versionInput.label',
    placeholder: 'Instruction.creation.refChoice.versionInput.placeholder',
    value: instruction.version,
    updateFunction: setInstructionVersion,
  },
  {
    label: 'Instruction.creation.refChoice.versionDateInput.label',
    placeholder: 'Instruction.creation.refChoice.versionDateInput.placeholder',
    value: instruction.versionDate,
    updateFunction: setInstructionVersionDate,
    type: 'date',
  },
  {
    label: 'Instruction.creation.refChoice.dateInput.label',
    placeholder: 'Instruction.creation.refChoice.dateInput.placeholder',
    value: instruction.applicationDate,
    updateFunction: setInstructionDate,
    needConfirmation: true,
    confirmTitle: 'Instruction.dashboard.parameters.confirmApplicationUpdateTitle',
    confirmContent: 'Instruction.dashboard.parameters.confirmApplicationUpdate',
    type: 'date',
  },

  {
    label: 'Instruction.creation.refChoice.regionInput.label',
    placeholder: 'Instruction.creation.refChoice.regionInput.placeholder',
    value: instruction.regions,
    updateFunction: setInstructionDate,
    type: 'custom',
  },
]

export default function GeneralInfo(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { instruction, loading } = useSelector((state: RootState) => state.instruction) as InstructionState

  return (
    <div id="general-info" className="d-flex align-items-center flex-column">
      {loggedAsManager() && loading && <Loader overlay />}
      <div className="w-75 pt-3">
        {INPUTS(instruction).map(input => (
          <div key={input.label} className="d-flex justify-content-center align-items-center">
            <div className="w-50 m-auto">
              {input.type === 'custom' ? (
                <>
                  <InputLabel shrink>{t(input.label)}</InputLabel>
                  <RegionDropdown
                    object={instruction}
                    preventEmpty
                    setRegions={newRegions => dispatch(InstructionServices.update({
                      ...instruction,
                      regions: newRegions,
                    }))}
                  />
                </>
              )
                : (
                  <CustomTextField
                    input={input as Input}
                    style={InputStyle.filled}
                    onBlur={() => dispatch(InstructionServices.update(instruction))}
                    dense
                  />
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
