import { Chip } from '@mui/material'
import { useTranslation } from '@osrdata/app_core/dist/translation'
import { MidiObject, ShortMidiObject } from 'objects/types'
import { ReactElement } from 'react'
import './ObjectTitle.scss'
import { getSubText } from 'components/ObjectItem/utils'

type Props = {
  object: MidiObject;
  type?: string;
}

const defaultProps = {
  type: '',
}

export default function ObjectTitle({ object, type }: Props): ReactElement {
  const { t } = useTranslation()

  return (
    <>
      <div className="titles-wrapper">
        <div className="label" title={object.mainRepresentation}>
          {object.mainRepresentation}
        </div>

        {object.inConflict && (
        <Chip
          className="conflict"
          label={t('Details.title.inConflict')}
        />
        )}
        <div className="subtitle">
          {type || t(getSubText(object as ShortMidiObject)) }
        </div>
      </div>
    </>
  )
}

ObjectTitle.defaultProps = defaultProps
