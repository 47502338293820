import get from 'lodash/get'
import { FeedbackType, setMessage } from 'reducers/feedback'
import { store } from 'Store'
import { ObjOfArrOfStr, ResponseError, UnknownObject } from 'types'

const NESTED_FIELDS = [
  'startKp', 'endKp', 'kp', 'kilometricPoints', 'extremities',
  'lineIdentificationSystem', 'locations', 'value', 'composition', 'location', 'instruction', 'chartis',
  'applicationDate', 'historyIdToDelete', 'historyIdToKeep', 'customError', 'line', 'errorMessage',
]

type ExtremityError = {
  location: {
    value: string[];
  };
}

export const DEFAULT_AUTOCLOSE_DURATION = 6000

export enum Severity {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
}

const getNestedMessage = (data: UnknownObject, additionalNestedFields: string[] = []): string => {
  if (data.nonFieldErrors) return (data.nonFieldErrors as string[])[0]

  if (typeof data?.detail === 'string') return data.detail

  if (Array.isArray(data)) return data[0] as string

  if (data.extremities) {
    const extremities = data.extremities as ExtremityError[]
    if (extremities.some(elem => elem?.location?.value)) {
      const message = (extremities).flatMap(elem => elem?.location?.value || [])
      return message.join('\n')
    }
  }

  const dataNestedFields = [
    ...NESTED_FIELDS.filter(field => Object.keys(data).includes(field)),
    ...additionalNestedFields,
  ]

  if (dataNestedFields.length > 0) {
    const nestedData = get(data, dataNestedFields[0])
    if (!nestedData) return ''
    if (nestedData && Array.isArray(nestedData)) {
      for (let j = 0; j < nestedData.length; j += 1) {
        const nestedMessage = getNestedMessage(nestedData[j])
        if (nestedMessage !== '') return nestedMessage
      }
    } else if (NESTED_FIELDS.filter(field => Object.keys(nestedData as UnknownObject).includes(field))) {
      return getNestedMessage(nestedData as UnknownObject)
    } else {
      return dataNestedFields[0]
    }
  } else if (typeof data === 'string') {
    return data
  }

  return ''
}

export const handleFeedback = (
  feedback: ResponseError,
  setOpen: (b: boolean) => void,
  setSeverity: (s: Severity) => void,
  setAutoCloseDuration: (n: number | null) => void,
): void => {
  setOpen(true)
  const nestedMessage = getNestedMessage(feedback.data, feedback.additionalNestedFields)
  switch (feedback.code) {
    case 200:
      if (feedback.data.isGeometryUpdate) {
        store.dispatch(setMessage('Map.Success.updateObjectGeometry'))
      } else if (feedback.data.type === FeedbackType.validation) {
        store.dispatch(setMessage('Error.validationSuccess'))
      } else if (feedback.data.type === FeedbackType.merge) {
        store.dispatch(setMessage('Details.merge.success'))
      } else {
        store.dispatch(setMessage('Map.Success.updateObject'))
      }
      setSeverity(Severity.success)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 201:
      store.dispatch(setMessage('Map.Success.createObject'))
      setSeverity(Severity.success)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 204:
      store.dispatch(setMessage('Map.Success.deleteObject'))
      setSeverity(Severity.success)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 400:
      setSeverity(Severity.error)
      // Handle nonFieldErrors
      if (feedback.data.nonFieldErrors) {
        store.dispatch(setMessage((feedback.data as ObjOfArrOfStr).nonFieldErrors[0]))
      } else if (nestedMessage) {
        store.dispatch(setMessage(nestedMessage))
      } else {
        store.dispatch(setMessage('Map.Error.badRequest'))
      }
      break
    case 403:
      store.dispatch(setMessage('Error.forbidden.content'))
      setSeverity(Severity.error)
      setAutoCloseDuration(null)
      break
    case 404:
      store.dispatch(setMessage('Map.Error.notFound'))
      setSeverity(Severity.error)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 409:
      store.dispatch(setMessage('Map.Error.conflict'))
      setSeverity(Severity.warning)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    case 500:
      store.dispatch(setMessage('Map.Error.server'))
      setSeverity(Severity.error)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
    default:
      store.dispatch(setMessage('Map.Error.unknown'))
      setSeverity(Severity.error)
      setAutoCloseDuration(DEFAULT_AUTOCLOSE_DURATION)
      break
  }
}
