import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import LineServices from 'objects/Lines/LineServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'
import AreaServices from 'services/AreaServices'
import InstructionServices from 'services/InstructionServices'
import FeederServices from 'objects/Feeders/FeederServices'
import ProjectionServices from 'services/ProjectionServices'

import { isAnyOf } from '@reduxjs/toolkit'

import TrackNodeServices from 'objects/TrackNodes/TrackNodeServices'
import TunnelServices from 'objects/Tunnels/TunnelServices'
import ObjectServices from 'services/ObjectServices'
import {
  updateEELinkedObjectsErrorMatcher, updateEELinkedObjectsFulfilledMatcher,
} from './EELinkedObjects'
import {
  updateEPGLinkedObjectsErrorMatcher, updateEPGLinkedObjectsFulfilledMatcher,
} from './EPGLinkedObjects'
import {
  updateFeederLinkedObjectsErrorMatcher, updateFeederLinkedObjectsFulfilledMatcher,
} from './FeederLinkedObjects'
import {
  updateSectorLinkedObjectsErrorMatcher, updateSectorLinkedObjectsFulfilledMatcher,
} from './SectorLinkedObjects'
import {
  updateSubSectorLinkedObjectsErrorMatcher, updateSubSectorLinkedObjectsFulfilledMatcher,
} from './SubSectorLinkedObjects'
import {
  updateTPGLinkedObjectsErrorMatcher, updateTPGLinkedObjectsFulfilledMatcher,
} from './TPGLinkedObjects'
import {
  updateTPLinkedObjectsErrorMatcher, updateTPLinkedObjectsFulfilledMatcher,
} from './TPLinkedObjects'
import { resetGaiaIdFulfilledMatcher } from '../resetGaiaMatchers'

export {
  updateEELinkedObjectsErrorMatcher, updateEELinkedObjectsFulfilledMatcher,
  updateEELinkedObjectsPendingMatcher,
} from './EELinkedObjects'
export {
  updateEPGLinkedObjectsErrorMatcher, updateEPGLinkedObjectsFulfilledMatcher,
  updateEPGLinkedObjectsPendingMatcher,
} from './EPGLinkedObjects'
export {
  updateFeederLinkedObjectsErrorMatcher, updateFeederLinkedObjectsFulfilledMatcher,
  updateFeederLinkedObjectsPendingMatcher,
} from './FeederLinkedObjects'
export {
  updateSectorLinkedObjectsErrorMatcher, updateSectorLinkedObjectsFulfilledMatcher,
  updateSectorLinkedObjectsPendingMatcher,
} from './SectorLinkedObjects'
export {
  updateSubSectorLinkedObjectsErrorMatcher, updateSubSectorLinkedObjectsFulfilledMatcher,
  updateSubSectorLinkedObjectsPendingMatcher,
} from './SubSectorLinkedObjects'
export {
  updateTPGLinkedObjectsErrorMatcher, updateTPGLinkedObjectsFulfilledMatcher,
  updateTPGLinkedObjectsPendingMatcher,
} from './TPGLinkedObjects'
export {
  updateTPLinkedObjectsErrorMatcher, updateTPLinkedObjectsFulfilledMatcher,
  updateTPLinkedObjectsPendingMatcher,
} from './TPLinkedObjects'

export const updateErrorMatcher = isAnyOf(
  AreaServices.update.rejected,
  ElectricalElementServices.update.rejected,
  ElectricalElementServices.addExtremity.rejected, updateEELinkedObjectsErrorMatcher,
  ElectricalProtectionGroupServices.update.rejected,
  ElectricalProtectionGroupServices.addExtremity.rejected, updateEPGLinkedObjectsErrorMatcher,
  FeederServices.update.rejected,
  FeederServices.addExtremity.rejected, updateFeederLinkedObjectsErrorMatcher,
  InstructionServices.addItems.rejected,
  InstructionServices.addUsers.rejected,
  InstructionServices.update.rejected,
  TrackSectionServices.update.rejected, LineServices.getAll.rejected,
  IsolatorServices.update.rejected,
  SignalServices.update.rejected, TrackProtectionServices.update.rejected,
  TrackProtectionServices.addExtremity.rejected, updateTPLinkedObjectsErrorMatcher,
  TrackProtectionGroupServices.update.rejected, TrackProtectionGroupServices.addExtremity.rejected,
  updateTPGLinkedObjectsErrorMatcher,
  ProjectionServices.update.rejected,
  SectorServices.update.rejected, updateSectorLinkedObjectsErrorMatcher,
  SubSectorServices.update.rejected, updateSubSectorLinkedObjectsErrorMatcher,
  TrackNodeServices.update.rejected, InstructionServices.removeItem.rejected,
  TunnelServices.update.rejected,
  ObjectServices.extend.rejected,
)

export const updatePanelObjFulfilledMatcher = isAnyOf(
  ElectricalElementServices.update.fulfilled,
  ElectricalProtectionGroupServices.update.fulfilled,
  FeederServices.update.fulfilled,
  IsolatorServices.update.fulfilled,
  SectorServices.update.fulfilled,
  SignalServices.update.fulfilled,
  SubSectorServices.update.fulfilled,
  TrackProtectionServices.update.fulfilled,
  TrackProtectionGroupServices.update.fulfilled,
  TrackNodeServices.update.fulfilled,
  TunnelServices.update.fulfilled,
)

export const updateFulfilledMatcher = isAnyOf(
  updatePanelObjFulfilledMatcher,
  TrackProtectionServices.addExtremity.fulfilled,
  updateTPLinkedObjectsFulfilledMatcher,
  TrackProtectionGroupServices.addExtremity.fulfilled,
  updateTPGLinkedObjectsFulfilledMatcher,
  ElectricalElementServices.addExtremity.fulfilled,
  updateEELinkedObjectsFulfilledMatcher,
  ElectricalProtectionGroupServices.addExtremity.fulfilled,
  updateEPGLinkedObjectsFulfilledMatcher,
  FeederServices.addExtremity.fulfilled,
  updateFeederLinkedObjectsFulfilledMatcher,
  updateSectorLinkedObjectsFulfilledMatcher,
  updateSubSectorLinkedObjectsFulfilledMatcher,
  InstructionServices.addItems.fulfilled,
  InstructionServices.addUsers.fulfilled,
  AreaServices.update.fulfilled,
  ProjectionServices.update.fulfilled,
  ObjectServices.extend.fulfilled,
  resetGaiaIdFulfilledMatcher,
)

export const updatePendingMatcher = isAnyOf(
  ElectricalElementServices.addExtremity.pending,
  ElectricalElementServices.update.pending,
  ElectricalProtectionGroupServices.addExtremity.pending,
  ElectricalProtectionGroupServices.update.pending,
  FeederServices.addExtremity.pending,
  FeederServices.update.pending,
  IsolatorServices.update.pending,
  SectorServices.update.pending,
  SignalServices.update.pending,
  SubSectorServices.update.pending,
  TrackProtectionGroupServices.addExtremity.pending,
  TrackProtectionGroupServices.update.pending,
  TrackProtectionServices.addExtremity.pending,
  TrackProtectionServices.update.pending,
  TrackSectionServices.update.pending,
  InstructionServices.removeItem.pending,
  ObjectServices.extend.pending,
  TrackNodeServices.update.pending,
  TunnelServices.update.pending,
)
