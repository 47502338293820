import { Tooltip } from '@mui/material'
import { PropsWithChildren, ReactElement } from 'react'

type Props = PropsWithChildren<{
  disabled: boolean;
  title: string;
}>

export default function DisabledToolTip({ disabled, title, children }: Props): ReactElement {
  return (
    <Tooltip
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      disableInteractive={disabled}
      disableTouchListener={disabled}
      title={title}
    >
      <span>
        {children}
      </span>
    </Tooltip>
  )
}
