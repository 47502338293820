import ButtonStatus from 'components/Common/buttonStatus'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import {
  AsyncResponse, MidiObject, ObjectHistory, ShortMidiObject,
} from 'objects/types'
import { createFulfilledDisplayedObjMatcher } from 'reducers/matchers/createMatchers'
import {
  getDetailsErrorMatcher, getDetailsFulfilledMatcher, getDetailsPendingMatcher,
} from 'reducers/matchers/getDetailsMatchers'
import { getGeomErrorMatcher, getGeomPendingMatcher } from 'reducers/matchers/getGeomMatchers'
import {
  updateEELinkedObjectsErrorMatcher, updateEELinkedObjectsFulfilledMatcher,
  updateEELinkedObjectsPendingMatcher, updateEPGLinkedObjectsErrorMatcher,
  updateEPGLinkedObjectsFulfilledMatcher,
  updateEPGLinkedObjectsPendingMatcher, updateFeederLinkedObjectsErrorMatcher,
  updateFeederLinkedObjectsFulfilledMatcher,
  updateFeederLinkedObjectsPendingMatcher, updateFulfilledMatcher,
  updatePanelObjFulfilledMatcher, updateSectorLinkedObjectsErrorMatcher,
  updateSectorLinkedObjectsFulfilledMatcher, updateSectorLinkedObjectsPendingMatcher,
  updateSubSectorLinkedObjectsErrorMatcher,
  updateSubSectorLinkedObjectsFulfilledMatcher, updateSubSectorLinkedObjectsPendingMatcher,
  updateTPGLinkedObjectsErrorMatcher, updateTPGLinkedObjectsFulfilledMatcher,
  updateTPGLinkedObjectsPendingMatcher, updateTPLinkedObjectsErrorMatcher, updateTPLinkedObjectsFulfilledMatcher,
  updateTPLinkedObjectsPendingMatcher,
} from 'reducers/matchers/update'
import {
  validationErrorMatcher, validationFulfilledMatcher, validationPendingMatcher,
} from 'reducers/matchers/validationMatchers'

import {
  createSlice,
  isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import GeoEditorService from 'components/GeoEditor/GeoEditorService'
import { ITEM_TYPES } from 'objects/types/const'
import { TaskStatus, TaskType } from 'objects/types/instructions'
import {
  getHistoryErrorMatcher, getHistoryFulfilledMatcher,
  getHistoryPendingMatcher,
} from 'reducers/matchers/getHistoryMatchers'
import {
  resetGaiaIdFulfilledMatcher, resetGaiaIdPendingMatcher,
  resetGaiaIdRejectedMatcher,
} from 'reducers/matchers/resetGaiaMatchers'
import { revertErrorMatcher, revertFulfilledMatcher, revertPendingMatcher } from 'reducers/matchers/restoreMatchers'
import { CollapsibleMenu } from 'reducers/types'
import InstructionServices from 'services/InstructionServices'
import ObjectServices from 'services/ObjectServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

export interface DetailsPanelState {
  item: ShortMidiObject | undefined;
  isLoading: boolean;
  shouldRefresh: boolean;
  buttonStatus: ButtonStatus;
  openMenus: CollapsibleMenu[];
  history: ObjectHistory[];
  displayHistory: boolean;
  historicItem: ShortMidiObject | undefined;
  objectToMerge: Partial<MidiObject & {historyList: ObjectHistory[]}>;
  mergeLoading: boolean;
  merging: boolean;
  revertLoading: boolean;
  fetchAsyncId: string;
  asyncTask: AsyncResponse;
}

export const EMPTY_TASK: AsyncResponse = {
  id: '',
  taskName: '',
  url: '',
  taskStatus: TaskStatus.PENDING,
  user: '',
  payload: {},
  objectId: '',
  objectType: ITEM_TYPES.tracknode,
  errorMessage: {},
}

const initialState: DetailsPanelState = {
  item: undefined,
  isLoading: true,
  shouldRefresh: false,
  buttonStatus: ButtonStatus.Base,
  openMenus: [],
  history: [],
  displayHistory: false,
  historicItem: undefined,
  objectToMerge: {},
  mergeLoading: false,
  merging: false,
  revertLoading: false,
  fetchAsyncId: '',
  asyncTask: EMPTY_TASK,
}

const updateLocalItem = (state: DetailsPanelState, action: PayloadAction<ShortMidiObject | undefined>) => {
  if (state.displayHistory) {
    if (!action.payload) return
    state.historicItem = action.payload
  } else {
    state.item = action.payload
  }

  state.isLoading = false
  state.shouldRefresh = false
}

export const detailsPanelSlice = createSlice({
  name: 'detailsPanel',
  initialState,
  reducers: {
    updateItem: updateLocalItem,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    shouldRefresh: (state, action: PayloadAction<boolean>) => {
      state.shouldRefresh = action.payload
    },
    addOpenMenu: (state, action: PayloadAction<CollapsibleMenu>) => {
      if (!state.openMenus.includes(action.payload)) state.openMenus.push(action.payload)
    },
    removeOpenMenu: (state, action: PayloadAction<CollapsibleMenu>) => {
      if (state.openMenus.includes(action.payload)) {
        state.openMenus = state.openMenus.filter(om => om !== action.payload)
      }
    },
    resetOpenMenus: state => {
      state.openMenus = []
    },
    showHistory: (state, action: PayloadAction<boolean>) => {
      state.displayHistory = action.payload
    },
    setObjectToMerge: (state, action: PayloadAction<Partial<MidiObject & {historyList: ObjectHistory[]}>>) => {
      state.objectToMerge = action.payload
    },
    resetHistory: state => {
      state.history = []
    },
    resetTask: state => {
      state.asyncTask = EMPTY_TASK
      state.fetchAsyncId = ''
    },
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.removeItem.fulfilled, (state, action) => {
      if (state.item) {
        state.item.instructions = state.item.instructions.filter(instr => instr.id !== action.meta.arg.instructionId)
      }
    })

    builder.addCase(ObjectServices.mergeHistories.pending, state => {
      state.merging = true
    })
    builder.addCase(ObjectServices.mergeHistories.fulfilled, (state, action) => {
      state.item = action.payload as ShortMidiObject
      state.merging = false
      state.objectToMerge = {}
      state.mergeLoading = false
      state.displayHistory = false
      state.history = []
      state.historicItem = undefined
    })
    builder.addCase(ObjectServices.mergeHistories.rejected, state => {
      state.merging = false
    })
    builder.addCase(ObjectServices.extend.fulfilled, (state, action) => {
      state.item = action.payload as ShortMidiObject
    })
    builder.addCase(ObjectServices.getTaskStatus.fulfilled, (state, action) => {
      state.asyncTask = action.payload
      if (action.payload.taskStatus === TaskStatus.PENDING) return

      state.isLoading = false

      state.isLoading = false

      // revert action
      if (action.payload.taskName === TaskType.revert) {
        state.displayHistory = false
        state.revertLoading = false
      }

      // successful actions
      if (action.payload.taskStatus === TaskStatus.FAILURE) return

      // delete tiv
      if (action.payload.taskName === TaskType.deleteTrackSection) {
        state.item = undefined
      }
    })
    builder.addCase(TrackSectionServices.update.pending, state => {
      state.isLoading = true
    })
    builder.addCase(GeoEditorService.updateTrackSectionGeometry.pending, state => {
      state.isLoading = true
    })
    builder.addCase(TrackSectionServices.update.rejected, state => {
      state.isLoading = false
    })

    builder.addCase(TrackSectionServices.delete.pending, state => {
      state.isLoading = true
    })

    builder.addCase(ObjectServices.getTaskStatus.rejected, state => {
      state.displayHistory = false
      state.revertLoading = false
    })
    builder.addMatcher(isAnyOf(InstructionServices.getDetails.pending, ObjectServices.getConflicts.pending,
      resetGaiaIdPendingMatcher), state => {
      state.isLoading = true
    })
    builder.addMatcher(isAnyOf(ObjectServices.getConflicts.rejected, InstructionServices.getDetails.fulfilled,
      InstructionServices.getDetails.rejected, resetGaiaIdRejectedMatcher), state => {
      state.isLoading = false
    })
    builder.addMatcher(getHistoryFulfilledMatcher, (state, action) => {
      state.mergeLoading = false
      if (action.meta.arg.merge) {
        state.objectToMerge.historyList = action.payload
      } else {
        state.history = action.payload
      }
    })

    builder.addMatcher(getHistoryPendingMatcher, state => {
      if (state.objectToMerge) {
        state.mergeLoading = true
      } else {
        state.history = []
      }

      state.historicItem = undefined
    })

    builder.addMatcher(isAnyOf(createFulfilledDisplayedObjMatcher, resetGaiaIdFulfilledMatcher), updateLocalItem)

    builder.addMatcher(getDetailsFulfilledMatcher, (state, action) => {
      if (action.meta.arg.merge) {
        state.objectToMerge = { ...action.payload, historyList: state.objectToMerge.historyList }
        state.isLoading = false
        state.shouldRefresh = false
      } else {
        updateLocalItem(state, action)
      }
    })

    builder.addMatcher(isAnyOf(
      TrackProtectionServices.addExtremity.fulfilled,
      updateTPLinkedObjectsFulfilledMatcher,
      TrackProtectionGroupServices.addExtremity.fulfilled,
      updateTPGLinkedObjectsFulfilledMatcher,
      updateEPGLinkedObjectsFulfilledMatcher,
      ElectricalElementServices.addExtremity.fulfilled,
      ElectricalProtectionGroupServices.addExtremity.fulfilled,
      FeederServices.addExtremity.fulfilled,
      updateFeederLinkedObjectsFulfilledMatcher,
      updateEELinkedObjectsFulfilledMatcher,
      updateSectorLinkedObjectsFulfilledMatcher,
      updateSubSectorLinkedObjectsFulfilledMatcher,
      updatePanelObjFulfilledMatcher,
      SectorServices.addExtremity.fulfilled,
      SubSectorServices.addExtremity.fulfilled,
    ), state => {
      state.shouldRefresh = true
    })

    builder.addMatcher(isAnyOf(getGeomPendingMatcher,
      updateTPLinkedObjectsPendingMatcher, updateTPGLinkedObjectsPendingMatcher,
      updateEPGLinkedObjectsPendingMatcher, updateFeederLinkedObjectsPendingMatcher,
      updateEELinkedObjectsPendingMatcher, updateSectorLinkedObjectsPendingMatcher,
      updateSubSectorLinkedObjectsPendingMatcher, getHistoryPendingMatcher), state => {
      state.isLoading = true
      state.historicItem = undefined
    })

    builder.addMatcher(isAnyOf(getDetailsPendingMatcher), (state, action) => {
      if (action.meta.arg.merge) {
        state.mergeLoading = true
      } else {
        state.isLoading = true
        state.historicItem = undefined
      }
    })

    builder.addMatcher(isAnyOf(
      getDetailsErrorMatcher, getGeomErrorMatcher,
      updateTPLinkedObjectsErrorMatcher, updateTPGLinkedObjectsErrorMatcher,
      updateEPGLinkedObjectsErrorMatcher, updateFeederLinkedObjectsErrorMatcher,
      updateEELinkedObjectsErrorMatcher, updateSectorLinkedObjectsErrorMatcher,
      updateSubSectorLinkedObjectsErrorMatcher, getHistoryFulfilledMatcher, getHistoryErrorMatcher,
    ), state => {
      state.isLoading = false
      state.mergeLoading = false
      state.displayHistory = false
    })

    builder.addMatcher(isAnyOf(
      validationFulfilledMatcher,
    ), state => {
      state.buttonStatus = ButtonStatus.Base
      state.item = undefined
    })

    builder.addMatcher(isAnyOf(
      validationPendingMatcher,
    ), state => {
      state.buttonStatus = ButtonStatus.Loading
    })
    builder.addMatcher(isAnyOf(
      validationErrorMatcher,
    ), state => {
      state.buttonStatus = ButtonStatus.Base
    })

    builder.addMatcher(isAnyOf(updateFulfilledMatcher), (state, action) => {
      if ('applicationDate' in action.payload) return

      if ('item' in action.payload) {
        state.item = action.payload.item as ShortMidiObject
      } else {
        state.item = action.payload as ShortMidiObject
      }
    })
    // Todo async matchers
    builder.addMatcher(isAnyOf(revertFulfilledMatcher, TrackSectionServices.update.fulfilled,
      TrackSectionServices.delete.fulfilled,
      GeoEditorService.updateTrackSectionGeometry.fulfilled), (state, action) => {
      state.fetchAsyncId = action.payload.midiObjectProcessResult
    })
    builder.addMatcher(isAnyOf(revertPendingMatcher), state => {
      state.revertLoading = true
    })
    builder.addMatcher(isAnyOf(revertErrorMatcher), state => {
      state.revertLoading = false
      state.displayHistory = false
    })
  },
})

export const {
  updateItem, setLoading, shouldRefresh, addOpenMenu, removeOpenMenu, resetOpenMenus, showHistory,
  setObjectToMerge, resetHistory, resetTask,
} = detailsPanelSlice.actions

export default detailsPanelSlice.reducer
