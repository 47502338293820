import { useTranslation } from '@osrdata/app_core/dist/translation'
import {
  ReactElement, useEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import TextField from '@mui/material/TextField'

import { RootState } from 'Store'
import { getActivePanel } from 'helpers'
import { CaptureMapClickParams } from 'objects/attributes'
import { ExtraKind, ObjectKind, ObjectLayer } from 'objects/types/const'
import { setError, setMessage } from 'reducers/feedback'
import { enableGeoEditor, hideGeoEditor } from 'reducers/geoEditor'
import {
  MapState, setCaptureClick, setCaptureClickIndex, setLayers, setSelectedCaptureClickParamsIndex,
} from 'reducers/map'
import { CreationPanelState } from 'reducers/panels/creationPanel'
import { PanelName } from 'reducers/panels/panel'
import { nestedObject } from 'types'

import { KIND_TO_LAYER } from 'objects/kind'
import { InputDataType } from '../utils'
import './CaptureClick.scss'

export default function CaptureClick({
  field, value, error, helperText, index, noLabel,
}: InputDataType): ReactElement | null {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    captureClick, layers, captureClickIndex, selectedCaptureClickParamsIndex,
  } = useSelector((state: RootState): MapState => state.map)
  const { newObjectKind } = useSelector((state: RootState): CreationPanelState => state.creationPanel)
  const { captureClickParams } = field
  const layersMemory = useRef<ObjectLayer[]>([])
  const [disabled, setDisabled] = useState(false)
  const [formattedValue, setFormattedValue] = useState(value)

  useEffect(() => {
    layersMemory.current = layers
  }, [])

  useEffect(() => {
    if (captureClickParams) {
      if (selectedCaptureClickParamsIndex === -1) return
      const formatter = Array.isArray(captureClickParams) && selectedCaptureClickParamsIndex !== undefined
        ? captureClickParams[selectedCaptureClickParamsIndex]?.displayedValueFormatter
        : (captureClickParams as CaptureMapClickParams)?.displayedValueFormatter

      if (formatter !== undefined) {
        setFormattedValue(formatter(value as nestedObject))
      } else {
        setFormattedValue(value)
      }
      dispatch(setLayers(layers))
    }
  }, [captureClick, selectedCaptureClickParamsIndex, value])

  useEffect(() => {
    setDisabled(captureClickIndex !== undefined && index !== captureClickIndex)
  }, [captureClickIndex, index])

  const setCaptureClickConfig = () => {
    dispatch(hideGeoEditor())
    dispatch(setCaptureClick(captureClickParams))
    if (Array.isArray(captureClickParams)) {
      dispatch(setLayers(captureClickParams.map(param => param.objectLayer)))
    } else if (captureClickParams !== undefined) {
      dispatch(setLayers([captureClickParams.objectLayer]))
    }
    dispatch(setCaptureClickIndex(index))
    dispatch(setMessage(field.captureClickHelper || 'Modification.helperText.defaultCaptureClick'))
    dispatch(setError(undefined))
  }

  const cleanCaptureClick = () => {
    dispatch(setCaptureClick(undefined))

    if (newObjectKind && !Object.values(ExtraKind).includes(newObjectKind as ExtraKind)) {
      const newLayer = KIND_TO_LAYER[newObjectKind as ObjectKind]

      const refreshLayers = layersMemory.current.includes(newLayer)
        ? layersMemory.current : [...layersMemory.current, newLayer]

      dispatch(setLayers(refreshLayers))
    } else {
      dispatch(setLayers(layersMemory.current))
    }

    dispatch(setCaptureClickIndex(undefined))
    dispatch(setSelectedCaptureClickParamsIndex(undefined))
    // Display geoeditor again when creating a Track Section
    if (newObjectKind === ObjectKind.TrackSection && getActivePanel() === PanelName.creation) {
      dispatch(enableGeoEditor())
    }
  }

  const onClick = () => {
    if (!disabled && captureClickParams !== undefined) {
      if (captureClick === undefined) {
        setCaptureClickConfig()
      } else {
        cleanCaptureClick()
      }
    }
  }

  useEffect(() => {
    if (!value) return
    dispatch(setLayers(layersMemory.current))
  }, [value])

  useEffect(() => {
    if (field.captureOnOpen) setCaptureClickConfig()

    return cleanCaptureClick
  }, [])

  if (captureClickParams === undefined) return null

  return (
    <div key={field.key} className={`w-100 px-2 ${noLabel ? '' : 'my-3'}`}>
      <TextField
        className={`capture-click w-100 ${noLabel ? 'no-label' : ''}`}
        variant="standard"
        value={formattedValue}
        label={noLabel ? undefined : t(field.label)}
        error={error}
        helperText={helperText ? t(helperText) : undefined}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          readOnly: true,
          className: 'input',
          disableUnderline: true,
        }}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  )
}
