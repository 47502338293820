enum ObjectURI {
  ElectricalElements = 'electrical-elements',
  ElectricalStation = 'electrical-stations',
  ElectricalProtectionGroups = 'electrical-protection-groups',
  Feeders = 'feeders',
  Isolators = 'isolators',
  SubSectors = 'sub-sectors',
  Sectors = 'sectors',
  Signals = 'signals',
  SubAreas = 'sub-areas',
  TIS = 'track-identification-systems',
  TrackProtectionGroups = 'track-protection-groups',
  TrackProtections = 'track-protections',
  TrackSections = 'track-sections',
  Tracks = 'tracks',
  TrackNode = 'track-nodes',
  Tunnel = 'tunnels',
  Zoes = 'zoes'
}

export const PROTECTIONS_SEARCH_URI = 'protection-search'

export const OBJECTS_SEARCH_URI = 'main-representation-search'

export const MIDI_URI = 'midi-ve'

export const INSTRUCTIONS_SEARCH_URI = 'instruction-search'

export const RESET_GAIA_URI = 'reset-gaia-id'

export default ObjectURI
