import { useTranslation } from '@osrdata/app_core/dist/translation'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'

import { RootState } from 'Store'
import CaptureAndSearch from 'components/Common/CaptureAndSearch/CaptureAndSearch'
import { onCaptureS11Object } from 'components/Map/onFeatureClick/helpers'
import 'components/ObjectItem/ObjectItem.scss'
import ObjectTile from 'components/ObjectItem/ObjectTile'
import { getSubText } from 'components/ObjectItem/utils'
import { canEditObject } from 'components/Panels/DetailsPanel/utils'
import PanelNavigator from 'components/Panels/PanelNavigator'
import { CaptureMapClickParams } from 'objects/attributes'
import DeleteButton from 'objects/common/Extremities/DeleteButton'
import { toggleOpenCollapsible } from 'objects/common/utils'
import { KIND_TO_LAYER, findObjectKind } from 'objects/kind'
import { ShortMidiObject } from 'objects/types'
import { ITEM_TYPES, ObjectLayer } from 'objects/types/const'
import { EPGComposition, ElectricalElement } from 'objects/types/protections'
import { ProtectionChild } from 'objects/types/protections/const'
import { setMessage } from 'reducers/feedback'
import { disableGeoEditor, hideGeoEditor } from 'reducers/geoEditor'
import {
  MapState,
  addLayer, setCaptureClick, setLayers,
} from 'reducers/map'
import { DetailsPanelState, shouldRefresh, updateItem } from 'reducers/panels/detailsPanel'
import { PanelName } from 'reducers/panels/panel'
import { CollapsibleMenu } from 'reducers/types'
import ElectricalProtectionGroupServices from '../ElectricalProtectionGroupServices'
import CompositionContextMenu from './CompositionContextMenu'

type Props = {
  electricalElements: EPGComposition[];
  editable: boolean;
};

const captureClickParams: CaptureMapClickParams[] = [{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.Sector,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.SubSector,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.ElectricalElement,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.Feeder,
},
{
  updateParams: [
    {
      clickedObjectPath: 'id',
      path: ['composition'],
      addElement: true,
    },
  ],
  objectLayer: ObjectLayer.ElectricalProtectionGroup,
},
]

export default function Composition({
  electricalElements, editable,
}: Props): ReactElement | null {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isCapturingClick, setIsCapturingClick] = useState(false)
  const { layers } = useSelector((state: RootState): MapState => state.map)
  const { openMenus, displayHistory } = useSelector((state: RootState): DetailsPanelState => state.detailsPanel)
  const [layersMemory, setMemory] = useState(layers)

  useEffect(() => {
    if (isCapturingClick) {
      setIsCapturingClick(false)
      dispatch(setLayers(layersMemory))
    }
  }, [electricalElements])

  const onClick = () => {
    if (!isCapturingClick) {
      setIsCapturingClick(true)
      dispatch(hideGeoEditor())
      dispatch(setCaptureClick(captureClickParams))
      setMemory(layers)
      dispatch(setLayers([ObjectLayer.TrackSection, ...captureClickParams.map(obj => obj.objectLayer)]))
      dispatch(
        setMessage(
          'Modification.helperText.electricalProtectionGroupCaptureClick',
        ),
      )
    } else {
      setIsCapturingClick(false)
      dispatch(setLayers(layersMemory))
      dispatch(setCaptureClick(undefined))
    }
  }

  const onTileClick = (ee: EPGComposition) => {
    if (displayHistory) {
      return
    }
    const objectKind = findObjectKind(ee as unknown as ShortMidiObject)
    dispatch(addLayer(KIND_TO_LAYER[objectKind]))
    dispatch(disableGeoEditor())
    dispatch(updateItem(ee as unknown as ShortMidiObject))
    dispatch(shouldRefresh(true))
    PanelNavigator.push(PanelName.details)
  }

  const formatActivityDates = (obj: EPGComposition) => `${(
    obj.activityStartDate && new Date(obj.activityStartDate).toLocaleDateString('fr-FR')) || '/'} 
    -
     ${(obj.activityEndDate && new Date(obj.activityEndDate).toLocaleDateString('fr-FR')) || '/'}`

  return (
    electricalElements ? (
      <Accordion
        disableGutters
        expanded={openMenus.includes(CollapsibleMenu.composition)}
        onChange={() => toggleOpenCollapsible(CollapsibleMenu.composition)}
      >
        <AccordionSummary expandIcon={<KeyboardArrowDownIcon />}>
          {`${t('Attributes.ElectricalProtectionGroup.composition')} (${
            electricalElements && electricalElements.length
          })`}
        </AccordionSummary>
        <AccordionDetails>
          {electricalElements
          && electricalElements.map(ee => (
            <ObjectTile
              key={ee.id}
              onClick={() => onTileClick(ee)}
              title={ee.mainRepresentation || ee.label}
              subtitle={t(getSubText(ee as unknown as ShortMidiObject))}
              activityDates={formatActivityDates(ee)}
              contextMenu={canEditObject() && editable ? <CompositionContextMenu ee={ee} /> : <></>}
            />
          ))}
          {canEditObject() && editable && (
          <div className="protection-buttons my-2 capture-button">
            <CaptureAndSearch
              onClick={onClick}
              filteredTypes={[ITEM_TYPES.feeder, ITEM_TYPES.electricalelement,
                ITEM_TYPES.electricalprotectiongroup, ITEM_TYPES.subsector, ITEM_TYPES.sector]}
              onOptionSelect={elem => onCaptureS11Object(elem as ElectricalElement)}
              title={t('Details.button.addElectricalProtectionToGroup')}
              protections={electricalElements}
            />
            {!!electricalElements.length && (
            <DeleteButton
              deleteAction={ElectricalProtectionGroupServices.deleteElements}
              field={ProtectionChild.composition}
            />
            )}

          </div>
          )}
        </AccordionDetails>
      </Accordion>
    ) : <></>
  )
}
