import {
  createSlice,
  isAnyOf, PayloadAction,
} from '@reduxjs/toolkit'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import { createFulfilledDisplayedObjMatcher } from 'reducers/matchers/createMatchers'
import { updatePanelObjFulfilledMatcher } from 'reducers/matchers/update'
import { validationFulfilledMatcher } from 'reducers/matchers/validationMatchers'

export enum PanelName {
  list = 'list',
  modification = 'modification',
  creation = 'creation',
  details = 'details',
}

export interface PanelState {
  panelHistory: PanelName[];
}

const initialState: PanelState = {
  panelHistory: [PanelName.list],
}

const goBackLocal = (state: PanelState) => {
  state.panelHistory.pop()
}

export const panelSlice = createSlice({
  name: 'panel',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<PanelName>) => {
      state.panelHistory.push(action.payload)
    },
    goBack: goBackLocal,
    replace: (state, action: PayloadAction<PanelName>) => {
      state.panelHistory.pop()
      state.panelHistory.push(action.payload)
    },
    setHistory: (state, action: PayloadAction<PanelName[]>) => {
      state.panelHistory = action.payload
    },
    reset: () => initialState,
  },
  extraReducers: builder => {
    // After modification
    builder.addMatcher(isAnyOf(
      validationFulfilledMatcher,
    ), goBackLocal)

    builder.addMatcher(updatePanelObjFulfilledMatcher, state => {
      if (state.panelHistory[state.panelHistory.length - 1] === PanelName.modification) {
        goBackLocal(state)
      }
    })
    // After creation
    builder.addMatcher(isAnyOf(
      createFulfilledDisplayedObjMatcher,
      TrackProtectionServices.addExtremity.fulfilled,
      TrackProtectionGroupServices.addExtremity.fulfilled,
      ElectricalElementServices.addExtremity.fulfilled,
      FeederServices.addExtremity.fulfilled,
      ElectricalProtectionGroupServices.addExtremity.fulfilled,
      SectorServices.addExtremity.fulfilled,
      SubSectorServices.addExtremity.fulfilled,
    ), state => {
      state.panelHistory.pop()
      const { length } = state.panelHistory
      if (state.panelHistory[length - 1] === PanelName.modification) {
        state.panelHistory.pop()
      }
      if (!state.panelHistory.includes(PanelName.details)) {
        state.panelHistory.push(PanelName.details)
      }
    })
  },
})

export const {
  push, goBack, replace, setHistory: setPanelHistory, reset: resetPanel,
} = panelSlice.actions

export default panelSlice.reducer
