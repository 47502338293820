import { isAnyOf } from '@reduxjs/toolkit'
import ElectricalElementServices from 'objects/ElectricalElements/ElectricalElementServices'
import ElectricalProtectionGroupServices from 'objects/ElectricalProtectionGroups/ElectricalProtectionGroupServices'
import FeederServices from 'objects/Feeders/FeederServices'
import IsolatorServices from 'objects/Isolators/IsolatorServices'
import SectorServices from 'objects/Sectors/SectorServices'
import SignalServices from 'objects/Signals/SignalServices'
import SubSectorServices from 'objects/SubSectors/SubSectorServices'
import TrackProtectionGroupServices from 'objects/TrackProtectionGroups/TrackProtectionGroupServices'
import TrackProtectionServices from 'objects/TrackProtections/TrackProtectionServices'
import TrackSectionServices from 'objects/TrackSections/TrackSectionServices'

export const validationErrorMatcher = isAnyOf(
  TrackSectionServices.validate.rejected,
  IsolatorServices.validate.rejected,
  SignalServices.validate.rejected,
  TrackProtectionServices.validate.rejected,
  TrackProtectionGroupServices.validate.rejected,
  SectorServices.validate.rejected,
  SubSectorServices.validate.rejected,
  FeederServices.validate.rejected,
  ElectricalElementServices.validate.rejected,
  ElectricalProtectionGroupServices.validate.rejected,
)

export const validationFulfilledMatcher = isAnyOf(
  TrackSectionServices.validate.fulfilled,
  IsolatorServices.validate.fulfilled,
  SignalServices.validate.fulfilled,
  TrackProtectionServices.validate.fulfilled,
  TrackProtectionGroupServices.validate.fulfilled,
  SectorServices.validate.fulfilled,
  SubSectorServices.validate.fulfilled,
  FeederServices.validate.fulfilled,
  ElectricalElementServices.validate.fulfilled,
  ElectricalProtectionGroupServices.validate.fulfilled,
)

export const validationPendingMatcher = isAnyOf(
  TrackSectionServices.validate.pending,
  IsolatorServices.validate.pending,
  SignalServices.validate.pending,
  TrackProtectionServices.validate.pending,
  TrackProtectionGroupServices.validate.pending,
  SectorServices.validate.pending,
  SubSectorServices.validate.pending,
  FeederServices.validate.pending,
  ElectricalElementServices.validate.pending,
  ElectricalProtectionGroupServices.validate.pending,
)
