import { RootState } from 'Store'
import { Instruction } from 'objects/types/instructions'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminState } from 'reducers/admin'
import { InstructionState } from 'reducers/instruction'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'
import { User as CerbereUser } from 'services/cerbereTypes'
import UserMultiSelect from '../common/UserMultiSelect'

import {
  getAdministrators, getManagers, getOperators, getRoots, getSupervisors,
} from '../utils'
import './Attributions.scss'

export const getInitialSelectedUsers = (instruction: Partial<Instruction>, users: CerbereUser[],
  previousUsers: CerbereUser[]): CerbereUser[] => {
  try {
    if (instruction.users !== undefined) {
      const res = users.filter(user => instruction.users?.map(u => u.sub).includes(user.id)).concat(previousUsers)
      const list = [...new Map(res.map(v => [v.id, v])).values()]
      return list
    }
    return []
  } catch (e) {
    return []
  }
}

export default function Attributions(): ReactElement {
  const dispatch = useDispatch()
  const {
    administrators, operators, supervisors, managers, instruction, roots,
  } = useSelector(
    (state: RootState) => state.instruction,
  ) as InstructionState

  const [selectedUsers, setSelectedUsers] = useState<CerbereUser[]>([])
  const { groups } = useSelector((state: RootState) => state.admin) as AdminState

  useEffect(() => {
    dispatch(UserServices.getGroups())
  }, [])

  useEffect(() => {
    getOperators(groups)
    getSupervisors(groups)
    getManagers(groups)
    getAdministrators(groups)
    getRoots(groups)
  }, [groups])

  useEffect(() => {
    setSelectedUsers(previousUsers => [...getInitialSelectedUsers(instruction,
      [...roots, ...administrators, ...supervisors, ...managers, ...operators],
      previousUsers)])
  }, [roots, administrators, operators, supervisors])

  useEffect(() => {
    setSelectedUsers([...getInitialSelectedUsers(instruction,
      [...roots, ...administrators, ...supervisors, ...managers, ...operators], [])])
  }, [instruction.users])

  return (
    <div id="users-attributions" className="d-flex align-items-center flex-column">
      <div className="w-75">
        <div className="my-5">
          <UserMultiSelect
            options={([...new Map([...roots, ...administrators, ...supervisors, ...managers, ...operators]
              .map(v => [v.id, v])).values()].filter(user => !selectedUsers
              .some((u: CerbereUser) => u.id === user.id)))}
            label="Instruction.creation.userAssignation.userSelect.label"
            placeholder="Instruction.creation.userAssignation.userSelect.placeholder"
            onChange={users => {
              dispatch(InstructionServices.addUsers({
                ...instruction,
                users: [
                  ...users.map((o:CerbereUser) => o.id),
                ],
              }))
            }}
            defaultValue={selectedUsers}
          />

        </div>
      </div>
    </div>
  )
}
