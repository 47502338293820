import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import LinkIcon from '@mui/icons-material/Link'
import { Tooltip } from '@mui/material'
import { RootState } from 'Store'
import { ReactComponent as GeoEditorIcon } from 'assets/icons/geoeditor.svg'
import ConfirmationDialog from 'components/Common/ConfirmationDialog/ConfirmationDialog'
import { switchToGeoEditor } from 'components/GeoEditor/utils'
import getCSSValue from 'helpers'
import { DeleteServiceOfKind } from 'objects/services'
import { ObjectKind } from 'objects/types/const'
import { useParams } from 'react-router-dom'
import { RouteParams } from 'types'
import DisabledToolTip from 'components/Common/DisabledTooltip/DisabledTooltip'
import { canEditObject } from './utils'

type Props = {
  kind: ObjectKind;
}

export default function DetailsPanelHeader({ kind }: Props): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { item } = useSelector((state: RootState) => state.detailsPanel)
  const [isDeleting, setDelete] = useState(false)
  const toggleDeletePopup = () => setDelete(!isDeleting)
  const params = useParams<RouteParams>()
  const [copied, setCopied] = useState(false)

  const deleteObject = () => {
    dispatch(DeleteServiceOfKind[kind](item.id))
  }

  const copyToClipboard = () => () => {
    setCopied(true)
    const url = params.id && params.type ? window.location.href
      : `${window.location.href}/${kind.toLowerCase()}/${item.id}`
    navigator.clipboard.writeText(url)
  }

  return (
    <>
      <Divider orientation="vertical" className="divider mr-3" />
      {canEditObject() && (
      <DisabledToolTip
        title={item.inInstruction ? t('Details.button.deleteObject') : t('Modification.notAllowed')}
        disabled={false}
      >
        <IconButton className="deletion" disabled={item.deletedAt || !item.inInstruction} onClick={toggleDeletePopup}>
          <DeleteOutlinedIcon htmlColor={getCSSValue('--color-carbone')} />
        </IconButton>
      </DisabledToolTip>
      )}

      <ConfirmationDialog
        title={t('Details.title.validateDeletion')}
        content={t('Details.content.validateDeletion')}
        toggle={toggleDeletePopup}
        open={isDeleting}
        actionToConfirm={deleteObject}
      />
      {kind === ObjectKind.TrackSection && canEditObject() && (
      <DisabledToolTip
        title={item.inInstruction ? t('Details.button.editGeometry') : t('Modification.notAllowed')}
        disabled={false}
      >
        <IconButton className="deletion" disabled={!item.inInstruction} onClick={() => switchToGeoEditor(item.id)}>
          <GeoEditorIcon
            fill={getCSSValue('--color-carbone')}
            height={24}
            width={24}
            viewBox="-3 -3 24 24"
          />
        </IconButton>
      </DisabledToolTip>
      )}
      {false && (
      <Tooltip
        onClose={() => setCopied(false)}
        title={copied ? t('Details.button.copied') : t('Details.button.copyLink')}
      >
        <IconButton className="deletion" onClick={copyToClipboard()}>
          <LinkIcon htmlColor={getCSSValue('--color-carbone')} />
        </IconButton>
      </Tooltip>
      )}

    </>
  )
}
