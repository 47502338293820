import { ReactElement } from 'react'

import Divider from '@mui/material/Divider'

import Attributes from 'components/Panels/DetailsPanel/Attributes'
import AssociatedSubAreas from 'objects/common/AssociatedSubAreas/AssociatedSubAreas'
import LinkedProtections from 'objects/common/LinkedProtections/LinkedProtections'
import ResponsibleSubAreas from 'objects/common/ResponsibleSubAreas/ResponsibleSubAreas'
import {
  AttributesProps, ExtraKind, ITEM_TYPES,
} from 'objects/types/const'
import { Sector } from 'objects/types/protections'

import Extremities from 'objects/common/Extremities/Extremities'
import Instructions from 'objects/common/Instructions/Instructions'
import { ProtectionChild } from 'objects/types/protections/const'
import { CollapsibleMenu } from 'reducers/types'
import IncompatibleProtections from 'objects/common/IncompatibleProtections/IncompatibleProtections'
import SECTOR_ATTRIBUTES from './SectorAttributes'
import SectorServices from './SectorServices'
import { captureClickParams } from './const'

export default function SectorDetails({ object }: AttributesProps): ReactElement | null {
  const sector = object as Sector
  const {
    incompatibleProtections, associatedSubAreas, responsibleSubAreas, composition,
  } = sector
  const editable = sector.inInstruction

  return (
    <div id="sectors-details" className="object-attributes">
      <Attributes item={sector} attributes={SECTOR_ATTRIBUTES(sector)} />
      <Divider className="my-2" />
      <div id="linked-objects">
        <Extremities
          editable={editable}
          type={ExtraKind.SectorExtremity}
          extremities={sector.extremities}
          deleteExtremity={SectorServices.deleteExtremity}
          deleteExtremities={SectorServices.deleteElements}
          switchOrientation={
            SectorServices.switchExtremityDirection
          }
        />
        <LinkedProtections
          editable={editable}
          linkedProtections={composition}
          fieldType={ProtectionChild.composition}
          deleteAll={SectorServices.deleteElements}
          deleteAction={SectorServices.deleteChild}
          addIPAction={SectorServices.addChild}
          params={{
            title: 'Attributes.Protection.children',
            searchPlaceholder: 'Details.searchPlaceholder.electricalProtections',
            buttonLabel: 'Details.button.addElectricalProtection',
            filteredTypes: [ITEM_TYPES.feeder, ITEM_TYPES.electricalelement,
              ITEM_TYPES.electricalprotectiongroup, ITEM_TYPES.subsector, ITEM_TYPES.sector],
          }}
          type={CollapsibleMenu.children}
          captureClickParams={captureClickParams}
          buttonTitle="Details.button.addElectricalProtection"
        />
        <ResponsibleSubAreas
          editable={editable}
          responsibleSubAreas={responsibleSubAreas}
          deleteRSAAction={SectorServices.deleteResponsibleSubArea}
          addRSAAction={SectorServices.addResponsibleSubArea}
          deleteAll={SectorServices.deleteElements}
        />
        <AssociatedSubAreas
          editable={editable}
          associatedSubAreas={associatedSubAreas}
          deleteASAAction={SectorServices.deleteAssociatedSubArea}
          addASAAction={SectorServices.addAssociatedSubArea}
          deleteAll={SectorServices.deleteElements}
        />
        <IncompatibleProtections
          editable={editable}
          fieldType={ProtectionChild.incompatibleProtections}
          linkedProtections={incompatibleProtections}
          deleteAction={SectorServices.deleteIncompatibleObject}
          addIPAction={SectorServices.addIncompatibleObject}
          type={CollapsibleMenu.incompatibleObjects}
          deleteAll={SectorServices.deleteElements}
        />
        <Instructions item={sector} />
      </div>
    </div>
  )
}
