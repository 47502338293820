import { ReactElement } from 'react'

import { User } from 'objects/types/instructions'
import { User as CerbereUser } from 'services/cerbereTypes'

import './UserBadges.scss'

type Props = {
  userIds: User[];
  usersInfo: CerbereUser[];
}

export default function UserBadges({
  userIds, usersInfo,
}: Props): ReactElement {
  return (
    <div className="user-badges d-flex justify-content-start align-items-center">
      {userIds
        .map(u => u.sub)
        .map(id => {
          const user = usersInfo.find(u => u.id === id)
          if (user) {
            return (
              <div
                key={user.id}
                className="initials-wrapper d-flex justify-content-center align-items-center mr-1"
                style={{
                  borderRadius: '20px', height: '32px', width: '32px', backgroundColor: 'var(--color-lighter-grey)',
                }}
              >
                <div className="initials">
                  {user.firstName?.charAt(0)}
                  {user.lastName?.charAt(0)}
                </div>
              </div>
            )
          }
          return null
        })}
    </div>
  )
}
