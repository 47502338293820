import {
  MenuItem, Select, SelectChangeEvent,
} from '@mui/material'
import { RootState } from 'Store'
import { loggedAsAdmin } from 'helpers/permissions'
import { Region } from 'objects/types'
import { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetOpenInstruction } from 'reducers/admin'
import { setPageNumber } from 'reducers/instruction'
import { resetOperator } from 'reducers/operator'
import { setSelectedRegion } from 'reducers/regions'
import InstructionServices from 'services/InstructionServices'
import RegionServices from 'services/RegionServices'
import './RegionSelect.scss'

const ALL_REGIONS = {
  id: 0,
  label: 'Toutes les régions',
  trigram: 'all',
}

export default function RegionSelect(): ReactElement {
  const dispatch = useDispatch()
  const { regions, selectedRegion } = useSelector((state: RootState) => state.regions)
  const { activeTab: operatorTab } = useSelector((state: RootState) => state.operator)
  const { activeTab: adminTab } = useSelector((state: RootState) => state.admin)

  const activeTab = loggedAsAdmin() ? adminTab : operatorTab

  useEffect(() => {
    dispatch(RegionServices.getAll())
  }, [])

  const isHomescreen = () => activeTab === 0

  const handleRegionChange = (event: SelectChangeEvent<string>) => {
    dispatch(setSelectedRegion(event.target.value))
    dispatch(setPageNumber(1))
    dispatch(resetOpenInstruction())
    dispatch(resetOperator())
    dispatch(InstructionServices.getAll({ region: event.target.value, page: 1 }))
  }

  return (
    regions && isHomescreen() ? (
      <Select
        className="region-select"
        value={selectedRegion}
        onChange={handleRegionChange}
        variant="standard"
        disableUnderline
      >
        {regions?.concat(ALL_REGIONS).map((region: Region) => (

          <MenuItem
            key={region.id}
            value={region.trigram}
          >
            {region.label}

          </MenuItem>
        ))}
      </Select>
    ) : <></>

  )
}
