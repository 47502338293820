import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { Instruction } from 'objects/types/instructions'
import { GroupListItem, User } from 'services/cerbereTypes'
import InstructionServices from 'services/InstructionServices'
import UserServices from 'services/UserServices'

export interface OperatorState {
  activeTab: number;
  loading: boolean;
  instructionsList: Instruction[];
  openInstructionsList: Instruction[];
  groups: GroupListItem[];
  usersInfo: User[];
  validatingInstruction: string;
  displayParameters: boolean;
}

const initialState: OperatorState = {
  activeTab: 0,
  loading: false,
  instructionsList: [],
  openInstructionsList: [],
  groups: [],
  usersInfo: [],
  validatingInstruction: '',
  displayParameters: false,
}

const openInstructionLocal = (state: OperatorState, action: PayloadAction<Instruction>) => {
  state.loading = false
  if (state.openInstructionsList.length === 0) {
    state.openInstructionsList.push(action.payload)
  } else {
    state.openInstructionsList = [action.payload]
  }

  if (!state.displayParameters) {
    state.activeTab = 1
  }
}

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload
    },
    openInstruction: openInstructionLocal,
    closeInstruction: (state, action: PayloadAction<string>) => {
      const deletedInstIndex = state.openInstructionsList.map(inst => inst.id).indexOf(action.payload)
      if (deletedInstIndex + 1 === state.activeTab && deletedInstIndex === state.openInstructionsList.length - 1) {
        state.activeTab -= 1
      }
      state.openInstructionsList = state.openInstructionsList.filter(inst => inst.id !== action.payload)
    },
    reset: () => initialState,
    resetInstructions: state => {
      state.instructionsList = []
    },
    setValidation: (state, action: PayloadAction<string>) => {
      state.validatingInstruction = action.payload
    },
    displayParameters: state => {
      state.displayParameters = !state.displayParameters
    },
  },
  extraReducers: builder => {
    builder.addCase(InstructionServices.getAll.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = [...state.instructionsList, ...action.payload.results]
    })
    builder.addCase(UserServices.getGroups.fulfilled, (state, action) => {
      state.groups = action.payload
    })
    builder.addCase(UserServices.getUsersListInfo.fulfilled, (state, action) => {
      state.usersInfo.push(...action.payload)
    })
    builder.addCase(InstructionServices.getDetails.fulfilled, openInstructionLocal)
    builder.addCase(InstructionServices.delete.fulfilled, (state, action) => {
      state.loading = false
      state.instructionsList = state.instructionsList.filter(instruction => instruction.id !== action.meta.arg)
      state.openInstructionsList = state.openInstructionsList.filter(instruction => instruction.id !== action.meta.arg)
    })
    builder.addCase(InstructionServices.getObjectInstructions.fulfilled, (state, { payload }) => {
      state.loading = false
      state.openInstructionsList = payload.length ? [payload[0]] : []
      state.activeTab = 1
    })
    builder.addMatcher(isAnyOf(
      InstructionServices.getAll.pending, InstructionServices.delete.pending, InstructionServices.getDetails.pending,
    ), state => {
      state.loading = true
    })
  },
})

export const {
  openInstruction,
  closeInstruction,
  setActiveTab,
  reset: resetOperator,
  resetInstructions,
  setValidation,
  displayParameters,
} = operatorSlice.actions

export default operatorSlice.reducer
