import { ReactElement } from 'react'

import { InputDataType } from '../utils'
import RawTextField from './RawTextField'

export default function CustomTextField({
  field, value, onChange, error, helperText, disabled, noLabel, multiline, inputProps, maxRows,
}: InputDataType): ReactElement {
  return (
    <div key={field.key} className={`w-100 px-2 text-field ${noLabel ? 'no-label' : 'my-3'}`}>
      <RawTextField
        value={value}
        label={noLabel ? undefined : field.label}
        onChange={e => onChange(e.target.value, field)}
        error={error}
        helperText={helperText}
        disabled={disabled}
        multiline={multiline}
        inputProps={inputProps}
        maxRows={maxRows}
      />
    </div>
  )
}
