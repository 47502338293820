import getCSSValue from 'helpers'
import { LayerProperties } from 'objects/types/common'
import { ObjectLayer } from 'objects/types/const'
import { AnyPaint } from 'mapbox-gl'

const textColor = '--color-medium-grey'

const layout: mapboxgl.AnyLayout = {
  'text-font': ['Open Sans Regular'],
  'symbol-placement': 'line',
  'text-size': 12,
  'text-allow-overlap': false,
}

const paint: AnyPaint = {
  'text-color': getCSSValue(textColor),
  'text-halo-width': 2,
  'text-halo-color': 'rgba(255,255,255,0.75)',
  'text-halo-blur': 1,
}

const trackSectionsPaint: LayerProperties = {
  lineCode: {
    id: `${ObjectLayer.TrackSection}-line-code`,
    type: 'symbol',
    layout: { ...layout, 'text-field': '{lineCode}' },
    paint,
  },
  trackCode: {
    id: `${ObjectLayer.TrackSection}-track-code`,
    type: 'symbol',
    layout: { ...layout, 'text-field': '{trackName}', 'text-offset': [3, 0] },
    paint: {
      'text-color': 'black',
      'text-halo-width': 4,
      'text-halo-color': 'rgba(255,255,255,0.75)',
      'text-halo-blur': 1,
    },
  },
}

export default trackSectionsPaint
