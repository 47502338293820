import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from '@osrdata/app_core/dist/translation'

import {
  CreationPanelState, resetButtonStatus, resetCreation, setButtonStatus, setCreationError,
  setErrorByKey,
  setNewObjectField, updateNewObject,
} from 'reducers/panels/creationPanel'
import { disableGeoEditor } from 'reducers/geoEditor'
import { RootState, store } from 'Store'

import TrackSectionCreationSnackBar from 'objects/TrackSections/TrackSectionCreationSnackBar'
import ActionButton from 'components/Common/ActionButton/ActionButton'
import { AttributesOfKind, CreateServiceOfKind } from 'objects/services'
import ButtonStatus from 'components/Common/buttonStatus'
import ErrorOverlay from 'components/Common/ErrorOverlay'
import { InstructionState } from 'reducers/instruction'
import { FieldValue } from 'reducers/types'
import { MapState } from 'reducers/map'
import { parseStringFieldValue } from 'objects/utils'
import { ObjectKind } from 'objects/types/const'
import { Attribute } from 'objects/attributes'

import { buildItem, getCreationButtonText, TitleOfKind } from './utils'
import './CreationPanel.scss'
import PanelTemplate from '../PanelTemplate/PanelTemplate'
import PanelFieldsContent from '../PanelFieldsContent'
import PanelNavigator from '../PanelNavigator'

export default function CreationPanel(): ReactElement | null {
  const {
    newObjectKind, buttonStatus, newObject, error: responseError, initialValues, hasError, isLoading, errorByKey,
  } = useSelector((state: RootState): CreationPanelState => state.creationPanel)
  const { captureClick } = useSelector((state: RootState) => state.map) as MapState
  const { instruction } = useSelector((state: RootState) => state.instruction) as InstructionState

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [displayError, setDisplayError] = useState(false)

  useEffect(() => {
    if (newObjectKind !== undefined && Object.keys(newObject).length === 0) {
      dispatch(updateNewObject(buildItem(newObjectKind)))
      if (initialValues) {
        initialValues.forEach(v => dispatch(setNewObjectField(v)))
      }
    }
  }, [newObjectKind])

  useEffect(() => {
    // if at least one field has an error, set creation Error to true
    dispatch(setCreationError(!Object.values(errorByKey).every(v => v === false)))
  }, [errorByKey])

  useEffect(() => {
    if (responseError && responseError.code !== 400) setDisplayError(true)
  }, [responseError])

  useEffect(() => {
    if (captureClick !== undefined) dispatch(setButtonStatus(ButtonStatus.Disabled))
  }, [captureClick])

  useEffect(() => () => {
    dispatch(resetCreation())
  }, [])

  const handleError = (key: string, value: boolean) => {
    dispatch(setErrorByKey({
      key, value,
    }))
  }

  if (newObjectKind === undefined) return null

  const onChange = (value: FieldValue, field: Attribute) => {
    const path = field.updatePath || field.path
    if (buttonStatus !== ButtonStatus.Base && !hasError) {
      dispatch(resetButtonStatus())
    }
    dispatch(setNewObjectField({ value: parseStringFieldValue(value), path }))
  }

  const onSave = () => {
    let formattedNewObject = newObject
    if (Object.values(ObjectKind).includes(newObjectKind as unknown as ObjectKind)) {
      formattedNewObject = {
        ...newObject,
        instruction: instruction.id,
      }
    }
    dispatch(CreateServiceOfKind[newObjectKind](formattedNewObject))
  }

  const onReturn = () => {
    dispatch(disableGeoEditor())
    dispatch(resetCreation())
    PanelNavigator.goBack()
  }

  const renderContent = () => (displayError && responseError
    ? <ErrorOverlay code={responseError.code} />
    : (
      <PanelFieldsContent
        attributes={AttributesOfKind(store)[newObjectKind].filter(att => att.creation?.required && att.isEditable)}
        handleError={handleError}
        item={newObject}
        responseError={responseError}
        onChange={onChange}
        isLoading={isLoading}
      />
    ))

  return (
    <PanelTemplate
      id="creation-panel"
      onClickReturn={onReturn}
      headerContent={(<h2 className="w-100 m-0 mt-1">{t(TitleOfKind[newObjectKind])}</h2>)}
      footerButton={displayError
        ? undefined
        : <ActionButton title={getCreationButtonText(newObjectKind)} onClick={onSave} status={buttonStatus} />}
    >
      {renderContent()}
      {newObjectKind === ObjectKind.TrackSection && <TrackSectionCreationSnackBar />}
    </PanelTemplate>
  )
}
