import { ReactElement, useState } from 'react'

import { ReactComponent as LayersIcon } from 'assets/icons/layers.svg'
import { ReactComponent as ProjectionsIcon } from 'assets/icons/projections.svg'
import { NavigationControl, _useMapControl } from 'react-map-gl'
import { MapControlProps } from 'react-map-gl/src/components/use-map-control'
import LayersMenu from './LayersMenu'
import ProjectionsMenu from './ProjectionsMenu'
import './Toolbar.scss'

enum MenuKeys {
  layers = 'layers',
  projections = 'projections',
}

const MENU_ITEMS = (toggleDisplayMenu: (k: MenuKeys) => void, lightVersion: boolean) => [
  {
    key: MenuKeys.layers,
    onClick: () => toggleDisplayMenu(MenuKeys.layers),
    icon: <LayersIcon />,
    display: !lightVersion,
  },
  {
    key: MenuKeys.projections,
    onClick: () => toggleDisplayMenu(MenuKeys.projections),
    icon: <ProjectionsIcon width={20} height={20} />,
    display: true,
  },
]

type Props = MapControlProps & {
  disableScroll: (b: boolean) => void;
  disableHover?: (b: boolean) => void;
  light?: boolean;
} & typeof defaultProps

const defaultProps = {
  light: false,
  disableHover: (_b: boolean) => {},
}

export default function Toolbar({
  disableHover, disableScroll, light, ...props
}: Props): ReactElement {
  const [displayLayers, setDisplayLayers] = useState(false)
  const [displayProjections, setDisplayProjections] = useState(false)

  const mapRef = _useMapControl(props)
  const { context } = mapRef

  const toggleDisplayMenu = (key: MenuKeys) => {
    switch (key) {
      case MenuKeys.layers:
        setDisplayLayers(!displayLayers)
        setDisplayProjections(false)
        break
      case MenuKeys.projections:
        setDisplayProjections(!displayProjections)
        setDisplayLayers(false)
        break
      default:
        break
    }
  }

  const captureClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    context.eventManager.on(
      'click',
      (e: MouseEvent) => e.stopPropagation(),
      evt.target,
    )
  }

  return (
    <span
      onMouseEnter={() => {
        disableScroll(true)
        disableHover(true)
      }}
      onMouseLeave={() => {
        disableScroll(false)
        disableHover(false)
      }}
    >
      <div
        id="map-menu"
        className={`
          d-flex justify-content-center align-items-center flex-column ${
            displayLayers || displayProjections ? 'display-menu' : ''
          }
        `}
      >
        {MENU_ITEMS(toggleDisplayMenu, light).filter(i => i.display).map(item => (
          <>
            <div
              className="icon-wrapper"
              key={item.key}
              role="button"
              tabIndex={-1}
              onClick={item.onClick}
              onClickCapture={e => captureClick(e)}
            >
              {item.icon}
            </div>
          </>
        ))}
        <NavigationControl {...props} className="navigation-control" />
      </div>
      <LayersMenu display={displayLayers} captureClick={captureClick} />
      <ProjectionsMenu display={displayProjections} captureClick={captureClick} />
    </span>
  )
}

Toolbar.defaultProps = defaultProps
