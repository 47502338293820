import { ObjectLayer } from 'objects/types/const'
import { EPGFilter, formatLayerUrl } from 'objects/utils'
import { ReactElement, useEffect, useState } from 'react'
import { Layer, Source } from 'react-map-gl'

import { MapIconNames } from 'components/Map/icons'
import { MidiObject } from 'objects/types'

type Props = {
  visible: boolean;
  item: MidiObject;
}

const sourceLayer = ObjectLayer.ElectricalProtectionGroup

export default function ElectricalProtectionGroupPoint({ visible, item }: Props): ReactElement {
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (item) setSelectedId(item.id)
    else setSelectedId(undefined)
  }, [item])

  const isSelected = selectedId
    ? ['==', ['get', 'id'], selectedId]
    : false

  return (
    <Source
      type="vector"
      url={formatLayerUrl(sourceLayer)}
      source-layer={sourceLayer}
      id={`${sourceLayer}-point`}
    >
      <Layer
        id={`${sourceLayer}-point-layer`}
        type="symbol"
        layout={{
          'icon-size': ['step', ['zoom'],
            0, 11,
            0.2,
          ],
          'icon-image': ['case', isSelected, MapIconNames.feederSelect, MapIconNames.feeder],
          visibility: visible ? 'visible' : 'none',
          'icon-allow-overlap': true,
        }}
        paint={{}}
        source-layer={sourceLayer}
        minzoom={12}
        filter={EPGFilter()}
      />
    </Source>
  )
}
